import { useMediaQuery } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ChangeIcons from './change-icons';
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import { DesktopProvider } from './contexts/DesktopProvider';
import { RightSidebarProvider } from './layouts/right-side-bar/rightSidebarProvider';
import MainComponent from './MainComponent';
import { SnackbarProvider } from 'notistack';
import OutComponent from './out-component';
import InitialSetup from './pages/common/initial-setup.component';
import HttpServices from './services/httpService';
import { userModuleURL } from './services/urlService';
import GlobalStyles from './theme/globalStyles';
import { ThemeProvider } from './theme/theme-provider';
import { CommonHelper } from './utils/commonHelper';
import Spinner from './utils/Loader/spinner';
import { LocalStorage } from './utils/storage/local-storage';
import { ToasterProvider } from './utils/toaster/toasterContext';
import { ReAuthProvider } from './utils/reAuth/ReAuthContext';
import AppLogo from '../src/assets/logo.png';

const InitApplication = () => {
  const customTheme = createTheme({
    breakpoints: {
      values: {
        mdCustom: 950,
      },
    },
  });

  const isDesktop = useMediaQuery(customTheme.breakpoints.up('mdCustom'));

  const isUserLoggedIn = CommonHelper.CheckUserLoggedIn();

  // State Variable
  const [appIcon, setAppIcon] = useState('');
  const [isAppFailed, setAppFailed] = useState(false);
  const [isAppLoaded, setAppLoaded] = useState(false);

  useEffect(() => {
    if (isUserLoggedIn) {
      getApplicationInit();
    } else {
      getDomainDetails();
    }
  }, []);

  const getDomainDetails = () => {
    const initProps = {
      url: userModuleURL.getDomainDetails,
      successCallback: domainSuccessCallback,
      failureCallback: domainFailureCallback,
    };
    HttpServices.Get(initProps);
  };

  const domainSuccessCallback = (data) => {
    if (!data.domain_small_icon) {
      data.domain_small_icon = AppLogo;
    }
    if (!data.domain_large_icon) {
      data.domain_large_icon = AppLogo;
    }
    let app_details = {
      primary_color: data.domain_primary_color,
      secondary_color: data.domain_secondary_color,
      small_icon: data.domain_small_icon,
      large_icon: data.domain_large_icon,
      org_name: data.org_name,
    };
    CommonHelper.ClearAppLocalStorage();
    LocalStorage.setItem('app_details', JSON.stringify(app_details));
    setAppIcon(data.domain_small_icon);
    setAppLoaded(true);
  };

  const domainFailureCallback = () => {
    localStorage.clear();
    setAppFailed(true);
  };

  const failureCallback = (message) => {
    localStorage.clear();
    setAppFailed(true);
  };

  const getApplicationInit = () => {
    CommonHelper.AppInitCall(initSuccessCallback, failureCallback);
  };

  const initSuccessCallback = (data) => {
    if (!data.org_icon_url) {
      data.org_icon_url = AppLogo;
    }
    let app_details = {
      primary_color: data.org_primary_color,
      secondary_color: data.org_secondary_color,
      small_icon: data.org_icon_url,
      large_icon: data.org_icon_url,
            currency_symbol: data.currency_symbol,
            timezone: data.timezone,
    };
    CommonHelper.ClearAppLocalStorage();
    LocalStorage.setItem('app_details', JSON.stringify(app_details));
    if (data.is_referral_user) {
      LocalStorage.setItem('is_referral_user', 'true');
    }
    setAppIcon(data.org_icon_url);
    let isSetupDone = InitialSetup(data);
    if (isSetupDone) {
      setAppLoaded(true);
    } else {
      setAppFailed(true);
    }
  };

  const handleInitCall = () => {
    setAppLoaded(false);
    getApplicationInit();
  };

  return (
    <>
      {(isAppLoaded || isAppFailed) && <ChangeIcons appIcon={appIcon} />}
      {isAppFailed ? (
        <ToasterProvider>
          <ReAuthProvider>
          <SnackbarProvider maxSnack={6}>
            <CollapseDrawerProvider>
              <BrowserRouter>
                <DesktopProvider>
                  <ThemeProvider>
                    <RightSidebarProvider>
                      <GlobalStyles />
                      <ScrollToTop />
                      {/* <StyledChart /> */}
                      <OutComponent isDesktop={isDesktop} />
                    </RightSidebarProvider>
                  </ThemeProvider>
                </DesktopProvider>
              </BrowserRouter>
            </CollapseDrawerProvider>
          </SnackbarProvider>
          </ReAuthProvider>
        </ToasterProvider>
      ) : isAppLoaded ? (
        <ToasterProvider>
          <ReAuthProvider>
          <SnackbarProvider maxSnack={6}>
            <CollapseDrawerProvider>
              <BrowserRouter>
                <DesktopProvider>
                  <ThemeProvider>
                    <RightSidebarProvider>
                      <GlobalStyles />
                      <ScrollToTop />
                      {/* <StyledChart /> */}
                      <MainComponent isDesktop={isDesktop} handleInitCall={handleInitCall} />
                    </RightSidebarProvider>
                  </ThemeProvider>
                </DesktopProvider>
              </BrowserRouter>
            </CollapseDrawerProvider>
          </SnackbarProvider>
          </ReAuthProvider>
        </ToasterProvider>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default InitApplication;
