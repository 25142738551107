import React, { useState, useRef, useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import { NavigateBefore, NavigateNext, PlayArrow } from '@mui/icons-material';
import { styled } from '@mui/system';

const CarouselContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  maxWidth: 500,
  height: '100%',
  overflow: 'hidden',
  backgroundColor: '#000',
});

const MediaWrapper = styled(Box)(({ currentIndex }) => ({
  display: 'flex',
  transform: `translateX(-${currentIndex * 100}%)`,
  transition: 'transform 0.4s ease-in-out',
  height: '100%',
}));

const MediaItem = styled(Box)({
  flex: '0 0 100%',
  height: '100%',
  position: 'relative',
});

const StyledImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const StyledVideo = styled('video')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const NavigationButton = styled(IconButton)({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  backgroundColor: '#fff',
  opacity: 0.8,
  zIndex: 2,
  width: 24,
  height: 24,
  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    backgroundColor: '#eee',
  },
});

const DotsContainer = styled(Box)({
  position: 'absolute',
  bottom: 12,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 6,
  zIndex: 2,
});

const Dot = styled('div')(({ active }) => ({
  width: 6,
  height: 6,
  borderRadius: '50%',
  backgroundColor: active ? '#fff' : 'rgba(255,255,255,0.5)',
}));

const PlayOverlay = styled(IconButton)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'rgba(0,0,0,0.6)',
  color: 'white',
  width: 60,
  height: 60,
  zIndex: 2,
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
});

const InstagramCarouselPost = (mediaFiles) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRefs = useRef([]);

  useEffect(() => {
    // Pause other videos when switching
    videoRefs.current.forEach((video, idx) => {
      if (video && idx !== currentIndex) {
        video.pause();
        video.currentTime = 0;
      }
    });
    setIsPlaying(false);
  }, [currentIndex]);

  const nextSlide = () => {
    if (currentIndex < mediaFiles.length - 1) {
      setCurrentIndex((prev) => prev + 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1);
    }
  };

  const handlePlay = (index) => {
    const video = videoRefs.current[index];
    if (video) {
      video.play();
      setIsPlaying(true);
    }
  };

  return (
    <CarouselContainer>
      <MediaWrapper currentIndex={currentIndex}>
        {mediaFiles?.map((media, index) => (
          <MediaItem key={index}>
            {media?.type === 'image' || media?.type === 'IMAGE' || media?.type === 'CAROUSEL_ALBUM' ? (
              <StyledImage src={media.url} alt={`media-${index}`} />
            ) : (
              <>
                <StyledVideo
                  ref={(el) => (videoRefs.current[index] = el)}
                  src={media.url || media.media_urls}
                  controls={isPlaying && currentIndex === index}
                  playsInline
                />
                {!isPlaying && currentIndex === index && (
                  <PlayOverlay onClick={() => handlePlay(index)}>
                    <PlayArrow fontSize="large" />
                  </PlayOverlay>
                )}
              </>
            )}
          </MediaItem>
        ))}
      </MediaWrapper>

      {/* Navigation Arrows */}
      {mediaFiles.length > 1 && (
        <>
          {currentIndex > 0 && (
            <NavigationButton onClick={prevSlide} sx={{ left: 8 }}>
              <NavigateBefore />
            </NavigationButton>
          )}

          {currentIndex < mediaFiles.length - 1 && (
            <NavigationButton onClick={nextSlide} sx={{ right: 8 }}>
              <NavigateNext />
            </NavigationButton>
          )}
        </>
      )}

      {/* Dots */}
      {mediaFiles.length > 1 && (
        <DotsContainer>
          {mediaFiles.map((_, i) => (
            <Dot key={i} active={i === currentIndex} />
          ))}
        </DotsContainer>
      )}
    </CarouselContainer>
  );
};

export default InstagramCarouselPost;
