import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TablePagination,
  TableSortLabel,
} from '@mui/material';
import { format } from 'date-fns';

const UserInformationTable = ({ userCreationData, filterDateRange }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('dateTime');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredData = Array.isArray(userCreationData)
    ? userCreationData.filter((user) => {
        if (!user || !user.created_time) return false; // Ensure user and created_time exist
        const userDate = new Date(user.created_time);
        const fromDate = filterDateRange?.fromDate ? new Date(filterDateRange.fromDate) : null;
        const toDate = filterDateRange?.toDate ? new Date(filterDateRange.toDate) : null;
        return (!fromDate || userDate >= fromDate) && (!toDate || userDate <= toDate);
      })
    : [];

  const sortedData = filteredData.sort((a, b) => {
    if (orderBy === 'org_hash') {
      return order === 'asc' ? a.org_hash.localeCompare(b.org_hash) : b.org_hash.localeCompare(a.org_hash);
    } else if (orderBy === 'dateTime') {
      const dateA = new Date(`${a.created_time} ${a.paymentTime}`);
      const dateB = new Date(`${b.created_time} ${b.paymentTime}`);
      return order === 'asc' ? dateA - dateB : dateB - dateA;
    }
    return 0;
  });

  return (
    <div>
      <Typography variant="h6" color="primary">
        User Information ({filteredData.length})
      </Typography>
      <TableContainer component={Paper} style={{ maxHeight: 300 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                sortDirection={orderBy === 'org_hash' ? order : false}
                style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}
              >
                <TableSortLabel
                  active={orderBy === 'org_hash'}
                  direction={orderBy === 'org_hash' ? order : 'asc'}
                  onClick={(event) => handleRequestSort(event, 'org_hash')}
                >
                  Org Hash
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={orderBy === 'dateTime' ? order : false}
                style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}
              >
                <TableSortLabel
                  active={orderBy === 'dateTime'}
                  direction={orderBy === 'dateTime' ? order : 'asc'}
                  onClick={(event) => handleRequestSort(event, 'dateTime')}
                >
                  Date & Time
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, index) => (
              <TableRow key={index}>
                <TableCell>{user.org_hash}</TableCell>
                <TableCell>{format(new Date(user.created_time), 'PPpp')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default UserInformationTable;
