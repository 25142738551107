import { json } from 'react-router-dom';
import { CommonHelper } from 'src/utils/commonHelper';

const requireJson = require.context('../../utils/demoUtil', true, /\.json$/);

const LoadJsonFromFile = (filename) => {
  try {
    const data = requireJson(`./${filename}`);
    return data;
  } catch (error) {
    console.error('Error loading JSON file:', error);
    throw new Error('File not found');
  }
};

const GetDemoJsonFileName = (url, body) => {
  const urlObj = new URL(url);
  let path = urlObj.pathname;
  path = path.replace('/api', '');
  var demoJsonfilename = '';
  switch (path) {
    case '/profile-master-services/private/initial-load':
      demoJsonfilename = 'profile-master.json';
      break;
    case '/integration-services/connected-profiles':
      demoJsonfilename = 'connectedprofiles/connectedprofiles.json';
      break;
    case '/integration-services/get-all-automation':
      demoJsonfilename = 'automation-post/get-all-automation.json';
      break;
    case '/integration-services/fetch-automation-post-view':
      demoJsonfilename = 'automation-post/fetch-automation-post-view.json';
      break;
    case '/integration-services/get-dashboard-details':
      demoJsonfilename = 'dashboard/dashboard.json';
      break;
    case '/notification-services/fetch-notification':
      demoJsonfilename = 'notification/notification.json';
      break;
    case '/facebook-services/get-published-posts':
      demoJsonfilename = 'publishedpost/facebook/facebook-publishedpost.json';
      break;
    case '/facebook-services/get-post-details':
      demoJsonfilename = 'publishedpost/facebook/facebook-getpostdetails.json';
      break;
    case '/instagram-services/get-published-posts':
      demoJsonfilename = 'publishedpost/instagram/instagram-publishedpost.json';
      break;
    case '/instagram-services/get-post-details':
      demoJsonfilename = 'publishedpost/instagram/instagram-getpostdetails.json';
      break;
    case '/youtube-services/get-published-posts':
      demoJsonfilename = 'publishedpost/youtube/youtube-publishedpost.json';
      break;
    case '/youtube-services/get-post-details':
      demoJsonfilename = 'publishedpost/youtube/youtube-getpostdetails.json';
      break;
    case '/linkedin-services/page/get-published-posts':
      demoJsonfilename = 'publishedpost/linkedin/linkedin-publishedpost.json';
      break;
    case '/linkedin-services/page/get-post-details':
      demoJsonfilename = 'publishedpost/linkedin/linkedin-getpostdetails.json';
      break;
    case '/pinterest-services/get-published-posts':
      demoJsonfilename = 'publishedpost/pinterest/pinterest-publishedpost.json';
      break;
    case '/pinterest-services/get-post-details':
      demoJsonfilename = 'publishedpost/pinterest/pinterest-getpostdetails.json';
      break;
    case '/tiktok-services/get-published-posts':
      demoJsonfilename = 'publishedpost/tiktok/tiktok-publishedpost.json';
      break;
    case '/tiktok-services/get-post-details':
      demoJsonfilename = 'publishedpost/tiktok/tiktok-getpostdetails.json';
      break;
    case '/gbusiness-services/get-published-posts':
      demoJsonfilename = 'publishedpost/gbusiness/gbusiness-publishedpost.json';
      break;
    case '/gbusiness-services/get-post-details':
      demoJsonfilename = 'publishedpost/gbusiness/gbusiness-getpostdetails.json';
      break;
    case '/facebook-services/get-all-messages':
      demoJsonfilename = 'socialinbox/facebook-inbox/facebook-messages.json';
      break;
    case '/facebook-services/get-conversation-message':
      demoJsonfilename = 'socialinbox/facebook-inbox/facebook-conversationmessage.json';
      break;
    case '/instagram-services/get-all-messages':
      demoJsonfilename = 'socialinbox/instagram-inbox/instagram-messages.json';
      break;
    case '/instagram-services/get-conversation-message':
      demoJsonfilename = 'socialinbox/instagram-inbox/instagram-conversationmessage.json';
      break;
    case '/gbusiness-services/get-all-review':
      demoJsonfilename = 'socialinbox/gbusiness-inbox/gbusiness-review.json';
      break;
    case '/gbusiness-services/get-all-questions':
      demoJsonfilename = 'socialinbox/gbusiness-inbox/gbusiness-questions.json';
      break;
    case '/integration-services/get-all-scheduled-reports':
      demoJsonfilename = 'scheduledreports/scheduledreport.json';
      break;
    case '/integration-services/get-share-approval-posts':
      demoJsonfilename = 'scheduled-post/get-share-approval-posts.json';
      break;
    case '/integration-services/approved-calendar-events':
      demoJsonfilename = 'scheduled-post/approved-calender.json';
      break;
    case '/integration-services/failed-calendar-events':
      demoJsonfilename = 'scheduled-post/failed-calender.json';
      break;
    case '/integration-services/scheduled-event-info':
      demoJsonfilename = 'scheduled-post/scheduled-event-info.json';
      break;
    case '/integration-services/calendar-events':
      demoJsonfilename = 'calenderevents/calender-events.json';
      break;
    case '/integration-services/get-unapproved-post':
      demoJsonfilename = 'approval/unapproval.json';
      break;
    case '/integration-services/get-rejected-post':
      demoJsonfilename = 'approval/rejected.json';
      break;
    case '/integration-services/get-elapsed-post':
      demoJsonfilename = 'approval/elapsed.json';
      break;
    case '/integration-services/get-saved-draft':
      demoJsonfilename = 'draft/saveddraft.json';
      break;
    case '/image-server/private/fetch-user-media':
      demoJsonfilename = 'media/media.json';
      break;
    case '/integration-services/get-all-reports':
      const params = new URLSearchParams(urlObj.search);
      const serviceName = params.get('service_name');
      switch (serviceName) {
        case 'facebook':
          demoJsonfilename = 'reports/get-all-reports-facebook.json';
          break;
        case 'instagram':
          demoJsonfilename = 'reports/get-all-reports-instagram.json';
          break;
        case 'youtube':
          demoJsonfilename = 'reports/get-all-reports-youtube.json';
          break;
        default:
          demoJsonfilename = 'reports/get-all-reports-facebook.json';
          break;
      }
      break;
    case '/facebook-services/get-predefined-report':
      switch (body.report_id) {
        case 124:
          demoJsonfilename = 'reports/facebook-predefined-report-aduience-summary.json';
          break;
        case 125:
          demoJsonfilename = 'reports/facebook-predefined-report-engagement-summary.json';
          break;
      }
      demoJsonfilename = 'reports/facebook-predefined-report-aduience-summary.json';
      break;
    case '/instagram-services/get-predefined-report':
      switch (body.report_id) {
        case 129:
          demoJsonfilename = 'reports/instagram-predefined-report-aduience-summary.json';
          break;
        case 130:
          demoJsonfilename = 'reports/instagram-predefined-report-engagement-summary.json';
          break;
      }
      demoJsonfilename = 'reports/instagram-predefined-report-aduience-summary.json';
      break;
    case '/youtube-services/get-predefined-report':
      switch (body.report_id) {
        case 135:
          demoJsonfilename = 'reports/youtube-predefined-report-aduience-summary.json';
          break;
        case 136:
          demoJsonfilename = 'reports/youtube-predefined-report-engagement-summary.json';
          break;
      }
      demoJsonfilename = 'reports/youtube-predefined-report-aduience-summary.json';
      break;
    case '/profile-master-services/private/fetch-user-details':
      demoJsonfilename = 'profile/fetch-user-details.json';
      break;
    case '/profile-master-services/private/fetch-users-brand':
      demoJsonfilename = 'profile/fetch-users-brand.json';
      break;
    case '/profile-master-services/private/fetch-brand-info':
      demoJsonfilename = 'brand/brand-info.json';
      break;
    case '/profile-master-services/private/fetch-all-roles':
      demoJsonfilename = 'brand/brand-all-roles.json';
      break;
    case '/profile-master-services/private/fetch-brand-users':
      demoJsonfilename = 'brand/brand-users.json';
      break;
    case '/profile-master-services/private/get-all-pending-invites':
      demoJsonfilename = 'brand/pending-invites.json';
      break;
    case '/profile-master-services/private/fetch-org-admins':
      demoJsonfilename = 'organization/org-admins.json';
      break;
    case '/profile-master-services/private/fetch-org-users':
      demoJsonfilename = 'organization/org-users.json';
      break;
    case '/profile-master-services/private/fetch-org-info':
      demoJsonfilename = 'organization/org-info.json';
      break;
    case '/payment-services/get-current-plan-details':
      demoJsonfilename = 'payment/current-plan-details.json';
      break;
    case '/payment-services/get-all-plans':
      demoJsonfilename = 'payment/get-all-plans.json';
      break;
    case '/profile-master-services/private/get-branding-details':
      demoJsonfilename = 'brand/branding-details.json';
      break;
    case '/notification-services/get-notification-settings':
      demoJsonfilename = 'notification/notification-settings.json';
      break;
    case '/integration-services/share-approval-posts':
      demoJsonfilename = 'scheduled-post/share-approval-posts.json';
      break;
    case '/pinterest-services/fetch-all-boards':
      demoJsonfilename = 'pinterest/fetch-all-boards.json';
      break;
    case '/gbusiness-services/get-phone-number':
      demoJsonfilename = 'gbusiness/get-phone-number.json';
      break;
    case '/integration-services/publish-new-post':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/profile-master-services/private/check-ai-enabled':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/profile-master-services/private/update-brand-details':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/integration-services/email-export-report':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/integration-services/create-scheduled-report':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/integration-services/edit-scheduled-report':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/integration-services/delete-scheduled-report':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/facebook-services/add-message':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/facebook-services/add-like':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/facebook-services/remove-like':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/facebook-services/add-comment':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/facebook-services/delete':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/instagram-services/add-comment':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/facebook-services/get-more-replies':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/instagram-services/add-reply-comment':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/instagram-services/delete':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/youtube-services/add-remove-like':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/youtube-services/add-comment':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/youtube-services/add-reply-comment':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/youtube-services/delete':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/linkedin-services/page/add-post-like':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/linkedin-services/page/add-comment':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/linkedin-services/page/add-comment-like':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/linkedin-services/page/add-reply-comment':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/linkedin-services/page/delete-comment':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/instagram-services/add-message':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/gbusiness-services/add-review':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/gbusiness-services/delete-review':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/integration-services/disable-scheduled-event':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/integration-services/add-comment-on-scheduled-event':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/integration-services/reschedule-new-post':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/image-server/fetch-media-metadata':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/integration-services/save-post-as-draft':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/integration-services/delete-scheduled-event':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/integration-services/unapproved-post':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/integration-services/remove-share-approval-posts':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/integration-services/retry-post':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/integration-services/approve-post':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/integration-services/reject-post':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/integration-services/delete-draft':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/image-server/upload-media':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/image-server/fetch-private-media-metadata':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/facebook-services/get-groups-and-pages':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/profile-master-services/public/get-domain-details':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/facebook-services/integrate-pages-and-groups':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/instagram-services/get-instagram-account-from-token':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/instagram-services/integrate-instagram':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/twitter-services/integrate':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/twitter-services/save-access-token':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/youtube-services/save-access-token':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/linkedin-services/profile/save-access-token':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/linkedin-services/page/get-linked-pages':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/pinterest-services/save-access-token':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/gbusiness-services/save-access-token':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/tiktok-services/save-access-token':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/integration-services/disable-accounts':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/facebook-services/update-account':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/facebook-services/re-authenticate-account':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/integration-services/disintegrate-accounts':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/instagram-services/update-account':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/instagram-services/re-authenticate-account':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/youtube-services/re-authenticate-account':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/youtube-services/update-account':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/gbusiness-services/re-authenticate-account':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/gbusiness-services/update-account':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/twitter-services/re-authenticate-account':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/twitter-services/update-account':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/linkedin-services/re-authenticate-account':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case 'api/linkedin-services/update-account':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/pinterest-services/re-authenticate-account':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/pinterest-services/update-account':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/tiktok-services/re-authenticate-account':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/tiktok-services/update-account':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/profile-master-services/private/new-role-init':
      demoJsonfilename = 'connectedprofiles/new-role-init.json';
      break;
    case '/profile-master-services/private/create-new-role':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/profile-master-services/private/send-invite-create-account':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/profile-master-services/private/update-user-role':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/profile-master-services/private/remove-user-from-org':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/profile-master-services/private/remove-user-from-brand':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/profile-master-services/private/new-user-init':
      demoJsonfilename = 'connectedprofiles/new-user-init.json';
      break;
    case '/profile-master-services/private/disable-user':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/profile-master-services/private/disable-brand':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/profile-master-services/private/delete-brand':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/profile-master-services/private/create-new-brand':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/profile-master-services/private/update-org-details':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/payment-services/change-plan':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/payment-services/change-plan-payment':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/profile-master-services/private/update-branding':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/profile-master-services/private/delete-cname':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/notification-services/save-notification-settings':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/profile-master-services/private/leave-from-org':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/profile-master-services/private/delete-brand':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/profile-master-services/private/leave-from-brand':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/profile-master-services/private/delete-me':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    case '/profile-master-services/private/delete-org':
      demoJsonfilename = 'postmethod/postmethod.json';
      break;
    default:
      demoJsonfilename = '';
      break;
  }
  if (demoJsonfilename == '') {
    return 'no preloaded data';
  }

  const params = new URLSearchParams(urlObj.search);
  const serviceName = params.get('service_name');
  var resp = {
    service_name: serviceName,
    report_id:body?.report_id,
    path: path,
  }
  return resp;
};

const IsDemoAccount = () => {
  var IsDemoAccount = CommonHelper.GetBrandInfoFromLocalStorage('is_demo_account');
  if (IsDemoAccount == true) {
    return true;
  }
  return false;
};

const GetDemoAccount = () => {
  return '347';
};


export const DemoHelper = {
  GetDemoJsonFileName,
  IsDemoAccount,
  LoadJsonFromFile,
  GetDemoAccount,
};
