import {
  Grid,
  Typography,
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Paper,
  Stack,
  useMediaQuery,
  useTheme,
  Divider,
} from '@mui/material';
import { CommonHelper } from 'src/utils/commonHelper';
import { useState } from 'react';
import { FavoriteBorderRounded as FavoriteBorderRoundedIcon, Public as PublicIcon, MoreVert as MoreVertIcon, FiberManualRecord as FiberManualRecordIcon, BookmarkBorder as BookmarkBorderIcon } from '@mui/icons-material';
import TwittercommentIcon from '../../../../assets/comment.svg';
import TwitterviewIcon from '../../../../assets/twitter-views.svg';
import TwitterRetweetIcon from '../../../../assets/twitter_retweet.svg';


const TwitterPostImagesPreview = ({ data }) => {
  let mediaFiles = CommonHelper.ProcessMediaUrl(data?.media_url)?.media_data;
  const isMultipleMedia = mediaFiles.length > 1;
  const mediaHeight = isMultipleMedia ? 150 : 200;
  const [showFullDescription, setShowFullDescription] = useState(false);
  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  const mediaStyle = { objectFit: 'cover', width: '100%', height: mediaHeight };
  const imageView = () => {
    let view = [];
    let xWidth = 6;
    let isThreeImages = false;
    const totalMedia = mediaFiles.length;
    const remainingImagesCount = totalMedia > 4 ? totalMedia - 4 : 0;

    switch (totalMedia) {
      case 1:
        xWidth = 12;
        break;
      case 2:
      case 4:
      default:
        xWidth = 6;
        break;
      case 3:
        isThreeImages = true;
        break;
    }

    const getBorderRadius = (i) => {
      const radius = '16px';
      if (totalMedia === 1) return radius;
      if (totalMedia === 2) return i === 0 ? `${radius} 0 0 ${radius}` : `0 ${radius} ${radius} 0`;
      if (totalMedia === 3) {
        if (i === 0) return `${radius} 0 0 0`;
        if (i === 1) return `0 ${radius} 0 0`;
        if (i === 2) return `0 0 ${radius} ${radius}`;
      }
      if (totalMedia >= 4) {
        if (i === 0) return `${radius} 0 0 0`;
        if (i === 1) return `0 ${radius} 0 0`;
        if (i === 2) return `0 0 0 ${radius}`;
        if (i === 3) return `0 0 ${radius} 0`;
      }
      return '0';
    };

    if (isThreeImages) {
      view = (
        <Grid container spacing={0.2} sx={{ position: 'relative' }}>
          <Grid item xs={6}>
            <img
              src={mediaFiles[0]?.url || mediaFiles[0]}
              style={{ width: '100%', height: 300, objectFit: 'cover', borderRadius: '12px 0 0 12px' }}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container direction="column" spacing={0.2}>
              {[1, 2].map((i) => (
                <Grid item xs={6} key={i}>
                  <img
                    src={mediaFiles[i]?.url || mediaFiles[i]}
                    style={{
                      width: '100%',
                      height: 150,
                      objectFit: 'cover',
                      borderRadius: i === 1 ? '0 12px 0 0' : '0 0 12px 0',
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      );
    } else {
      view = mediaFiles.slice(0, 4).map((image, i) => {
        const isImage = image?.type === 'image' || (!image.type && typeof image === 'string');
        const src = image?.url || image;

        return (
          <Grid
            item
            xs={xWidth}
            key={i}
            sx={{
              maxWidth: 500,
              maxHeight: 250,
              overflow: 'hidden',
              position: 'relative',
              borderRadius: getBorderRadius(i),
            }}
          >
            {isImage ? (
              <img src={src} alt={`media-${i}`} style={{ ...mediaStyle, height: 250, borderRadius: getBorderRadius(i) }} />
            ) : (
              <video src={src} controls style={{ ...mediaStyle, height: 250, borderRadius: getBorderRadius(i) }} />
            )}

            {i === 3 && remainingImagesCount > 0 && (
              <Typography
                variant="h2"
                color="white"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 1,
                }}
              >
                +{remainingImagesCount}
              </Typography>
            )}
          </Grid>
        );
      });
    }

    return <Grid container spacing={0.2} sx={{ position: 'relative' }}>{view}</Grid>;
  };

  const theme = useTheme();
  const respwidth = useMediaQuery(theme.breakpoints.up('xl'));
  const ConnectedProfiles = data.connected_profile_details;
  const ProfileTime = () => {
    return (
      <>
        <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ gap: '0.3rem' }}>
          <Typography variant="span">Just Now</Typography>
          <FiberManualRecordIcon sx={{ fontSize: 5, mt: '0.6rem' }} />
          <PublicIcon sx={{ fontSize: 15, mt: '0.3rem' }} />
        </Stack>
      </>
    );
  };

  return (
    <>
      <Stack container justifyContent="center" alignItems="center">
        <Card
          sx={{
            maxWidth: 500,
            minWidth: respwidth ? 400 : null,
            boxShadow: 3,
            borderRadius: 2,
            border: 'none',
            borderColor: theme.palette.preview.postcard.color,
          }}
        >
          <CardHeader
            avatar={
              <Avatar
                src={
                  ConnectedProfiles.connected_profile_image
                    ? ConnectedProfiles.connected_profile_image
                    : ConnectedProfiles.connected_profile_name || ConnectedProfiles.connected_profile_id
                }
                alt={
                  ConnectedProfiles.connected_profile_name
                    ? ConnectedProfiles.connected_profile_name
                    : ConnectedProfiles.connected_profile_id
                }
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  height: 40,
                  width: 40,
                  bgcolor: theme.palette.primary.main,
                }}
                style={{ color: 'white' }}
              />
            }
            title={ConnectedProfiles?.connected_profile_name}
            sx={{ fontSize: { xl: 15, lg: 13, md: 11, sm: 9, xs: 7 } }}
            subheader={<ProfileTime />}
            action={<MoreVertIcon sx={{ mt: 1 }} />}
          />
          <CardContent sx={{ pt: 1, pb: 1 }}>
            <Typography
              noWrap={!showFullDescription}
              sx={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: showFullDescription ? 'initial' : 2,
                overflow: 'hidden',
                WebkitBoxDecorationBreak: 'clone',
                whiteSpace: 'pre-line',
                textOverflow: 'ellipsis',
                fontSize: '0.875rem',
              }}
            >
              {CommonHelper.ParseDescription(data.description)}
            </Typography>
            {!showFullDescription && data.description.length > 100 && (
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 700,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontSize: '0.875rem',
                  color: theme.palette.primary.main
                }}
                onClick={handleToggleDescription}
              >
                Show More
              </Typography>
            )}
            {showFullDescription && (
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 700,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontSize: '0.875rem',
                  color: theme.palette.primary.main
                }}
                onClick={handleToggleDescription}
              >
                Show Less
              </Typography>
            )}
          </CardContent>
          <CardMedia sx={{ pl: 2, pr: 2 }}>{imageView()}</CardMedia>
          <CardActions disableSpacing sx={{ display: 'block' }}>
            <Divider />
            <Stack direction="row" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <Paper sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                <img
                  src={TwittercommentIcon}
                  style={{ height: 'auto', width: '100%', maxWidth: '22px', maxHeight: '22px', marginLeft: '1rem' }}
                />
              </Paper>
              <Paper sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={TwitterRetweetIcon}
                  style={{ height: 'auto', width: '100%', maxWidth: '22px', maxHeight: '22px', marginLeft: '0.5rem' }}
                />
              </Paper>
              <Paper sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={TwitterviewIcon}
                  style={{ height: 'auto', width: '100%', maxWidth: '20px', maxHeight: '20px', marginLeft: '0.5rem' }}
                />
              </Paper>
              <Paper sx={{ display: 'flex', alignItems: 'center' }}>
                <BookmarkBorderIcon sx={{ marginRight: '0.5rem', color: theme.palette.preview.postcard.icon }} />
              </Paper>
            </Stack>
          </CardActions>
        </Card>
      </Stack>
    </>
  );
};

export default TwitterPostImagesPreview;
