import AddIcon from '@mui/icons-material/Add';
import { Button, Toolbar } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';
import AddAutomation from './addAutomation.component';

const StyledRoot = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1, 0, 3),
}));

export default function AutomationToolbar({ handleSuccess }) {
    const theme = useTheme();
    const [openNewAutomationModel, setNewAutomationModel] = useState(false);
    const handleNewAutomationModal = () => {
        setNewAutomationModel(true);
    };
    const handleClose = () => {
        setNewAutomationModel(false);
    };
    return (
        <>
            <StyledRoot>
                <Button variant='contained' startIcon={<AddIcon />} onClick={handleNewAutomationModal}>Add New Cross Post</Button>
            </StyledRoot>
            {openNewAutomationModel && <AddAutomation handleClose={handleClose} handleSuccess={handleSuccess} />}
        </>
    );
}
