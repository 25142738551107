import {
  Avatar,
  Box,
  Card,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  useMediaQuery,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { StyledComponent } from 'src/components/styled-components/styledComponent';
import { useRightSidebar } from 'src/layouts/right-side-bar/rightSidebarProvider';
import HttpServices from 'src/services/httpService';
import { postsModuleURL } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import DynamicHeightComponent from 'src/utils/dynamicSize.component';
import Spinner from 'src/utils/Loader/spinner';
import { useToaster } from 'src/utils/toaster/toasterContext';
import Scrollbar from '../../../components/scrollbar';
import { UserListHead } from '../../../sections/@dashboard/user';
import NoPostFound from '../noPost.component';
import GoogleMyBusinessPostDetailView from './googlemybusinessPostDetailView';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { PostDetailsHelper } from 'src/utils/postDetailsHelper';
import GbusinessMobileViewPosts from '../mobile-publishedPosts/gbusinessMobileViewPosts';
import { Warning as WarningIcon } from '@mui/icons-material';

const GoogleMyBusinessPostGrid = ({ connectedNetworks, handleProfileClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { showToast } = useToaster();
  const { showRightSidebar } = useRightSidebar();
  const navigate = useNavigate();
  const hasIntegratationPermission = CommonHelper.CheckForPermissionAvailableForUser(
    CommonHelper.PermissionList.INTEGRATE_PERMISSION
  );

  const [isLoading, setloading] = useState(true);
  const [mobileLoader, setMobileLoader] = useState(false);
  const [gbusinessPageId, setGbusinessPageId] = useState('');
  const [publishedPosts, setPublishedPosts] = useState([]);
  const [connectedMedias, setConnectedMedias] = useState([]);
  const [postId, setPostId] = useState(null);
  const [height, setHeight] = useState(window.innerHeight - 250);
  const [pagination, setPagination] = useState('');
  const [isAccountNeedsReauth, setAccountNeedsReauth] = useState(false);

  //Table Variables
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const getTableDesk = [
    // { id: 'created_time', label: 'Date Time', alignRight: false },
    // { id: 'message', label: 'Content', alignRight: false },
    // { id: 'image_url', label: 'Image', alignRight: false },
    { id: 'created_time', width: '25%', label: 'Date Time', alignRight: false },
    { id: 'image_url', width: '25%', label: 'Image', alignRight: false },
    { id: 'message', width: '50%', label: 'Content', alignRight: false },
  ];
  const getTableMobile = [
    { id: 'created_time', label: 'Date Time', alignRight: false },
    { id: 'message', label: 'Content', alignRight: false },
    { id: 'like_count', label: 'Likes', alignRight: false },
    { id: 'comment_count', label: 'Comments', alignRight: false },
    { id: 'share_count', label: 'Shares', alignRight: false },
    { id: 'image_url', label: 'Image', alignRight: false },
  ];
  const TABLE_HEAD = isMobile ? getTableMobile : getTableDesk;

  useEffect(() => {
    const activeNetworks = connectedNetworks.filter((c) => c.is_active);
    let googlemybusinessId = activeNetworks && activeNetworks.length > 0 && activeNetworks[0].id;
    googlemybusinessId && setGbusinessPageId(googlemybusinessId);
    setConnectedMedias(connectedNetworks);
    if (activeNetworks && activeNetworks.length === 0) {
      setloading(false);
      setAccountNeedsReauth(true);
    }
    const handleResize = () => {
      setHeight(window.innerHeight - 300);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [connectedNetworks]);
  useEffect(() => {
    gbusinessPageId && getGoogleMyBusinessPublishedPosts();
  }, [gbusinessPageId]);
  const googlemybusinessPublishedPostSuccessCallback = (data, message) => {
    setPublishedPosts(data.posts ? data.posts : '');
    setPagination(data.pagination);
    setloading(false);
  };
  const googlemybusinessNextPublishedPostSuccessCallback = (data, message) => {
    let updatedPost = [...publishedPosts, ...data.posts];
    setPublishedPosts(updatedPost);
    setPagination(data.pagination);
    setloading(false);
    setMobileLoader(false);
  };
  const googlemybusinessPublishedPostFailureCallback = (message) => {
    setPublishedPosts([]);
    message && showToast(message, 'e');
    setloading(false);
  };
  const googlemybusinessNextPublishedPostFailureCallback = (message) => {
    setPage(0);
    message && showToast(message, 'e');
    setloading(false);
    setloading(false);
  };
  const getGoogleMyBusinessPublishedPosts = (newRowsPerPage = '10') => {
    setloading(true);
    let getProps = {
      url: `${postsModuleURL.googlemybusinessPosts}${gbusinessPageId}&post_limit=${newRowsPerPage}`,
      successCallback: googlemybusinessPublishedPostSuccessCallback,
      failureCallback: googlemybusinessPublishedPostFailureCallback,
    };
    HttpServices.Get(getProps);
  };
  const getNextGoogleMyBusinessPublishedPosts = () => {
    setloading(true);
    let getProps = {
      url: `${postsModuleURL.googlemybusinessPosts}${gbusinessPageId}&pagination=${pagination}&post_limit=${rowsPerPage}`,
      successCallback: googlemybusinessNextPublishedPostSuccessCallback,
      failureCallback: googlemybusinessNextPublishedPostFailureCallback,
    };
    HttpServices.Get(getProps);
  };
  const getMobileViewGbusinessNextPublishedPosts = () => {
    if (pagination) {
      setMobileLoader(true);
      let getProps = {
        url: `${postsModuleURL.googlemybusinessPosts}${gbusinessPageId}&pagination=${pagination}&post_limit=10`,
        successCallback: googlemybusinessNextPublishedPostSuccessCallback,
        failureCallback: googlemybusinessNextPublishedPostFailureCallback,
      };
      HttpServices.Get(getProps);
    }
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if ((newPage + 1) * rowsPerPage > publishedPosts.length && pagination != '') {
      getNextGoogleMyBusinessPublishedPosts();
    }
  };
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    if (newRowsPerPage > publishedPosts.length) {
      getGoogleMyBusinessPublishedPosts(newRowsPerPage);
    }
  };
  const handleOpenPost = (post) => {
    let data = {
      content: postView,
      data: post,
    };
    showRightSidebar(data);
  };
  const postView = (post) => {
    let profile = connectedMedias.find((a) => a.id === gbusinessPageId);
    profile['date'] = CommonHelper.GetDateFormat(post.create_time);
    let postDetails = {
      postId: post.id,
      pageId: gbusinessPageId,
      image_url: post.image_url,
      message: post.message,
      topic_type: post.topic_type,
      callToAction: [post.callToAction],
      event: [post.event],
      offer: [post.offer],
      profile,
      ...post,
      height,
    };
    return <GoogleMyBusinessPostDetailView {...postDetails} />;
  };
  const handleGoogleMyBusiness = (id) => {
    setGbusinessPageId(id);
    setRowsPerPage(10);
    setPage(0);
  };
  const navigateToIntegration = () => {
    navigate('/integration');
  };
  const getTableBodyData = () => {
    return publishedPosts.length > 0 ? (
      publishedPosts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
        const { create_time, id, image_url, message } = row;
        return (
          <StyledComponent.StyledTableRow
            key={id}
            onClick={() => {
              handleOpenPost(row);
            }}
          >
            <StyledComponent.StyledTableCell align="left">
              {CommonHelper.getTableDateAndTime(create_time)}
            </StyledComponent.StyledTableCell>
            <StyledComponent.StyledTableCell align="left">
              {PostDetailsHelper.getPostMedias(image_url)}
            </StyledComponent.StyledTableCell>
            <StyledComponent.StyledTableCell align="left">
              {PostDetailsHelper.GetPostContent(message, 400)}
            </StyledComponent.StyledTableCell>
          </StyledComponent.StyledTableRow>
        );
      })
    ) : (
      <NoPostFound />
    );
  };
  const getNetworkChip = () => {
    return (
      connectedMedias &&
      connectedMedias.length > 0 &&
      connectedMedias.map((c) => {
        const isActive = c.id === gbusinessPageId;
        const isProfileActive = c.is_active;
        const showReauth = !isProfileActive && hasIntegratationPermission;
        return (
          <Chip
            key={c.id}
            style={{
              borderRadius: 8,
              opacity: isActive ? 1 : 0.9,
              ...(!isProfileActive && {
                backgroundColor: theme.palette.error.lighter,
                border: `1px solid ${theme.palette.error.main}`,
              }),
              ...(isActive &&
                isProfileActive && {
                  backgroundColor: theme.palette.primary.main,
                  color: 'white',
                  fontWeight: 800,
                }),
            }}
            sx={{
              m: 0.4,
              '&:hover': {
                opacity: isProfileActive ? 0.8 : 1,
              },
              ...(!isProfileActive && {
                '& .MuiChip-deleteIcon': {
                  color: theme.palette.error.main,
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                },
              }),
            }}
            onClick={
              isProfileActive
                ? () => handleGoogleMyBusiness(c.id)
                : showReauth
                ? () => handleProfileClick(c)
                : undefined
            }
            deleteIcon={!isProfileActive ? <WarningIcon /> : undefined}
            onDelete={showReauth ? () => handleProfileClick(c) : undefined}
            avatar={
              <Avatar
                src={c.profile_image ? c.profile_image : c.profile_name || c.id}
                alt={c.profile_name ? c.profile_name : c.id}
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  height: 40,
                  width: 40,
                  bgcolor: isActive ? 'white' : theme.palette.primary.main,
                  ...(!isProfileActive && {
                    bgcolor: theme.palette.error.main,
                    color: 'white',
                  }),
                }}
                style={{
                  color: isActive ? theme.palette.primary.main : 'white',
                }}
              />
            }
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {c.profile_name ? c.profile_name : c.id}
                {!isProfileActive && (
                  <Typography variant="caption" sx={{ ml: 1, color: theme.palette.error.dark }}>
                    (Needs Re-auth)
                  </Typography>
                )}
              </Box>
            }
          />
        );
      })
    );
  };

  const getGridView = () => {
    const postcontentL = 220;
    const postcontentS = 110;

    return (
      <DynamicHeightComponent>
        <Scrollbar>
          {isMobile ? (
            <GbusinessMobileViewPosts
              publishedPosts={publishedPosts}
              getMobileViewGbusinessNextPublishedPosts={getMobileViewGbusinessNextPublishedPosts}
              mobileLoader={mobileLoader}
              pagination={pagination}
            />
          ) : (
            <TableContainer
              sx={{
                maxHeight: 'calc(100vh - 290px)',
                minHeight: 'calc(100vh - 290px)',
                height: height - 60,
              }}
            >
              <Table stickyHeader size="small">
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={publishedPosts.length}
                  isShowCheckbox={false}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell colSpan={6} align="center" sx={{ width: '100%', borderBottom: 'none' }}>
                        <Spinner height={height} />
                      </TableCell>
                    </TableRow>
                  ) : (
                    getTableBodyData()
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {!isMobile && (
            <TablePagination
              component="div"
              rowsPerPageOptions={[10, 20, 30, 50]}
              count={publishedPosts.length + 1}
              rowsPerPage={rowsPerPage}
              page={page}
              labelDisplayedRows={() => ''}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Scrollbar>
      </DynamicHeightComponent>
    );
  };
  return (
    <Box>
      <Card sx={{ minHeight: 'calc(100vh - 150px)', maxHeight: 'calc(100vh - 150px)' }}>
        {connectedMedias && connectedMedias.length > 0 ? (
          <>
            <Card sx={{ mb: 2, p: 2, maxHeight: 100, minHeight: isMobile && 100, overflowY: 'auto', visibility: true }}>
              {getNetworkChip()}
            </Card>
            <Card
              sx={{
                boxShadow: isMobile && publishedPosts?.length > 0 && 'none',
                backgroundColor: isMobile && publishedPosts?.length > 0 && 'transparent',
              }}
            >
              {isLoading ? (
                <Spinner height={height} />
              ) : publishedPosts?.length === 0 ? (
                <NoPostFound
                  height={isMobile ? 'calc(100vh - 330px)' : height}
                  contentText={isAccountNeedsReauth ? 'Needs Re-authentication' : 'No Post Available'}
                />
              ) : (
                getGridView()
              )}
            </Card>
          </>
        ) : isLoading ? (
          <Spinner height={height} />
        ) : (
          <Card
            sx={{
              mb: 2,
              p: 2,
              height: height,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: 'calc(100vh - 150px)',
              maxHeight: 'calc(100vh - 150px)',
            }}
          >
            {hasIntegratationPermission ? (
              <LoadingButton
                variant="contained"
                autoFocus
                onClick={() => {
                  navigateToIntegration();
                }}
              >
                Connect Social Accounts
              </LoadingButton>
            ) : (
              <NoPostFound />
            )}
          </Card>
        )}
      </Card>
    </Box>
  );
};
export default GoogleMyBusinessPostGrid;
