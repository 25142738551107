import { LoadingButton } from '@mui/lab';
import { Button, DialogTitle, CardContent,ListItemIcon,Paper  } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import VerifiedUserIcon from  '@mui/icons-material/VerifiedUser';

const TiktokAuthorizationModal = (props) => {
    const [isLoadingOn, setLoadingOn] = useState(false);
    const handleConnectTiktok = () => {
        setLoadingOn(true);
        props.handleConnectTiktok();
    };

    const handleClose = () => {
        props.handleClose();
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open>
               <DialogTitle id="customized-dialog-title">
                <Typography gutterBottom variant="h4">
                    {props.reAuth ? "Re-Authenticate Your TikTok Account" : "Connect Your TikTok Account"}
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <CardContent sx={{ p: { xs: 0, md: 0 }, color: 'grey.800' }}>
                    <Typography variant="body1" sx={{ mx: 'auto', fontWeight: 'bold' }}>
                        {props.reAuth 
                            ? "Verify these requirements to reconnect:" 
                            : "Make sure you've completed these steps before continuing:"}
                    </Typography>

                    <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1, mt: 2 }}>
                        <ListItemIcon>
                            <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
                        </ListItemIcon>
                        <Typography variant="body1">
                            {props.reAuth 
                                ? "Your TikTok account remains valid and accessible"
                                : "You have a standard TikTok account (no Business Account required)"}
                        </Typography>
                    </Paper>

                    <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
                        <ListItemIcon>
                            <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
                        </ListItemIcon>
                        <Typography variant="body1">
                            {props.reAuth 
                                ? "Your account is still active and up-to-date"
                                : "Your TikTok account is active and up-to-date"}
                        </Typography>
                    </Paper>

                    <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
                        <ListItemIcon>
                            <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
                        </ListItemIcon>
                        <Typography variant="body1">
                            {props.reAuth 
                                ? "You're logged into the originally authorized TikTok account"
                                : "You're logged into the correct TikTok account"}
                        </Typography>
                    </Paper>
                </CardContent>
            </DialogContent>
            <DialogActions>
                <Button
                color='error'
                variant="outlined"
                    onClick={handleClose}
                >
                    Close
                </Button>
                <LoadingButton
                    variant="contained"
                    loading={isLoadingOn}
                    autoFocus
                    onClick={() => {
                        handleConnectTiktok();
                    }}
                >
                    Connect
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
export default TiktokAuthorizationModal;
