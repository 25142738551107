import React, { useState, useEffect } from 'react';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import Spinner from 'src/utils/Loader/spinner';
import { useToaster } from 'src/utils/toaster/toasterContext';
import { TextField, Typography, Box, Paper, Container, IconButton, Tooltip, useTheme } from '@mui/material';
import UserInformationTable from './UserInformationTable';
import PaymentDetailsTable from './PaymentDetailsTable';
import ReferForm from './ReferForm';
import { startOfMonth, endOfMonth, format } from 'date-fns';
import { Helmet } from 'react-helmet-async';
import Header from 'src/layouts/dashboard/header/header.component';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const ReferAndEarnComponent = () => {
  const theme = useTheme();
  const [isReferAndEarnEnabled, setIsReferAndEarnEnabled] = useState(true);
  const [loader, setLoader] = useState(false);
  const [referralData, setReferralData] = useState([]);
  const [affiliateLink, setAffiliateLink] = useState();
  const [allTimeCommission, setAllTimeCommission] = useState();
  const [allTimeUsers, setAllTimeUsers] = useState();
  const [referralAPIData, setReferralAPIData] = useState({});
  const [filterDateRange, setFilterDateRange] = useState({
    fromDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    toDate: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
  });
  const { showToast } = useToaster();

  useEffect(() => {
    getReferAndEarnData();
  }, []);

  const getReferAndEarnData = async () => {
    setLoader(true);
    let postProps = {
      url: userModuleURL.getReferAndEarn,
      isNeedActiveBrandId: false,
      successCallback,
      failureCallback,
    };
    HttpServices.Get(postProps);
  };

  const successCallback = (data, message) => {
    setIsReferAndEarnEnabled(data?.is_refer_and_earn_enabled);
    setReferralData(data?.referral_data || []);
    setReferralAPIData(data || {});
    setAffiliateLink(data?.referral_code_data?.base_url + data?.referral_code_data?.referral_user_code);
    const allTimeCommission =
      data?.referral_data?.payment_data?.reduce((sum, payment) => sum + (payment?.commission_amount ?? 0), 0) ?? 0;
    setAllTimeCommission(allTimeCommission);
    const allTimeUsers = data?.referral_data?.user_creation_data?.length ?? 0;
    setAllTimeUsers(allTimeUsers);

    setLoader(false);
    showToast(message, 's');
  };

  const failureCallback = (message) => {
    setLoader(false);
    showToast(message, 'e');
  };

  const handleFilterDateChange = (e) => {
    const { name, value } = e.target;
    setFilterDateRange((prev) => ({ ...prev, [name]: value }));
  };

  const isDateRangeValid = () => {
    const { fromDate, toDate } = filterDateRange;
    return !fromDate || !toDate || new Date(fromDate) <= new Date(toDate);
  };

  const getDateClass = (date) => {
    const { fromDate, toDate } = filterDateRange;
    const currentDate = new Date(date);
    const from = fromDate ? new Date(fromDate) : null;
    const to = toDate ? new Date(toDate) : null;
    if (from && to && currentDate >= from && currentDate <= to) {
      return { backgroundColor: '#e0f7fa' };
    }
    return {};
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(affiliateLink);
    showToast('Affiliate link copied to clipboard', 's');
  };

  if (loader) {
    return <Spinner />;
  }

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
        <Typography variant="h4">Refer and Earn</Typography>
        <Box
          display="flex"
          alignItems="center"
          gap={2}
          justifyContent="center"
          flexGrow={1}
          sx={{ padding: '4px', borderRadius: '4px' }}
        >
          <Typography variant="body1" sx={{ color: theme.palette.primary.dark }}>
            Affiliate Link: {affiliateLink}
          </Typography>
          <Tooltip title="Copy to clipboard">
            <IconButton onClick={copyToClipboard} sx={{ color: theme.palette.primary.main, borderRadius: '4px' }}>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <TextField
            type="date"
            name="fromDate"
            label="From Date"
            value={filterDateRange.fromDate}
            onChange={handleFilterDateChange}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            sx={getDateClass(filterDateRange.fromDate)}
          />
          <TextField
            type="date"
            name="toDate"
            label="To Date"
            value={filterDateRange.toDate}
            onChange={handleFilterDateChange}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: filterDateRange.fromDate,
            }}
            sx={getDateClass(filterDateRange.toDate)}
          />
        </div>
      </Box>
      {isReferAndEarnEnabled ? (
        <Box component={Paper} padding={2} marginTop={2}>
          {!isDateRangeValid() && (
            <Typography color="error">"From Date" should be less than or equal to "To Date"</Typography>
          )}
          {isDateRangeValid() && (
            <>
              <Box display="flex" justifyContent="space-between" marginBottom={2}>
                <Box sx={{ border: '1px solid #ccc', borderRadius: '8px', padding: 2, width: '23%' }}>
                  <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
                    All Time Unique Users
                  </Typography>
                  <Typography variant="h4" sx={{ color: theme.palette.primary.main }}>
                    {referralAPIData?.cookie_data?.total_cookies}
                  </Typography>
                </Box>
                <Box sx={{ border: '1px solid #ccc', borderRadius: '8px', padding: 2, width: '23%' }}>
                  <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
                    All Time Accounts Created
                  </Typography>
                  <Typography variant="h4" sx={{ color: theme.palette.primary.main }}>
                    {allTimeUsers}
                  </Typography>
                </Box>
                <Box sx={{ border: '1px solid #ccc', borderRadius: '8px', padding: 2, width: '23%' }}>
                  <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
                    All Time Commissions Earned
                  </Typography>
                  <Typography variant="h4" sx={{ color: theme.palette.primary.main }}>
                    {allTimeCommission}$
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ border: '1px solid #ccc', borderRadius: '8px', padding: 2, marginBottom: 2 }}>
                <UserInformationTable
                  userCreationData={referralData.user_creation_data}
                  filterDateRange={filterDateRange}
                />
              </Box>
              <Box sx={{ border: '1px solid #ccc', borderRadius: '8px', padding: 2 }}>
                <PaymentDetailsTable PaymentData={referralData.payment_data} filterDateRange={filterDateRange} />
              </Box>
            </>
          )}
        </Box>
      ) : (
        <ReferForm />
      )}
    </>
  );
};

export default ReferAndEarnComponent;
