import dayjs from 'dayjs';
import { PostUtil } from './post-util';
import { CommonHelper } from 'src/utils/commonHelper';
const brandTimeZone = CommonHelper.GetBrandTimeZone();

const facebookReelsConfiguration = PostUtil.ServiceConfigurations.FACEBOOK.REELS;
const facebookPostConfiguration = PostUtil.ServiceConfigurations.FACEBOOK.POST;
const facebookStoryConfiguration = PostUtil.ServiceConfigurations.FACEBOOK.STORY;
const instagramReelsConfiguration = PostUtil.ServiceConfigurations.INSTAGRAM.REELS;
const instagramPostConfiguration = PostUtil.ServiceConfigurations.INSTAGRAM.POST;
const instagramStoryConfiguration = PostUtil.ServiceConfigurations.INSTAGRAM.STORY;
const youtubeCommonConfiguration = PostUtil.ServiceConfigurations.YOUTUBE.COMMON;
const twitterPostConfiguration = PostUtil.ServiceConfigurations.TWITTER.POST;
const youtubePostConfiguration = PostUtil.ServiceConfigurations.YOUTUBE.POST;
const youtubeShortsConfiguration = PostUtil.ServiceConfigurations.YOUTUBE.SHORTS;
const linkedinPostConfiguration = PostUtil.ServiceConfigurations.LINKEDIN.POST;
const gbusinessPostConfiguration = PostUtil.ServiceConfigurations.GBUSINESS.POST;
const pinterestPostConfiguration = PostUtil.ServiceConfigurations.PINTEREST.POST;
const tiktokPostConfiguration = PostUtil.ServiceConfigurations.TIKTOK.POST;

const FacebookBulkUploadConditions = (request) => {
  let fbErrors = [];
  const validPostTypes = ['post', 'reels'];
  const postTypes = request?.post_type
    ?.toLowerCase()
    .split('/')
    .map((type) => type.trim());

  if (postTypes.length > 1) {
    fbErrors.push('Please select only one post type (Post / Reels).');
  }

  const isValidPostType = postTypes.every((type) =>
    validPostTypes.some((validType) => validType?.toLowerCase() === type?.toLowerCase())
  );

  if (!isValidPostType) {
    fbErrors.push('Please provide a correct post type (Post / Reels).');
  }
  return fbErrors;
};

const InstagramBulkUploadConditions = (request) => {
  let insErrors = [];
  const validPostTypes = ['post', 'reels', 'story'];
  const postTypes = request?.post_type
    ?.toLowerCase()
    .split('/')
    .map((type) => type.trim());

  if (postTypes.length > 1) {
    insErrors.push('Please select only one post type (Post / Reels / Story).');
  }

  const isValidPostType = postTypes.every((type) =>
    validPostTypes.some((validType) => validType?.toLowerCase() === type?.toLowerCase())
  );

  if (!isValidPostType) {
    insErrors.push('Please provide a correct post type (Post / Reels / Story).');
  }
  return insErrors;
};

const YoutubeBulkUploadConditions = (request) => {
  let ytErrors = [];
  const validPostTypes = ['shorts', 'video'];
  const validPrivacy = ['public', 'private', 'unlisted'];
  const postTypes = request?.post_type
    ?.toLowerCase()
    .split('/')
    .map((type) => type.trim());
  const privacy = request?.privacy
    ?.toLowerCase()
    .split('/')
    .map((type) => type.trim());

  if (postTypes.length > 1) {
    ytErrors.push('Please select only one post type (Video / Shorts).');
  }

  const isValidPostType = postTypes.every((type) =>
    validPostTypes.some((validType) => validType?.toLowerCase() === type?.toLowerCase())
  );

  if (!isValidPostType) {
    ytErrors.push('Please provide a correct post type (Video / Shorts).');
  }

  if (privacy.length > 1) {
    ytErrors.push('Please select only one privacy type (Public / Private / Unlisted).');
  }

  const isValidPrivacy = privacy.every((type) =>
    validPrivacy.some((validprivacy) => validprivacy?.toLowerCase() === type?.toLowerCase())
  );

  if (!isValidPrivacy) {
    ytErrors.push('Please provide a correct privacy type (Public / Private / Unlisted).');
  }
  return ytErrors;
};

const TiktokBulkUploadConditions = (request) => {
  let tkErrors = [];
  const validPostTypes = ['DIRECT_POST', 'UPLOAD_TO_INBOX'];
  const validPrivacy = ['PUBLIC_TO_EVERYONE', 'MUTUAL_FOLLOW_FRIENDS', 'FOLLOWER_OF_CREATOR', 'SELF_ONLY'];
  const postTypes = request?.post_type
    ?.toLowerCase()
    .split('/')
    .map((type) => type.trim());
  const privacy = request?.privacy
    ?.toLowerCase()
    .split('/')
    .map((type) => type.trim());

  if (postTypes.length > 1) {
    tkErrors.push('Please select only one post type (UPLOAD_TO_INBOX / DIRECT_POST).');
  }
  const isValidPostType = postTypes.every((type) =>
    validPostTypes.some((validType) => validType?.toLowerCase() === type?.toLowerCase())
  );

  if (!isValidPostType) {
    tkErrors.push('Please provide a correct post type (UPLOAD_TO_INBOX / DIRECT_POST).');
  }
  if (privacy.length > 1) {
    tkErrors.push(
      'Please select only one privacy type (PUBLIC_TO_EVERYONE / MUTUAL_FOLLOW_FRIENDS / FOLLOWER_OF_CREATOR / SELF_ONLY).'
    );
  }

  const isValidPrivacy = privacy.every((type) =>
    validPrivacy.some((validprivacy) => validprivacy?.toLowerCase() === type?.toLowerCase())
  );

  if (!isValidPrivacy) {
    tkErrors.push(
      'Please provide a correct privacy type (PUBLIC_TO_EVERYONE / MUTUAL_FOLLOW_FRIENDS / FOLLOWER_OF_CREATOR / SELF_ONLY).'
    );
  }
  return tkErrors;
};

const GbusinessBulkUploadConditions = (request) => {
  let gbErrors = [];
  const validPostTypes = ['STANDARD', 'OFFER', 'EVENT'];
  const validButtonTypes = ['None', 'BOOK', 'ORDER', 'LEARN_MORE', 'SIGN_UP', 'CALL'];
  const postTypes = request?.post_type
    ?.toLowerCase()
    .split('/')
    .map((type) => type.trim());
  const buttonTypes = request?.button_type
    ?.toLowerCase()
    .split('/')
    .map((type) => type.trim());

  if (postTypes.length > 1) {
    gbErrors.push('Please select only one post type (STANDARD / OFFER / EVENT).');
  }

  const isValidPostType = postTypes.every((type) =>
    validPostTypes.some((validType) => validType?.toLowerCase() === type?.toLowerCase())
  );

  if (!isValidPostType) {
    gbErrors.push('Please provide a correct post type (STANDARD / OFFER / EVENT).');
  }
  if (buttonTypes.length > 1) {
    gbErrors.push('Please select only one button type (None / BOOK / ORDER / LEARN_MORE / SIGN_UP / CALL).');
  }

  const isValidButtonType = buttonTypes.every((type) =>
    validButtonTypes.some((validbuttontype) => validbuttontype?.toLowerCase() === type?.toLowerCase())
  );

  if (!isValidButtonType) {
    gbErrors.push('Please provide a correct button type (None / BOOK / ORDER / LEARN_MORE / SIGN_UP / CALL).');
  }
  return gbErrors;
};

const TwitterBulkUploadConditions = (request) => {
  let trErrors = [];
  const validPostTypes = ['post'];
  const postTypes = request?.post_type
    ?.toLowerCase()
    .split('/')
    .map((type) => type.trim());

  if (postTypes.length > 1) {
    trErrors.push('Please select only one post type (Post).');
  }

  const isValidPostType = postTypes.every((type) =>
    validPostTypes.some((validType) => validType?.toLowerCase() === type?.toLowerCase())
  );

  if (!isValidPostType) {
    trErrors.push('Please provide a correct post type (Post).');
  }
  return trErrors;
};

const LinkedinBulkUploadConditions = (request) => {
  let lnErrors = [];
  const validPostTypes = ['post'];
  const postTypes = request?.post_type
    ?.toLowerCase()
    .split('/')
    .map((type) => type.trim());

  if (postTypes.length > 1) {
    lnErrors.push('Please select only one post type (Post).');
  }

  const isValidPostType = postTypes.every((type) =>
    validPostTypes.some((validType) => validType?.toLowerCase() === type?.toLowerCase())
  );

  if (!isValidPostType) {
    lnErrors.push('Please provide a correct post type (Post).');
  }
  return lnErrors;
};

const PinterestBulkUploadConditions = (request) => {
  let ptErrors = [];
  const validPostTypes = ['post'];
  const postTypes = request?.post_type
    ?.toLowerCase()
    .split('/')
    .map((type) => type.trim());
  const Boards = request?.board_id
    ?.toLowerCase()
    .split('/')
    .map((type) => type.trim());

  if (postTypes.length > 1) {
    ptErrors.push('Please select only one post type (Post).');
  }

  const isValidPostType = postTypes.every((type) =>
    validPostTypes.some((validType) => validType?.toLowerCase() === type?.toLowerCase())
  );

  if (!isValidPostType) {
    ptErrors.push('Please provide a correct post type (Post).');
  }

  if (Boards.length > 1) {
    ptErrors.push('Please select only one Board.');
  }
  return ptErrors;
};

const FacebookPostConditions = (request) => {
  let fErrors = [];
  let isMultipleVideoFiles = request?.media_data?.filter((a) => a.media_type?.includes('VIDEO'))?.length > 1;
  let imageFiles = request.media_data?.filter((a) => a.media_type === 'IMAGE') || [];
  if (isMultipleVideoFiles) fErrors.push("You can't post multiple video's");
  if (imageFiles.length > facebookPostConfiguration.MAXIMAGECOUNT) {
    fErrors.push('Maximum 10 images allowed in a single Facebook post.');
  } else {
    let videoFile = request.media_data?.find((a) => a.media_type === 'VIDEO');
    if (videoFile) {
      if (videoFile.video_length > facebookPostConfiguration.MAXSECONDS)
        fErrors.push(
          `Maximum video length for the post is ${facebookPostConfiguration.MAXSECONDS} seconds. Please choose a video maximum of ${facebookPostConfiguration.MAXSECONDS} seconds.`
        );
      const { width, height } = videoFile.aspect_ratio;
      if (width < facebookPostConfiguration.MINWIDTH || height < facebookPostConfiguration.MINHEIGHT) {
        fErrors.push(
          `Minimum resolution is ${facebookPostConfiguration.MINWIDTH}x${facebookPostConfiguration.MINHEIGHT}px. Your media is ${width}x${height}px.`
        );
      }
      if (
        videoFile?.file_size !== 0 &&
        videoFile?.file_size > facebookPostConfiguration.MAX_VIDEO_SIZE_MB * 1024 * 1024
      ) {
        fErrors.push(
          `Video size must be less than ${facebookPostConfiguration.MAX_VIDEO_SIZE_MB} MB. Your video is ${(
            videoFile?.file_size /
            1024 /
            1024
          ).toFixed(2)} MB.`
        );
      }
    }
  }
  for (let i = 0; i < imageFiles.length; i++) {
    const { width, height } = imageFiles[i].aspect_ratio;
    if (width > facebookPostConfiguration.MAXWIDTH || height > facebookPostConfiguration.MAXHEIGHT) {
      fErrors.push(
        `Maximum resolution is ${facebookPostConfiguration.MAXWIDTH}x${facebookPostConfiguration.MAXHEIGHT}px. Your media is ${width}x${height}px.`
      );
    }
    if (
      imageFiles[i]?.file_size !== 0 &&
      imageFiles[i]?.file_size > facebookPostConfiguration.MAX_IMAGE_SIZE_MB * 1024 * 1024
    ) {
      fErrors.push(
        `Image size must be less than ${facebookPostConfiguration.MAX_IMAGE_SIZE_MB} MB. Your image is ${(
          imageFiles[i]?.file_size /
          1024 /
          1024
        ).toFixed(2)} MB.`
      );
    }
  }

  let link = request?.link?.trim();
  let hasMedia = request?.media_data?.length > 0;

  if (link && hasMedia) {
    fErrors.push('If a link is added, do not add photos or videos in the Facebook post.');
  }
  if (link) {
    if (!CommonHelper.isValidUrl(link)) {
      fErrors.push('Please enter a valid URL');
    }
  }

  let hasAttachments = request.media_data?.length > 0;
  if (!hasAttachments && !request.description) {
    fErrors.push('Please enter a description');
  }
  if (request?.description && request?.description?.length > facebookReelsConfiguration.DESCRIPTION_MAX) {
    fErrors.push(
      `Your description is ${request.description.length} characters long, but it should not exceed ${facebookReelsConfiguration.DESCRIPTION_MAX} characters.`
    );
  }
  //Need to uncomment
  // let errorMessage = CommonHelper.CheckAspectRatioForVideo(request.media_data, facebookPostConfiguration.ASPECTRATIO);
  // if (errorMessage) {
  //     fErrors.push(errorMessage);
  // }
  return fErrors;
};

const FacebookReelsConditions = (request) => {
  let fErrors = [];
  let isVideoFileAvailable = request.media_data?.some((a) => a.media_type === 'VIDEO');
  let isImageFileAvailable = request.media_data?.some((a) => a.media_type === 'IMAGE');
  let isMultipleVideoFiles = request.media_data?.filter((a) => a.media_type?.includes('VIDEO'))?.length > 1;
  if (!request.media_data?.some((a) => a.media_type?.includes('VIDEO')))
    fErrors.push('Please select any video to post a Reel');
  if (isMultipleVideoFiles) fErrors.push("You can't post multiple video's as a Reel");
  else {
    let videoFile = request.media_data?.find((a) => a.media_type === 'VIDEO');
    if (videoFile) {
      if (videoFile.video_length < facebookReelsConfiguration.MINSECONDS)
        fErrors.push(
          `Minimum video length for the reels is ${facebookReelsConfiguration.MINSECONDS} seconds. Please choose a video minimum of ${facebookReelsConfiguration.MINSECONDS} seconds.`
        );
      if (videoFile.video_length > facebookReelsConfiguration.MAXSECONDS)
        fErrors.push(
          `Maximum video length for the reels is ${facebookReelsConfiguration.MAXSECONDS} seconds. Please choose a video maximum of ${facebookReelsConfiguration.MAXSECONDS} seconds.`
        );

      const { width, height } = videoFile.aspect_ratio;
      const aspectRatio = width / height;
      if (width < facebookReelsConfiguration.MINWIDTH || height < facebookReelsConfiguration.MINHEIGHT) {
        fErrors.push(
          `Minimum resolution is ${facebookReelsConfiguration.MINWIDTH}x${facebookReelsConfiguration.MINHEIGHT}px. Your media is ${width}x${height}px.`
        );
      }
      if (aspectRatio.toFixed(2) !== facebookReelsConfiguration.MAXASPECTRATIO.toFixed(2)) {
        fErrors.push(
          `Invalid aspect ratio. The required aspect ratio is ${facebookReelsConfiguration.MAXASPECTRATIO.toFixed(
            2
          )}:1 (9:16). Your aspect ratio is ${aspectRatio.toFixed(2)}:1.`
        );
      }
      if (videoFile.aspect_ratio.height <= facebookReelsConfiguration.MINHEIGHT) {
        fErrors.push(
          `Minimum height for the video is ${facebookReelsConfiguration.MINHEIGHT} pixels. Please choose a video minimum of ${facebookReelsConfiguration.MINHEIGHT} pixels.`
        );
      }
    }
  }
  if (request?.description?.length > facebookReelsConfiguration.DESCRIPTION_MAX) {
    fErrors.push(
      `Your description is ${request.description.length} characters long, but it should not exceed ${facebookReelsConfiguration.DESCRIPTION_MAX} characters.`
    );
  }
  if (isVideoFileAvailable && isImageFileAvailable) {
    fErrors.push("You cant post image's and videos's in Facebook");
  }
  //Need to uncomment
  // let errorMessage = CommonHelper.CheckAspectRatioForVideo(request.media_data, reelsConfiguration.ASPECTRATIO);
  // if (errorMessage) {
  //     fErrors.push(errorMessage);
  // }
  return fErrors;
};

const FacebookStoryConditions = (request) => {
  let fErrors = [];
  let isVideoFileAvailable = request.media_data?.some((a) => a.media_type === 'VIDEO');
  let isImageFileAvailable = request.media_data?.some((a) => a.media_type === 'IMAGE');
  let isMultipleVideoFiles = request.media_data?.filter((a) => a.media_type?.includes('VIDEO')).length > 1;
  let isMultipleImageFiles = request.media_data?.filter((a) => a.media_type?.includes('IMAGE')).length > 1;
  if (!request.media_data?.length > 0) {
    fErrors.push('Please select any video or image to post a Story');
  }
  if (isMultipleVideoFiles) {
    fErrors.push("You can't post multiple video's as a Story");
  } else {
    let videoFile = request.media_data?.find((a) => a.media_type === 'VIDEO');
    if (videoFile) {
        if (videoFile.video_length < facebookStoryConfiguration.MINSECONDS)
            fErrors.push(
              `Minimum video length for the reels is ${facebookStoryConfiguration.MINSECONDS} seconds. Please choose a video minimum of ${facebookStoryConfiguration.MINSECONDS} seconds.`
            );
          if (videoFile.video_length > facebookStoryConfiguration.MAXSECONDS)
            fErrors.push(
              `Maximum video length for the reels is ${facebookStoryConfiguration.MAXSECONDS} seconds. Please choose a video maximum of ${facebookStoryConfiguration.MAXSECONDS} seconds.`
            );
    
          const { width, height } = videoFile.aspect_ratio;
          const aspectRatio = width / height;
          if (width < facebookStoryConfiguration.MINWIDTH || height < facebookStoryConfiguration.MINHEIGHT) {
            fErrors.push(
              `Minimum resolution is ${facebookStoryConfiguration.MINWIDTH}x${facebookStoryConfiguration.MINHEIGHT}px. Your media is ${width}x${height}px.`
            );
          }
          if (aspectRatio.toFixed(2) !== facebookStoryConfiguration.MAXASPECTRATIO.toFixed(2)) {
            fErrors.push(
              `Invalid aspect ratio. The required aspect ratio is ${facebookStoryConfiguration.MAXASPECTRATIO.toFixed(
                2
              )}:1 (9:16). Your aspect ratio is ${aspectRatio.toFixed(2)}:1.`
            );
          }
          if (videoFile.aspect_ratio.height <= facebookStoryConfiguration.MINHEIGHT) {
            fErrors.push(
              `Minimum height for the video is ${facebookStoryConfiguration.MINHEIGHT} pixels. Please choose a video minimum of ${facebookStoryConfiguration.MINHEIGHT} pixels.`
            );
          }
    }
  }
  if (isMultipleImageFiles) {
    fErrors.push("You can't post multiple image's as a Story");
  } else {
    let imageFile = request.media_data?.find((a) => a.media_type === 'IMAGE');
    if (imageFile) {
   const { width, height } = imageFile?.aspect_ratio;
      if (
        imageFile?.file_size !== 0 &&
        imageFile?.file_size > facebookStoryConfiguration.MAX_IMAGE_SIZE_MB * 1024 * 1024
      ) {
        fErrors.push(
          `Image size must be less than ${facebookStoryConfiguration.MAX_IMAGE_SIZE_MB} MB. Your Image is ${(
            imageFile?.file_size /
            1024 /
            1024
          ).toFixed(2)} MB.`
        );
      }
      if (width > facebookStoryConfiguration.MAXWIDTH || height > facebookStoryConfiguration.MAXHEIGHT) {
        fErrors.push(
          `Maximum resolution is ${facebookStoryConfiguration.MAXWIDTH}x${facebookStoryConfiguration.MAXHEIGHT}px. Your media is ${width}x${height}px.`
        );
      }
    }
  }
  if (isVideoFileAvailable && isImageFileAvailable) {
    fErrors.push("You cant post only image's and videos's in Instagram");
  }
  return fErrors;
};

const InstagramPostConditions = (request) => {
  let iErrors = [];
  let videoFiles = request.media_data?.filter((a) => a.media_type === 'VIDEO') || [];
  let imageFiles = request.media_data?.filter((a) => a.media_type === 'IMAGE') || [];
  let isMultipleFiles = request.media_data?.filter((a) => a.media_type.includes('IMAGE')).length > 10;
  if (isMultipleFiles) {
    iErrors.push("You can't post media files that exceed the limit.");
  }
  if (videoFiles.length > 0) {
    iErrors.push('Videos are not allowed in Instagram posts. Please use the Reels option for video content.');
  }
  if (imageFiles.length > instagramPostConfiguration.MAXIMAGECOUNT) {
    iErrors.push('Maximum 10 images allowed in a single Instagram post.');
  }

  for (let i = 0; i < imageFiles.length; i++) {
    const { width, height } = imageFiles[i].aspect_ratio;
    const aspectRatio = width / height;

    if (
      imageFiles[i]?.file_size !== 0 &&
      imageFiles[i]?.file_size > instagramReelsConfiguration.MAX_IMAGE_SIZE_MB * 1024 * 1024
    ) {
      iErrors.push(
        `Image size must be less than ${instagramReelsConfiguration.MAX_IMAGE_SIZE_MB} MB. Your Image is ${(
          imageFiles[i]?.file_size /
          1024 /
          1024
        ).toFixed(2)} MB.`
      );
    }

    if (aspectRatio < 0.8 || aspectRatio > 1.91) {
      iErrors.push(
        `Image ${i + 1} has an invalid aspect ratio of ${aspectRatio.toFixed(
          2
        )}. The allowed range is 4:5 (0.8) to 1.91:1 (1.91).`
      );
    }
  }

  let hasAttachments = request.media_data?.length > 0;
  if (!hasAttachments && !request.description) {
    iErrors.push('Please enter a description');
  }
  if (!hasAttachments) {
    iErrors.push('Please select at least one media item');
  }
  if (request.description) {
    const hashtags = request.description.match(/#\w+/g) || []; // Match all hashtags
    if (hashtags.length > 30) {
      iErrors.push(`Your description contains ${hashtags.length} hashtags but only up to 30 are allowed.`);
    }
    if (request.description.length > instagramReelsConfiguration.DESCRIPTION_MAX) {
      iErrors.push(
        `Your description is ${request.description.length} characters long, but it should not exceed ${instagramReelsConfiguration.DESCRIPTION_MAX} characters.`
      );
    }
  }
  return iErrors;
};

const InstagramReelsConditions = (request) => {
  let iErrors = [];
  let isVideoFileAvailable = request.media_data?.some((a) => a.media_type === 'VIDEO');
  let isImageFileAvailable = request.media_data?.some((a) => a.media_type === 'IMAGE');
  let isMultipleVideoFiles = request.media_data?.filter((a) => a.media_type?.includes('VIDEO')).length > 1;

  if (!request.media_data?.some((a) => a.media_type?.includes('VIDEO'))) {
    iErrors.push('Please select any video to post a Reel');
  }
  if (isMultipleVideoFiles) {
    iErrors.push("You can't post multiple video's as a Reel");
  } else {
    let videoFile = request.media_data?.find((a) => a.media_type === 'VIDEO');
    if (videoFile) {
      if (videoFile.video_length > instagramReelsConfiguration.MAXSECONDS)
        iErrors.push(
          `Maximum video length for the reels is ${instagramReelsConfiguration.MAXSECONDS} seconds. Please choose a video maximum of ${instagramReelsConfiguration.MAXSECONDS} seconds.`
        );
      if (videoFile.video_length < instagramReelsConfiguration.MINSECONDS)
        iErrors.push(
          `Minimum video length for the reels is ${instagramReelsConfiguration.MINSECONDS} seconds. Please choose a video minimum of ${instagramReelsConfiguration.MINSECONDS} seconds.`
        );
      if (
        videoFile?.file_size !== 0 &&
        videoFile?.file_size > instagramReelsConfiguration.MAX_VIDEO_SIZE_MB * 1024 * 1024
      ) {
        iErrors.push(
          `Video size must be less than ${instagramReelsConfiguration.MAX_VIDEO_SIZE_MB} MB. Your video is ${(
            videoFile?.file_size /
            1024 /
            1024
          ).toFixed(2)} MB.`
        );
      }
      const { width, height } = videoFile.aspect_ratio;
      const aspectRatio = width / height;
      if (width > instagramReelsConfiguration.MAXWIDTH) {
        iErrors.push(`Maximum resolution is ${instagramReelsConfiguration.MAXWIDTH}px. Your media is ${width}px.`);
      }

      if (aspectRatio < 0.01 || aspectRatio > 10) {
        iErrors.push(
          `Video  has an invalid aspect ratio of ${aspectRatio.toFixed(2)}. The required range is 0.01:1 to 10:1.`
        );
      }
    }
  }
  if (request.description) {
    const hashtags = request.description.match(/#\w+/g) || []; // Match all hashtags
    if (hashtags.length > 30) {
      iErrors.push(`Your description contains ${hashtags.length} hashtags but only up to 30 are allowed.`);
    }
    if (request.description.length > instagramReelsConfiguration.DESCRIPTION_MAX) {
      iErrors.push(
        `Your description is ${request.description.length} characters long, but it should not exceed ${instagramReelsConfiguration.DESCRIPTION_MAX} characters.`
      );
    }
  }
  if (isVideoFileAvailable && isImageFileAvailable) {
    iErrors.push("You cant post only image's and videos's in Instagram");
  }
  return iErrors;
};

const InstagramStoryConditions = (request) => {
  let iErrors = [];
  let isVideoFileAvailable = request.media_data?.some((a) => a.media_type === 'VIDEO');
  let isImageFileAvailable = request.media_data?.some((a) => a.media_type === 'IMAGE');
  let isMultipleVideoFiles = request.media_data?.filter((a) => a.media_type?.includes('VIDEO')).length > 1;
  let isMultipleImageFiles = request.media_data?.filter((a) => a.media_type?.includes('IMAGE')).length > 1;
  if (!request.media_data?.length > 0) {
    iErrors.push('Please select any video or image to post a Story');
  }
  if (isMultipleVideoFiles) {
    iErrors.push("You can't post multiple video's as a Story");
  } else {
    let videoFile = request.media_data?.find((a) => a.media_type === 'VIDEO');
    if (videoFile) {
      if (videoFile.video_length > instagramStoryConfiguration.MAXSECONDS)
        iErrors.push(
          `Maximum video length for the story is ${instagramStoryConfiguration.MAXSECONDS} seconds. Please choose a video maximum of ${instagramStoryConfiguration.MAXSECONDS} seconds.`
        );
      const { width, height } = videoFile.aspect_ratio;
      const aspectRatio = width / height;
      if (width > instagramStoryConfiguration.MAXWIDTH || height > instagramStoryConfiguration.MAXHEIGHT) {
        iErrors.push(
          `Maximum resolution is ${instagramStoryConfiguration.MAXWIDTH}x${instagramStoryConfiguration.MAXHEIGHT}px. Your media is ${width}x${height}px.`
        );
      }
      if (
        aspectRatio < instagramStoryConfiguration.MIN_VIDEO_ASPECT_RATIO ||
        aspectRatio > instagramStoryConfiguration.MAX_VIDEO_ASPECT_RATIO
      ) {
        iErrors.push(
          `Video  has an invalid aspect ratio of ${aspectRatio.toFixed(2)}. The required range is 0.01:1 to 10:1.`
        );
      }
    }
  }
  if (isMultipleImageFiles) {
    iErrors.push("You can't post multiple image's as a Story");
  } else {
    let imageFile = request.media_data?.find((a) => a.media_type === 'IMAGE');
    if (imageFile) {
      const { width, height } = imageFile?.aspect_ratio;
      const aspectRatio = width / height;
      if (
        imageFile?.file_size !== 0 &&
        imageFile?.file_size > instagramStoryConfiguration.MAX_IMAGE_SIZE_MB * 1024 * 1024
      ) {
        iErrors.push(
          `Image size must be less than ${instagramStoryConfiguration.MAX_IMAGE_SIZE_MB} MB. Your Image is ${(
            imageFile?.file_size /
            1024 /
            1024
          ).toFixed(2)} MB.`
        );
      }

      if (
        aspectRatio < instagramStoryConfiguration.MIN_IMAGE_ASPECT_RATIO ||
        aspectRatio > instagramStoryConfiguration.MAX_IMAGE_ASPECT_RATIO
      ) {
        iErrors.push(
          `Image has an invalid aspect ratio of ${aspectRatio.toFixed(
            2
          )}. The allowed range is 4:5 (0.8) to 1.91:1 (1.91).`
        );
      }
    }
  }
  if (isVideoFileAvailable && isImageFileAvailable) {
    iErrors.push("You cant post only image's and videos's in Instagram");
  }
  return iErrors;
};

const TwitterPostConditions = (request) => {
  let tErrors = [];
  let imageFiles = request.media_data?.filter((a) => a.media_type === 'IMAGE') || [];
  let videoFiles = request.media_data?.filter((a) => a.media_type === 'VIDEO') || [];

  // Check for required content
  let hasAttachments = request.media_data?.length > 0;
  if (!hasAttachments && !request.description) {
    tErrors.push('Please enter a description or add media');
  }

  if (imageFiles.length > 4) {
    tErrors.push(`Only a maximum of ${twitterPostConfiguration.MAXIMAGECOUNT} images are allowed for a post.`);
  }

  if (videoFiles.length > 1) {
    tErrors.push('Only a single video is allowed for a post.');
  }

  if (imageFiles.length > 0 && videoFiles.length > 0) {
    tErrors.push('You cannot include both images and videos in a single Twitter post.');
  }

  if (request.description && request.description.length > 280) {
    tErrors.push('Twitter description should not exceed 280 characters.');
  }

  // Image validations
  for (let i = 0; i < imageFiles.length; i++) {
    if (imageFiles[i]?.aspect_ratio) {
      const { width, height } = imageFiles[i].aspect_ratio;
      if (width < twitterPostConfiguration.IMAGE_MINWIDTH || height < twitterPostConfiguration.IMAGE_MINHEIGHT) {
        tErrors.push(
          `Image ${i + 1}: The minimum allowed dimensions are ${twitterPostConfiguration.IMAGE_MINWIDTH}x${
            twitterPostConfiguration.IMAGE_MINHEIGHT
          }. Your image is ${width}x${height}.`
        );
      }
      if (width > twitterPostConfiguration.IMAGE_MAXWIDTH || height > twitterPostConfiguration.IMAGE_MAXHEIGHT) {
        tErrors.push(
          `Image ${i + 1}: The maximum allowed dimensions are ${twitterPostConfiguration.IMAGE_MAXWIDTH}x${
            twitterPostConfiguration.IMAGE_MAXHEIGHT
          }. Your image is ${width}x${height}.`
        );
      }
    }

    if (
      imageFiles[i]?.file_size !== 0 &&
      imageFiles[i]?.file_size > twitterPostConfiguration.MAX_IMAGE_SIZE_MB * 1024 * 1024
    ) {
      tErrors.push(
        `Image ${i + 1} size must be less than ${twitterPostConfiguration.MAX_IMAGE_SIZE_MB} MB. Your image is ${(
          imageFiles[i]?.file_size /
          1024 /
          1024
        ).toFixed(2)} MB.`
      );
    }
  }

  // Video validations
  if (videoFiles.length > 0) {
    videoFiles.forEach((videoFile, index) => {
      if (!videoFile?.video_length) {
        tErrors.push(`Video ${index + 1}: Unable to determine video length.`);
      } else {
        if (videoFile.video_length < twitterPostConfiguration.MINSECONDS) {
          tErrors.push(
            `Video ${index + 1}: Minimum video length for Twitter is ${
              twitterPostConfiguration.MINSECONDS
            } seconds. Your video is ${videoFile.video_length} seconds.`
          );
        }
        if (videoFile.video_length > twitterPostConfiguration.MAXSECONDS) {
          tErrors.push(
            `Video ${index + 1}: Maximum video length for Twitter is ${
              twitterPostConfiguration.MAXSECONDS
            } seconds. Your video is ${videoFile.video_length} seconds.`
          );
        }
      }

      if (videoFile?.aspect_ratio) {
        const { width, height } = videoFile.aspect_ratio;
        if (width < twitterPostConfiguration.MINWIDTH || height < twitterPostConfiguration.MINHEIGHT) {
          tErrors.push(
            `Video ${index + 1}: The minimum allowed dimensions are ${twitterPostConfiguration.MINWIDTH}x${
              twitterPostConfiguration.MINHEIGHT
            }. Your video is ${width}x${height}.`
          );
        }
        if (width > twitterPostConfiguration.MAXWIDTH || height > twitterPostConfiguration.MAXHEIGHT) {
          tErrors.push(
            `Video ${index + 1}: The maximum allowed dimensions are ${twitterPostConfiguration.MAXWIDTH}x${
              twitterPostConfiguration.MAXHEIGHT
            }. Your video is ${width}x${height}.`
          );
        }
      }

      if (
        videoFile?.file_size !== 0 &&
        videoFile?.file_size > twitterPostConfiguration.MAX_VIDEO_SIZE_MB * 1024 * 1024
      ) {
        tErrors.push(
          `Video size must be less than ${twitterPostConfiguration.MAX_VIDEO_SIZE_MB} MB. Your video is ${(
            videoFile?.file_size /
            1024 /
            1024
          ).toFixed(2)} MB.`
        );
      }
    });
  }

  return tErrors;
};

const YoutubePostConditions = (request) => {
  let yErrors = [];
  let isImageFileAvailable = request.media_data?.some((a) => a.media_type === 'IMAGE');
  let isVideoFileAvailable = request.media_data?.some((a) => a.media_type === 'VIDEO');
  let isMultipleVideoFiles = request.media_data?.filter((a) => a.media_type?.includes('VIDEO')).length > 1;
  if (!isVideoFileAvailable) {
    yErrors.push('Please select any video');
  }
  if (isMultipleVideoFiles) {
    yErrors.push("You can't post multiple video's");
  } else {
    let videoFile = request.media_data?.find((a) => a.media_type === 'VIDEO');
    if (videoFile) {
      if (videoFile.video_length > youtubePostConfiguration.MAXSECONDS)
        yErrors.push(
          `Maximum video length for the post is ${youtubePostConfiguration.MAXSECONDS} seconds. Please choose a video maximum of ${youtubePostConfiguration.MAXSECONDS} seconds.`
        );
      if (
        videoFile?.file_size !== 0 &&
        videoFile?.file_size > youtubePostConfiguration.MAX_VIDEO_SIZE_MB * 1024 * 1024
      ) {
        yErrors.push(
          `Video size must be less than ${youtubePostConfiguration.MAX_VIDEO_SIZE_MB} MB. Your video is ${(
            videoFile?.file_size /
            1024 /
            1024
          ).toFixed(2)} MB.`
        );
      }
    }
  }
  if (isImageFileAvailable) yErrors.push("You can't post image in YouTube");
  if (!request.title) {
    yErrors.push('Please enter YouTube title');
  }
  if (request.title && request.title.length > youtubeCommonConfiguration.TITLE_MAX) {
    yErrors.push('YouTube titles should not exceed 100 characters');
  }
  if (request.description && request.description.length > youtubeCommonConfiguration.DESCRIPTION_MAX) {
    yErrors.push('YouTube description should not exceed 5000 characters');
  }
  let isThumbnailAvailable = request?.thumbnail;
  if (isVideoFileAvailable && isThumbnailAvailable && !CommonHelper.isValidImageUrl(isThumbnailAvailable))
    yErrors.push('Please Enter Valid Thumbnail Url');
  return yErrors;
};

const YoutubeShortsConditions = (request) => {
  let yErrors = [];
  let isVideoFileAvailable = request.media_data?.some((a) => a.media_type === 'VIDEO');
  let isImageFileAvailable = request.media_data?.some((a) => a.media_type === 'IMAGE');
  let isMultipleVideoFiles = request.media_data?.filter((a) => a.media_type.includes('VIDEO')).length > 1;
  if (!isVideoFileAvailable) {
    yErrors.push('Please select any video to post a Shorts');
  }
  if (isMultipleVideoFiles) {
    yErrors.push("You can't post multiple video's as a Shorts");
  } else {
    let videoFile = request.media_data?.find((a) => a.media_type === 'VIDEO');
    if (videoFile) {
      const { width, height } = videoFile.aspect_ratio || {};
      const aspectRatio = width && height ? width / height : null;

      // Aspect ratio validation
      if (aspectRatio !== null) {
        if (aspectRatio !== 1 && aspectRatio !== 9 / 16) {
          yErrors.push(
            `Video dimensions are invalid. Only square (1:1) and portrait (9:16) aspect ratios are accepted for YouTube. Your video has an aspect ratio of ${aspectRatio.toFixed(
              2
            )}.`
          );
        }
      }
      if (videoFile.video_length > youtubeShortsConfiguration.MAXSECONDS)
        yErrors.push(
          `Maximum video length for the Shorts is ${youtubeShortsConfiguration.MAXSECONDS} seconds. Please choose a video maximum of ${youtubeShortsConfiguration.MAXSECONDS} seconds.`
        );
      if (
        videoFile?.file_size !== 0 &&
        videoFile?.file_size > youtubePostConfiguration.MAX_VIDEO_SIZE_MB * 1024 * 1024
      ) {
        yErrors.push(
          `Video size must be less than ${youtubePostConfiguration.MAX_VIDEO_SIZE_MB} MB. Your video is ${(
            videoFile.file_size /
            1024 /
            1024
          ).toFixed(2)} MB.`
        );
      }
      // if (!CommonHelper.CheckAspectRatioForVideo(request.media_data, youtubeShortsConfiguration.ASPECTRATIO)) {
      //     yErrors.push(
      //         `YouTube shorts only support ${youtubeShortsConfiguration.ASPECTRATIO} aspect ratio. the video file you selected is not supported for posting reels. To change the aspect ratio please check the checbox below`
      //     );
      // }
    }
  }
  if (request.media_data?.length === 0) yErrors.push('You can post only video in YouTube');
  if (!request.title) {
    yErrors.push('Please enter YouTube title');
  }
  if (request.title && request.title.length > youtubeCommonConfiguration.TITLE_MAX) {
    yErrors.push('YouTube titles should not exceed 100 characters');
  }
  if (request.description && request.description.length > youtubeCommonConfiguration.DESCRIPTION_MAX) {
    yErrors.push('YouTube description should not exceed 5000 characters');
  }
  if (isImageFileAvailable) yErrors.push("You can't post image in YouTube");
  let isThumbnailAvailable = request?.thumbnail;
  if (isVideoFileAvailable && isThumbnailAvailable && !CommonHelper.isValidImageUrl(isThumbnailAvailable))
    yErrors.push('Please Enter Valid Thumbnail Url');
  return yErrors;
};

const LinkedinPostConditions = (request) => {
  let lErrors = [];
  let videoFiles = request.media_data && request.media_data?.filter((a) => a.media_type.includes('VIDEO'));
  let isMultipleVideoFiles = videoFiles?.length > 1;
  let imagesCount = request.media_data && request.media_data?.filter((a) => a.media_type.includes('IMAGE')).length;
  let altText = request.alert_text ? request.alert_text.split(',') : [];

  if (isMultipleVideoFiles) {
    lErrors.push("You can't post multiple videos at a time.");
  } else if (videoFiles && videoFiles.length && videoFiles.length === 1 && imagesCount > 0) {
    lErrors.push('You can only post one video at a time without images.');
  } else if (!isMultipleVideoFiles && imagesCount > linkedinPostConfiguration.MAXIMAGECOUNT) {
    lErrors.push(`Only a maximum of ${linkedinPostConfiguration.MAXIMAGECOUNT} images are allowed per post.`);
  }
  if (videoFiles && videoFiles?.length > 0) {
    if (videoFiles[0]?.video_length > linkedinPostConfiguration.MAXSECONDS) {
      lErrors.push(
        `Maximum video length for the post is ${
          linkedinPostConfiguration.MAXSECONDS / 60
        } minutes. Please choose a video maximum of ${linkedinPostConfiguration.MAXSECONDS / 60} minutes.`
      );
    }

    if (
      videoFiles[0]?.aspect_ratio.width < linkedinPostConfiguration.MINWIDTH ||
      videoFiles[0]?.aspect_ratio.height < linkedinPostConfiguration.MINHEIGHT
    ) {
      lErrors.push(
        `Video The minimum allowed video dimensions are ${linkedinPostConfiguration.MINWIDTH}x${linkedinPostConfiguration.MINHEIGHT}. Your video is ${videoFiles[0]?.aspect_ratio.width}x${videoFiles[0]?.aspect_ratio.height}.`
      );
    }

    if (
      videoFiles[0]?.aspect_ratio.width > linkedinPostConfiguration.MAXWIDTH ||
      videoFiles[0]?.aspect_ratio.height > linkedinPostConfiguration.MAXHEIGHT
    ) {
      lErrors.push(
        `Video  The maximum allowed video dimensions are ${linkedinPostConfiguration.MAXWIDTH}x${linkedinPostConfiguration.MAXHEIGHT}. Your video is ${videoFiles[0]?.aspect_ratio.width}x${videoFiles[0]?.aspect_ratio.height}.`
      );
    }
    if (
      videoFiles[0]?.file_size !== 0 &&
      videoFiles[0]?.file_size > linkedinPostConfiguration.MAX_VIDEO_SIZE_MB * 1024 * 1024
    ) {
      lErrors.push(
        `Video size must be less than ${linkedinPostConfiguration.MAX_VIDEO_SIZE_MB} MB. Your video is ${(
          videoFiles[0]?.file_size /
          1024 /
          1024
        ).toFixed(2)} MB.`
      );
    }
  }

  if (request.description.length > linkedinPostConfiguration.DESCRIPTION_MAX) {
    lErrors.push(
      `Your description is ${request.description.length} characters long, but it should not exceed ${linkedinPostConfiguration.DESCRIPTION_MAX} characters.`
    );
  }

  let hasAttachments = request.media_data?.length > 0;
  if (!hasAttachments && !request.description) {
    lErrors.push('Please enter a description');
  }
  for (let i = 0; i < altText.length; i++) {
    if (altText[i].length > linkedinPostConfiguration.ALTER_TEXT_MAX) {
      lErrors.push(
        `Alt text ${i + 1} exceeds the maximum character limit of ${linkedinPostConfiguration.ALTER_TEXT_MAX}.`
      );
    }
  }
  return lErrors;
};

const GbusinessStandardConditions = (request) => {
  let gErrors = [];
  let isMultipleVideoFiles = request.media_data?.filter((a) => a.media_type.includes('IMAGE')).length > 1;
  if (isMultipleVideoFiles) gErrors.push("You can't post multiple image");
  else {
    let videoFile = request.media_data?.some((a) => a.media_type === 'VIDEO');
    if (videoFile) {
      gErrors.push("You can't post videos");
    }
  }

  if (request.button_type !== 'None' && request.button_type !== 'CALL') {
    if (!request.url) {
      gErrors.push('Please enter a Button URL');
    } else if (!CommonHelper.isValidUrl(request.url)) {
      gErrors.push('Please enter a valid Button URL');
    }
  }

  let hasAttachments = request.media_data?.length > 0;
  if (!hasAttachments && !request.description) {
    gErrors.push('Please enter a description');
  }
  request.media_data?.forEach((media) => {
    if (media.media_type === 'IMAGE') {
      let { width, height } = media.aspect_ratio || {};
      if (
        width < gbusinessPostConfiguration.MINWIDTH ||
        height < gbusinessPostConfiguration.MINHEIGHT ||
        width > gbusinessPostConfiguration.MAXWIDTH ||
        height > gbusinessPostConfiguration.MAXHEIGHT
      ) {
        gErrors.push(
          `Image dimensions must be between 250x250 and 3000x3000 pixels. Current dimensions: ${width}x${height}`
        );
      }
      if (media?.file_size !== 0 && media?.file_size > gbusinessPostConfiguration.MAX_IMAGE_SIZE_MB * 1024 * 1024) {
        gErrors.push(
          `Image  size must be less than ${gbusinessPostConfiguration.MAX_IMAGE_SIZE_MB} MB. Your image is ${(
            media?.file_size /
            1024 /
            1024
          ).toFixed(2)} MB.`
        );
      }
      if (media?.file_size !== 0 && media?.file_size < gbusinessPostConfiguration.MIN_IMAGE_SIZE_KB * 1024) {
        gErrors.push(
          `Image  size must be less than ${gbusinessPostConfiguration.MIN_IMAGE_SIZE_KB} KB. Your image is ${(
            media?.file_size / 1024
          ).toFixed(2)} KB.`
        );
      }
    }
  });

  if (request.description && request.description.length > 1500) {
    gErrors.push('Character count cannot exceed 1500 characters for Google Business Profile description');
  }
  if (request.title && request.title.length >= 58) {
    gErrors.push('Character count cannot exceed 58 characters for Google Business Profile Event title');
  }

  return gErrors;
};

const GbusinessEventConditions = (request) => {
  let gErrors = [];
  let isMultipleVideoFiles = request.media_data?.filter((a) => a.media_type.includes('IMAGE')).length > 1;
  if (isMultipleVideoFiles) gErrors.push("You can't post multiple image");
  else {
    let videoFile = request.media_data?.some((a) => a.media_type === 'VIDEO');
    if (videoFile) {
      gErrors.push("You can't post videos");
    }
  }
  if (!request.title) {
    gErrors.push('Please enter the Event title');
  }

  if (request.button_type !== 'None' && request.button_type !== 'CALL') {
    if (!request.url) {
      gErrors.push('Please enter a Button URL');
    } else if (!CommonHelper.isValidUrl(request.url)) {
      gErrors.push('Please enter a valid Button URL');
    }
  }

  let hasAttachments = request.media_data?.length > 0;
  if (!hasAttachments && !request.description) {
    gErrors.push('Please enter a description');
  }

  const startDateTime = dayjs(request.start_date)
    .set('hour', dayjs(request.start_time).hour())
    .set('minute', dayjs(request.start_time).minute());
  const endDateTime = dayjs(request.end_date)
    .set('hour', dayjs(request.end_time).hour())
    .set('minute', dayjs(request.end_time).minute());
  if (startDateTime.isAfter(endDateTime)) {
    gErrors.push('Start Date & Time must be earlier than End Date & Time.');
  }

  request.media_data?.forEach((media) => {
    if (media.media_type === 'IMAGE') {
      let { width, height } = media.aspect_ratio || {};
      if (
        width < gbusinessPostConfiguration.MINWIDTH ||
        height < gbusinessPostConfiguration.MINHEIGHT ||
        width > gbusinessPostConfiguration.MAXWIDTH ||
        height > gbusinessPostConfiguration.MAXHEIGHT
      ) {
        gErrors.push(
          `Image dimensions must be between 250x250 and 3000x3000 pixels. Current dimensions: ${width}x${height}`
        );
      }
      if (media?.file_size !== 0 && media?.file_size > gbusinessPostConfiguration.MAX_IMAGE_SIZE_MB * 1024 * 1024) {
        gErrors.push(
          `Image  size must be less than ${gbusinessPostConfiguration.MAX_IMAGE_SIZE_MB} MB. Your image is ${(
            media.file_size /
            1024 /
            1024
          ).toFixed(2)} MB.`
        );
      }
    }
  });

  if (request.description && request.description.length > 1500) {
    gErrors.push('Character count cannot exceed 1500 characters for Google Business Profile description');
  }
  if (request.title && request.title.length >= 58) {
    gErrors.push('Character count cannot exceed 58 characters for Google Business Profile Event title');
  }
  return gErrors;
};

const GbusinessOfferConditions = (request) => {
  let gErrors = [];
  let isMultipleVideoFiles = request.media_data?.filter((a) => a.media_type.includes('IMAGE')).length > 1;
  if (isMultipleVideoFiles) gErrors.push("You can't post multiple image");
  else {
    let videoFile = request.media_data?.some((a) => a.media_type === 'VIDEO');
    if (videoFile) {
      gErrors.push("You can't post videos");
    }
  }
  if (!request.title) {
    gErrors.push('Please enter the Offer title');
  }

  let hasAttachments = request.media_data?.length > 0;
  if (!hasAttachments && !request.description) {
    gErrors.push('Please enter a description');
  }

  const startDateTime = dayjs(request.start_date)
    .tz(brandTimeZone, true)
    .set('hour', dayjs(request.start_time).hour())
    .set('minute', dayjs(request.start_time).minute());
  const endDateTime = dayjs(request.end_date)
    .tz(brandTimeZone, true)
    .set('hour', dayjs(request.end_time).hour())
    .set('minute', dayjs(request.end_time).minute());
  if (startDateTime.isAfter(endDateTime)) {
    gErrors.push('Start Date & Time must be earlier than End Date & Time.');
  }
  request.media_data?.forEach((media) => {
    if (media.media_type === 'IMAGE') {
      let { width, height } = media.aspect_ratio || {};
      if (
        width < gbusinessPostConfiguration.MINWIDTH ||
        height < gbusinessPostConfiguration.MINHEIGHT ||
        width > gbusinessPostConfiguration.MAXWIDTH ||
        height > gbusinessPostConfiguration.MAXHEIGHT
      ) {
        gErrors.push(
          `Image dimensions must be between 250x250 and 3000x3000 pixels. Current dimensions: ${width}x${height}`
        );
      }
      if (media?.file_size !== 0 && media?.file_size > gbusinessPostConfiguration.MAX_IMAGE_SIZE_MB * 1024 * 1024) {
        gErrors.push(
          `Image  size must be less than ${gbusinessPostConfiguration.MAX_IMAGE_SIZE_MB} MB. Your image is ${(
            media?.file_size /
            1024 /
            1024
          ).toFixed(2)} MB.`
        );
      }
    }
  });

  if (request.description && request.description.length > 1500) {
    gErrors.push('Character count cannot exceed 1500 characters for Google Business Profile description');
  }
  if (request.title && request.title.length >= 58) {
    gErrors.push('Character count cannot exceed 58 characters for Google Business Profile Event title');
  }
  return gErrors;
};

const TiktokDirectPostConditions = (request) => {
  let fErrors = [];

  let isMultipleVideoFiles = request.media_data?.filter((a) => a.media_type.includes('VIDEO'))?.length > 1;
  let isImageFileAvailable = request.media_data?.some((a) => a.media_type === 'IMAGE');
  let isMultipleImageFiles = request.media_data?.filter((a) => a.media_type.includes('IMAGE'))?.length > 1;
  let isVideoFileAvailable = request.media_data?.some((a) => a.media_type === 'VIDEO');
  if (isImageFileAvailable && isVideoFileAvailable) {
    fErrors.push("You can't post both an image and a video in the same post.");
  }
  if (isMultipleVideoFiles) {
    fErrors.push("You can't post multiple videos.");
  } else {
    let videoFile = request.media_data?.find((a) => a.media_type === 'VIDEO');
    if (videoFile) {
      if (videoFile.video_length > tiktokPostConfiguration.VIDEO.MAXSECONDS) {
        fErrors.push(
          `Maximum video length for the post is ${tiktokPostConfiguration.VIDEO.MAXSECONDS} seconds. Please choose a video with a maximum length of ${tiktokPostConfiguration.VIDEO.MAXSECONDS} seconds.`
        );
      }

      if (videoFile.video_length < tiktokPostConfiguration.VIDEO.MINSECONDS) {
        fErrors.push(
          `Minimum video length for the post is ${tiktokPostConfiguration.VIDEO.MINSECONDS} seconds. Please choose a video with at least ${tiktokPostConfiguration.VIDEO.MINSECONDS} seconds.`
        );
      }

      if (
        videoFile.aspect_ratio.width < tiktokPostConfiguration.VIDEO.MINWIDTH ||
        videoFile.aspect_ratio.height < tiktokPostConfiguration.VIDEO.MINHEIGHT
      ) {
        fErrors.push(
          `Video resolution is too low. Minimum resolution should be ${tiktokPostConfiguration.VIDEO.MINWIDTH}x${tiktokPostConfiguration.VIDEO.MINHEIGHT}.`
        );
      }
      if (
        videoFile?.file_size !== 0 &&
        videoFile?.file_size > tiktokPostConfiguration.MAX_VIDEO_SIZE_MB * 1024 * 1024
      ) {
        fErrors.push(
          `Video size must be less than ${tiktokPostConfiguration.MAX_VIDEO_SIZE_MB} MB. Your video is ${(
            videoFile?.file_size /
            1024 /
            1024
          ).toFixed(2)} MB.`
        );
      }
    }
  }
  let hasAttachments = request.media_data?.length > 0;
  if (!hasAttachments && !request.description) {
    fErrors.push('Please enter a description');
  }
  if (!hasAttachments) {
    fErrors.push('Please select any video or image');
  }
  if (isImageFileAvailable) {
    request.media_data.forEach((imageFile) => {
      if (imageFile.media_type === 'IMAGE') {
        let imageFileType = CommonHelper.GetFileTypeFromUrl(imageFile.media_url);
        if (imageFileType && imageFileType.toLowerCase() !== 'jpeg') {
          fErrors.push(`Tiktok only supports JPEG image`);
        } else {
          if (
            imageFile.aspect_ratio.width < tiktokPostConfiguration.IMAGE.MINWIDTH ||
            imageFile.aspect_ratio.height < tiktokPostConfiguration.IMAGE.MINHEIGHT
          ) {
            fErrors.push(`Image resolution is too low. Minimum resolution should be 360x360 pixels.`);
          }
          if (
            (imageFile.aspect_ratio.width >= 1080 || imageFile.aspect_ratio.height >= 1920) &&
            (imageFile.aspect_ratio.width >= 1920 || imageFile.aspect_ratio.height >= 1080)
          ) {
            fErrors.push(`Image resolution is too high. Maximum resolution should be 1080x1920 or 1920x1080 pixels.`);
          }
          if (
            imageFile?.file_size !== 0 &&
            imageFile?.file_size > tiktokPostConfiguration.IMAGE.FILESIZE * 1024 * 1024
          ) {
            fErrors.push(
              `Image size must be less than ${tiktokPostConfiguration.IMAGE.FILESIZE} MB. Your image is ${(
                imageFile?.file_size /
                1024 /
                1024
              ).toFixed(2)} MB.`
            );
          }
        }
      }
    });
  }
  let imageFiles = request.media_data?.filter((a) => a.media_type === 'IMAGE');
  if (imageFiles?.length > tiktokPostConfiguration.IMAGE.MAXIMAGECOUNT) {
    fErrors.push(
      `You can upload a maximum of ${tiktokPostConfiguration.IMAGE.MAXIMAGECOUNT} images. Please remove some images.`
    );
  }
  if (request.description && request.description.length > tiktokPostConfiguration.DESCRIPTION_MAX) {
    fErrors.push('Tiktok description should not exceed 2200 characters');
  }
  if (request.title && request.description.title > tiktokPostConfiguration.TITLE_MAX) {
    fErrors.push('Tiktok title should not exceed 90 characters');
  }

  return fErrors;
};

const TiktokUploadToInboxConditions = (request) => {
  let fErrors = [];

  let isMultipleVideoFiles = request.media_data?.filter((a) => a.media_type.includes('VIDEO'))?.length > 1;
  let isImageFileAvailable = request.media_data?.some((a) => a.media_type === 'IMAGE');
  let isVideoFileAvailable = request.media_data?.some((a) => a.media_type === 'VIDEO');

  if (isImageFileAvailable && isVideoFileAvailable) {
    fErrors.push("You can't post both an image and a video in the same post.");
  }

  if (isMultipleVideoFiles) {
    fErrors.push("You can't post multiple videos.");
  } else {
    let videoFile = request.media_data?.find((a) => a.media_type === 'VIDEO');
    if (videoFile) {
      if (videoFile.video_length > tiktokPostConfiguration.VIDEO.MAXSECONDS) {
        fErrors.push(
          `Maximum video length for the post is ${tiktokPostConfiguration.VIDEO.MAXSECONDS} seconds. Please choose a video with a maximum length of ${tiktokPostConfiguration.VIDEO.MAXSECONDS} seconds.`
        );
      }

      if (videoFile.video_length < tiktokPostConfiguration.VIDEO.MINSECONDS) {
        fErrors.push(
          `Minimum video length for the post is ${tiktokPostConfiguration.VIDEO.MINSECONDS} seconds. Please choose a video with at least ${tiktokPostConfiguration.VIDEO.MINSECONDS} seconds.`
        );
      }

      if (
        videoFile.aspect_ratio.width < tiktokPostConfiguration.VIDEO.MINWIDTH ||
        videoFile.aspect_ratio.height < tiktokPostConfiguration.VIDEO.MINHEIGHT
      ) {
        fErrors.push(
          `Video resolution is too low. Minimum resolution should be ${tiktokPostConfiguration.VIDEO.MINWIDTH}x${tiktokPostConfiguration.VIDEO.MINHEIGHT}.`
        );
      }
      if (
        videoFile?.file_size !== 0 &&
        videoFile?.file_size > tiktokPostConfiguration.MAX_VIDEO_SIZE_MB * 1024 * 1024
      ) {
        fErrors.push(
          `Video size must be less than ${tiktokPostConfiguration.MAX_VIDEO_SIZE_MB} MB. Your video is ${(
            videoFile?.file_size /
            1024 /
            1024
          ).toFixed(2)} MB.`
        );
      }
    }
  }

  let hasAttachments = request.media_data?.length > 0;
  if (!hasAttachments && !request.description) {
    fErrors.push('Please enter a description');
  }
  if (!hasAttachments) {
    fErrors.push('Please select any video or image');
  }
  if (isImageFileAvailable) {
    request.media_data.forEach((imageFile) => {
      if (imageFile.media_type === 'IMAGE') {
        let imageFileType = CommonHelper.GetFileTypeFromUrl(imageFile.media_url);
        if (imageFileType && imageFileType.toLowerCase() !== 'jpeg') {
          fErrors.push(`Tiktok only supports JPEG image`);
        } else {
          if (
            imageFile.aspect_ratio.width < tiktokPostConfiguration.IMAGE.MINWIDTH ||
            imageFile.aspect_ratio.height < tiktokPostConfiguration.IMAGE.MINHEIGHT
          ) {
            fErrors.push(`Image resolution is too low. Minimum resolution should be 360x360 pixels.`);
          }
          if (
            (imageFile.aspect_ratio.width >= 1080 || imageFile.aspect_ratio.height >= 1920) &&
            (imageFile.aspect_ratio.width >= 1920 || imageFile.aspect_ratio.height >= 1080)
          ) {
            fErrors.push(`Image resolution is too high. Maximum resolution should be 1080x1920 or 1920x1080 pixels.`);
          }
        }
      }
    });
  }
  let imageFiles = request.media_data?.filter((a) => a.media_type === 'IMAGE');
  if (imageFiles?.length > tiktokPostConfiguration.IMAGE.MAXIMAGECOUNT) {
    fErrors.push(
      `You can upload a maximum of ${tiktokPostConfiguration.IMAGE.MAXIMAGECOUNT} images. Please remove some images.`
    );
  }
  if (request.description && request.description.length > tiktokPostConfiguration.DESCRIPTION_MAX) {
    fErrors.push('Tiktok description should not exceed 2200 characters');
  }
  if (request.title && request.description.title > tiktokPostConfiguration.TITLE_MAX) {
    fErrors.push('Tiktok title should not exceed 90 characters');
  }
  return fErrors;
};

const PinterestPostConditions = (request) => {
  let pErrors = [];
  let isImageFileAvailable = request.media_data?.some((a) => a.media_type === 'IMAGE');
  let isVideoFileAvailable = request.media_data?.some((a) => a.media_type === 'VIDEO');
  let isMultipleVideoFiles = request.media_data?.filter((a) => a.media_type.includes('VIDEO'))?.length > 1;
  if (isMultipleVideoFiles) pErrors.push("You can't post multiple video's");

  let isMultipleImageFiles = request.media_data?.filter((a) => a.media_type.includes('IMAGE'))?.length > 1;
  if (isMultipleImageFiles) pErrors.push("You can't post multiple image's");

  if (isImageFileAvailable && isVideoFileAvailable) {
    pErrors.push("You can't post both an image and a video in the same post.");
  }

  let hasAttachments = request.media_data?.length > 0;
  if (!hasAttachments) {
    pErrors.push('Please select any video or image');
  }

  let isBoardIdCreated = request.board_id === '';
  if (isBoardIdCreated) {
    pErrors.push('You must create a Board');
  }

  let isLinkEnters = request.link?.length > 0;
  if (isLinkEnters && !CommonHelper.isValidUrl(request.link)) {
    pErrors.push('Please enter a valid URL');
  }
  if (request.description && request.description.length > pinterestPostConfiguration.DESCRIPTION_MAX) {
    pErrors.push('Pinterest description should not exceed 800 characters');
  }
  if (request.title && request.title.length > pinterestPostConfiguration.TITLE_MAX) {
    pErrors.push('Pinterest title should not exceed 100 characters');
  }
  request?.media_data?.forEach((media) => {
    if (media.media_type === 'IMAGE') {
      if (media?.file_size !== 0 && media?.file_size > pinterestPostConfiguration.MAX_IMAGE_SIZE_MB * 1024 * 1024) {
        pErrors.push(
          `Image size must be less than ${pinterestPostConfiguration.MAX_IMAGE_SIZE_MB} MB. Your image is ${(
            media?.file_size /
            1024 /
            1024
          ).toFixed(2)} MB.`
        );
      }
    } else {
      if (media?.file_size !== 0 && media?.file_size > tiktokPostConfiguration.MAX_VIDEO_SIZE_MB * 1024 * 1024) {
        pErrors.push(
          `Video size must be less than ${pinterestPostConfiguration.MAX_VIDEO_SIZE_MB} MB. Your video is ${(
            media?.file_size /
            1024 /
            1024
          ).toFixed(2)} MB.`
        );
      }
    }
  });

  let isCoverImageAvailable = request?.cover_image_url;
  if (isVideoFileAvailable) {
    if (!isCoverImageAvailable) {
      pErrors.push('Please Choose the Cover Image');
    } else if (!CommonHelper.isValidImageUrl(isCoverImageAvailable)) {
      pErrors.push('Please Enter Valid Cover Image Url');
    }
  }

  return pErrors;
};

export const NewPostErrorUtil = {
  FacebookPostConditions,
  FacebookReelsConditions,
  FacebookStoryConditions,
  InstagramPostConditions,
  InstagramReelsConditions,
  InstagramStoryConditions,
  TwitterPostConditions,
  YoutubePostConditions,
  YoutubeShortsConditions,
  LinkedinPostConditions,
  GbusinessStandardConditions,
  GbusinessEventConditions,
  GbusinessOfferConditions,
  TiktokDirectPostConditions,
  TiktokUploadToInboxConditions,
  PinterestPostConditions,
  FacebookBulkUploadConditions,
  InstagramBulkUploadConditions,
  YoutubeBulkUploadConditions,
  TiktokBulkUploadConditions,
  GbusinessBulkUploadConditions,
  PinterestBulkUploadConditions,
  LinkedinBulkUploadConditions,
  TwitterBulkUploadConditions,
};
