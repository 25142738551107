import { Box, Card, Avatar, Typography, IconButton, useTheme, useMediaQuery } from '@mui/material';
import {
  FavoriteBorder,
  MoreHoriz,
  PlayArrow,
  FiberManualRecord,
  VolumeUp,
  VolumeOff,
  MusicNote,
} from '@mui/icons-material';
import { keyframes } from '@mui/system';
import { useState, useEffect, useRef } from 'react';
import styled from '@mui/material/styles/styled';
import Instagramshare from '../../../../src/assets/instagram-share-icon.svg';

const progressAnimation = keyframes`
  0% { width: 0%; }
  100% { width: 100%; }
`;

const ProgressContainer = styled(Box)({
  position: 'absolute',
  top: 8,
  left: 8,
  right: 8,
  display: 'flex',
  gap: 4,
  zIndex: 2,
});

const ProgressBarContainer = styled(Box)({
  height: 2,
  borderRadius: 2,
  backgroundColor: 'rgba(255, 255, 255, 0.4)',
  flexGrow: 1,
  overflow: 'hidden',
  position: 'relative',
});

const ProgressFill = styled(Box)(({ progress }) => ({
  height: '100%',
  backgroundColor: 'white',
  width: `${progress}%`,
  transition: 'width 0.1s linear',
}));

const InstagramStoryCard = ({ media_url, media_type, profile }) => {
  const videoRef = useRef(null);
  const theme = useTheme();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [progress, setProgress] = useState(0);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleTimeUpdate = () => {
      if (video.duration > 0) {
        const currentProgress = (video.currentTime / video.duration) * 100;
        setProgress(currentProgress);
      }
    };

    video.addEventListener('timeupdate', handleTimeUpdate);
    return () => video.removeEventListener('timeupdate', handleTimeUpdate);
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true;
    }
  }, []);

  useEffect(() => {
    setProgress(0);
  }, [media_url]);

  const togglePlay = () => {
    if (!videoRef.current) return;

    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.muted = false;
      videoRef.current.play().catch(console.error);
    }
    setIsPlaying(!isPlaying);
  };

  const toggleMute = () => {
    if (!videoRef.current) return;
    videoRef.current.muted = !isMuted;
    setIsMuted(!isMuted);
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" p={1}>
      <Card
        sx={{
          width: isMobile ? 300 : 340,
          height: 600,
          borderRadius: '12px',
          position: 'relative',
          overflow: 'hidden',
          bgcolor: 'black',
        }}
      >
        <ProgressContainer>
          <ProgressBarContainer>
            <ProgressFill progress={progress} />
          </ProgressBarContainer>
        </ProgressContainer>

        {media_type === 'IMAGE' ? (
          <img
            src={media_url}
            alt="Story"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        ) : (
          <video
            ref={videoRef}
            src={media_url}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              cursor: 'pointer',
            }}
            onClick={togglePlay}
            muted
            playsInline
          />
        )}

        {!isPlaying && media_type === 'VIDEO' && (
          <IconButton
            onClick={togglePlay}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'rgba(0,0,0,0.6)',
              '&:hover': { bgcolor: 'rgba(0,0,0,0.8)' },
              width: 56,
              height: 56,
            }}
          >
            <PlayArrow sx={{ color: 'white', fontSize: 40 }} />
          </IconButton>
        )}

        {/* Gradient Overlay */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '20%',
            background: 'linear-gradient(to bottom, rgba(0,0,0,0.3), transparent)',
          }}
        />

        {/* Header Section */}
        <Box
          sx={{
            position: 'absolute',
            top: 16,
            left: 8,
            right: 8,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            zIndex: 1,
            transition: 'transform 0.3s ease',
            transform: 'translateY(0)',
          }}
        >
          <Avatar
            src={profile.profile_image}
            sx={{
              width: 34,
              height: 34,
              border: '2px solid #fff',
              boxShadow: '0 2px 8px rgba(0,0,0,0.2)',
              marginRight: 1,
            }}
          />
          {/* Centered Content (Profile Name & Music Section) */}
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '50%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.2 }}>
              <Typography
                variant="body2"
                color="white"
                fontWeight={600}
                style={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                  overflow: 'hidden',
                  WebkitBoxDecorationBreak: 'clone',
                  whiteSpace: 'pre-line',
                  textOverflow: 'ellipsis',
                  nowrap: 'true',
                }}
              >
                {profile.profile_name}
              </Typography>
              <FiberManualRecord sx={{ color: 'white', fontSize: 8, mx: 0.5 }} />
              <Typography variant="caption" color="rgba(255,255,255,0.9)">
                Just&nbsp;Now
              </Typography>
            </Box>

            {/* Music Section */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'white', width: '80%' }}>
              <MusicNote sx={{ fontSize: 16 }} />
              <Box sx={{ position: 'relative', flexGrow: 1, overflow: 'hidden' }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: 'max-content',
                    animation: 'marquee 10s linear infinite',
                    '@keyframes marquee': {
                      '0%': { transform: 'translateX(0%)' },
                      '100%': { transform: 'translateX(-50%)' },
                    },
                  }}
                >
                  <Typography variant="body2" sx={{ fontSize: 12, fontWeight: 500, whiteSpace: 'nowrap', pr: 2 }}>
                    {profile.profile_name} · Original sound
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: 12, fontWeight: 500, whiteSpace: 'nowrap' }}>
                    {profile.profile_name} · Original sound
                  </Typography>
                </Box>

                {/* Progress Bar */}
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: -6,
                    left: 0,
                    right: 0,
                    height: 2,
                    backgroundColor: 'rgba(255,255,255,0.2)',
                    borderRadius: 2,
                    overflow: 'hidden',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'white',
                      animation: `${progressAnimation} 3s infinite linear`,
                      position: 'absolute',
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          {/* Right Side - More Options Icon */}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            {media_type === 'VIDEO' && (
              <IconButton sx={{ color: 'white', p: 0.5 }} onClick={toggleMute}>
                {isMuted ? <VolumeOff sx={{ fontSize: 24 }} /> : <VolumeUp sx={{ fontSize: 24 }} />}
              </IconButton>
            )}
            <MoreHoriz sx={{ fontSize: 24, color: 'white' }} />
          </Box>
        </Box>

        {/* Bottom Controls */}
        <Box
          sx={{
            position: 'absolute',
            bottom: 16,
            left: 8,
            right: 8,
            display: 'flex',
            gap: 1.5,
            alignItems: 'center',
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              position: 'relative',
              '&:before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                borderRadius: 4,
                border: '1px solid white',
              },
            }}
          >
            <Typography
              sx={{
                padding: '2px 10px',
                borderRadius: '20px',
                color: 'white',
              }}
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
                overflow: 'hidden',
                WebkitBoxDecorationBreak: 'clone',
                whiteSpace: 'pre-line',
                textOverflow: 'ellipsis',
                nowrap: 'true',
              }}
            >
              Reply to @{profile.profile_name}
            </Typography>
          </Box>
          <FavoriteBorder sx={{ fontSize: 28, color: 'white' }} />
          <img
            src={Instagramshare}
            style={{
              height: 'auto',
              width: '100%',
              maxWidth: '22px',
              maxHeight: '22px',
              color: '#fff !important',
            }}
          />
        </Box>
      </Card>
    </Box>
  );
};

export default InstagramStoryCard;
