import { useEffect, useRef, useState } from 'react';
import { Grid, Typography, IconButton, Box } from '@mui/material';
import { PlayArrow } from '@mui/icons-material';
import { CommonHelper } from 'src/utils/commonHelper';

const PublishedPostMediaView = ({ mediaFiles }) => {
  const [aspectRatios, setAspectRatios] = useState([]);
  const mediaRefs = useRef([]);
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const totalMedia = mediaFiles?.length;
  const remainingCount = totalMedia > 5 ? totalMedia - 5 : 0;

  useEffect(() => {
    const newRatios = mediaRefs.current.map((el) => {
      if (!el) return 1;
      if (el.tagName === 'IMG') return el.naturalWidth / el.naturalHeight;
      if (el.tagName === 'VIDEO') return el.videoWidth / el.videoHeight;
      return 1;
    });
    setAspectRatios(newRatios);
  }, [mediaFiles]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true;
    }
  }, []);

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play().catch(console.error);
      }
      setIsPlaying(!isPlaying);
    }
  };

  const renderMedia = (media, i) => {
    const isImage = media?.type === 'image' || media?.type === 'photo' || media?.media_type === 'photo' || typeof media === 'string';
    const src = media?.url || media?.media_urls || media;

    return (
      <Box sx={{ ...(totalMedia > 1 && { position: 'relative', paddingTop: '100%', overflow: 'hidden' }) }}>
        {isImage ? (
          <img
            ref={(el) => (mediaRefs.current[i] = el)}
            src={src}
            alt={`media-${i}`}
            style={{
              ...(totalMedia > 1 && {
                position: 'absolute',
                top: 0,
                left: 0,
              }),
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        ) : (
          <Box
            sx={{
              position: totalMedia > 1 ? 'absolute' : 'relative',
              top: 0,
              left: 0,
              overflow: 'hidden',
              ...(totalMedia && { width: '100%', height: '100%' }),
            }}
          >
            <video
              ref={(el) => {
                mediaRefs.current[i] = el;
                videoRef.current = el;
              }}
              src={src}
              controls={false}
              onClick={togglePlay}
              muted
              playsInline
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
            {!isPlaying && (
              <IconButton
                onClick={togglePlay}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  bgcolor: 'rgba(0,0,0,0.6)',
                  '&:hover': { bgcolor: 'rgba(0,0,0,0.8)' },
                }}
              >
                <PlayArrow sx={{ color: 'white', fontSize: 40 }} />
              </IconButton>
            )}
          </Box>
        )}
      </Box>
    );
  };

  if (totalMedia === 1) {
    return <Box>{renderMedia(mediaFiles[0], 0)}</Box>;
  }

  if (totalMedia === 2) {
    return (
      <Grid container spacing={0.2}>
        {mediaFiles.map((media, i) => (
          <Grid item xs={6} key={i}>
            {renderMedia(media, i)}
          </Grid>
        ))}
      </Grid>
    );
  }

  if (totalMedia === 3) {
    const hasWideFirst = aspectRatios[0] < 1.0;

    if (hasWideFirst) {
      return (
        <Grid container spacing={0.2}>
          <Grid item xs={8}>
            {renderMedia(mediaFiles[0], 0)}
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {renderMedia(mediaFiles[1], 1)}
              {renderMedia(mediaFiles[2], 2)}
            </Box>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container spacing={0.2}>
        <Grid item xs={8}>
          {renderMedia(mediaFiles[0], 0)}
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {renderMedia(mediaFiles[1], 1)}
            {renderMedia(mediaFiles[2], 2)}
          </Box>
        </Grid>
      </Grid>
    );
  }

  if (totalMedia === 4) {
    const hasWideFirst = aspectRatios[0] < 1.0;
    if (hasWideFirst) {
      return (
        <Grid container spacing={0.2}>
          <Grid item xs={9}>
            {renderMedia(mediaFiles[0], 0)}
          </Grid>
          <Grid item xs={3}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.2 }}>
              {renderMedia(mediaFiles[1], 1)}
              {renderMedia(mediaFiles[2], 2)}
              {renderMedia(mediaFiles[3], 3)}
            </Box>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container spacing={0.2}>
        <Grid item xs={6}>
          {renderMedia(mediaFiles[0], 0)}
        </Grid>
        <Grid item xs={6}>
          {renderMedia(mediaFiles[1], 1)}
        </Grid>
        <Grid item xs={6}>
          {renderMedia(mediaFiles[2], 2)}
        </Grid>
        <Grid item xs={6}>
          {renderMedia(mediaFiles[3], 3)}
        </Grid>
      </Grid>
    );
  }

  if (totalMedia >= 5) {
    const firstFive = mediaFiles.slice(0, 5);
    return (
      <Grid container spacing={0.2}>
        <Grid item xs={6}>
          {renderMedia(firstFive[0], 0)}
        </Grid>
        <Grid item xs={6}>
          {renderMedia(firstFive[1], 1)}
        </Grid>
        <Grid item xs={4}>
          {renderMedia(firstFive[2], 2)}
        </Grid>
        <Grid item xs={4}>
          {renderMedia(firstFive[3], 3)}
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ position: 'relative' }}>
            {renderMedia(firstFive[4], 4)}
            {remainingCount > 0 && (
              <Typography
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  bgcolor: 'rgba(0,0,0,0.6)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  fontSize: 32,
                  fontWeight: 'bold',
                }}
              >
                +{remainingCount}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    );
  }

  // Default for 2 media
  return (
    <Grid container spacing={0.2}>
      {mediaFiles.slice(0, 2).map((media, i) => (
        <Grid item xs={6} key={i}>
          {renderMedia(media, i)}
        </Grid>
      ))}
    </Grid>
  );
};

export default PublishedPostMediaView;
