import axios from "axios";
import HttpServices from "src/services/httpService";
import { imageModeuleUrl } from "src/services/urlService";
import { LocalStorage } from "src/utils/storage/local-storage";

const getToken = () => {
    let token = LocalStorage.getItem('token');
    return token ? token : '';
};

const getRequestHeader = () => {
    const headers = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Content-type': 'multipart/form-data',
        Authorization: getToken(),
    };
    return headers;
};

const UploadMediaComponent = ({ files, handleUploadMediaSuccessCallback, handleUploadMediaFailureCallback }) => {
    let formData = new FormData();
    for (var i = 0; i < files.length; i++) {
        formData.append('fileupload', files[i]);
    }
    // let postProps = {
    //     url: imageModeuleUrl.uploadMedia,
    //     body: formData,
    //     contentType: 'form',
    //     successCallback: handleUploadMediaSuccessCallback,
    //     failureCallback: handleUploadMediaFailureCallback
    // };
    // HttpServices.Post(postProps);

    let config = { headers: getRequestHeader() };
    axios.post(imageModeuleUrl.uploadMedia, formData, config)
        .then((result) => result)
        .then((response) => {
            if (response.status === 200)
                handleUploadMediaSuccessCallback(response.data.data.media_urls[0],response.data.data.media_data[0].media_type,response.data.data.media_data[0].file_size,response.data.data.media_data[0].aspect_ratio);
            else
                handleUploadMediaFailureCallback(response.data.message);
        })
        .catch((error) => {
            handleUploadMediaFailureCallback(error.message);
        });
}
export default UploadMediaComponent;