import { useEffect } from "react";
import { Config } from "src/config";
import HttpServices from "src/services/httpService";
import { integrateModuleURL } from "src/services/urlService";
import YoutubeAuthorizationModal from "../youtubeIntegration/youtubeAuthorizationModal";
import { useState } from "react";
import { useToaster } from "src/utils/toaster/toasterContext";
import { CommonHelper } from "src/utils/commonHelper";
import CustomDomainRedirectIframe from '../customDomainIframe';

const YouTubeReAuthenticate = ({ AccountId, handleClose,getConnectedNetworks }) => {
  const [isAuthorizationModalOpen, setAuthorizationModal] = useState(true);
  const { showToast } = useToaster();
  const isCustomDomain = CommonHelper.CustomDomain();
  let isProcessing = false;
  let intervalId = null;

  useEffect(() => {
    intervalId = setInterval(() => {
      checkAccessTokenReceivedFromYoutube();
    }, Config.Common.socialAccountsIntegrationInterval);
    return () => {
      clearInterval(intervalId);
    };
  }, [])

  const getYoutubeOauthToken = () => {
    window.open(
      `${Config.Youtube.authorizeUrl}?client_id=${Config.Youtube.clientId}&scope=${Config.Youtube.scope}&prompt=consent&access_type=offline&include_granted_scopes=true&state=state_parameter_passthrough_value&redirect_uri=${Config.Youtube.redirectUri}&response_type=code`, "_blank"
    );
  }

  const checkAccessTokenReceivedFromYoutube = () => {
    if (isCustomDomain) {
      const handleMessage = (event) => {
          const { type, code } = event.data;
          if (type === "youtube_token" && code && !isProcessing) {
            isProcessing=true;
              window.removeEventListener("message", handleMessage, false); // Remove event listener
              clearInterval(intervalId); // Stop polling
              sendAccessTokenToServer(code); // Process the code
          }
      };

      window.addEventListener("message", handleMessage, false);
  } else {
    var token = localStorage.getItem("youtube_token");
    var error = localStorage.getItem("youtube_error");
    if (token) {
      localStorage.removeItem("youtube_token");
      clearInterval(intervalId);
      sendAccessTokenToServer(token);
    }
    else if (error) {
      localStorage.removeItem("youtube_error");
      clearInterval(intervalId);
      showToast(error, 'e');
    }
  }
  };
  const youtubeSuccessCallback = (data, message) => {
    showToast(message, 's');
    getConnectedNetworks();
    handleClose();
  }
  const youtubeFailureCallback = (message) => {
    showToast(message, 'e');
    handleClose();
  }
  const sendAccessTokenToServer = (code) => {
    let youtubeForm = {
      code,
      account_id:AccountId
    };
    let postProps = {
      url: integrateModuleURL.reAuthenticateYoutube,
      body: youtubeForm,
      successCallback: youtubeSuccessCallback,
      failureCallback: youtubeFailureCallback
    }
    HttpServices.Post(postProps);
  };


  return (
    <>{isAuthorizationModalOpen &&
    <YoutubeAuthorizationModal
      handleConnectYoutube={getYoutubeOauthToken}
      handleClose={handleClose}
      reAuth={true}
    />}
    {isCustomDomain && <CustomDomainRedirectIframe serviceName="youtube"/> }
    </>
  );
};
export default YouTubeReAuthenticate;