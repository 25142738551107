import { useEffect, useState } from 'react';
import { Box, Stack, Button, CardContent, Tab, Tabs, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { userModuleURL } from 'src/services/urlService';
import HttpServices from 'src/services/httpService';
import { useToaster } from 'src/utils/toaster/toasterContext';
import { CommonHelper } from 'src/utils/commonHelper';
import TrailPricingPlanCard from './trailPricingPlanCard';

const TrialPlanDetailsPopup = ({ closePopup }) => {
    const [isLoadingOn, setLoadingOn] = useState(false);
    const [pricingPlan, setPricingPlan] = useState([]);
    const [planType, setPlanType] = useState(30);
    const [currentPlan, setCurrentPlan] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [isPopupClosed, setIsPopupClosed] = useState(false); // New state to track popup closure
    const [timeZone, setTimeZone] = useState(CommonHelper.GetLocalTimeZone());
    const { showToast } = useToaster();
    const orgDetails = CommonHelper.GetOrgDetails();

    useEffect(() => {
        if (!isPopupClosed) {
            getPlanList();
        }
    }, [isPopupClosed]);

    const getPlanList = () => {
        let getProps = {
            url: userModuleURL.getAllPlan + timeZone,
            successCallback: planSuccessCallback,
            failureCallback
        };
        HttpServices.Get(getProps);
    }

    const planSuccessCallback = (data, message) => {
        setPricingPlan(data.paid_plan_detials);
        getCurrentPlan(data.paid_plan_detials);
    }
    const failureCallback = (message) => {
        showToast(message, "e");
    }

    const getCurrentPlan = (data) => {
        const planDetails = CommonHelper.GetBrandInfoFromLocalStorage("current_plan_info");
        const plan = data.find((a) => a.id === planDetails.id);
        setCurrentPlan(planDetails);
        setSelectedPlan(plan);
    };

    const ACCOUNT_TABS = [
        {
            value: 30,
            label: 'MONTHLY',
        },
        {
            value: 365,
            label: 'YEARLY',
        },
    ];

    const handleInputChange = (event, newValue) => {
        if (event?.target?.name === "planType") {
            setPlanType(newValue);
        }
    };

    const handleClosePopup = () => {
        setIsPopupClosed(true); // Set the state to true when the popup is closed
        closePopup();
    };

    return (
        <Dialog aria-labelledby="customized-dialog-title" maxWidth="lg" fullWidth open={!isPopupClosed}>
            <IconButton
                aria-label="close"
                onClick={handleClosePopup}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                })}
            >
                <CloseIcon />
            </IconButton>
            <CardContent
                sx={{
                    p: { xs: 0, md: 0 },
                    color: 'grey.800',
                }}
            >
                <Box sx={{ textAlign: 'center', m: 1 }}>
                    <Stack alignItems="center" justifyContent="center" >
                        <Typography variant="h3" sx={{ mt: 1 }} >Start your Trial Plan</Typography>
                        <Typography variant="body1" sx={{ mt: 1 }} >Your current trial plan is active. Explore the full features by upgrading your plan.</Typography>
                        {/* <Tabs
                            name="planType"
                            value={planType}
                            onChange={handleInputChange}
                        >
                            {ACCOUNT_TABS.map((tab) => (
                                <Tab
                                    name="planType"
                                    key={tab.value}
                                    label={tab.label}
                                    value={tab.value}
                                    sx={{ flexDirection: 'row', minHeight: '10px', mr: '0.50rem', fontWeight: 600 }}
                                />
                            ))}
                        </Tabs> */}
                    </Stack>
                </Box>
                <Grid container >
                    {pricingPlan.length > 0 && pricingPlan.filter((a) => a.plan_validity === planType).map((plan) => (
                        <Grid item xs={12} md={3} key={plan.id} sx={{ display: 'flex', padding: '2rem' }}>
                            <TrailPricingPlanCard {...plan} selectedPlan={selectedPlan} currencySymbol={orgDetails.currency_symbol} closePopup={closePopup} />
                        </Grid>
                    ))}
                </Grid>
            </CardContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
                <Button variant="contained" color="error" onClick={handleClosePopup} sx={{ paddingX: 5 }}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};
export default TrialPlanDetailsPopup;
