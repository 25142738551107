import { useEffect, useRef, useState, useMemo } from 'react';
import { IconButton, Box } from '@mui/material';
import { PlayArrow } from '@mui/icons-material';
import { CommonHelper } from 'src/utils/commonHelper';
import { styled } from '@mui/system';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import UseAspectRatios from './useAspectRatios.mediaView.component';

const CarouselContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  maxWidth: 500,
  height: '100%',
  overflow: 'hidden',
  backgroundColor: '#000',
});

const MediaWrapper = styled(Box)(({ currentIndex }) => ({
  display: 'flex',
  transform: `translateX(-${currentIndex * 100}%)`,
  transition: 'transform 0.4s ease-in-out',
  height: '100%',
  alignItems: 'center',
}));

const MediaItem = styled(Box)({
  flex: '0 0 100%',
  height: '100%',
  position: 'relative',
});

const StyledImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
});

const NavigationButton = styled(IconButton)({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  backgroundColor: '#fff',
  opacity: 0.8,
  zIndex: 2,
  width: 24,
  height: 24,
  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    backgroundColor: '#eee',
  },
});

const DotsContainer = styled(Box)({
  position: 'absolute',
  bottom: 12,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 6,
  zIndex: 2,
});

const Dot = styled('div')(({ active }) => ({
  width: 6,
  height: 6,
  borderRadius: '50%',
  backgroundColor: active ? '#fff' : 'rgba(255,255,255,0.5)',
}));

const PinterestMediaView = ({ data }) => {
  const mediaFiles = useMemo(() => {
    return CommonHelper.ProcessMediaUrl(data?.media_url)?.media_data || [];
  }, [data?.media_url]);
  const { aspectRatios, mediaRefs } = UseAspectRatios(mediaFiles);
  const [currentIndex, setCurrentIndex] = useState(0);
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true;
    }
  }, []);

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play().catch(console.error);
      }
      setIsPlaying(!isPlaying);
    }
  };

  const nextSlide = () => {
    if (currentIndex < mediaFiles.length - 1) {
      setCurrentIndex((prev) => prev + 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1);
    }
  };

  const renderMedia = (media, i) => {
    const isImage = media?.type === 'image' || typeof media === 'string';
    const src = media?.url || media;
    const aspectRatio = aspectRatios[i] || 1;

    return (
      <Box
        sx={{
          position: 'relative',
          paddingTop: `${(1 / aspectRatio) * 100}%`,
          overflow: 'hidden',
          maxHeight: 460,
          width: '100%',
        }}
      >
        {isImage ? (
          <img
            ref={(el) => (mediaRefs.current[i] = el)}
            src={src}
            alt={`media-${i}`}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        ) : (
          <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
            <video
              ref={(el) => {
                mediaRefs.current[i] = el;
                videoRef.current = el;
              }}
              src={src}
              controls={false}
              onClick={togglePlay}
              muted
              playsInline
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
            />
            {!isPlaying && (
              <IconButton
                onClick={togglePlay}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  bgcolor: 'rgba(0,0,0,0.6)',
                  '&:hover': { bgcolor: 'rgba(0,0,0,0.8)' },
                }}
              >
                <PlayArrow sx={{ color: 'white', fontSize: 40 }} />
              </IconButton>
            )}
          </Box>
        )}
      </Box>
    );
  };

  // Single media file: display directly
  if (mediaFiles.length === 1) {
    return <Box sx={{ maxWidth: 500, width: '100%' }}>{renderMedia(mediaFiles[0], 0)}</Box>;
  }

  // Multiple media files: display in a carousel
  return (
    <CarouselContainer>
      <MediaWrapper currentIndex={currentIndex}>
        {mediaFiles.map((media, index) => (
          <MediaItem key={index}>
            {media.type === 'image' ? (
              <StyledImage ref={(el) => (mediaRefs.current[index] = el)} src={media.url} alt={`media-${index}`} />
            ) : (
              <>
                <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                  <video
                    ref={(el) => {
                      mediaRefs.current[index] = el;
                      videoRef.current = el;
                    }}
                    src={media.url}
                    controls={false}
                    onClick={togglePlay}
                    muted
                    playsInline
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                  />
                  {!isPlaying && (
                    <IconButton
                      onClick={togglePlay}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'rgba(0,0,0,0.6)',
                        '&:hover': { bgcolor: 'rgba(0,0,0,0.8)' },
                      }}
                    >
                      <PlayArrow sx={{ color: 'white', fontSize: 40 }} />
                    </IconButton>
                  )}
                </Box>
              </>
            )}
          </MediaItem>
        ))}
      </MediaWrapper>

      {/* Navigation Arrows */}
      {mediaFiles.length > 1 && (
        <>
          {currentIndex > 0 && (
            <NavigationButton onClick={prevSlide} sx={{ left: 8 }}>
              <NavigateBefore />
            </NavigationButton>
          )}

          {currentIndex < mediaFiles.length - 1 && (
            <NavigationButton onClick={nextSlide} sx={{ right: 8 }}>
              <NavigateNext />
            </NavigationButton>
          )}
        </>
      )}

      {/* Dots */}
      <DotsContainer>
        {mediaFiles.map((_, i) => (
          <Dot key={i} active={i === currentIndex} />
        ))}
      </DotsContainer>
    </CarouselContainer>
  );
};

export default PinterestMediaView;
