// import * as Yup from 'yup';
// import { useSnackbar } from 'notistack';
// // form
// import { yupResolver } from '@hookform/resolvers/yup';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useForm } from 'react-hook-form';
// @mui
import { Stack, Card, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import { useState } from 'react';
import { userModuleURL } from 'src/services/urlService';
import HttpServices from 'src/services/httpService';
import { useToaster } from 'src/utils/toaster/toasterContext';
// components
//import { FormProvider, RHFTextField } from '../../../../components/hook-form';

// ----------------------------------------------------------------------

export default function AccountChangePassword() {
  // const { enqueueSnackbar } = useSnackbar();

  // const ChangePassWordSchema = Yup.object().shape({
  //   oldPassword: Yup.string().required('Old Password is required'),
  //   newPassword: Yup.string().min(6, 'Password must be at least 6 characters').required('New Password is required'),
  //   confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  // });
  
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false); 

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const { showToast } = useToaster();
  const defaultValues = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };
  const successCallback = (data, message) => {
    showToast(message, "s");
    reset();
  }
  const failureCallback = (message) => {
    showToast(message, "e");
    reset();
  }

  const methods = useForm({
    // resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });
  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    if (oldPassword === ""){
      showToast("Old password cannot be empty", 'e');
      return;
    }
    if (oldPassword === newPassword) {
      showToast("Old password and new password cannot be the same", 'e');
      return;
    }

    if (newPassword !== confirmNewPassword) {
      showToast("Passwords do not match", 'e');
      return;
    }
    let changePasswordForm = {
    old_password: oldPassword,
    new_password: newPassword,
   }
  let postProps = {
      url: userModuleURL.updatePasswordRequest,
      body: changePasswordForm,
      successCallback,
      failureCallback
  }
  HttpServices.Post(postProps);
  };

  const handleInputChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    switch (name) {
      case "oldPassword":
        setOldPassword(value);
        break;
      case "newPassword":
        setNewPassword(value);
        break;
      case "confirmNewPassword":
        setConfirmNewPassword(value);
        break;
      default:
        break;
    }
  }
  return (
    <Card sx={{ p: 3 }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} alignItems="flex-end" >
          <RHFTextField 
            name="oldPassword" 
            type={showOldPassword ? "text" : "password"} 
            value={oldPassword} 
            label="Old Password" 
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowOldPassword(!showOldPassword)} edge="end">
                    {showOldPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <RHFTextField 
            name="newPassword" 
            type={showNewPassword ? "text" : "password"} 
            value={newPassword} 
            label="New Password" 
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowNewPassword(!showNewPassword)} edge="end">
                    {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <RHFTextField 
            name="confirmNewPassword" 
            type={showConfirmNewPassword ? "text" : "password"} 
            value={confirmNewPassword} 
            label="Confirm New Password" 
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)} edge="end">
                    {showConfirmNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Save Changes
          </LoadingButton>
        </Stack>
      </FormProvider>
    </Card>
  );
}
