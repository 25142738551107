import { useEffect, useState } from 'react';
import PaymentDialogComponent from 'src/pages/payment/payment-dialog.component';
import HttpServices from 'src/services/httpService';
import { paymentModuleUrl } from 'src/services/urlService';

const AddonPaymentComponent = ({ addonUpgrade, amount, currencySymbol, handleClose }) => {
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    createPaymentLink();
  }, []);

  const successCallback = (data, message) => {
    setClientSecret(data.payment_intent);
  };

  const createPaymentLink = () => {
    let form = {
      add_on_upgrade: addonUpgrade,
    };
    let postProps = {
      url: paymentModuleUrl.addonPayment,
      body: form,
      successCallback,
    };
    HttpServices.Post(postProps);
  };

  return clientSecret ? (
    <PaymentDialogComponent
      clientSecret={clientSecret}
      amount={amount}
      handleClose={handleClose}
      currencySymbol={currencySymbol}
    />
  ) : null;
};
export default AddonPaymentComponent;
