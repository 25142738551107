import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CommonHelper } from 'src/utils/commonHelper';
import Spinner from 'src/utils/Loader/spinner';
import { LocalStorage } from 'src/utils/storage/local-storage';
import { useToaster } from 'src/utils/toaster/toasterContext';
import AISetUpComponent from './ai-onboarding.component';
import ManualOnBoardingComponent from './manual-onboarding.component';

const OnBoardingComponent = () => {
  let onBoardingJson = LocalStorage.getItem('onboarding');

  const { showToast } = useToaster();
  const navigate = useNavigate();

  //State Variable
  const [onBoardingInfo, setOnBoardingInfo] = useState(onBoardingJson ? JSON.parse(onBoardingJson) : null);
  const [isLoader, setLoader] = useState(true);

  useEffect(() => {
    if (!CommonHelper.CheckForPermissionAvailableForUser()) {
      navigate('/welcome');
    }
  
    if (!onBoardingInfo) {
      navigate('/welcome');
    }
  
    if (!checkOnBoardingOn()) {
      checkPaymentOnBoardingOn() ? navigateToPaymentOnBoarding() : navigate('/welcome');
    }
  
    setLoader(false);
  }, []);
  

  const checkOnBoardingOn = () => {
    return onBoardingInfo['onboarding'] && 
    !onBoardingInfo['onboarding_skipped'] && 
    !onBoardingInfo['onboarding_done'];
  }
  
  const checkPaymentOnBoardingOn = () => {
    return onBoardingInfo['payment'] && 
    !onBoardingInfo['payment_skipped'] && 
    !onBoardingInfo['payment_done'];
  }

  const navigateToPaymentOnBoarding = () => {
    navigate('/payment-onboarding');
  }

  const handleSkipAIOnBoarding = () => {
    let onboarding = { ...onBoardingInfo };
    onboarding['ai_onboarding_skipped'] = true;
    LocalStorage.setItem('onboarding', JSON.stringify(onboarding));
    setOnBoardingInfo({ ...onboarding });
  };

  const handleSkipOnBoarding = () => {
    let onboarding = { ...onBoardingInfo };
    onboarding['onboarding_skipped'] = true;
    LocalStorage.setItem('onboarding', JSON.stringify(onboarding));
    setOnBoardingInfo({ ...onboarding });
  };

  const aiOnboardingsuccessCallback = (data, message) => {
    let onboarding = { ...onBoardingInfo };
    onboarding['ai_onboarding_done'] = true;
    LocalStorage.setItem('onboarding', JSON.stringify(onboarding));
    setOnBoardingInfo({ ...onboarding });
  };
  const manualOnBoardingSuccessCallback = (data, message) =>{
    let onboarding = { ...onBoardingInfo };
    onboarding['onboarding_done'] = true;
    LocalStorage.setItem('onboarding', JSON.stringify(onboarding));
    navigateToPaymentOnBoarding();
  }
  const failureCallback = (message) => {
    message && showToast(message, 'e');
  };

  return isLoader ? (
    <Spinner />
  ) : (
    <>
      {/* {onBoardingInfo && (onBoardingInfo['ai_onboarding_skipped'] || onBoardingInfo['ai_onboarding_done']) ? ( */}
      {/* For Testing */}
      {!(onBoardingInfo && (onBoardingInfo['ai_onboarding_skipped'] || onBoardingInfo['ai_onboarding_done'])) ? (
        <ManualOnBoardingComponent
          handleSkip={handleSkipOnBoarding}
          failureCallback={failureCallback}
          successCallback={manualOnBoardingSuccessCallback}
          showToast={showToast}
          onBoardingInfo={onBoardingInfo}
        />
      ) : (
        <AISetUpComponent
          handleSkip={handleSkipAIOnBoarding}
          failureCallback={failureCallback}
          successCallback={aiOnboardingsuccessCallback}
          showToast={showToast}
          onBoardingInfo={onBoardingInfo}
        />
      )}
    </>
  );
};

export default OnBoardingComponent;
