import { useTheme } from "@mui/material/styles";

const NoPost = ({ contentText }) => {
    const theme = useTheme();

    const keyframesFadeIn = `
        @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
        }
    `;

    return (
        <div style={{ position: "relative", width: "100%", height: "100px", backgroundColor: "#fff", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <style>{keyframesFadeIn}</style>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100" viewBox="0 0 350 50">
                <text
                    x="50%"
                    y="50%"
                    textAnchor="middle"
                    dy="0.35em"
                    textLength="90%"
                    overflow="visible"
                    style={{
                        fontFamily: "sans-serif",
                        fontSize: "24px",
                        fill: theme.palette.primary.main || "black",
                        fontWeight: "800",
                        opacity: 1,
                        animation: "fadeIn 1.5s ease-out forwards",
                    }}
                >
                    {contentText || "No Post Available"}
                </text>
            </svg>
        </div>
    );
};

export default NoPost;
