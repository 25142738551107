import {
    Avatar,
    Box,
    Card, Chip,
    Table, TableBody,
    TableCell,
    TableContainer, TablePagination,
    TableRow,
    useMediaQuery, Stack,
    Typography,
    tabsClasses,
    Grid
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { StyledComponent } from 'src/components/styled-components/styledComponent';
import HttpServices from 'src/services/httpService';
import { postsModuleURL } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import DynamicHeightComponent from 'src/utils/dynamicSize.component';
import { Warning as WarningIcon } from '@mui/icons-material';
import Spinner from 'src/utils/Loader/spinner';
import { useToaster } from 'src/utils/toaster/toasterContext';
import Scrollbar from '../../../components/scrollbar';
import { UserListHead } from '../../../sections/@dashboard/user';
import NoPostFound from '../noPost.component';
import { useRightSidebar } from 'src/layouts/right-side-bar/rightSidebarProvider';
import { Tab, Tabs } from '@mui/material';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import InstagramPostDetailView from './instagramPostDetailView';
import { PostDetailsHelper } from 'src/utils/postDetailsHelper';
import InstagramMobileViewPosts from '../mobile-publishedPosts/instagramMobileViewPosts';

const InstagramPostGrid = ({ connectedNetworks, handleProfileClick }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const cardMaxWidth = useMediaQuery(theme.breakpoints.up('xl'));
    const { showToast } = useToaster();
    const { showRightSidebar } = useRightSidebar();
    const navigate = useNavigate()

    const [mobileLoader, setMobileLoader] = useState(false);
    const [viewType, setViewType] = useState('post');
    const [isLoading, setloading] = useState(true);
    const [InstagramPageId, setinstagramPageId] = useState('');
    const [publishedPosts, setPublishedPosts] = useState([]);
    const [connectedMedias, setConnectedMedias] = useState([]);
    const [postId, setPostId] = useState(null);
    const [height, setHeight] = useState(window.innerHeight - 250);
    const [pagination, setPagination] = useState('');
    const [isAccountNeedsReauth, setAccountNeedsReauth] = useState(false);
    const hasIntegratationPermission = CommonHelper.CheckForPermissionAvailableForUser(
        CommonHelper.PermissionList.INTEGRATE_PERMISSION
    );

    //Table Variables
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const getTableDesk = [
        { id: 'created_time', width: '15%', label: 'Date Time', alignRight: false },
        { id: 'image_url', width: '15%', label: 'Image', alignRight: false },
        { id: 'message', width: '40%', label: 'Content', alignRight: false },
        { id: 'like_count', width: '10%', label: 'Likes', alignCenter: true },
        { id: 'comment_count', width: '10%', label: 'Comments', alignCenter: true },
        { id: 'share_count', width: '10%', label: 'Shares', alignCenter: true },
    ];
    const getTableMobile = [
        { id: 'created_time', label: 'Date Time', alignRight: false },
        { id: 'message', label: 'Content', alignRight: false },
        { id: 'like_count', label: 'Likes', alignRight: false },
        { id: 'comment_count', label: 'Comments', alignRight: false },
        { id: 'share_count', label: 'Shares', alignRight: false },
        { id: 'image_url', label: 'Image', alignRight: false },
    ];
    const TABLE_HEAD = isMobile ? getTableMobile : getTableDesk;

    useEffect(() => {
        const activeNetworks = connectedNetworks.filter(c => c.is_active);
        let instagramId = activeNetworks && activeNetworks.length > 0 && activeNetworks[0].id;
        instagramId && setinstagramPageId(instagramId);
        setConnectedMedias(connectedNetworks);
        if (activeNetworks && activeNetworks.length === 0) {
            setloading(false)
            setAccountNeedsReauth(true)
        }
        const handleResize = () => {
            setHeight(window.innerHeight - 300);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [connectedNetworks]);
    useEffect(() => {
        InstagramPageId && getInstagramPublishedPosts();
    }, [InstagramPageId, viewType]);
    const instagramPublishedPostSuccessCallback = (data, message) => {
        setPublishedPosts(data.posts ? data.posts : "");
        setPagination(data.pagination);
        setloading(false);
    }
    const instagramNextPublishedPostSuccessCallback = (data, message) => {
        let updatedPost = [...publishedPosts, ...data.posts];
        setPublishedPosts(updatedPost);
        setPagination(data.pagination);
        setloading(false);
        setMobileLoader(false);
    }
    const instagramPublishedPostFailureCallback = (message) => {
        setPublishedPosts([]);
        message && showToast(message, "e");
        setloading(false);
    }
    const instagramNextPublishedPostFailureCallback = (message) => {
        setPage(0);
        message && showToast(message, "e");
        setloading(false);
        setMobileLoader(false)
    }
    const getInstagramPublishedPosts = (newRowsPerPage = "10") => {
        setloading(true);
        const baseUrl = viewType === 'story'
            ? postsModuleURL.instagramStory
            : postsModuleURL.instagramPosts;

        let getProps = {
            url: `${baseUrl}${InstagramPageId}&post_limit=${newRowsPerPage}`,
            successCallback: instagramPublishedPostSuccessCallback,
            failureCallback: instagramPublishedPostFailureCallback
        }
        HttpServices.Get(getProps);
    }
    const getNextInstagramPublishedPosts = () => {
        setloading(true);
        const baseUrl = viewType === 'story'
            ? postsModuleURL.instagramStory
            : postsModuleURL.instagramNextPosts;
        let getProps = {
            url: `${baseUrl}${InstagramPageId}&pagination=${pagination}&post_limit=${rowsPerPage}`,
            successCallback: instagramNextPublishedPostSuccessCallback,
            failureCallback: instagramNextPublishedPostFailureCallback
        }
        HttpServices.Get(getProps);
    }
    const getMobileViewInstagramNextPublishedPosts = () => {
        setMobileLoader(true);
        const baseUrl = viewType === 'story'
            ? postsModuleURL.instagramStory
            : postsModuleURL.instagramNextPosts;
        let getProps = {
            url: `${baseUrl}${InstagramPageId}&pagination=${pagination}&post_limit=${rowsPerPage}`,
            successCallback: instagramNextPublishedPostSuccessCallback,
            failureCallback: instagramNextPublishedPostFailureCallback
        }
        HttpServices.Get(getProps);
    }
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        if ((newPage + 1) * rowsPerPage > publishedPosts?.length) {
            getNextInstagramPublishedPosts();
        }
    };
    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
        if (newRowsPerPage > publishedPosts?.length) {
            getInstagramPublishedPosts(newRowsPerPage);
        }
    };
    const handleViewType = (e, value) => {
        setViewType(value);
        setPage(0);
        setRowsPerPage(10);
        setPublishedPosts([]); // Clear existing posts when switching types

    };
    const handleOpenPost = (post) => {
        let data = {
            content: postView,
            data: post
        };
        showRightSidebar(data);
    }
    const postView = (post) => {
        let profile = connectedMedias.find(a => a.id === InstagramPageId);
        profile["date"] = CommonHelper.GetDateFormat(post.timestamp);
        let postDetails = {
            viewType: viewType,
            postId: post.id,
            pageId: InstagramPageId,
            profile,
            ...post,
            height
        };
        return (<InstagramPostDetailView {...postDetails} />);
    }
    const handleInstagramPage = (id) => {
        setinstagramPageId(id);
        setRowsPerPage(10);
        setPage(0);
    };
    const navigateToIntegration = () => {
        navigate('/integration');
    }
    const getNetworkChip = () => {
        return (
            connectedMedias &&
            connectedMedias.length > 0 &&
            connectedMedias.map((c) => {
                const isActive = c.id === InstagramPageId;
                const isProfileActive = c.is_active;
                const showReauth = !isProfileActive && hasIntegratationPermission;
                return (
                    <Chip
                        key={c.id}
                        style={{
                            borderRadius: 8,
                            opacity: isActive ? 1 : 0.9,
                            ...(!isProfileActive && {
                                backgroundColor: theme.palette.error.lighter,
                                border: `1px solid ${theme.palette.error.main}`
                            }),
                            ...(isActive && isProfileActive && {
                                backgroundColor: theme.palette.primary.main,
                                color: 'white',
                                fontWeight: 800
                            })
                        }}
                        sx={{
                            m: 0.4,
                            '&:hover': {
                                opacity: isProfileActive ? 0.8 : 1
                            },
                            ...(!isProfileActive && {
                                '& .MuiChip-deleteIcon': {
                                    color: theme.palette.error.main,
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: 'transparent'
                                    }
                                }
                            })
                        }}
                        onClick={isProfileActive ?
                            () => handleInstagramPage(c.id) :
                            (showReauth ? () => handleProfileClick(c) : undefined)
                        }
                        deleteIcon={!isProfileActive ? <WarningIcon /> : undefined}
                        onDelete={showReauth ? () => handleProfileClick(c) : undefined}
                        avatar={<Avatar
                            src={c.profile_image ? c.profile_image : c.profile_name || c.id}
                            alt={c.profile_name ? c.profile_name : c.id}
                            sx={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                height: 40,
                                width: 40,
                                bgcolor: isActive ? 'white' : theme.palette.primary.main,
                                ...(!isProfileActive && {
                                    bgcolor: theme.palette.error.main,
                                    color: 'white'
                                })
                            }}
                            style={{
                                color: isActive ? theme.palette.primary.main : 'white',
                            }}
                        />}
                        label={
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {c.profile_name ? c.profile_name : c.id}
                                {!isProfileActive && (
                                    <Typography variant="caption" sx={{ ml: 1, color: theme.palette.error.dark }}>
                                        (Needs Re-auth)
                                    </Typography>
                                )}
                            </Box>
                        }
                    />
                )
            })
        )
    }

    const getTableBodyData = () => {
        return publishedPosts?.length > 0 ? (
            publishedPosts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                const { timestamp, id, image_url, media_url, like_count, caption, share_count, comment_count } = row;
                return (
                    <StyledComponent.StyledTableRow key={id} onClick={() => { handleOpenPost(row) }}>
                        <StyledComponent.StyledTableCell align="left">
                            {CommonHelper.getTableDateAndTime(timestamp)}
                        </StyledComponent.StyledTableCell>
                        <StyledComponent.StyledTableCell align="left">
                            {PostDetailsHelper.getPostMedias(image_url || media_url)}
                        </StyledComponent.StyledTableCell>
                        <StyledComponent.StyledTableCell align="left">
                            {PostDetailsHelper.GetPostContent(caption, 400)}
                        </StyledComponent.StyledTableCell>
                        <StyledComponent.StyledTableCell align="center">
                            {CommonHelper.formatNumber(like_count)}
                        </StyledComponent.StyledTableCell>
                        <StyledComponent.StyledTableCell align="center">
                            {CommonHelper.formatNumber(comment_count)}
                        </StyledComponent.StyledTableCell>
                        <StyledComponent.StyledTableCell align="center">
                            {CommonHelper.formatNumber(share_count)}
                        </StyledComponent.StyledTableCell>
                    </StyledComponent.StyledTableRow>
                );
            })
        ) : (
            <NoPostFound />
        );
    };

    const getGrid = () => {
        const postcontentL = 220;
        const postcontentS = 110;
        return <DynamicHeightComponent >
            <Scrollbar>
                {isMobile ?
                    <InstagramMobileViewPosts publishedPosts={publishedPosts} getMobileViewInstagramNextPublishedPosts={getMobileViewInstagramNextPublishedPosts} mobileLoader={mobileLoader}
                        pagination={pagination} />
                    :
                    <TableContainer sx={{
                        maxHeight: 'calc(100vh - 330px)',
                        minHeight: 'calc(100vh - 330px)'
                        , height: height - 60
                    }} >
                        <Table stickyHeader size='small'>
                            <UserListHead order={order} orderBy={orderBy} headLabel={TABLE_HEAD} rowCount={publishedPosts?.length}
                                isShowCheckbox={false} onRequestSort={handleRequestSort} />
                            <TableBody>
                                {isLoading ?
                                    <TableRow>
                                        <TableCell colSpan={6} align="center" sx={{ width: '100%', borderBottom: 'none' }}>
                                            <Spinner height={height} />
                                        </TableCell>
                                    </TableRow>
                                    :
                                    getTableBodyData()
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
                {!isMobile && <TablePagination component="div" rowsPerPageOptions={[10, 20, 30, 50]} count={publishedPosts?.length + 1}
                    rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage}
                    labelDisplayedRows={() => ''} onRowsPerPageChange={handleChangeRowsPerPage}
                />}
            </Scrollbar>
        </DynamicHeightComponent>
    }
    return (
        <Box>
            <Box sx={{ minHeight: 'calc(100vh - 150px)', maxHeight: 'calc(100vh - 150px)' }} >
                {connectedMedias && connectedMedias.length > 0 ?
                    <>
                        <Card sx={{ mb: 2, p: 2, maxHeight: 100, minHeight: isMobile && 100, overflowY: 'auto', visibility: true }} >
                            {getNetworkChip()}
                        </Card>
                        <Stack display="flex-end" sx={{ pb: 2, pt: 0, pl: 0, pr: 0 }}>
                            <Tabs
                                value={viewType}
                                onChange={handleViewType}
                                sx={{
                                    minHeight: 36, // Reduced from default 48
                                    [`& .${tabsClasses.scrollButtons}`]: {
                                        '&.Mui-disabled': { display: 'none' },
                                    },
                                    marginLeft: 'auto',
                                    '& .MuiTab-root': {
                                        minHeight: 36, // Reduced tab height
                                        padding: '6px 12px', // Smaller padding
                                    }
                                }}
                                allowScrollButtonsMobile
                            >
                                <Tab
                                    value="post"
                                    sx={{
                                        fontSize: '0.75rem', // Smaller font
                                        padding: '6px 8px', // Compact padding
                                        '& svg': {
                                            fontSize: '16px', // Icon size
                                        }
                                    }}
                                    label={
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 0.5, // Reduced gap
                                            fontSize: 'inherit'
                                        }}>
                                            <InsertPhotoIcon fontSize="inherit" />
                                            <span>Post</span>
                                        </Box>
                                    }
                                />
                                <Tab
                                    value="story"
                                    sx={{
                                        fontSize: '0.75rem',
                                        padding: '6px 8px',
                                        '& svg': {
                                            fontSize: '16px',
                                        }
                                    }}
                                    label={
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 0.5,
                                            fontSize: 'inherit'
                                        }}>
                                            <VideoLibraryIcon fontSize="inherit" />
                                            <span>Story</span>
                                        </Box>
                                    }
                                />
                            </Tabs>
                        </Stack>
                        <Card sx={{ boxShadow: isMobile && publishedPosts?.length > 0 && 'none', backgroundColor: isMobile && publishedPosts?.length > 0 && 'transparent' }}>
                            {isLoading ?
                                <Spinner height={height} />
                                :
                                publishedPosts?.length === 0 ?
                                    <NoPostFound
                                        height={isMobile ? 'calc(100vh - 330px)' : height}
                                        contentText={isAccountNeedsReauth ? "Needs Re-authentication" : "No Post Available"}
                                    />
                                    : getGrid()}
                        </Card>
                    </>
                    :
                    isLoading ?
                        <Spinner height={height} />
                        :
                        <Card sx={{ mb: 2, p: 2, height: height, display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 'calc(100vh - 150px)', maxHeight: 'calc(100vh - 150px)' }}>
                            <LoadingButton
                                variant="contained"
                                autoFocus
                                onClick={() => {
                                    navigateToIntegration();
                                }}
                            >
                                Connect Social Accounts
                            </LoadingButton>
                        </Card>}
            </Box>
        </Box>
    )
}
export default InstagramPostGrid;