import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, Paper, CircularProgress } from '@mui/material';
import { debounce } from 'lodash';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import { useToaster } from 'src/utils/toaster/toasterContext';

const ReferForm = () => {
  const [referCode, setReferCode] = useState('');
  const [isCodeAvailable, setIsCodeAvailable] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const { showToast } = useToaster();

  const checkReferralCode = async (code) => {
    setLoading(true);
    let postProps = {
      url: userModuleURL.getReferAndEarnCheckReferralCode + `?referral_code=${code}`,
      successCallback,
      failureCallback,
    };
    HttpServices.Get(postProps);
  };

  const confirmReferralCode = async (code) => {
    let postProps = {
      url: userModuleURL.confirmReferAndEarnReferralCode,
      body: { referral_code: code },
      successCallback: confirmSuccessCallback,
      failureCallback: confirmFailureCallback,
    };
    HttpServices.Post(postProps);
  };

  const successCallback = (data, message) => {
    setIsCodeAvailable(data.is_refer_and_earn_code_available);
    setLoading(false);
    showToast(message, 's');
  };

  const failureCallback = (message) => {
    setIsCodeAvailable(false);
    setLoading(false);
    showToast(message, 'e');
  };

  const confirmSuccessCallback = (data, message) => {
    showToast(message, 's');
  };

  const confirmFailureCallback = (message) => {
    showToast(message, 'e');
  };

  const debouncedCheckReferralCode = debounce(checkReferralCode, 1000);

  useEffect(() => {
    if (referCode) {
      debouncedCheckReferralCode(referCode);
    } else {
      setIsCodeAvailable(null);
    }
    return () => {
      debouncedCheckReferralCode.cancel();
    };
  }, [referCode]);

  const handleInputChange = (e) => {
    setReferCode(e.target.value.toUpperCase());
  };

  const handleConfirmClick = () => {
    setOpenConfirmDialog(true);
  };

  const handleDialogClose = (confirm) => {
    setOpenConfirmDialog(false);
    if (confirm) {
      confirmReferralCode(referCode);
    }
  };

  return (
    <Box component={Paper} padding={3} display="flex" flexDirection="column" alignItems="flex-start" height="100vh">
      <Box width="100%" maxWidth="900px" marginTop={2}>
        <Box display="flex" alignItems="center" gap="1rem" width="100%">
          <TextField
            type="email"
            value={referCode}
            onChange={handleInputChange}
            placeholder="Enter email to refer"
            variant="outlined"
            margin="normal"
          />
          {loading ? (
            <Typography variant="body1" style={{ color: 'blue' }}>
              Checking for Referral Code...
            </Typography>
          ) : (
            isCodeAvailable !== null && (
              <Typography variant="body1" style={{ color: isCodeAvailable ? 'green' : 'red' }}>
                {isCodeAvailable ? 'Referral Code Available' : 'Referral Code Not Available'}
              </Typography>
            )
          )}
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleConfirmClick}
          style={{ marginTop: '1rem' }}
          disabled={!isCodeAvailable}
        >
          Confirm Referral Code
        </Button>
      </Box>
      <Dialog open={openConfirmDialog} onClose={() => handleDialogClose(false)}>
        <DialogTitle>Confirm Referral Code</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to confirm the referral code? This action cannot be changed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleDialogClose(true)} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ReferForm;
