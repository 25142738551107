import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  Box,
  Button,
  Drawer,
  List,
  Stack,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Chip,
  Avatar,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';
import { NavConfig } from 'src/navConfig';
import { CommonHelper } from 'src/utils/commonHelper';
import Logo from '../Logo';
import { NavItemRoot } from '../nav-section/vertical/NavItem';
import Scrollbar from '../scrollbar/Scrollbar';
import CollapseButton from './CollapseButton';
import { useEffect, useState } from 'react';
import WorkPlaceBox from './WorkPlaceBox';
import ProfileCard from '../profile-card/profile-card.component';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

const MenuBarComponent = ({ isMainMenuBarView, menuItems, backButtonClick, handleChangeBrand, handleInitCall }) => {
  const hasPublishPostPermission = CommonHelper.CheckForPermissionAvailableForUser(
    CommonHelper.PermissionList.PUBLISHED_POSTS
  );
  let appName = CommonHelper.GetBrandInfoFromLocalStorage('org_name');
  let appDescription = CommonHelper.GetBrandInfoFromLocalStorage('org_description');
  appName = appName ? appName : 'Brand2Social';
  appDescription = appDescription ? appDescription : 'Social Media Scheduler';
  const theme = useTheme();
  const navigate = useNavigate();
  const { isCollapse, onToggleCollapse } = useCollapseDrawer();
  const initialLoad = CommonHelper.GetBrandInfoFromLocalStorage();
  const activeBrandId = CommonHelper.GetCurrentBrandId();
  const [brandList, setBrandList] = useState([]);
  const [currentBrandId, setCurrentBrand] = useState(activeBrandId);
  const [openWorkPlaceModal, setWorkPlaceModal] = useState(false);

  useEffect(() => {
    getAllBrandsFromLocalStorage();
  }, []);

  const getAllBrandsFromLocalStorage = () => {
    let brand_details = initialLoad?.brand_details;
    if (brand_details?.length > 0) {
      setBrandList(brand_details);
    }
  };

  const handleSidebar = (menuItem) => {
    navigate(menuItem.path);
  };
  const HighlightButton = styled(Button)(({}) => ({
    margin: '5px 15px 0px',
    borderRadius: '4px',
    boxShadow: 'none',
    fontWeight: 600,
    fontSize: '16px',
    gap: '0.5rem',
    minWidth: '0px',
    span: {
      margin: '0px',
    },
  }));
  const AddIconStyle = styled(AddIcon)(({}) => ({
    fontSize: '21px !important',
  }));
  const StyledTypography = styled(Typography)(({ theme }) => ({
    textDecoration: 'underline',
    textTransform: 'none',
    fontWeight: 800,
    fontSize: '18px !important',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  }));
  const CustomIconButton = styled(IconButton)(({ theme }) => ({
    color: `${theme.palette.primary.main}`,
    backgroundColor: `${theme.palette.secondary.main}`,
    width: '30px',
    height: '30px',
    padding: 0,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    borderRadius: '4px',
  }));
  const handleBrand = (event) => {
    let value = event.target.value;
    if (value === 'CREATE-BRAND') {
      setWorkPlaceModal(true);
      return;
    }
    setCurrentBrand(value);
    handleChangeBrand(value);
  };

  const handleClose = () => {
    setWorkPlaceModal(false);
  };

  const handleSuccess = () => {
    handleClose();
    handleInitCall();
  };

  const workPlaceProps = { handleClose, handleSuccess };

  const brandListView = () => {
    return (
      <FormControl sx={{ margin: '5px 15px 0px', mt: '1rem', mb: '0.5rem' }} size="small">
        {!isCollapse ? (
          <>
            <InputLabel id="brand_id">Brand</InputLabel>
            <Select
              value={currentBrandId}
              label="Brand"
              onChange={handleBrand}
              renderValue={(selected) => {
                let current = brandList.find((brand) => brand.brand_id === selected);
                return (
                  <CommonHelper.HtmlTooltip
                    title={
                      <ProfileCard
                        primaryText={current?.brand_name}
                        secondaryText={current?.description}
                        imageUrl={current?.brand_image}
                      />
                    }
                    placement="right"
                  >
                    <Chip
                      sx={{ width: '100%', justifyContent: 'flex-start' }}
                      avatar={
                        <Avatar
                          src={current?.brand_image ? current?.brand_image : current?.brand_name}
                          alt={current?.brand_image ? current?.brand_image : current?.brand_name}
                          sx={{ bgcolor: theme.palette.primary.main, color: 'white !important' }}
                        />
                      }
                      label={current?.brand_name ? current?.brand_name : current?.brand_id}
                      variant="contained"
                    ></Chip>
                  </CommonHelper.HtmlTooltip>
                );
              }}
              sx={{
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'primary.main',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'primary.main',
                },
              }}
            >
              {brandList.map((brand) => (
                <MenuItem key={brand.brand_id} value={brand.brand_id}>
                  <CommonHelper.HtmlTooltip
                    title={
                      <ProfileCard
                        primaryText={brand?.brand_name}
                        secondaryText={brand?.description}
                        imageUrl={brand?.brand_image}
                      />
                    }
                    placement="right"
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Avatar
                        src={brand?.brand_image ? brand?.brand_image : brand?.brand_name}
                        alt={brand?.brand_image ? brand?.brand_image : brand?.brand_name}
                        sx={{ bgcolor: theme.palette.primary.main }}
                      />
                      <Box
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '170px',
                        }}
                      >
                        {brand?.brand_name ? brand?.brand_name : brand?.brand_id}
                      </Box>
                    </Box>
                  </CommonHelper.HtmlTooltip>
                </MenuItem>
              ))}
              {CommonHelper.CheckForPermissionAvailableForUser() && (
                <MenuItem value="CREATE-BRAND">
                  <Box sx={{ display: 'flex', fontSize: '15px', alignItems: 'center', gap: 1 }}>
                    <AddCircleIcon fontSize="large" sx={{ color: 'primary.main' }} />
                    CREATE BRAND
                  </Box>
                </MenuItem>
              )}
            </Select>
          </>
        ) : (
          <Select
            value={currentBrandId}
            onChange={handleBrand}
            renderValue={(selected) => {
              let current = brandList.find((brand) => brand.brand_id === selected);
              return (
                <CommonHelper.HtmlTooltip
                  title={
                    <ProfileCard
                      primaryText={current?.brand_name}
                      secondaryText={current?.description}
                      imageUrl={current?.brand_image}
                    />
                  }
                  placement="right"
                >
                  <Avatar
                    src={current?.brand_image ? current?.brand_image : current?.brand_name}
                    alt={current?.brand_image ? current?.brand_image : current?.brand_name}
                    sx={{
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  />
                </CommonHelper.HtmlTooltip>
              );
            }}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '.MuiSelect-icon': {
                left: '2.3rem',
              },
              paddingLeft: 0,
              '& .MuiSelect-select': {
                paddingLeft: 0,
              },
            }}
          >
            {brandList.map((brand) => (
              <MenuItem key={brand.brand_id} value={brand.brand_id}>
                <CommonHelper.HtmlTooltip
                  title={
                    <ProfileCard
                      primaryText={brand?.brand_name}
                      secondaryText={brand?.description}
                      imageUrl={brand?.brand_image}
                    />
                  }
                  placement="right"
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Avatar
                      src={brand?.brand_image ? brand?.brand_image : brand?.brand_name}
                      alt={brand?.brand_image ? brand?.brand_image : brand?.brand_name}
                    />
                    <Box
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '170px',
                      }}
                    >
                      {brand?.brand_name ? brand?.brand_name : brand?.brand_id}
                    </Box>
                  </Box>
                </CommonHelper.HtmlTooltip>
              </MenuItem>
            ))}
            {CommonHelper.CheckForPermissionAvailableForUser() && (
              <MenuItem value="CREATE-BRAND">
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <AddCircleIcon fontSize="large" sx={{ color: 'primary.main' }} />
                  CREATE BRAND
                </Box>
              </MenuItem>
            )}
          </Select>
        )}
      </FormControl>
    );
  };
  return (
    <>
      <Drawer
        open
        variant="persistent"
        PaperProps={{
          sx: {
            position: 'relative',
            width: NavConfig.NAVBAR.DASHBOARD_WIDTH,
            borderRightStyle: 'dashed',
            bgcolor: 'background.paper.',
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.standard,
              }),
            ...(isCollapse && {
              width: NavConfig.NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
            }),
          },
        }}
      >
        <Scrollbar
          sx={{
            height: 1,
            '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
            ...(isCollapse && {
              scrollbarWidth: 'none',
            }),
          }}
        >
          <Stack
            spacing={3}
            sx={{
              pt: 1,
              pb: 1,
              px: 2.5,
              flexShrink: 0,
              ...(isCollapse && { alignItems: 'center' }),
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Stack direction="row" alignItems="center" gap="1rem">
              {!isCollapse && (
                <>
                  {isMainMenuBarView && (
                    <>
                      <Logo />
                      <Box>
                        <Typography
                          sx={{ fontWeight: 800, ml: 0, fontSize: '18px', color: theme.palette.primary.main }}
                        >
                          {appName}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            ml: 0,
                            fontSize: '12px',
                            color: theme.palette.grey[500],
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {appDescription}
                        </Typography>
                      </Box>
                    </>
                  )}
                </>
              )}
              <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={isCollapse} />
            </Stack>
          </Stack>
          {/* {!isCollapse && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#ffebee',
                p: 1,
                px: 2,
                borderRadius: 2,
                margin: 1,
              }}
            >
              <ReportProblemIcon color="error" sx={{ mr: 1 }} />
              <Typography variant="body2" color="error" sx={{ flexGrow: 1 }}>
                We are in maintainance until March 28, 2025 11AM UTC -  01PM UTC
              </Typography>
            </Box>
          )} */}

          {!isCollapse ? (
            <>
              {!isMainMenuBarView ? (
                <Stack direction="row" alignItems="center" spacing={0.8} sx={{ ml: '1rem', mb: '0.5rem' }}>
                  <CustomIconButton onClick={backButtonClick}>
                    <ArrowBackIcon />
                  </CustomIconButton>
                  <StyledTypography variant="body2" onClick={backButtonClick}>
                    Back
                  </StyledTypography>
                </Stack>
              ) : (
                hasPublishPostPermission && (
                  <HighlightButton
                    variant="contained"
                    startIcon={<AddIconStyle />}
                    onClick={() => {
                      navigate('/new-post');
                    }}
                  >
                    Compose Post
                  </HighlightButton>
                )
              )}
              {brandListView()}
            </>
          ) : (
            <>
              {!isMainMenuBarView ? (
                <HighlightButton
                  variant="contained"
                  startIcon={<ArrowBackIcon />}
                  onClick={backButtonClick}
                ></HighlightButton>
              ) : (
                hasPublishPostPermission && (
                  <HighlightButton
                    variant="contained"
                    startIcon={<AddIconStyle />}
                    onClick={() => {
                      navigate('/new-post');
                    }}
                  ></HighlightButton>
                )
              )}
              {brandListView()}
            </>
          )}
          <Box>
            <List
              disablePadding
              sx={{
                px: 2,
                ...(isCollapse && {
                  padding: '4px',
                }),
              }}
            >
              <NavItemRoot menuItems={menuItems} isCollapse={isCollapse} handleSidebar={handleSidebar} />
            </List>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
        </Scrollbar>
      </Drawer>
      {openWorkPlaceModal && <WorkPlaceBox {...workPlaceProps} />}
    </>
  );
};
export default MenuBarComponent;
