import { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Paper,
  Stack,
  useMediaQuery,
  useTheme,
  Divider,
} from '@mui/material';
import { CommonHelper } from 'src/utils/commonHelper';
import {
  FavoriteBorderRounded as FavoriteBorderRoundedIcon,
  Public as PublicIcon,
  MoreVert as MoreVertIcon,
  FiberManualRecord as FiberManualRecordIcon,
  BookmarkBorder as BookmarkBorderIcon,
  NavigateBefore,
  NavigateNext,
} from '@mui/icons-material';
import Instagramcomment from '../../../../assets/comment.svg';
import Instagramshare from '../../../../assets/instagram-share-icon.svg';
import InstagramCarouselPost from './instagram-carousel-post-preview.component';

const InstagramPostImagesPreview = ({ data }) => {
  let mediaFiles = CommonHelper.ProcessMediaUrl(data?.media_url)?.media_data;
  const theme = useTheme();
  const respwidth = useMediaQuery(theme.breakpoints.up('xl'));
  const ConnectedProfiles = data.connected_profile_details;
  const [showFullDescription, setShowFullDescription] = useState(false);
  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  const ProfileTime = () => {
    return (
      <>
        <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ gap: '0.3rem' }}>
          <Typography variant="span">Just Now</Typography>
          <FiberManualRecordIcon sx={{ fontSize: 5, mt: '0.6rem' }} />
          <PublicIcon sx={{ fontSize: 15, mt: '0.3rem' }} />
        </Stack>
      </>
    );
  };

  return (
    <>
      <Stack container justifyContent="center" alignItems="center">
        <Card
          sx={{
            maxWidth: 500,
            minWidth: respwidth ? 400 : null,
            boxShadow: 3,
            borderRadius: 2,
            border: 'none',
            borderColor: theme.palette.preview.postcard.color,
          }}
        >
          <CardHeader
            avatar={
              <Avatar
                src={
                  ConnectedProfiles.connected_profile_image
                    ? ConnectedProfiles.connected_profile_image
                    : ConnectedProfiles.connected_profile_name || ConnectedProfiles.connected_profile_id
                }
                alt={
                  ConnectedProfiles.connected_profile_name
                    ? ConnectedProfiles.connected_profile_name
                    : ConnectedProfiles.connected_profile_id
                }
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  height: 40,
                  width: 40,
                  bgcolor: theme.palette.primary.main,
                }}
                style={{ color: 'white' }}
              />
            }
            title={
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 1,
                    overflow: 'hidden',
                    WebkitBoxDecorationBreak: 'clone',
                    whiteSpace: 'nowrap',
                    maxWidth: '200px',
                    textOverflow: 'ellipsis',
                    fontWeight: 600,
                  }}
                >
                  {ConnectedProfiles?.connected_profile_name}
                </Typography>
                <FiberManualRecordIcon
                  sx={{ fontSize: 4, mt: '0.6rem', alignItems: 'center', color: theme.palette.background.primary }}
                />
                <Typography variant="subtitle2" sx={{ color: theme.palette.background.primary }}>
                  Just Now
                </Typography>
              </Box>
            }
            sx={{ fontSize: { xl: 15, lg: 13, md: 11, sm: 9, xs: 7 }, mb: 1 }}
            // subheader={<ProfileTime />}
            action={<MoreVertIcon sx={{ mt: 1 }} />}
          />
          <CardMedia>{InstagramCarouselPost(mediaFiles)}</CardMedia>
          <CardActions disableSpacing sx={{ display: 'block', p: 0 }}>
            {data.has_comment && data.comment_description && (
              <Stack sx={{ justifyContent: 'flex-end', display: 'flex', alignItems: 'flex-end' }}>
                <Typography color={'text.secondary'}>1 comment</Typography>
              </Stack>
            )}
            {/* <Divider /> */}
            <Stack direction="row" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <Paper sx={{ display: 'flex', alignItems: 'center', mt: 0.5, gap: 2, pl: 1 }}>
                <FavoriteBorderRoundedIcon sx={{ color: theme.palette.preview.postcard.icon }} />
                <img
                  src={Instagramcomment}
                  style={{ height: 'auto', width: '100%', maxWidth: '22px', maxHeight: '22px' }}
                />
                <img
                  src={Instagramshare}
                  style={{ height: 'auto', width: '100%', maxWidth: '20px', maxHeight: '20px' }}
                />
              </Paper>
              <Paper sx={{ display: 'flex', alignItems: 'center', mt: 0.5, gap: 1, pr: 1 }}>
                <BookmarkBorderIcon sx={{ color: theme.palette.preview.postcard.icon }} />
              </Paper>
            </Stack>
          </CardActions>
          <CardContent sx={{ p: 1 }}>
            <Typography
              noWrap={!showFullDescription}
              sx={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: showFullDescription ? 'initial' : 2,
                overflow: 'hidden',
                WebkitBoxDecorationBreak: 'clone',
                whiteSpace: 'pre-line',
                textOverflow: 'ellipsis',
                fontSize: '0.875rem',
              }}
            >
              <span style={{ position: 'relative', fontWeight: 'bold', marginRight: '5px' }}>
                {ConnectedProfiles?.connected_profile_name}
              </span>
              {CommonHelper.ParseDescription(data.description)}
            </Typography>
            {!showFullDescription && data.description.length > 100 && (
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 700,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontSize: '0.875rem',
                  color: theme.palette.primary.main,
                }}
                onClick={handleToggleDescription}
              >
                Show More
              </Typography>
            )}
            {showFullDescription && (
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 700,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontSize: '0.875rem',
                  color: theme.palette.primary.main,
                }}
                onClick={handleToggleDescription}
              >
                Show Less
              </Typography>
            )}

            {data.has_comment && data.comment_description && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: '0.5rem',
                    marginTop: '8px',
                  }}
                >
                  <Avatar
                    src={
                      ConnectedProfiles.connected_profile_image
                        ? ConnectedProfiles.connected_profile_image
                        : ConnectedProfiles.connected_profile_name || ConnectedProfiles.connected_profile_id
                    }
                    alt={
                      ConnectedProfiles.connected_profile_name
                        ? ConnectedProfiles.connected_profile_name
                        : ConnectedProfiles.connected_profile_id
                    }
                    sx={{
                      textAlign: 'flex-start',
                      height: 30,
                      width: 30,
                      bgcolor: theme.palette.primary.main,
                    }}
                  />
                  <Box sx={{ width: '100%' }}>
                    <Stack spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
                      <Typography variant="body2" sx={{ fontWeight: 800 }}>
                        @{ConnectedProfiles?.connected_profile_name}
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 1,
                          overflow: 'hidden',
                          WebkitBoxDecorationBreak: 'clone',
                          whiteSpace: 'pre-line',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {CommonHelper.ParseDescription(data.comment_description)}
                      </Typography>
                    </Stack>

                    <Stack spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Just Now
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: 800, color: 'text.secondary' }}>
                        Like
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: 800, color: 'text.secondary' }}>
                        Reply
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
              </>
            )}
          </CardContent>
        </Card>
      </Stack>
    </>
  );
};

export default InstagramPostImagesPreview;
