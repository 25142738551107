import { Box, Typography, Avatar, Card, Paper, Stack, useMediaQuery, useTheme, IconButton } from '@mui/material';
import { useState, useRef, useEffect } from 'react';
import { CommonHelper } from 'src/utils/commonHelper';
import {
  MoreHoriz as MoreHorizIcon,
  IosShare as IosShareIcon,
  StarBorder as StarBorderIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  PlayArrow,
} from '@mui/icons-material';

const PinterestMediaView = ({ mediaFiles }) => {
  const [aspectRatios, setAspectRatios] = useState([]);
  const mediaRefs = useRef([]);
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const newRatios = mediaRefs.current.map((el) => {
      if (!el) return 1;
      if (el.tagName === 'IMG') return el.naturalWidth / el.naturalHeight;
      if (el.tagName === 'VIDEO') return el.videoWidth / el.videoHeight;
      return 1;
    });
    setAspectRatios(newRatios);
  }, [mediaFiles]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true;
    }
  }, []);

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play().catch(console.error);
      }
      setIsPlaying(!isPlaying);
    }
  };

  const renderMedia = (media, i) => {
    const isImage = media?.type === 'image' || typeof media === 'string';
    const src = media?.url || media;
    const aspectRatio = aspectRatios[i] || 1;

    return (
      <Box
        sx={{
          position: 'relative',
          paddingTop: `${(1 / aspectRatio) * 100}%`,
          overflow: 'hidden',
          maxHeight: 460,
          width: '100%',
        }}
      >
        {isImage ? (
          <img
            ref={(el) => (mediaRefs.current[i] = el)}
            src={src}
            alt={`media-${i}`}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        ) : (
          <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
            <video
              ref={(el) => {
                mediaRefs.current[i] = el;
                videoRef.current = el;
              }}
              src={src}
              controls={false}
              onClick={togglePlay}
              muted
              playsInline
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
            />
            {!isPlaying && (
              <IconButton
                onClick={togglePlay}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  bgcolor: 'rgba(0,0,0,0.6)',
                  '&:hover': { bgcolor: 'rgba(0,0,0,0.8)' },
                }}
              >
                <PlayArrow sx={{ color: 'white', fontSize: 40 }} />
              </IconButton>
            )}
          </Box>
        )}
      </Box>
    );
  };

  return <Box sx={{ maxWidth: 500, width: '100%' }}>{renderMedia(mediaFiles[0], 0)}</Box>;
};

const PinterestPostCard = ({ title, description, media_url, profile }) => {
  let mediaFiles = CommonHelper.PublishedPostProcessMediaUrl(media_url)?.media_data;
  const [showFullDescription, setShowFullDescription] = useState(false);
  const theme = useTheme();
  const respwidth = useMediaQuery(theme.breakpoints.up('xl'));
  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  return (
    <>
      <Stack container justifyContent="flex-start" alignItems="flex-start">
        <Card
          sx={{
            width: 300,
            maxWidth: 500,
            minWidth: respwidth ? 300 : 200,
            boxShadow: 'none',
            border: 'solid 2px',
            borderColor: theme.palette.preview.postcard.color,
          }}
        >
          <Box sx={{ position: 'relative' }}>
            <PinterestMediaView mediaFiles={mediaFiles} />
          </Box>

          <Box sx={{ alignSelf: 'flex-start', p: 1.3 }}>
            <Stack direction="row" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <Paper sx={{ display: 'flex', alignItems: 'center', gap: '0.4rem', mb: 1 }}>
                <MoreHorizIcon fontSize="small" />
                <IosShareIcon fontSize="small" />
                <StarBorderIcon fontSize="small" />
              </Paper>
              <Paper sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Box sx={{ p: 1, borderRadius: '50px', display: 'flex' }}>
                  <Typography sx={{}}>Board</Typography>
                  <KeyboardArrowDownIcon />
                </Box>
                <Box sx={{ p: 1, backgroundColor: 'error.main', borderRadius: '50px' }}>
                  <Typography sx={{ color: 'white' }}>Save</Typography>
                </Box>
              </Paper>
            </Stack>
            {/* <Typography
                            style={{
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 1,
                                overflow: 'hidden',
                                WebkitBoxDecorationBreak: 'clone',
                                whiteSpace: 'pre-line',
                                textOverflow: 'ellipsis',
                                fontSize: '0.9rem',
                                color: 'black',
                                textDecoration: 'underline',
                                marginBottom: '8px',
                            }}
                        >
                            {data.link}
                        </Typography> */}

            <Typography
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
                overflow: 'hidden',
                WebkitBoxDecorationBreak: 'clone',
                whiteSpace: 'pre-line',
                textOverflow: 'ellipsis',
                fontSize: '1.3rem',
                color: 'black',
                fontWeight: 700,
              }}
            >
              {title}
            </Typography>

            {/* <Typography
                            style={{
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 1,
                                overflow: 'hidden',
                                WebkitBoxDecorationBreak: 'clone',
                                whiteSpace: 'pre-line',
                                textOverflow: 'ellipsis',
                                fontSize: '0.8rem',
                                color: 'black'
                            }}
                        >
                            {data.description}
                        </Typography> */}

            <Typography
              noWrap={!showFullDescription}
              sx={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: showFullDescription ? 'initial' : 2,
                overflow: 'hidden',
                WebkitBoxDecorationBreak: 'clone',
                whiteSpace: 'pre-line',
                textOverflow: 'ellipsis',
                fontSize: '0.875rem',
              }}
            >
              {description}
            </Typography>
            {!showFullDescription && description.length > 100 && (
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 700,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontSize: '0.875rem',
                  color: theme.palette.primary.main,
                }}
                onClick={handleToggleDescription}
              >
                Show More
              </Typography>
            )}
            {showFullDescription && (
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 700,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontSize: '0.875rem',
                  color: theme.palette.primary.main,
                }}
                onClick={handleToggleDescription}
              >
                Show Less
              </Typography>
            )}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mt: 2,
              }}
            >
              <Box sx={{ display: 'flex', gap: '0.3rem' }}>
                <Avatar
                  src={profile.image_url ? profile.image_url : profile.profile_name}
                  alt={profile.profile_name ? profile.profile_name : profile.id}
                  sx={{
                    height: 25,
                    width: 25,
                    bgcolor: theme.palette.primary.main,
                  }}
                />
                <Typography
                  variant="body2"
                  style={{
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 1,
                    overflow: 'hidden',
                    WebkitBoxDecorationBreak: 'clone',
                    whiteSpace: 'pre-line',
                    textOverflow: 'ellipsis',
                    fontSize: '1rem',
                    fontWeight: '700',
                  }}
                >
                  {profile?.profile_name}
                </Typography>
              </Box>
              <Box sx={{ p: 1, backgroundColor: 'background.shadow', borderRadius: '50px' }}>
                <Typography>Follow</Typography>
              </Box>
            </Box>
          </Box>
        </Card>
      </Stack>
    </>
  );
};

export default PinterestPostCard;
