import { Box, Button, Card, Divider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import NextBillPaymentComponent from 'src/components/stripe/nextBill-payment.component';
import HttpServices from 'src/services/httpService';
import { paymentModuleUrl } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import { useToaster } from 'src/utils/toaster/toasterContext';

const NextPlanBillDetails = ({
  hasSubscription,
  hasTrailPeriod,
  expireDate,
  isAppsumoUser,
  defaultPlanName,
  handleBuyPlan,
}) => {
  const orgDetails = CommonHelper.GetOrgDetails();
  const [isPaymentOn, setPaymentOn] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [expiresOn, setPlanExpiresOn] = useState('');
  const [lifeTimePlanName, setLifeTimePlanName] = useState('');
  const [newExpiresOn, setNewPlanExpiresOn] = useState('');
  // const [hasActiveSubscription, setActiveSubscription] = useState(false);
  const { showToast } = useToaster();

  useEffect(() => {
    if (hasSubscription) {
      getNextPriceBill();
    }
    if (isAppsumoUser) {
      switch (defaultPlanName) {
        case 'brand_lifetime':
          setLifeTimePlanName('Brand');
          break;
        case 'agency_lifetime':
          setLifeTimePlanName('Agency');
          break;
        case 'mini_agency_lifetime':
          setLifeTimePlanName('Agency');
          break;
        case 'business_lifetime':
          setLifeTimePlanName('Business');
          break;
        case 'team_lifetime':
          setLifeTimePlanName('Team');
          break;
        default:
          break;
      }
    }
  }, []);

  const handlePayment = (show) => {
    setPaymentOn(show);
  };
  const failureCallback = (message) => {
    message && showToast(message);
  };

  const successCallback = (data, message) => {
    setTotalAmount(data.amount);
    setPlanExpiresOn(data.expire_date);
    setNewPlanExpiresOn(data.new_expire_date);
    // setActiveSubscription(data.has_active_subscription);
  };

  const getNextPriceBill = () => {
    let postProps = {
      url: paymentModuleUrl.getForNextBill,
      successCallback: successCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };

  return (
    <Box sx={{}}>
      {hasTrailPeriod ? (
        <Box sx={{ p: 2, textAlign: 'center', borderRadius: 2 }}>
          <Typography variant="h6" sx={{ fontWeight: 600, color: 'primary.main' }}>
            You are currently on a trial plan.
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 700, color: 'text.secondary', mt: 1 }}>
            Expires On:
          </Typography>
          <Typography variant="body2" sx={{ color: 'error.main', fontWeight: 600 }}>
            {CommonHelper.formatDateAndTime(expireDate, 'date')}
          </Typography>
          <Typography
            variant="body2"
            sx={{ mt: 2, color: 'primary.main', fontWeight: 600, cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleBuyPlan}
          >
            Please click here to buy a plan
          </Typography>
        </Box>
      ) : (
        <>
          {isAppsumoUser && (
            <Box sx={{ padding: '5px', margin: '1rem', mb: 0 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                You have lifetime subscription of {lifeTimePlanName} plan.
              </Typography>
            </Box>
          )}
          {hasSubscription ? (
            <Card
              sx={{
                p: 1,
                mt: 1,
                width: '100%',
                borderRadius: 1,
              }}
            >
              <Typography variant="h6" sx={{ mb: 1 }}>
                Current Plan pay to next month
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography sx={{ fontWeight: 700, color: 'text.secondary' }}>Expires On:</Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  {CommonHelper.formatDateAndTime(expiresOn, 'date')}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography sx={{ fontWeight: 700, color: 'text.secondary' }}>New Expires On:</Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  {CommonHelper.formatDateAndTime(newExpiresOn, 'date')}
                </Typography>
              </Box>
              <Divider />
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography sx={{ fontWeight: 700 }}>Total Amount:</Typography>
                <Typography>{orgDetails.currency_symbol + totalAmount}</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                {hasSubscription && (
                  <Button variant="contained" onClick={() => handlePayment(true)} sx={{ mb: 0.5 }}>
                    Pay Next Bill
                  </Button>
                )}
              </Box>
              {isPaymentOn && (
                <NextBillPaymentComponent
                  handleClose={handlePayment}
                  amount={totalAmount}
                  currencySymbol={orgDetails.currency_symbol}
                />
              )}
            </Card>
          ) : (
            !isAppsumoUser && (
              <>
                <Box sx={{ p: 2, textAlign: 'center', borderRadius: 2 }}>
                  <Typography variant="h6" sx={{ fontWeight: 600, color: 'primary.main' }}>
                    You dont have an active subscription.
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      mt: 2,
                      color: 'primary.main',
                      fontWeight: 600,
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                    onClick={handleBuyPlan}
                  >
                    Please click here to buy a plan
                  </Typography>
                </Box>
              </>
            )
          )}
        </>
      )}
    </Box>
  );
};

export default NextPlanBillDetails;
