import {
  Box,
  Card,
  Avatar,
  Typography,
  IconButton,
  Button,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useRef, useState, useEffect } from 'react';
import { CommonHelper } from 'src/utils/commonHelper';
import {
  Public,
  PlayArrow,
  MoreHoriz,
  MusicNote,
  CameraAlt, // Added for effect icon
} from '@mui/icons-material';
import { keyframes } from '@mui/system';

import FBcomment from '../../../../assets/comment-white.svg';
import FBlike from '../../../../assets/fblike-white.svg';
import FBshare from '../../../../assets/fbshare-white.svg';

const FacebookReelsPreview = ({ data }) => {
  const theme = useTheme();
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const ConnectedProfiles = data.connected_profile_details;
  const progressAnimation = keyframes`
  0% { width: 0%; }
  100% { width: 100%; }
`;

  useEffect(() => {
    // Initialize video with muted autoplay for mobile browsers
    if (videoRef.current) {
      videoRef.current.muted = true;
    }
  }, []);

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
        videoRef.current.muted = true; // Mute when paused
      } else {
        videoRef.current.muted = false; // Unmute when playing
        videoRef.current.play().catch(console.error);
      }
      setIsPlaying(!isPlaying);
    }
  };



  return (
    <Box display="flex" justifyContent="center" alignItems="center" p={1}>
      <Card sx={{
        width: isMobile ? 300 : 340,
        height: 600,
        borderRadius: '12px',
        position: 'relative',
        overflow: 'hidden',
        bgcolor: 'black',
      }}>
        <video
          ref={videoRef}
          src={data.media_url}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          controls={false}
          onClick={togglePlay}
          muted  // Required for autoplay in most browsers
          playsInline // Required for iOS Safari
          onError={(e) => console.error('Video error:', e)}
        />

        {!isPlaying && (
          <IconButton
            onClick={togglePlay}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'rgba(0,0,0,0.6)',
              '&:hover': { bgcolor: 'rgba(0,0,0,0.8)' },
              width: 56,
              height: 56,
            }}
          >
            <PlayArrow sx={{ color: 'white', fontSize: 40 }} />
          </IconButton>
        )}

        {/* Top Profile Section */}
        {/* Top Profile Section with Follow Button */}
        <Box sx={{
          position: 'absolute',
          top: 16,
          left: 12,
          right: 12,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: 'white',
        }}>
          <Box display="flex" alignItems="center" gap={0.5}>
            <Avatar
              src={
                ConnectedProfiles.connected_profile_image
                  ? ConnectedProfiles.connected_profile_image
                  : ConnectedProfiles.connected_profile_name || ConnectedProfiles.connected_profile_id
              }
              alt={
                ConnectedProfiles.connected_profile_name
                  ? ConnectedProfiles.connected_profile_name
                  : ConnectedProfiles.connected_profile_id
              }
              sx={{ width: 32, height: 32, bgcolor: theme.palette.primary.main, }}
            />
            <Typography variant="subtitle2" fontWeight={600} sx={{ ml: 0.5 }}>
              {ConnectedProfiles.connected_profile_name}
            </Typography>
            <Public sx={{ fontSize: 14, color: 'rgba(255,255,255,0.8)', mx: 0.5 }} />
            <Typography component="span" sx={{ color: 'rgba(255,255,255,0.8)', fontSize: 12 }}>•</Typography>
            <Button
              variant="text"
              size="small"
              sx={{
                color: 'white',
                textTransform: 'none',
                fontSize: '13px',
                fontWeight: 600,
                p: 0,
                minWidth: 0,
                '&:hover': {
                  backgroundColor: 'transparent',
                  opacity: 0.9
                }
              }}
            >
              Follow
            </Button>
          </Box>
          <IconButton sx={{ color: 'white', p: 0 }}>
            <MoreHoriz sx={{ fontSize: 25 }} />
          </IconButton>
        </Box>
        {/* Caption Section */}


        {/* Updated Caption Section (moved up) */}
        <Box sx={{
          position: 'absolute',
          bottom: 55, // Adjusted position
          left: 12,
          right: 50,
          color: 'white',
        }}>
          <Typography variant="body2" sx={{
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            fontSize: 14,
            lineHeight: 1.4,
          }}>
            {CommonHelper.ParseDescription(data.description)}
          </Typography>
        </Box>
        <Box sx={{
          position: 'absolute',
          bottom: 30,
          left: 12,
          right: 12,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          color: 'white',
        }}>
          <MusicNote sx={{ fontSize: 16 }} />
          <Box sx={{
            position: 'relative',
            flexGrow: 1,
            overflow: 'hidden',
          }}>
            {/* Animated text container */}
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              width: 'max-content',
              animation: 'marquee 10s linear infinite',
              '@keyframes marquee': {
                '0%': { transform: 'translateX(0%)' },
                '100%': { transform: 'translateX(-50%)' }
              }
            }}>
              <Typography variant="body2" sx={{
                fontSize: 12,
                fontWeight: 500,
                whiteSpace: 'nowrap',
                pr: 2, // Add spacing between repeated text
              }}>
                Original sound · {ConnectedProfiles.connected_profile_name}
              </Typography>
              {/* Duplicate text for seamless animation */}
              <Typography variant="body2" sx={{
                fontSize: 12,
                fontWeight: 500,
                whiteSpace: 'nowrap',
              }}>
                Original sound · {ConnectedProfiles.connected_profile_name}
              </Typography>
            </Box>

            {/* Progress bar container */}
            <Box sx={{
              position: 'absolute',
              bottom: -6,
              left: 0,
              right: 0,
              height: 2,
              backgroundColor: 'rgba(255,255,255,0.2)',
              borderRadius: 2,
              overflow: 'hidden',
            }}>
              {/* Animated progress bar */}
              <Box sx={{
                width: '100%',
                height: '100%',
                backgroundColor: 'white',
                animation: `${progressAnimation} 3s infinite linear`,
                position: 'absolute',
              }} />
            </Box>
          </Box>
        </Box>


        {/* Right Action Icons */}
        <Box
          sx={{
            position: 'absolute',
            bottom: '16px',
            right: '12px',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <IconButton sx={{
              color: 'white',
              p: 1,
              '&:hover': {
                backgroundColor: 'rgba(255,255,255,0.1)'
              }
            }}>
              <img
                src={FBlike}
                style={{
                  width: '24px',
                  height: '24px',
                  filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.3))'
                }}
              />
            </IconButton>
            <Typography variant="caption" sx={{
              color: 'white',
              fontSize: 12,
              fontWeight: 500,
              textShadow: '0 1px 2px rgba(0,0,0,0.3)'
            }}>
              24.5K
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <IconButton sx={{
              color: 'white',
              p: 1,
              '&:hover': {
                backgroundColor: 'rgba(255,255,255,0.1)'
              }
            }}>
              <img
                src={FBcomment}
                style={{
                  width: '24px',
                  height: '24px',
                  filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.3))'
                }}
              />
            </IconButton>
            <Typography variant="caption" sx={{
              color: 'white',
              fontSize: 12,
              fontWeight: 500,
              textShadow: '0 1px 2px rgba(0,0,0,0.3)'
            }}>
              1.2K
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <IconButton sx={{
              color: 'white',
              p: 1,
              '&:hover': {
                backgroundColor: 'rgba(255,255,255,0.1)'
              }
            }}>
              <img
                src={FBshare}
                style={{
                  width: '24px',
                  height: '24px',
                  filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.3))'
                }}
              />
            </IconButton>
            <Typography variant="caption" sx={{
              color: 'white',
              fontSize: 12,
              fontWeight: 500,
              textShadow: '0 1px 2px rgba(0,0,0,0.3)'
            }}>
              Share
            </Typography>
          </Box>


          <Avatar
            src={
              ConnectedProfiles.connected_profile_image
                ? ConnectedProfiles.connected_profile_image
                : ConnectedProfiles.connected_profile_name || ConnectedProfiles.connected_profile_id
            }
            alt={
              ConnectedProfiles.connected_profile_name
                ? ConnectedProfiles.connected_profile_name
                : ConnectedProfiles.connected_profile_id
            }
            sx={{
              width: '34px',
              height: '34px',
              borderRadius: '50%',
              border: '2px solid white',
              mt: 1,
              overflow: 'hidden',
              boxShadow: '0 2px 8px rgba(0,0,0,0.2)',
              bgcolor: theme.palette.primary.main,
              '&:hover': {
                transform: 'scale(1.05)'
              }
            }}
          />
        </Box>
      </Card>
    </Box>
  );
};

export default FacebookReelsPreview;