import { Box, Card, Avatar, Typography, IconButton, useTheme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useRef, useState, useEffect } from 'react';
import { CommonHelper } from 'src/utils/commonHelper';
import { Public, PlayArrow, MoreHoriz, Send, VolumeUp, VolumeOff } from '@mui/icons-material';
import { keyframes } from '@mui/system';

const ProgressContainer = styled(Box)({
  position: 'absolute',
  top: 8,
  left: 8,
  right: 8,
  display: 'flex',
  gap: 4,
  zIndex: 2,
});

const ProgressBarContainer = styled(Box)({
  height: 2,
  borderRadius: 2,
  backgroundColor: 'rgba(255, 255, 255, 0.4)',
  flexGrow: 1,
  overflow: 'hidden',
  position: 'relative',
});

const ProgressFill = styled(Box)(({ progress }) => ({
  height: '100%',
  backgroundColor: 'white',
  width: `${progress}%`,
  transition: 'width 0.1s linear',
}));

const progressAnimation = keyframes`
0% { width: 0%; }
100% { width: 100%; }
`;

const FacebookStoryCard = ({
  media_url,
  media_type,
  message,
  like_count,
  share_count,
  comment_count,
  is_liked,
  profile,
  handleUnLikePost,
  handleLikePost,
  hasLikePermission,
  handleFocusComment,
}) => {
  const theme = useTheme();
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [progress, setProgress] = useState(0);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleTimeUpdate = () => {
      if (video.duration > 0) {
        const currentProgress = (video.currentTime / video.duration) * 100;
        setProgress(currentProgress);
      }
    };

    video.addEventListener('timeupdate', handleTimeUpdate);
    return () => video.removeEventListener('timeupdate', handleTimeUpdate);
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = isMuted;
    }
  }, [isMuted]);

  useEffect(() => {
    setProgress(0);
  }, [media_url]);

  const togglePlay = () => {
    if (!videoRef.current) return;

    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play().catch(console.error);
    }
    setIsPlaying(!isPlaying);
  };

  const toggleMute = () => {
    if (!videoRef.current) return;
    videoRef.current.muted = !isMuted;
    setIsMuted(!isMuted);
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" p={1}>
      <Card
        sx={{
          width: isMobile ? 300 : 340,
          height: 600,
          borderRadius: '12px',
          position: 'relative',
          overflow: 'hidden',
          bgcolor: 'black',
        }}
      >
        <ProgressContainer>
          <ProgressBarContainer>
            <ProgressFill progress={progress} />
          </ProgressBarContainer>
        </ProgressContainer>

        {media_type === 'photo' ? (
          <img
            src={media_url}
            alt="Story"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        ) : (
          <video
            ref={videoRef}
            src={media_url}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
            controls={false}
            onClick={togglePlay}
            muted // Required for autoplay in most browsers
            playsInline // Required for iOS Safari
            onError={(e) => console.error('Video error:', e)}
          />
        )}

        {!isPlaying && media_type === 'video' && (
          <IconButton
            onClick={togglePlay}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'rgba(0,0,0,0.6)',
              '&:hover': { bgcolor: 'rgba(0,0,0,0.8)' },
              width: 56,
              height: 56,
            }}
          >
            <PlayArrow sx={{ color: 'white', fontSize: 40 }} />
          </IconButton>
        )}

        {/* Top Profile Section */}
        {/* Top Profile Section with Follow Button */}
        <Box
          sx={{
            position: 'absolute',
            top: 16,
            left: 8,
            right: 8,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            zIndex: 1,
            transition: 'transform 0.3s ease',
            transform: 'translateY(0)',
          }}
        >
          {/* Left Side - Avatar */}
          <Avatar
            src={profile.profile_image ? profile.profile_image : profile.profile_name || profile.id}
            alt={profile.profile_name ? profile.profile_name : profile.id}
            sx={{ width: 32, height: 32, bgcolor: theme.palette.primary.main }}
          />

          {/* Centered Content (Profile Name) */}
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '50%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <Typography
                variant="body2"
                color="white"
                fontWeight={600}
                style={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                  overflow: 'hidden',
                  WebkitBoxDecorationBreak: 'clone',
                  whiteSpace: 'pre-line',
                  textOverflow: 'ellipsis',
                  nowrap: 'true',
                }}
              >
                {profile?.profile_name}
              </Typography>
              <Typography variant="caption" sx={{ color: 'white' }}>
                Just&nbsp;Now
              </Typography>
              <Public sx={{ fontSize: 14, color: 'white' }} />
            </Box>
          </Box>

          {/* Right Side - More Options Icon */}
          <Box sx={{ flexGrow: 0.5 }} />
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            {media_type === 'video' && (
              <IconButton sx={{ color: 'white', p: 0.5 }} onClick={toggleMute}>
                {isMuted ? <VolumeOff sx={{ fontSize: 24 }} /> : <VolumeUp sx={{ fontSize: 24 }} />}
              </IconButton>
            )}
            <MoreHoriz sx={{ fontSize: 24, color: 'white' }} />
          </Box>
        </Box>
        {/* Caption Section */}

        {/* Updated Caption Section (moved up) */}
        <Box
          sx={{
            position: 'absolute',
            bottom: 55, // Adjusted position
            left: 12,
            right: 50,
            color: 'white',
          }}
        >
          <Typography
            variant="body2"
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              fontSize: 14,
              lineHeight: 1.4,
            }}
          >
            {CommonHelper.ParseDescription(message)}
          </Typography>
        </Box>

        {/* Bottom Controls */}
        <Box
          sx={{
            position: 'absolute',
            bottom: 16,
            left: 8,
            right: 8,
            display: 'flex',
            gap: 1,
            alignItems: 'center',
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              position: 'relative',
              '&:before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                borderRadius: 4,
                border: '1px solid white',
              },
            }}
          >
            <Typography
              sx={{
                padding: '2px 10px',
                borderRadius: '20px',
                color: 'white',
              }}
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
                overflow: 'hidden',
                WebkitBoxDecorationBreak: 'clone',
                whiteSpace: 'pre-line',
                textOverflow: 'ellipsis',
                nowrap: 'true',
              }}
            >
              Reply to @{profile?.profile_name}
            </Typography>

            {/* Send Icon positioned on the right */}
            <Send
              sx={{
                position: 'absolute',
                right: 8,
                top: '50%',
                transform: 'translateY(-50%)',
                color: 'white',
                cursor: 'pointer',
              }}
            />
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default FacebookStoryCard;
