import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import Spinner from 'src/utils/Loader/spinner';
import { useToaster } from 'src/utils/toaster/toasterContext';
import CurrentSubscriptionDetails from './current-subscription-details.component';

const BillingComponent = ({ handleBuyPlan }) => {
  const { showToast } = useToaster();

  // State Variable
  const [loader, setLoader] = useState(false);
  const [currentPlanDetails, setCurrentPlanDetails] = useState(null);

  useEffect(() => {
    getCurrentSubscription();
  }, []);

  const successCallback = (data, message) => {
    setLoader(false);
    setCurrentPlanDetails(data);
  };

  const failureCallback = (message) => {
    message && showToast(message, 'e');
    setLoader(false);
  };

  const getCurrentSubscription = () => {
    const getProps = {
      url: userModuleURL.getCurrentSubscription,
      successCallback,
      failureCallback,
    };
    HttpServices.Get(getProps);
  };

  return (
    <Box>
      {loader ? (
        <Spinner />
      ) : (
        currentPlanDetails && (
          <CurrentSubscriptionDetails currentPlanDetails={currentPlanDetails} handleBuyPlan={handleBuyPlan} />
        )
      )}
    </Box>
  );
};
export default BillingComponent;
