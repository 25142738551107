import { LoadingButton } from '@mui/lab';
import {
    Avatar,
    Box,
    Card,
    Chip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CommonHelper } from 'src/utils/commonHelper';
import Spinner from 'src/utils/Loader/spinner';
import NoPostFound from 'src/pages/publishedPosts/noPost.component';
import ReportHeaderComponent from 'src/components/reports/report.header.component';
import NoMessage from '../../../assets/app-image/no-message.svg';
import { Warning as WarningIcon } from '@mui/icons-material';

const FacebookReportComponent = ({ connectedNetworks, startDate, endDate, viewBy, handleProfileClick }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const hasIntegratationPermission = CommonHelper.CheckForPermissionAvailableForUser(
        CommonHelper.PermissionList.INTEGRATE_PERMISSION
    );
    const [isLoading, setLoading] = useState(true);
    const [facebookPageId, setFacebookPageId] = useState('');
    const [connectedMedias, setConnectedMedias] = useState([]);
    const [height, setHeight] = useState(window.innerHeight - 150);
    const [isAccountNeedsReauth, setAccountNeedsReauth] = useState(false);

    useEffect(() => {
      const activeNetworks = connectedNetworks.filter(c => c.is_active);
        let facebookId = activeNetworks && activeNetworks.length > 0 && activeNetworks[0].id;
        facebookId && setFacebookPageId(facebookId);
        setConnectedMedias(connectedNetworks);
        if (activeNetworks && activeNetworks.length === 0) {
          setLoading(false)
          setAccountNeedsReauth(true)
      }
        const handleResize = () => {
            setHeight(window.innerHeight - 300);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [connectedNetworks]);

    const handleFacebookPage = (id) => {
        setFacebookPageId(id);
    };

    const navigateToIntegration = () => {
        navigate('/integration');
    };


    const getNetworkChip = () => {
        return (
          connectedMedias &&
          connectedMedias.length > 0 &&
          connectedMedias.map((c) => {
            const isActive = c.id === facebookPageId;
            const isProfileActive = c.is_active;
            const showReauth = !isProfileActive && hasIntegratationPermission;
            return (
              <Chip
                key={c.id}
                style={{
                  borderRadius: 8,
                  opacity: isActive ? 1 : 0.9,
                  ...(!isProfileActive && {
                    backgroundColor: theme.palette.error.lighter,
                    border: `1px solid ${theme.palette.error.main}`
                  }),
                  ...(isActive && isProfileActive && {
                    backgroundColor: theme.palette.primary.main,
                    color: 'white',
                    fontWeight: 800
                  })
                }}
                sx={{
                  m: 0.4,
                  '&:hover': {
                    opacity: isProfileActive ? 0.8 : 1
                  },
                  ...(!isProfileActive && {
                    '& .MuiChip-deleteIcon': {
                      color: theme.palette.error.main,
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: 'transparent'
                      }
                    }
                  })
                }}
                onClick={isProfileActive ?
                  () => handleFacebookPage(c.id) :
                  (showReauth ? () => handleProfileClick(c) : undefined)
                }
                deleteIcon={!isProfileActive ? <WarningIcon /> : undefined}
                onDelete={showReauth ? () => handleProfileClick(c) : undefined}
                avatar={<Avatar
                  src={c.profile_image ? c.profile_image : c.profile_name || c.id}
                  alt={c.profile_name ? c.profile_name : c.id}
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    height: 40,
                    width: 40,
                    bgcolor: isActive ? 'white' : theme.palette.primary.main,
                    ...(!isProfileActive && {
                      bgcolor: theme.palette.error.main,
                      color: 'white'
                    })
                  }}
                  style={{
                    color: isActive ? theme.palette.primary.main : 'white',
                  }}
                />}
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {c.profile_name ? c.profile_name : c.id}
                    {!isProfileActive && (
                      <Typography variant="caption" sx={{ ml: 1, color: theme.palette.error.dark }}>
                        (Needs Re-auth)
                      </Typography>
                    )}
                  </Box>
                }
              />
            );
          })
        );
      };

    return (
        <Box>
            {connectedMedias && connectedMedias.length > 0 ? (
                <>
                    <Card sx={{ p: 2, maxHeight: 100, minHeight: isMobile && 100, overflowY: 'auto', visibility: true }} >
                        {getNetworkChip()}
                    </Card>
                    {isAccountNeedsReauth ? <Card
                        sx={{
                            mb: 2,
                            p: 2,
                            maxHeight: 'calc(100vh - 250px)',
                            minHeight: 'calc(100vh - 250px)',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
<img
                      src={NoMessage}
                      style={{ width: '45%', height: '45%', maxWidth: '45%', maxHeight: '45%' }}
                      alt="No Message Available"
                    />
                        <Typography variant="h4" sx={{ fontWeight: 800 }}>
                        Account Needs Re-authentication
                        </Typography>
                    </Card> :
                        <ReportHeaderComponent connectedProfileId={facebookPageId} startDate={startDate} endDate={endDate} viewBy={viewBy} serviceName={"facebook"} />}
                </>)
                :
                isLoading ?
                    <Spinner height={height} />
                    :
                    <Card sx={{ mb: 2, p: 2, height: height, display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 'calc(100vh - 150px)', maxHeight: 'calc(100vh - 150px)' }}>
                        {hasIntegratationPermission ? <LoadingButton
                            variant="contained"
                            autoFocus
                            onClick={() => {
                                navigateToIntegration();
                            }}
                        >
                            Connect Social Accounts
                        </LoadingButton> : <NoPostFound />}
                    </Card>}


        </Box>
    );
};

export default FacebookReportComponent;
