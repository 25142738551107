import { LoadingButton } from '@mui/lab';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';

const AISetUpComponent = ({ handleSkip, showToast, successCallback, failureCallback, onBoardingInfo }) => {
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [isLoading, setLoading] = useState(false);

  const handleAIOnboarding = () => {
    if (websiteUrl) {
      if (CommonHelper.isValidUrl(websiteUrl)) {
        setLoading(true);
        let postProps = {
          url: `${userModuleURL.getOnboardingInfoFromWebsite}`,
          successCallback,
          failureCallback,
        };
        HttpServices.Post(postProps);
      } else {
        showToast('Please enter valid website URL', 'e');
      }
    } else {
      showToast('Please enter website URL', 'e');
    }
  };

  const handleInputChange = (event) => {
    setWebsiteUrl(event.target.value);
  };

  return (
    <>
      {onBoardingInfo && onBoardingInfo['onboarding_skippable'] && (
        <Box display="flex" justifyContent="flex-end" sx={{ mb: 2 }}>
          <Button variant="contained" color="primary" onClick={handleSkip}>
            Skip
          </Button>
        </Box>
      )}

      <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', mt: 2 }}>
        Welcome to Brand2Social!
      </Typography>
      <Typography variant="body1" component="div" sx={{ color: 'text.secondary', mt: 1, mb: 4 }}>
        Let's start by creating your own personalized business profile. This will be used to generate unique custom
        content for your brand.
      </Typography>

      <Box component="form" noValidate autoComplete="off">
        <Typography variant="subtitle1" component="label" sx={{ display: 'block', textAlign: 'start', mb: 1 }}>
          Business Website
        </Typography>
        <TextField
          id="websiteUrl"
          name="websiteUrl"
          placeholder="URL"
          variant="outlined"
          fullWidth
          onChange={handleInputChange}
        />
      </Box>

      <LoadingButton
        variant="contained"
        fullWidth
        sx={{
          mt: 4,
          backgroundColor: 'black',
          color: 'white',
          py: 1.5,
          fontWeight: 'bold',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
        }}
        loading={isLoading}
        onClick={handleAIOnboarding}
      >
        Generate Business Profile
      </LoadingButton>
    </>
  );
};

export default AISetUpComponent;
