import { CommonHelper } from 'src/utils/commonHelper';
import { LocalStorage } from 'src/utils/storage/local-storage';
import { SessionStorage } from 'src/utils/storage/session-storage';

const InitialSetup = (data) => {
  let isSetupDone = false;
  if (data.org_id) {
    if (true) {
      if (data.is_user_enabled) {
        data.brand_details = data.brand_details.filter((a) => a.is_enabled);
          if (data.brand_details?.length > 0) {
            let currentBrandId = CommonHelper.GetCurrentBrandId();
            if (currentBrandId) {
              let isBrandAvailable = data.brand_details?.some((a) => a.brand_id === currentBrandId);
              if (!isBrandAvailable) {
                sessionStorage.clear();
                SessionStorage.setItem('current_brand_id', JSON.stringify(data.brand_details[0].brand_id));
              }
              isSetupDone = true;
            } else {
              sessionStorage.clear();
              SessionStorage.setItem('current_brand_id', JSON.stringify(data.brand_details[0].brand_id));
              isSetupDone = true;
            }
          } else {
            sessionStorage.clear();
          }
      }
    }
  }
  LocalStorage.setItem('initial-load', JSON.stringify(data));
  return isSetupDone;
};
export default InitialSetup;
