import { Avatar, Card, IconButton, Typography, useMediaQuery, useTheme, Box } from '@mui/material';
import { useState, useRef, useEffect } from 'react';
import { CommonHelper } from 'src/utils/commonHelper';
import { MusicNote, Favorite, Bookmark, AddCircle } from '@mui/icons-material';
import Instagramcomment from '../../../assets/tiktok-share-arrow.svg';
import Instagramshare from '../../../assets/tiktok-comment-white.svg';
import { keyframes } from '@mui/system';

const progressAnimation = keyframes`
  0% { width: 0%; }
  100% { width: 100%; }
`;

const TiktokPostCard = ({ media_url, description, profile, share_count, comment_count, like_count, view_count }) => {
  let mediaFiles = CommonHelper.PublishedPostProcessMediaUrl(media_url)?.media_data;
  const theme = useTheme();
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showFullDescription, setShowFullDescription] = useState(false);

  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  return (
    <Box display="flex" justifyContent="center" alignItems="center" p={1}>
      <Card
        sx={{
          width: isMobile ? 300 : 340,
          height: 600,
          borderRadius: '12px',
          position: 'relative',
          overflow: 'hidden',
          bgcolor: 'black',
        }}
      >
        {mediaFiles && mediaFiles[0]?.url && (
          <img
            src={mediaFiles[0].url}
            alt="Tiktok Reels"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        )}

        {/* Caption Section */}
        <Box
          sx={{
            position: 'absolute',
            bottom: 55,
            left: 12,
            right: 50,
            color: 'white',
          }}
        >
          <Typography
            variant="body2"
            sx={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: showFullDescription ? 'initial' : 2,
              overflow: 'hidden',
              WebkitBoxDecorationBreak: 'clone',
              whiteSpace: 'pre-line',
              textOverflow: 'ellipsis',
              fontSize: '0.875rem',
            }}
          >
            {description}
          </Typography>
          {!showFullDescription && description.length > 100 && (
            <Typography
              variant="body1"
              sx={{
                fontWeight: 700,
                textDecoration: 'underline',
                cursor: 'pointer',
                fontSize: '0.875rem',
                color: theme.palette.primary.main,
              }}
              onClick={handleToggleDescription}
            >
              Show More
            </Typography>
          )}
          {showFullDescription && (
            <Typography
              variant="body1"
              sx={{
                fontWeight: 700,
                textDecoration: 'underline',
                cursor: 'pointer',
                fontSize: '0.875rem',
                color: theme.palette.primary.main,
              }}
              onClick={handleToggleDescription}
            >
              Show Less
            </Typography>
          )}
        </Box>

        {/* Music Section */}
        <Box
          sx={{
            position: 'absolute',
            bottom: 30,
            left: 12,
            right: 12,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            color: 'white',
          }}
        >
          <MusicNote sx={{ fontSize: 16 }} />
          <Box
            sx={{
              position: 'relative',
              flexGrow: 1,
              overflow: 'hidden',
            }}
          >
            {/* Animated text container */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: 'max-content',
                animation: 'marquee 10s linear infinite',
                '@keyframes marquee': {
                  '0%': { transform: 'translateX(0%)' },
                  '100%': { transform: 'translateX(-50%)' },
                },
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontSize: 12,
                  fontWeight: 500,
                  whiteSpace: 'nowrap',
                  pr: 2,
                }}
              >
                {profile?.profile_name} · Original sound
              </Typography>
              {/* Duplicate text for seamless animation */}
              <Typography
                variant="body2"
                sx={{
                  fontSize: 12,
                  fontWeight: 500,
                  whiteSpace: 'nowrap',
                }}
              >
                {profile?.profile_name} · Original sound
              </Typography>
            </Box>

            {/* Progress bar container */}
            <Box
              sx={{
                position: 'absolute',
                bottom: -6,
                left: 0,
                right: 0,
                height: 2,
                backgroundColor: 'rgba(255,255,255,0.2)',
                borderRadius: 2,
                overflow: 'hidden',
              }}
            >
              {/* Animated progress bar */}
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'white',
                  animation: `${progressAnimation} 3s infinite linear`,
                  position: 'absolute',
                }}
              />
            </Box>
          </Box>
        </Box>

        {/* Right Action Icons */}
        <Box
          sx={{
            position: 'absolute',
            bottom: '16px',
            right: '12px',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            alignItems: 'center',
          }}
        >
          <Box sx={{ position: 'relative', display: 'inline-block' }}>
            <Avatar
              src={profile.profile_image ? profile.profile_image : profile.profile_name || profile.id}
              alt={profile.profile_name ? profile.profile_name : profile.id}
              sx={{
                width: '36px',
                height: '36px',
                borderRadius: '50%',
                border: '2px solid white',
                mt: 1,
                overflow: 'hidden',
                boxShadow: '0 2px 8px rgba(0,0,0,0.2)',
                bgcolor: theme.palette.primary.main,
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                bottom: -10,
                left: '50%',
                transform: 'translateX(-50%)',
                width: 20,
                height: 20,
                borderRadius: '50%',
                bgcolor: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <AddCircle color="error" />
            </Box>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <IconButton
              sx={{
                color: 'white',
                p: 1,
                '&:hover': {
                  backgroundColor: 'rgba(255,255,255,0.1)',
                },
              }}
            >
              <Favorite sx={{ fontSize: 28 }} />
            </IconButton>
            <Typography
              variant="caption"
              sx={{
                color: 'white',
                fontSize: 12,
                fontWeight: 500,
                textShadow: '0 1px 2px rgba(0,0,0,0.3)',
              }}
            >
              {like_count !== 0 ? like_count : 'Likes'}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <IconButton
              sx={{
                color: 'white',
                p: 1,
                '&:hover': {
                  backgroundColor: 'rgba(255,255,255,0.1)',
                },
              }}
            >
              <img
                src={Instagramshare}
                style={{
                  height: 'auto',
                  width: '100%',
                  maxWidth: '26px',
                  maxHeight: '26px',
                  color: 'white',
                }}
              />
            </IconButton>
            <Typography
              variant="caption"
              sx={{
                color: 'white',
                fontSize: 12,
                fontWeight: 500,
                textShadow: '0 1px 2px rgba(0,0,0,0.3)',
              }}
            >
              {comment_count !== 0 ? comment_count : 'Comments'}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <IconButton
              sx={{
                color: 'white',
                p: 1,
                '&:hover': {
                  backgroundColor: 'rgba(255,255,255,0.1)',
                },
              }}
            >
              <Bookmark sx={{ fontSize: 28 }} />
            </IconButton>
            <Typography
              variant="caption"
              sx={{
                color: 'white',
                fontSize: 12,
                fontWeight: 500,
                textShadow: '0 1px 2px rgba(0,0,0,0.3)',
              }}
            >
              {view_count !== 0 ? `${view_count} views` : 'views'}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <IconButton
              sx={{
                color: 'white',
                p: 1,
                '&:hover': {
                  backgroundColor: 'rgba(255,255,255,0.1)',
                },
              }}
            >
              <img
                src={Instagramcomment}
                style={{
                  height: 'auto',
                  width: '100%',
                  maxWidth: '26px',
                  maxHeight: '26px',
                  color: 'white',
                }}
              />
            </IconButton>
            <Typography
              variant="caption"
              sx={{
                color: 'white',
                fontSize: 12,
                fontWeight: 500,
                textShadow: '0 1px 2px rgba(0,0,0,0.3)',
              }}
            >
              {share_count !== 0 ? share_count : 'Shares'}
            </Typography>
          </Box>

          <Avatar
            src={profile.profile_image ? profile.profile_image : profile.profile_name || profile.id}
            alt={profile.profile_name ? profile.profile_name : profile.id}
            sx={{
              width: '34px',
              height: '34px',
              borderRadius: '50%',
              border: '2px solid white',
              mb: 5,
              overflow: 'hidden',
              boxShadow: '0 2px 8px rgba(0,0,0,0.2)',
              bgcolor: theme.palette.primary.main,
              animation: 'rotate 2s linear infinite',
              '@keyframes rotate': {
                '0%': { transform: 'rotate(0deg)' },
                '100%': { transform: 'rotate(360deg)' },
              },
            }}
          />
        </Box>
      </Card>
    </Box>
  );
};
export default TiktokPostCard;
