import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { CommonHelper } from 'src/utils/commonHelper';
import FBcomment from '../../../assets/comment.svg';
import FBlike from '../../../assets/fblike.svg';
import FBshare from '../../../assets/fbshare.svg';
import FBUnlike from '../../../assets/fbunlike.svg';
import PublicIcon from '@mui/icons-material/Public';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FacebookLikeIcon from '../../../assets/icons/facebook-reaction-like.svg';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import { useState } from 'react';
import PublishedPostMediaView from './publishedPostMediaPreview.component';

const FacebookPostCard = ({
  media_url,
  media_urls,
  message,
  like_count,
  share_count,
  comment_count,
  is_liked,
  profile,
  handleUnLikePost,
  handleLikePost,
  hasLikePermission,
  handleFocusComment,
}) => {
  let mediaFiles = CommonHelper.PublishedPostProcessMediaUrl(media_url)?.media_data;
  const theme = useTheme();
  const respwidth = useMediaQuery(theme.breakpoints.up('xl'));

  const isMultipleMedia = mediaFiles.length > 1;
  const mediaHeight = isMultipleMedia ? 150 : 200;
  const mediaStyle = { objectFit: 'cover', width: '100%', height: mediaHeight };
  const [showFullDescription, setShowFullDescription] = useState(false);
  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  const hasManageLikesCommentPermission = CommonHelper.CheckForPermissionAvailableForUser(
    CommonHelper.PermissionList.MANAGE_COMMENTS_LIKES
  );
  const imageView = () => {
    let view = [];
    let xWidth = 6;
    const remainingImagesCount = mediaFiles.length > 5 ? mediaFiles.length - 4 : 0;
    switch (mediaFiles.length) {
      case 1:
        xWidth = 12;
        break;
      case 2:
        xWidth = 12;
        break;
      case 3:
      case 4:
        xWidth = 6;
        break;
      default:
        xWidth = 6;
        break;
    }
    view = mediaFiles.slice(0, 4).map((image, i) => (
      <Grid
        item
        xs={mediaFiles.length === 3 && i === 2 ? 12 : xWidth}
        key={i}
        sx={{
          maxWidth: 500,
          maxHeight: 250,
          overflow: 'hidden',
          position: 'relative',
          display: 'inline-flex',
          opacity: 1,
          transform: 'scale(0.99)',
        }}
      >
        {image && image.url ? (
          image.type === 'image' ? (
            <img src={image.url} alt={image.url} style={mediaStyle} />
          ) : (
            <video src={image.url} alt={image.url} style={mediaStyle} />
          )
        ) : (
          <img src={image} alt={image} style={{ ...mediaStyle, height: 200 }} />
        )}
      </Grid>
    ));
    return (
      <Grid container sx={{ position: 'relative' }}>
        {view}
        {remainingImagesCount > 0 && (
          <Typography
            variant="h2"
            color="white"
            style={{
              position: 'absolute',
              top: '75%',
              left: '75%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
              width: '208px',
              height: '150px',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            +{remainingImagesCount}
          </Typography>
        )}
      </Grid>
    );
  };
  const checkActionsAreAvailable = () => {
    return like_count > 0 || comment_count > 0 || share_count > 0;
  };

  const ProfileTime = () => {
    return (
      <>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={{ gap: '0.3rem', display: 'flex', alignItems: 'center' }}
        >
          <Typography variant="span">{CommonHelper.DateTimeFromCurrentTime(profile?.date)}</Typography>
          <FiberManualRecordIcon sx={{ fontSize: 3 }} />
          <PublicIcon sx={{ fontSize: 15 }} />
        </Stack>
      </>
    );
  };
  return (
    <>
      <Stack container justifyContent="center" alignItems="center">
        <Card
          sx={{
            maxWidth: 500,
            minWidth: respwidth ? 400 : null,
            boxShadow: 'none',
            border: 'solid 2px',
            borderColor: theme.palette.preview.postcard.color,
          }}
        >
          <CardHeader
            avatar={
              <Avatar
                src={profile.profile_image ? profile.profile_image : profile.profile_name || profile.id}
                alt={profile.profile_name ? profile.profile_name : profile.id}
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  height: 40,
                  width: 40,
                  bgcolor: theme.palette.primary.main,
                }}
                style={{ color: 'white' }}
              />
            }
            title={
              <Box sx={{ display: 'flex', gap: 0.7, alignItems: 'center' }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                  {profile?.profile_name}
                </Typography>
                <FiberManualRecordIcon sx={{ fontSize: 3 }} />
                <Typography
                  sx={{
                    textTransform: 'none',
                    color: theme.palette.preview.facebook.color,
                    '&:hover': { textDecoration: 'underline' },
                    cursor: 'pointer',
                    fontFamily: 'sans-serif',
                    variant: 'subtitle1',
                    fontWeight: 600,
                  }}
                >
                  Follow
                </Typography>
              </Box>
            }
            sx={{ fontSize: { xl: 15, lg: 13, md: 11, sm: 9, xs: 7 } }}
            subheader={<ProfileTime />}
            action={<MoreHorizIcon sx={{ mt: 1 }} />}
          />
          {message && (
            <CardContent sx={{ pt: 1, pb: 1 }}>
              <Typography
                noWrap={!showFullDescription}
                sx={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: showFullDescription ? 'initial' : 2,
                  overflow: 'hidden',
                  WebkitBoxDecorationBreak: 'clone',
                  whiteSpace: 'pre-line',
                  textOverflow: 'ellipsis',
                  fontSize: '0.875rem',
                }}
              >
                {CommonHelper.ParseDescription(message)}
              </Typography>
              {!showFullDescription && message.length > 100 && (
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 700,
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    fontSize: '0.875rem',
                    color: theme.palette.primary.main,
                  }}
                  onClick={handleToggleDescription}
                >
                  Show More
                </Typography>
              )}
              {showFullDescription && (
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 700,
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    fontSize: '0.875rem',
                    color: theme.palette.primary.main,
                  }}
                  onClick={handleToggleDescription}
                >
                  Show Less
                </Typography>
              )}
            </CardContent>
          )}
          <CardMedia sx={{ mt: message.length === 0 ? 1 : 0 }}>
            <PublishedPostMediaView mediaFiles={media_urls} />
          </CardMedia>
          <CardActions disableSpacing sx={{ display: 'block' }}>
            {checkActionsAreAvailable() && (
              <>
                <Stack direction="row" style={{ justifyContent: 'space-between', alignItems: 'center' }} sx={{ mb: 1 }}>
                  {/* Like & Heart group */}
                  {like_count !== 0 && (
                    <Paper sx={{ display: 'flex', alignItems: 'center' }}>
                      <img src={FacebookLikeIcon} alt="like" style={{ width: 18, height: 18, marginLeft: '0.5rem' }} />
                      <FavoriteOutlinedIcon sx={{ width: 18, height: 18, color: '#ff3040' }} />

                      <Typography variant="body2"> {CommonHelper.formatNumber(like_count)} likes</Typography>
                    </Paper>
                  )}

                  {/* Comment count (only shown if data.has_comment and data.comment_description) */}
                  {comment_count !== 0 && (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ marginRight: '0.5rem', display: 'flex', justifyContent: 'flex-end' }}
                    >
                      {CommonHelper.formatNumber(comment_count)} comments
                    </Typography>
                  )}
                </Stack>
                <Divider />
              </>
            )}
            <Divider sx={{ borderBottom: '1px solid #ddd' }} />
            <Stack direction="row" style={{ justifyContent: 'space-between', alignItems: 'center' }} sx={{ px: 1 }}>
              <Paper sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  disabled={!hasManageLikesCommentPermission}
                  onClick={handleUnLikePost ? (is_liked ? handleUnLikePost : handleLikePost) : null}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: { xl: '30px', lg: '24px' },
                    },
                  }}
                >
                  <img
                    src={is_liked ? FBUnlike : FBlike}
                    style={{ height: 'auto', width: '100%', maxWidth: '18px', maxHeight: '18px' }}
                  />
                </IconButton>
                <Typography
                  color={'text.secondary'}
                  sx={{ fontWeight: 700, fontSize: { xl: 15, lg: 13, md: 11, sm: 9, xs: 7 } }}
                >
                  Like{' '}
                </Typography>
              </Paper>
              <Paper sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  onClick={handleFocusComment ? handleFocusComment : null}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: { xl: '30px', lg: '24px' },
                    },
                  }}
                >
                  <img src={FBcomment} style={{ height: 'auto', width: '100%', maxWidth: '20px', maxHeight: '20px' }} />
                </IconButton>
                <Typography
                  sx={{ fontWeight: 700, color: 'text.secondary', fontSize: { xl: 15, lg: 13, md: 11, sm: 9, xs: 7 } }}
                >
                  Comment
                </Typography>
              </Paper>
              <Paper sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  disabled
                  onClick={handleFocusComment ? handleFocusComment : null}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: { xl: '30px', lg: '24px' },
                    },
                  }}
                >
                  <img src={FBshare} style={{ height: 'auto', width: '100%', maxWidth: '20px', maxHeight: '20px' }} />
                </IconButton>
                <Typography
                  sx={{ fontWeight: 700, color: 'text.secondary', fontSize: { xl: 15, lg: 13, md: 11, sm: 9, xs: 7 } }}
                >
                  Share
                </Typography>
              </Paper>
            </Stack>
          </CardActions>
        </Card>
      </Stack>
    </>
  );
};
export default FacebookPostCard;
