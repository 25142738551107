import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  TableContainer,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import HttpServices from 'src/services/httpService';
import { paymentModuleUrl, userModuleURL } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import { useToaster } from 'src/utils/toaster/toasterContext';
import ConfirmationDialog from 'src/components/ConfirmationDialog';

const DowngradeUpgradeComponent = ({ planAddonDetails, currentPlanDetails }) => {
  const { showToast } = useToaster();
  const theme = useTheme();

  // State variables
  const [addons, setAddons] = useState([]);
  const [upgradeItems, setUpgradeItems] = useState({});
  const [newExpiresOn, setNewPlanExpiresOn] = useState('');
  const [expiresOn, setPlanExpiresOn] = useState('');
  const [extraDay, setExtraDay] = useState('');
  const [hasActiveSubscription, setActiveSubscription] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  useEffect(() => {
    currSubscribtionSuccessCallback(currentPlanDetails);
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (currentPlanDetails.has_subscription) {
        getAddonPrice();
        setActiveSubscription(false);
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [upgradeItems]);

  const currSubscribtionSuccessCallback = (data, message) => {
    let plan = data.plan_details;
    let initialDownstruct = {
      no_of_social_accounts: plan.no_of_social_accounts.no_of_social_accounts_addon,
      no_of_users: plan.no_of_users.no_of_users_addon,
      no_of_brands: plan.no_of_brands.no_of_brands_addon,
      no_of_scheduled_posts: plan.no_of_scheduled_posts.no_of_scheduled_posts_addon,
      no_of_scheduled_days_ahead: plan.no_of_scheduled_days_ahead.no_of_scheduled_days_ahead_plan,
      no_of_drafts: plan.no_of_draft_posts.no_of_scheduled_posts_addon,
      no_of_storage: plan.no_of_storage.no_of_storage_addon,
      has_c_name: plan.cname.has_cname,
    };
    const getInitialStruct = planAddonDetails.map((addon) => {
      let limit;
      switch (addon.name) {
        case 'no_of_social_accounts':
          limit = plan.no_of_social_accounts.no_of_social_accounts_addon;
          break;
        case 'no_of_users':
          limit = plan.no_of_users.no_of_users_addon;
          break;
        case 'no_of_brands':
          limit = plan.no_of_brands.no_of_brands_addon;
          break;
        case 'no_of_scheduled_posts':
          limit = plan.no_of_scheduled_posts.no_of_scheduled_posts_addon;
          break;
        case 'no_of_drafts':
          limit = plan.no_of_draft_posts.no_of_scheduled_posts_addon;
          break;
        case 'no_of_storage':
          limit = plan.no_of_storage.no_of_storage_addon;
          break;
        default:
          limit = 0;
          break;
      }
      return {
        Value: addon.name,
        DisplayName: addon.name,
        Quantity: addon.quantity,
        Limit: limit,
      };
    });

    setUpgradeItems(initialDownstruct);
    setAddons(getInitialStruct);
  };

  const handleInputChange = (event, addon) => {
    let value = event.target.value;
    let items = { ...upgradeItems };
    items[addon.Value] = value ? parseInt(value) : 0;
    setUpgradeItems(items);
  };

  const handleIncrement = (addon) => {
    let items = { ...upgradeItems };
    if ((items[addon.Value] || 0) < addon.Limit) {
      items[addon.Value] = (items[addon.Value] || 0) + 1;
      setUpgradeItems(items);
    } else {
      showToast(`Cannot exceed the limit of ${addon.Limit} for ${addon.DisplayName}`, 'e');
    }
  };

  const handleDecrement = (addon) => {
    let items = { ...upgradeItems };
    items[addon.Value] = (items[addon.Value] || 0) - 1;
    if (items[addon.Value] < 0) {
      items[addon.Value] = 0;
    }
    setUpgradeItems(items);
  };

  const failureCallback = (message) => {
    // message && showToast(message, 'e');
    setConfirmOpen(false);
  };

  const successCallback = (data, message) => {
    setNewPlanExpiresOn(data.new_expire_date);
    setPlanExpiresOn(data.expire_date);
    setActiveSubscription(data.has_active_subscription);
    setExtraDay(data.extra_days);
    setConfirmOpen(false);
  };
  const downGradePaymentSuccessCallback = (data, message) => {
    message && showToast(message, 's');
    getAddonPrice();
  };

  const getAddonPrice = () => {
    let postProps = {
      url: userModuleURL.getAddonDowngradePrice,
      body: upgradeItems,
      successCallback: successCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };

  const handlDownGradePayment = () => {
    let postProps = {
      url: paymentModuleUrl.downGradePayment,
      body: upgradeItems,
      successCallback: downGradePaymentSuccessCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };

  const handleWhiteLabel = () => {
    let items = { ...upgradeItems };
    items['has_c_name'] = !items['has_c_name'];
    setUpgradeItems(items);
  };

  return (
    <>
      <TableContainer
        sx={{
          maxHeight: 'calc(94vh - 310px)',
          minHeight: 'calc(94vh - 310px)',
        }}
      >
        {addons &&
          addons
            .filter(
              (addon) => !['no_of_draft', 'has_cname', 'no_of_brands', 'no_of_scheduled_posts', 'no_of_storage'].includes(addon.Value)
            )
            .map((addon) => (
              <Box key={addon.Value} sx={{ m: 1, pl: 2, pr: 2 }}>
                <Typography>{CommonHelper.DisplayPlanAddonName(addon.DisplayName)}</Typography>
                <Box
                  key={addon.Value}
                  display="flex"
                  alignItems="center"
                  mb={1}
                  sx={{
                    borderRadius: '4px',
                    padding: '4px',
                    justifyContent: 'space-between',
                    backgroundColor: 'background.default',
                  }}
                >
                  <IconButton
                    onClick={() => handleDecrement(addon)}
                    sx={{
                      color: 'black',
                      backgroundColor: 'white',
                      borderRadius: 1,
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>
                  <TextField
                    name={addon.Value}
                    value={upgradeItems[addon.Value] * addon.Quantity || 0}
                    onChange={(event) => handleInputChange(event, addon)}
                    size="small"
                    inputProps={{ style: { textAlign: 'center' } }}
                    sx={{
                      ml: 1,
                      mr: 1,
                      width: '100%',
                      '& .MuiInputBase-root': {
                        color: 'black',
                        backgroundColor: 'white',
                        borderRadius: 1,
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                      },
                    }}
                  />
                  <IconButton
                    onClick={() => handleIncrement(addon)}
                    sx={{
                      color: 'black',
                      backgroundColor: 'white',
                      borderRadius: 1,
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
              </Box>
            ))}
        <Box key="white-label" sx={{ mt: 3, mb: 1, ml: 1, pl: 2, pr: 2 }}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={upgradeItems.has_c_name} onChange={handleWhiteLabel} />}
              label="White Label"
            />
          </FormGroup>
        </Box>
      </TableContainer>
      {hasActiveSubscription ? (
        <Box sx={{ p: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography sx={{ fontWeight: 700, color: 'text.secondary' }}>Expires On:</Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              {expiresOn ? CommonHelper.formatDateAndTime(expiresOn, 'date') : '-'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography sx={{ fontWeight: 700, color: 'text.secondary' }}>New Expires On:</Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              {newExpiresOn ? CommonHelper.formatDateAndTime(newExpiresOn, 'date') : '-'}
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography sx={{ fontWeight: 700 }}>Extra Day:</Typography>
            <Typography>{extraDay}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
            <Button variant="contained" onClick={() => setConfirmOpen(true)} sx={{ mb: 0.5 }}>
              Save
            </Button>
          </Box>
        </Box>
      ) : (
        <></>
      )}

      <ConfirmationDialog
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={handlDownGradePayment}
        title="Confirm Downgrade"
        message="Are you sure you want to downgrade your plan?"
      />
    </>
  );
};

export default DowngradeUpgradeComponent;
