
import HttpServices from 'src/services/httpService';
import { integrateModuleURL } from 'src/services/urlService';
import { useToaster } from 'src/utils/toaster/toasterContext';
import { createContext, useContext, useState } from 'react';

const ReAuthContext = createContext();

export function ReAuthProvider({ children }) {
  const { showToast } = useToaster();
  const [inActiveProfiles, setInActiveProfiles] = useState(null);

  const checkAndTriggerReAuth = (account=null) => {
    if(account == null){
      getConnectedNetworks()
    }
    else{
      let inActiveNetworks = account?.filter((a) => a.is_enabled && !a.is_active);
      setInActiveProfiles(inActiveNetworks);
    }
  };

  const getConnectedNetworks = () => {
      let getProps = {
        url: integrateModuleURL.getConnectedNetworks,
        successCallback: connectedNetworksSuccessCallback,
        failureCallback: connectedNetworksFailureCallback,
      };
      HttpServices.Get(getProps);
    };
    const connectedNetworksSuccessCallback = (data, message) => {
      let sNetworks = data?.filter((a) => a.is_enabled && !a.is_active);
      setInActiveProfiles(sNetworks)
    }
    const connectedNetworksFailureCallback = (message) => {
      message && showToast(message, 'e');
      setInActiveProfiles([])
    };

  return (
    <ReAuthContext.Provider
      value={{
        inActiveProfiles,
        checkAndTriggerReAuth,
      }}
    >
      {children}
    </ReAuthContext.Provider>
  );
}

export const useReAuth = () => {
  const context = useContext(ReAuthContext);
  return context;
};