import { BrandingWatermark as BrandingWatermarkIcon, Business as BusinessIcon } from '@mui/icons-material';
import { Box, Tab, Tabs, useMediaQuery, tabsClasses } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { capitalCase } from 'change-case';
import { useState } from 'react';
import DowngradeUpgradeComponent from './downgrade-subscription.component';
import UpgradeSubscriptionComponent from './upgrade-subscription.component';

const SubscriptionTabComponent = ({ planAddonDetails, currentPlanDetails }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [currentTab, setCurrentTab] = useState('Upgrade Subscription');

  const ACCOUNT_TABS = [
    {
      value: 'Upgrade Subscription',
      icon: <BusinessIcon sx={{ width: '20px', height: '25px' }} />,
      component: (
        <UpgradeSubscriptionComponent
          planAddonDetails={planAddonDetails}
          currentPlanDetails={currentPlanDetails}
        />
      ),
    },
    {
      value: 'Downgrade Subscription',
      icon: <BrandingWatermarkIcon sx={{ width: '20px', height: '25px' }} />,
      component: (
        <DowngradeUpgradeComponent
          planAddonDetails={planAddonDetails}
          currentPlanDetails={currentPlanDetails}
        />
      ),
    },
  ];
  return (
    <>
      <Tabs
        value={currentTab}
        scrollButtons="auto"
        variant="scrollable"
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { display: 'none' },
          },
          gap: '0.5rem',
          minHeight: '37px',
          pt: '10px',
          pl: '10px',
          pr: '10px',
        }}
        allowScrollButtonsMobile
        onChange={(e, value) => setCurrentTab(value)}
      >
        {ACCOUNT_TABS.map((tab) => {
          return (
            <Tab
              disableRipple
              key={tab.value}
              label={isMobile ? '' : capitalCase(tab.value)}
              icon={tab.icon}
              value={tab.value}
              sx={{
                flexDirection: 'row',
                gap: '0.25rem',
                padding: 0,
                minHeight: '10px',
                mr: '0.75rem',
                fontWeight: 600,
              }}
            />
          );
        })}
      </Tabs>

      <Box sx={{ mb: 2 }} />
      {ACCOUNT_TABS.map((tab) => {
        const isMatched = tab.value === currentTab;
        return isMatched && <Box key={tab.value}>{tab.component}</Box>;
      })}
    </>
  );
};
export default SubscriptionTabComponent;
