import { useRef, useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Paper,
  Stack,
  useMediaQuery,
  useTheme,
  Divider,
} from '@mui/material';
import {
  Public as PublicIcon,
  PlayArrow as PlayArrowIcon,
  MoreVert as MoreVertIcon,
  BookmarkBorder as BookmarkBorderIcon,
  FiberManualRecord as FiberManualRecordIcon,
} from '@mui/icons-material';
import { CommonHelper } from 'src/utils/commonHelper';
import { styled } from '@mui/material/styles';
import LinkedinLikeIcon from '../../../../assets/linkedin_unlike.svg';
import LinkedinRetweetIcon from '../../../../assets/twitter_retweet.svg';
import LinkedinShareIcon from '../../../../assets/linkedin-share-icon.svg';
import LinkedinCommentIcon from '../../../../assets/linkedin_comment.svg';
import LinkedInMediaView from '../linkedin-media-view.component';

const PlayButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '48px',
  height: '48px',
  backgroundColor: '#fff',
  borderRadius: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0,
}));

const GradientOverlay = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '48px',
  height: '48px',
});

const LinkedinPostVideoPreview = ({ data }) => {
  const theme = useTheme();
  const respwidth = useMediaQuery(theme.breakpoints.up('xl'));
  const ConnectedProfiles = data.connected_profile_details;
  const videoRef = useRef(null);
  const [isVideoPlaying, setVideoPlaying] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  useEffect(() => {
    return () => {
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    };
  }, []);

  const handlePlayButtonClick = () => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {});
      setShowControls(true);
    }
  };

  const handleVideoPlay = () => {
    setVideoPlaying(true);
  };
  const handleVideoPause = () => {
    setVideoPlaying(false);
  };

  const videoShow = () => {
    return (
      <Box sx={{ position: 'relative', width: 'auto', height: '250px', borderRadius: '10px' }}>
        <video
          preload="auto"
          crossOrigin="anonymous"
          src={data.media_url}
          width="100%"
          height="100%"
          style={{ objectFit: 'cover', position: 'absolute', borderRadius: '10px' }}
          ref={videoRef}
          controls={showControls}
          onPlay={handleVideoPlay}
          onPause={handleVideoPause}
        />
        {!isVideoPlaying && (
          <PlayButton onClick={handlePlayButtonClick}>
            <PlayArrowIcon sx={{ fontSize: 40 }} />
          </PlayButton>
        )}
        <GradientOverlay />
      </Box>
    );
  };

  const ProfileTime = () => {
    return (
      <>
        <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ gap: '0.3rem' }}>
          <Typography variant="span">Just Now</Typography>
          <FiberManualRecordIcon sx={{ fontSize: 5, mt: '0.6rem' }} />
          <PublicIcon sx={{ fontSize: 15, mt: '0.3rem' }} />
        </Stack>
      </>
    );
  };

  return (
    <>
      <Stack container justifyContent="center" alignItems="center">
        <Card
          sx={{
            maxWidth: 500,
            minWidth: respwidth ? 400 : null,
            boxShadow: 3,
            borderRadius: 2,
            border: 'none',
            borderColor: theme.palette.preview.postcard.color,
          }}
        >
          <CardHeader
            avatar={
              <Avatar
                src={
                  ConnectedProfiles.connected_profile_image
                    ? ConnectedProfiles.connected_profile_image
                    : ConnectedProfiles.connected_profile_name || ConnectedProfiles.connected_profile_id
                }
                alt={
                  ConnectedProfiles.connected_profile_name
                    ? ConnectedProfiles.connected_profile_name
                    : ConnectedProfiles.connected_profile_id
                }
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  height: 40,
                  width: 40,
                  bgcolor: theme.palette.primary.main,
                }}
                style={{ color: 'white' }}
              />
            }
            title={ConnectedProfiles?.connected_profile_name}
            sx={{ fontSize: { xl: 15, lg: 13, md: 11, sm: 9, xs: 7 } }}
            subheader={<ProfileTime />}
            action={<MoreVertIcon sx={{ mt: 1 }} />}
          />
          <CardContent sx={{ pt: 1, pb: 1 }}>
            <Typography
              noWrap={!showFullDescription}
              sx={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: showFullDescription ? 'initial' : 2,
                overflow: 'hidden',
                WebkitBoxDecorationBreak: 'clone',
                whiteSpace: 'pre-line',
                textOverflow: 'ellipsis',
                fontSize: '0.875rem',
              }}
            >
              {CommonHelper.ParseDescription(data.description)}
            </Typography>
            {!showFullDescription && data.description.length > 100 && (
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 700,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontSize: '0.875rem',
                  color: theme.palette.primary.main,
                }}
                onClick={handleToggleDescription}
              >
                Show More
              </Typography>
            )}
            {showFullDescription && (
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 700,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontSize: '0.875rem',
                  color: theme.palette.primary.main,
                }}
                onClick={handleToggleDescription}
              >
                Show Less
              </Typography>
            )}
          </CardContent>
          <CardMedia>
            <LinkedInMediaView data={data} />
          </CardMedia>
          <CardActions disableSpacing sx={{ display: 'block' }}>
            {data.has_comment && data.comment_description && (
              <Stack sx={{ justifyContent: 'flex-end', display: 'flex', alignItems: 'flex-end', mb: 1 }}>
                <Typography color={'text.secondary'}>1 comment</Typography>
              </Stack>
            )}
            <Divider />
            <Stack direction="row" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <Paper sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                <img
                  src={LinkedinLikeIcon}
                  style={{ height: 'auto', width: '100%', maxWidth: '20px', maxHeight: '20px', marginLeft: '1rem' }}
                />
              </Paper>
              <Paper sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                <img
                  src={LinkedinCommentIcon}
                  style={{ height: 'auto', width: '100%', maxWidth: '20px', maxHeight: '20px', marginLeft: '0.5rem' }}
                />
              </Paper>
              <Paper sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                <img
                  src={LinkedinRetweetIcon}
                  style={{
                    height: 'auto',
                    width: '100%',
                    maxWidth: '20px',
                    maxHeight: '20px',
                    transform: 'rotate(90deg)',
                    marginLeft: '0.5rem',
                  }}
                />
              </Paper>
              <Paper sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                <img
                  src={LinkedinShareIcon}
                  style={{ height: 'auto', width: '100%', maxWidth: '20px', maxHeight: '20px', marginRight: '1rem' }}
                />
              </Paper>
            </Stack>
            {data.has_comment && data.comment_description && (
              <>
                <Divider sx={{ mt: 0.5 }} />
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: '0.5rem',
                    marginTop: '8px',
                  }}
                >
                  <Avatar
                    src={
                      ConnectedProfiles.connected_profile_image
                        ? ConnectedProfiles.connected_profile_image
                        : ConnectedProfiles.connected_profile_name || ConnectedProfiles.connected_profile_id
                    }
                    alt={
                      ConnectedProfiles.connected_profile_name
                        ? ConnectedProfiles.connected_profile_name
                        : ConnectedProfiles.connected_profile_id
                    }
                    sx={{
                      textAlign: 'flex-start',
                      height: 30,
                      width: 30,
                      bgcolor: theme.palette.primary.main,
                    }}
                  />
                  <Box sx={{ width: '100%' }}>
                    <Box
                      sx={{
                        padding: 1,
                        borderRadius: '8px',
                        border: `1px solid ${theme.palette.text.border}`,
                        background: theme.palette.background.default,
                      }}
                    >
                      <Stack spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
                        <Typography variant="body2" sx={{ fontWeight: 800 }}>
                          {ConnectedProfiles?.connected_profile_name}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: 'white',
                            backgroundColor: 'text.primary',
                            pl: 0.5,
                            pr: 0.5,
                            borderRadius: '3px',
                          }}
                        >
                          Author
                        </Typography>
                      </Stack>
                      <Typography
                        variant="body2"
                        style={{
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 1,
                          overflow: 'hidden',
                          WebkitBoxDecorationBreak: 'clone',
                          whiteSpace: 'pre-line',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {data.comment_description}
                      </Typography>
                    </Box>
                    <Stack spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
                      <Typography variant="body2" sx={{ fontWeight: 800, color: 'text.secondary' }}>
                        Like
                      </Typography>
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{
                          color: 'text.secondary',
                          borderColor: 'text.disabled',
                          borderWidth: 1,
                        }}
                      />
                      <Typography variant="body2" sx={{ fontWeight: 800, color: 'text.secondary' }}>
                        Reply
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
              </>
            )}
          </CardActions>
        </Card>
      </Stack>
    </>
  );
};

export default LinkedinPostVideoPreview;
