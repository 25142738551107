import { Box, Button, Card, Divider, Stack, TextField, Typography } from '@mui/material';
import { CommonHelper } from 'src/utils/commonHelper';

const PlanBillingComponent = ({ expiresOn, currency_symbol, totalAmount, newExpiresOn, handlePaymentDialog }) => {
  return (
    <Card sx={{ p: 3, maxWidth: 400, width: '100%', mt: 1, flex: 1 }}>
      <Box>
        <Stack sx={{ width: '100%', alignItems: 'center' }}>
          {/* <TextField fullWidth name="billingName" label="Billing Name" onChange={handleInputChange} sx={{ mb: 1.5 }} />
          <TextField
            fullWidth
            name="billingAddress"
            label="Billing Address"
            onChange={handleInputChange}
            sx={{ mb: 1.5 }}
          /> */}
          <Typography color="text.secondary">Current Plan Expires on</Typography>
          <Typography variant="overline" display="block" sx={{ mb: 1.5 }}>
            {CommonHelper.formateDateWithYear(expiresOn)}
          </Typography>
          <Typography color="text.secondary">New Plan Begins from</Typography>
          <Typography variant="overline" display="block">
            {CommonHelper.formateDateWithYear(expiresOn)}
          </Typography>
          <Typography color="text.secondary">To</Typography>
          <Typography variant="overline" display="block" sx={{ mb: 1.5 }}>
            {CommonHelper.formateDateWithYear(newExpiresOn)}
          </Typography>
          <Divider />
          <Stack alignItems="center" sx={{ mb: 1.5 }}>
            <Typography variant="h5">
              Total = {currency_symbol}
              {totalAmount}
            </Typography>
          </Stack>
          <Divider />
          <Button
            fullWidth
            size="large"
            variant="contained"
            sx={{ margin: '15px' }}
            onClick={() => handlePaymentDialog(true)}
          >
            Proceed
          </Button>
        </Stack>
      </Box>
    </Card>
  );
};
export default PlanBillingComponent;
