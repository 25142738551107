import { Box, Card, Avatar, Typography, IconButton, useTheme, Stack, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MoreHoriz, PlayArrow, VolumeUp, VolumeOff, Public, Send } from '@mui/icons-material';
import { keyframes } from '@mui/system';
import { useRef, useState, useEffect } from 'react';

const progressAnimation = keyframes`
  0% { width: 0%; }
  100% { width: 100%; }
`;

const ProgressContainer = styled(Box)({
  position: 'absolute',
  top: 8,
  left: 8,
  right: 8,
  display: 'flex',
  gap: 4,
  zIndex: 2,
});

const ProgressBarContainer = styled(Box)({
  height: 2,
  borderRadius: 2,
  backgroundColor: 'rgba(255, 255, 255, 0.4)',
  flexGrow: 1,
  overflow: 'hidden',
  position: 'relative',
});

const ProgressFill = styled(Box)(({ progress }) => ({
  height: '100%',
  backgroundColor: 'white',
  width: `${progress}%`,
  transition: 'width 0.1s linear',
}));

const FacebookStoryPreview = ({ data }) => {
  const theme = useTheme();
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isMuted, setIsMuted] = useState(true);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const ConnectedProfiles = data.connected_profile_details;
  const isImage = () => {
    if (data.media_data?.media_data?.[0]?.media_type === 'image') return true;
    const url = data.media_url || '';
    const extension = url.split('.').pop().toLowerCase();
    return ['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(extension);
  };
  const mediaIsImage = isImage();
  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleTimeUpdate = () => {
      if (video.duration > 0) {
        const currentProgress = (video.currentTime / video.duration) * 100;
        setProgress(currentProgress);
      }
    };

    video.addEventListener('timeupdate', handleTimeUpdate);
    return () => video.removeEventListener('timeupdate', handleTimeUpdate);
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = isMuted;
    }
  }, [isMuted]);

  useEffect(() => {
    setProgress(0);
  }, [data.media_url]);

  const togglePlay = () => {
    if (!videoRef.current) return;

    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play().catch(console.error);
    }
    setIsPlaying(!isPlaying);
  };

  const toggleMute = () => {
    if (!videoRef.current) return;
    videoRef.current.muted = !isMuted;
    setIsMuted(!isMuted);
  };

  return (
    <>
      <Stack container justifyContent="center" alignItems="center">
        <Card
          sx={{
            width: isMobile ? 300 : 340,
            height: 600,
            borderRadius: '12px',
            position: 'relative',
            overflow: 'hidden',
            bgcolor: 'black',
          }}
        >
          <ProgressContainer>
            <ProgressBarContainer>
              <ProgressFill progress={progress} />
            </ProgressBarContainer>
          </ProgressContainer>

          {mediaIsImage ? (
            <img
              src={data.media_url}
              alt="Story"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
            />
          ) : (
            <video
              ref={videoRef}
              src={data.media_url}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                cursor: 'pointer',
              }}
              onClick={togglePlay}
              muted
              playsInline
            />
          )}

          {!isPlaying && !mediaIsImage && (
            <IconButton
              onClick={togglePlay}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'rgba(0,0,0,0.6)',
                '&:hover': { bgcolor: 'rgba(0,0,0,0.8)' },
                width: 56,
                height: 56,
              }}
            >
              <PlayArrow sx={{ color: 'white', fontSize: 40 }} />
            </IconButton>
          )}

          {/* Gradient Overlay */}
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: '20%',
              background: 'linear-gradient(to bottom, rgba(0,0,0,0.3), transparent)',
            }}
          />

          {/* Header Section */}

          <Box
            sx={{
              position: 'absolute',
              top: 16,
              left: 8,
              right: 8,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              zIndex: 1,
              transition: 'transform 0.3s ease',
              transform: 'translateY(0)',
            }}
          >
            {/* Left Side - Avatar */}
            <Avatar
              src={
                ConnectedProfiles.connected_profile_image
                  ? ConnectedProfiles.connected_profile_image
                  : ConnectedProfiles.connected_profile_name || ConnectedProfiles.connected_profile_id
              }
              alt={
                ConnectedProfiles.connected_profile_name
                  ? ConnectedProfiles.connected_profile_name
                  : ConnectedProfiles.connected_profile_id
              }
              sx={{
                width: 34,
                height: 34,
                border: '2px solid #fff',
                boxShadow: '0 2px 8px rgba(0,0,0,0.2)',
                mr: 1,
              }}
            />

            {/* Centered Content (Profile Name & Music Section) */}
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '50%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <Typography
                  variant="body2"
                  color="white"
                  fontWeight={600}
                  style={{
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 1,
                    overflow: 'hidden',
                    WebkitBoxDecorationBreak: 'clone',
                    whiteSpace: 'pre-line',
                    textOverflow: 'ellipsis',
                    nowrap: 'true',
                  }}
                >
                  {data?.connected_profile_details?.connected_profile_name}
                </Typography>
                <Typography variant="caption" sx={{ color: 'white' }}>
                  Just&nbsp;Now
                </Typography>
                <Public sx={{ fontSize: 14, color: 'white' }} />
              </Box>
            </Box>

            {/* Right Side - More Options Icon */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              {!mediaIsImage && (
                <IconButton sx={{ color: 'white', p: 0.5 }} onClick={toggleMute}>
                  {isMuted ? <VolumeOff sx={{ fontSize: 24 }} /> : <VolumeUp sx={{ fontSize: 24 }} />}
                </IconButton>
              )}
              <MoreHoriz sx={{ fontSize: 24, color: 'white' }} />
            </Box>
          </Box>

          {/* Bottom Controls */}
          <Box
            sx={{
              position: 'absolute',
              bottom: 16,
              left: 8,
              right: 8,
              display: 'flex',
              gap: 1,
              alignItems: 'center',
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                paddingRight: '32px', // Give space for the icon
                '&:before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  borderRadius: 4,
                  border: '1px solid white',
                },
              }}
            >
              <Typography
                sx={{
                  padding: '2px 10px',
                  borderRadius: '20px',
                  color: 'white',
                  width: '100%',
                }}
                style={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                  overflow: 'hidden',
                  WebkitBoxDecorationBreak: 'clone',
                  whiteSpace: 'pre-line',
                  textOverflow: 'ellipsis',
                }}
              >
                Reply to @{ConnectedProfiles.connected_profile_name}
              </Typography>

              {/* Send Icon positioned on the right */}
              <Send
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  color: 'white',
                  cursor: 'pointer',
                }}
              />
            </Box>
          </Box>
        </Card>
      </Stack>
    </>
  );
};

export default FacebookStoryPreview;
