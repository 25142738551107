import { Box, Button, Card, Grid, IconButton, InputAdornment, InputLabel, Stack, Typography } from '@mui/material';
import ColorizeRoundedIcon from '@mui/icons-material/ColorizeRounded';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormProvider, RHFTextField, RHFUploadAvatar } from 'src/components/hook-form';
import TimeZoneSelect from 'src/components/time-zone/time-zone.component';
import HttpServices from 'src/services/httpService';
import { imageModeuleUrl, userModuleURL } from 'src/services/urlService';
import ValidationTool from 'src/utils/validationHelper';
import { LoadingButton } from '@mui/lab';
import ColorPopover from '../branding/branding-color-popover';
import { Label } from '@mui/icons-material';

const ManualOnBoardingComponent = ({ handleSkip, showToast, successCallback, failureCallback, onBoardingInfo }) => {
  const maxFileSizeInBytes = 10485760;
  const supportedFileFormat = ['image/jpeg', 'image/png', 'image/jpg'];

  //   State Variables
  const [orgName, setOrgName] = useState('');
  const [orgDescription, setOrgDescription] = useState('');
  const [orgWebsite, setOrgWebsite] = useState('');
  const [orgPrimaryColor, setOrgPrimaryColor] = useState('');
  const [orgSecondaryColor, setOrgSecondaryColor] = useState('');
  const [orgSmallIcon, setOrgSmallIcon] = useState('');
  const [orgLargeIcon, setOrgLargeIcon] = useState('');
  const [brandName, setBrandName] = useState('');
  const [brandImage, setBrandImage] = useState('');
  const [brandDescription, setBrandDescription] = useState('');
  const [timeZone, setTimeZone] = useState('UTC');
  const [formSchema, setFormSchema] = useState({});
  const [formValidationErrors, setFormValidationErrors] = useState({});
  const [fileUploadprogress, setFileUploadprogress] = useState(0);
  const [popoverElement, setPopoverElement] = useState(null);
  const [selectedPopOverData, setSelectedPopOverData] = useState('');
  const [isSubmitting, setOnSubmit] = useState('');

  const defaultValues = {
    orgName: orgName || '',
    orgDescription: orgDescription || '',
    orgWebsite: orgWebsite || '',
    orgPrimaryColor: orgPrimaryColor || '',
    orgSecondaryColor: orgSecondaryColor || '',
    orgSmallIcon: orgSmallIcon || '',
    orgLargeIcon: orgLargeIcon || '',
    brandName: brandName || '',
    brandImage: brandImage || '',
    brandDescription: brandDescription || '',
    timeZone: timeZone || '',
  };

  useEffect(() => {
    generateFormModelSchema();
  }, []);

  const generateFormModelSchema = () => {
    let fields = [
      ValidationTool.GenerateSchema('orgName', 'Organization Name', 'TEXT', true),
      ValidationTool.GenerateSchema('brandName', 'Brand Name', 'TEXT', true),
    ];
    const schema = ValidationTool.GenerateSchemaModel(fields);
    setFormSchema(schema);
  };

  const handleInputChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    switch (name) {
      case 'orgName':
        setOrgName(value);
        break;
      case 'orgDescription':
        setOrgDescription(value);
        break;
      case 'orgWebsite':
        setOrgWebsite(value);
        break;
      case 'brandName':
        setBrandName(value);
        break;
      case 'brandDescription':
        setBrandDescription(value);
        break;
      case 'timezone':
        setTimeZone(value);
        break;
      default:
        break;
    }
  };
  const handleDeleteBrandImage = () => {
    setBrandImage('');
  };
  const handleDrop = (acceptedFiles, iconType) => {
    const file = acceptedFiles[0];
    handleFileUpload(file, iconType);
  };
  const handleFileUpload = (file, iconType) => {
    let isFileInCorrectFormat = supportedFileFormat.some((a) => a.includes(file.type));
    if (isFileInCorrectFormat) {
      if (file.size < maxFileSizeInBytes) {
        setFileUploadprogress(1);
        let formData = new FormData();
        formData.append('fileupload', file);
        axios
          .post(imageModeuleUrl.uploadMedia, formData, {
            headers: HttpServices.GetRequestHeader('form', true),
            onUploadProgress: (data) => {
              let loadedProgress = Math.round((100 * data.loaded) / data.total);
              setFileUploadprogress(loadedProgress > 90 ? 90 : loadedProgress);
            },
          })
          .then((result) => result)
          .then((response) => {
            setFileUploadprogress(100);
            if (response.status === 200 && response.data.http_code === 200)
              fileUploadSuccessCallback(response.data.data, iconType, 'File Uploaded Successfully');
            else showToast('Something went wrong. Please contact our technical team', 'e');
            setFileUploadprogress(0);
          })
          .catch((error) => {
            setFileUploadprogress(0);
            showToast(error.toString(), 'e');
          });
      } else showToast('Maximum file size for image is 10MB. Please select image below 10 MB', 'e');
    } else {
      showToast('We only support JPEG, and PNG file formats. The selected file are not in the supported format', 'e');
    }
  };
  const fileUploadSuccessCallback = (data, iconType, message) => {
    if (iconType === 'orgLargeIcon') setOrgSmallIcon(data.media_data[0].media_url);
    else setOrgLargeIcon(data.media_data[0].media_url);
  };
  const methods = useForm({
    defaultValues,
  });
  const { handleSubmit } = methods;
  const onSubmit = () => {
    let onboardingForm = {
      org_name: orgName,
      org_description: orgDescription,
      organization_url: orgWebsite,
      org_primary_color: orgPrimaryColor,
      org_secondary_color: orgSecondaryColor,
      domain_small_icon: orgSmallIcon,
      domain_large_icon: orgLargeIcon,
      brand_name: brandName,
      brand_image: brandImage,
      brand_description: brandDescription,
      timezone: timeZone,
    };
    let postProps = {
      url: userModuleURL.saveOnBoardingInfo,
      body: onboardingForm,
      successCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };
  const handleSubmitForm = () => {
    let userForm = {
      brandName: brandName,
      orgName: orgName
    };
    let { formValidationErrors, isValidForm } = ValidationTool.FormValidation(formSchema, userForm);
    if (isValidForm) {
      setFormValidationErrors({});
      onSubmit();
    } else setFormValidationErrors(formValidationErrors);
  };
  const handleOpenMenu = (event, data) => {
    setSelectedPopOverData(data);
    setPopoverElement(event.currentTarget);
  };
  const handleColor = (color, type) => {
    if (type === 'primary') {
      setOrgPrimaryColor(color.hex);
    } else {
      setOrgSecondaryColor(color.hex);
    }
  };
  const handleCloseMenu = () => {
    setPopoverElement(null);
  };
  const colorProps = {
    popoverElement: popoverElement,
    selectedPopOverData: selectedPopOverData,
    handleCloseMenu,
    handleColor,
    color: orgPrimaryColor,
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitForm)}>
        <Card style={{ margin: 10, padding: 10 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <InputLabel style={{ fontWeight: 800, fontSize: 18 }}>Organization Information</InputLabel>
              <Card sx={{ pt: 5, pb: 5, px: 3, textAlign: 'center', display: 'flex' }}>
                <RHFUploadAvatar
                  name="orgSmallIcon"
                  accept="image/*"
                  maxSize={5242880}
                  onDrop={(e) => {
                    handleDrop(e, 'orgSmallIcon');
                  }}
                  handleDeleteProfiles={handleDeleteBrandImage}
                  url={orgSmallIcon}
                  helperText={
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 2,
                        mx: 'auto',
                        display: 'block',
                        textAlign: 'center',
                        color: 'text.secondary',
                      }}
                    >
                      Allowed *.jpeg, *.jpg, *.png, *.gif
                      <br /> max size of 5Mb
                    </Typography>
                  }
                />
                <RHFUploadAvatar
                  name="orgLargeIcon"
                  accept="image/*"
                  maxSize={5242880}
                  onDrop={(e) => {
                    handleDrop(e, 'orgLargeIcon');
                  }}
                  handleDeleteProfiles={handleDeleteBrandImage}
                  url={orgLargeIcon}
                  helperText={
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 2,
                        mx: 'auto',
                        display: 'block',
                        textAlign: 'center',
                        color: 'text.secondary',
                      }}
                    >
                      Allowed *.jpeg, *.jpg, *.png, *.gif
                      <br /> max size of 5Mb
                    </Typography>
                  }
                />
              </Card>
              <Card sx={{ p: 3, flexGrow: 1 }}>
                <Box
                  sx={{
                    display: 'grid',
                    rowGap: 3,
                    columnGap: 2,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                  }}
                >
                  <RHFTextField
                    name="orgName"
                    label="Organization Name"
                    value={orgName}
                    onChange={handleInputChange}
                    error={formValidationErrors?.orgName}
                    helperText={formValidationErrors?.orgName}
                  />
                  <RHFTextField
                    name="orgWebsite"
                    value={orgWebsite}
                    label="Organization Website URL"
                    onChange={handleInputChange}
                    error={formValidationErrors?.orgWebsite}
                    helperText={formValidationErrors?.orgWebsite}
                  />
                  <RHFTextField
                    name="orgPrimaryColor"
                    value={orgPrimaryColor}
                    label="Organization Primary Color"
                    onChange={handleInputChange}
                    error={formValidationErrors?.orgPrimaryColor}
                    helperText={formValidationErrors?.orgPrimaryColor}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={(e) => {
                              handleOpenMenu(e, 'primary');
                            }}
                          >
                            <ColorizeRoundedIcon />
                          </IconButton>
                          {orgPrimaryColor && (
                            <Box
                              style={{
                                width: 24,
                                height: 24,
                                borderRadius: '50%',
                                boxShadow: 1,
                                backgroundColor: orgPrimaryColor,
                                marginLeft: 1,
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <RHFTextField
                    name="orgSecondaryColor"
                    value={orgSecondaryColor}
                    label="Organization Secondary Color"
                    onChange={handleInputChange}
                    error={formValidationErrors?.orgSecondaryColor}
                    helperText={formValidationErrors?.orgSecondaryColor}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="large"
                            color="green"
                            onClick={(e) => {
                              handleOpenMenu(e, 'secondary');
                            }}
                          >
                            <ColorizeRoundedIcon />
                          </IconButton>
                          {orgSecondaryColor && (
                            <Box
                              style={{
                                width: 24,
                                height: 24,
                                borderRadius: '50%',
                                boxShadow: 1,
                                backgroundColor: orgSecondaryColor,
                                marginLeft: 1,
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <RHFTextField
                    name="orgDescription"
                    label="Organization Description"
                    multiline
                    rows={5}
                    value={orgDescription}
                    onChange={handleInputChange}
                  />
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel style={{ fontWeight: 800, fontSize: 18 }}>Brand Information</InputLabel>
              <Card sx={{ pt: 5, pb: 5, px: 3, textAlign: 'center', display: 'flex' }}>
                <RHFUploadAvatar
                  name="brandImage"
                  accept="image/*"
                  maxSize={5242880}
                  onDrop={(e) => {
                    handleDrop(e, 'brandImage');
                  }}
                  handleDeleteProfiles={handleDeleteBrandImage}
                  url={brandImage}
                  helperText={
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 2,
                        mx: 'auto',
                        display: 'block',
                        textAlign: 'center',
                        color: 'text.secondary',
                      }}
                    >
                      Allowed *.jpeg, *.jpg, *.png, *.gif
                      <br /> max size of 5Mb
                    </Typography>
                  }
                />
              </Card>
              <Card sx={{ p: 3, flexGrow: 1 }}>
                <Box
                  sx={{
                    display: 'grid',
                    rowGap: 3,
                    columnGap: 2,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                  }}
                >
                  <RHFTextField
                    name="brandName"
                    label="Brand Name"
                    value={brandName}
                    onChange={handleInputChange}
                    error={formValidationErrors?.brandName}
                    helperText={formValidationErrors?.brandName}
                  />
                  <TimeZoneSelect name="timezone" value={timeZone} onChange={handleInputChange} />
                  <RHFTextField
                    name="brandDescription"
                    label="Brand Description"
                    multiline
                    rows={5}
                    value={brandDescription}
                    onChange={handleInputChange}
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
          {onBoardingInfo['onboarding_skippable'] && (
            <Button variant="contained" color="primary" onClick={handleSkip}>
              Skip
            </Button>
          )}
          <Stack spacing={3} alignItems="flex-end" sx={{ mt: 5 }}>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              Submit
            </LoadingButton>
          </Stack>
        </Card>
      </FormProvider>
      <ColorPopover {...colorProps} />
    </>
  );
};
export default ManualOnBoardingComponent;
