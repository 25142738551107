import { LoadingButton } from '@mui/lab';
import { Button, DialogTitle, CardContent, ListItemIcon, Paper } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

const GoogleMyBusinessAuthorizationModal = (props) => {
    const [isLoadingOn, setLoadingOn] = useState(false);

    const handleConnectGoogleMyBusiness = () => {
        setLoadingOn(true);
        props.handleConnectGoogleMyBusiness();
    };

    const handleClose = () => {
        props.handleClose();
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open>
            <DialogTitle id="customized-dialog-title">
                <Typography gutterBottom variant="h4">
                    {props.reAuth 
                        ? "Reauthenticate Google Business Profile Connection" 
                        : "Have you converted your Google account to a Business Profile?"}
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <CardContent sx={{ p: { xs: 0, md: 0 }, color: 'grey.800' }}>
                    <Typography variant="body1" sx={{ mx: 'auto', fontWeight: 'bold' }}>
                        {props.reAuth 
                            ? "Verify these requirements to reconnect your profile:" 
                            : "Ensure you've completed the following steps before continuing:"}
                    </Typography>

                    <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1, mt: 2 }}>
                        <ListItemIcon>
                            <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
                        </ListItemIcon>
                        <Typography variant="body1">
                            {props.reAuth 
                                ? "Confirm you're still signed in to the original Google Account" 
                                : "Confirm that you're signed in to your Google Account"}
                        </Typography>
                    </Paper>

                    <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
                        <ListItemIcon>
                            <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
                        </ListItemIcon>
                        <Typography variant="body1">
                            {props.reAuth 
                                ? "Verify your Business Profile still has admin access" 
                                : "Verify that your Google My Business account is active and verified"}
                        </Typography>
                    </Paper>

                    <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
                        <ListItemIcon>
                            <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
                        </ListItemIcon>
                        <Typography variant="body1">
                            {props.reAuth 
                                ? "Ensure connection permissions are still valid" 
                                : "Ensure this app maintains data privacy and security"}
                        </Typography>
                    </Paper>

                    {!props.reAuth && (
                        <>
                            <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
                                <ListItemIcon>
                                    <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
                                </ListItemIcon>
                                <Typography variant="body1">
                                    Make sure not to infringe on others' copyright or privacy rights
                                </Typography>
                            </Paper>
                            
                            <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
                                <ListItemIcon>
                                    <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
                                </ListItemIcon>
                                <Typography variant="body1">
                                    You can now proceed with integration
                                </Typography>
                            </Paper>
                        </>
                    )}
                </CardContent>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color='error' onClick={handleClose}>Close</Button>
                <LoadingButton
                    variant='contained'
                    loading={isLoadingOn}
                    autoFocus
                    onClick={() => {
                        handleConnectGoogleMyBusiness();
                    }}
                >
                   {props.reAuth ? "Re-Authenticate" : "Connect"}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
export default GoogleMyBusinessAuthorizationModal;
