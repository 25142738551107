import { useEffect, useState } from "react";
import {
    Box,
    Card,
    CardContent,
    Avatar,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
    Badge,
    Chip,
    Autocomplete,
    IconButton,
    Stack,
} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { styled, useTheme } from '@mui/material/styles';
import { integrateModuleURL, userModuleURL } from "src/services/urlService";
import { CommonFunction } from 'src/utils/commonFunctions/common-function.component';
import HttpServices from "src/services/httpService";
import FacebookIcon from '../../assets/facebook.svg';
import GBusinessIcon from '../../assets/google-my-business-logo.svg';
import CloseIcon from '@mui/icons-material/Close';
import InstagramIcon from '../../assets/instagram.svg';
import LinkedInIcon from '../../assets/linked_in.svg';
import TikTokIcon from '../../assets/tiktok.svg';
import TwitterIcon from '../../assets/twitter.svg';
import YoutubeIcon from '../../assets/youtube.png';
import PinterestIcon from '../../assets/pinterest-icon.svg';
import { useToaster } from "src/utils/toaster/toasterContext";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { PostUtil } from '../new-post-new/post-util';
import { CommonHelper } from 'src/utils/commonHelper';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PinterestCreateBoard from "../new-post-new/post-condition/pinterest-create-board";
import { useReAuth } from 'src/utils/reAuth/ReAuthContext';
import CommonReAuthenticateComponent from '../integration/commonReAuthenticate';
import ConnectedMediaComponent from "../new-post-new/connected-media.component";
import { Key as KeyIcon } from '@mui/icons-material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import AddIcon from '@mui/icons-material/Add';

dayjs.extend(utc);
dayjs.extend(timezone);

const AddAutomation = ({ handleClose, handleSuccess }) => {
    const curentBrandTime = CommonHelper.getBrandTimeZoneTime();
    const [connectedProfiles, setConnectedProfiles] = useState([]);
    const [targetConnectedProfiles, setTargetConnectedProfiles] = useState([]);
    const [sourceProfile, setSourceProfile] = useState();
    const [targetProfiles, setTargetProfiles] = useState([]);
    const [timesForTargets, setTimesForTargets] = useState({}); // { targetId: [time1, time2, ...] }
    const [selectedBoards, setSelectedBoards] = useState({}); // { targetId: boardId }
    const [isLoading, setLoading] = useState(false);
    const [pinterestCreateBoardOpen, setPinterestCreateBoardOpen] = useState(false);

    const [selectedPopOverAccount, setSelectedPopOverAccount] = useState();
    const [reAuthenticateOpen, setReAuthenticateOpen] = useState(false);
    const { showToast } = useToaster();
    const { checkAndTriggerReAuth } = useReAuth();
    const is24HourFormat = CommonHelper.GetBrandInfoFromLocalStorage('is_24_hour_format')
    const hasIntegratationPermission = CommonHelper.CheckForPermissionAvailableForUser(
        CommonHelper.PermissionList.INTEGRATE_PERMISSION
    );

    const theme = useTheme();
    let fbColor = theme.palette.preview.facebook.color;
    let instaColor = theme.palette.preview.instagram.color;

    useEffect(() => {
        getConnectedNetworks();
    }, []);

    const getConnectedNetworks = () => {
        let getProps = {
            url: integrateModuleURL.getAutomationConnectedNetworks,
            successCallback: connectedNetworksSuccessCallback,
            failureCallback: connectedNetworkFailureCallback,
        };
        HttpServices.Get(getProps);
    };

    const connectedNetworksSuccessCallback = (data) => {
        let sNetworks = data?.filter((a) =>
            (a.service_name.toUpperCase() === PostUtil.ServiceName.INSTAGRAM
                || a.service_name.toUpperCase() === PostUtil.ServiceName.YOUTUBE
                || a.service_name.toUpperCase() === PostUtil.ServiceName.FACEBOOK
                || (a.service_name.toUpperCase() === PostUtil.ServiceName.LINKEDIN && a.profile_type == "PAGE")
                || a.service_name.toUpperCase() === PostUtil.ServiceName.GBUSINESS) && a.is_enabled);
        let tNetworks = data?.filter((a) =>
            (a.service_name.toUpperCase() === PostUtil.ServiceName.INSTAGRAM
                || a.service_name.toUpperCase() === PostUtil.ServiceName.YOUTUBE
                || a.service_name.toUpperCase() === PostUtil.ServiceName.FACEBOOK
                || a.service_name.toUpperCase() === PostUtil.ServiceName.LINKEDIN
                || a.service_name.toUpperCase() === PostUtil.ServiceName.TIKTOK
                || a.service_name.toUpperCase() === PostUtil.ServiceName.TWITTER
                || a.service_name.toUpperCase() === PostUtil.ServiceName.GBUSINESS) && a.is_enabled);
        checkAndTriggerReAuth(data)
        setConnectedProfiles(sNetworks || []);
        setTargetConnectedProfiles(tNetworks || []);
    };

    const connectedNetworkFailureCallback = (message) => {
        showToast(message, "e");
    };

    const handleSubmitAutomation = () => {
        if (!sourceProfile) {
            showToast("Please select a source profile.", "e");
            return;
        }

        if (targetProfiles.length === 0) {
            showToast("Please select at least one target profile.", "e");
            return;
        }

        const isSourceInTargets = targetProfiles.some(target => target.id === sourceProfile);
        if (isSourceInTargets) {
            showToast("Source profile cannot be the same as any target profile.", "e");
            return;
        }

        let isValid = true;

        for (const target of targetProfiles) {
            const times = timesForTargets[target.id] || [];

            // Check if target profile has an empty times array
            if (times.length === 0) {
                showToast(`Please add at least one post time for ${target.profile_name}.`, "e");
                return;
            }

            // Check if Pinterest profile has a selected board
            if (target.service_name.toUpperCase() === PostUtil.ServiceName.PINTEREST && !selectedBoards[target.id]) {
                showToast(`Please select a board for ${target.profile_name}.`, "e");
                return;
            }

            for (let i = 0; i < times.length; i++) {
                for (let j = i + 1; j < times.length; j++) {
                    const timeDiff = Math.abs(dayjs(times[i]).diff(dayjs(times[j]), 'minute'));
                    if (timeDiff < 10) {
                        isValid = false;
                        showToast(`Times for ${target.profile_name} must be at least 10 minutes apart.`, "e");
                        break;
                    }
                }
                if (!isValid) break;
            }
            if (!isValid) break;
        }

        if (!isValid) {
            return; // Stop if validation fails
        }
        setLoading(true);

        const targetProfilesWithTimes = targetProfiles.map((target) => ({
            target_profile_id: target.id,
            post_times: timesForTargets[target.id] || [],
            board_id: selectedBoards[target.id] || null, // Include board ID for Pinterest
        }));

        const payload = {
            source_profile_id: sourceProfile,
            source_service_name: connectedProfiles.find((a) => a.id === sourceProfile)?.service_name,
            target_profiles_with_times: targetProfilesWithTimes,
        };

        // Submit the payload
        HttpServices.Post({
            url: userModuleURL.createAutomation,
            body: payload,
            successCallback: createAutomationPostSuccessCallback,
            failureCallback: createAutomationPostFailureCallback,
        });
    };

    const createAutomationPostFailureCallback = (message) => {
        showToast(message, "e");
        setLoading(false);
    };

    const createAutomationPostSuccessCallback = (data, message) => {
        handleSuccess(message);
        setLoading(false);
    };

    const handleCreateBoard = () => {
        setPinterestCreateBoardOpen(true)
    }
    const handlePinterestBoardClose = () => {
        setPinterestCreateBoardOpen(false)
    }
    const NewBoardCreationCallback = (newBoard, profileId) => {
        setTargetConnectedProfiles((prevProfiles) => {
            return prevProfiles.map((profile) => {
                if (profile.id === profileId) {
                    return {
                        ...profile,
                        pinterst_boards: [...profile.pinterst_boards, newBoard],
                    };
                }
                return profile;
            });
        });
        setPinterestCreateBoardOpen(false);
    };

    const handleTimeChange = (targetId, index, newTime) => {
        if (newTime) {
            const convertedTime = CommonHelper.getBrandTimeZoneTime(newTime);
            setTimesForTargets((prevTimes) => {
                const updatedTimes = { ...prevTimes };
                if (!updatedTimes[targetId]) {
                    updatedTimes[targetId] = [];
                }
                updatedTimes[targetId][index] = convertedTime;
                return updatedTimes;
            });
        }
    };

    const addTimeForTarget = (targetId) => {
        setTimesForTargets((prevTimes) => {
            const updatedTimes = { ...prevTimes };
            if (!updatedTimes[targetId]) {
                updatedTimes[targetId] = [];
            }
            updatedTimes[targetId].push(curentBrandTime); // Add a new time slot
            return updatedTimes;
        });
    };

    const handleCloseReauthenticate = () => {
        setReAuthenticateOpen(false);
        setSelectedPopOverAccount(null);
    };
    const handleProfileClick = (profile) => {
        setSelectedPopOverAccount(profile);
        setReAuthenticateOpen(true);
    };


    const removeTimeForTarget = (targetId, index) => {
        setTimesForTargets((prevTimes) => {
            const updatedTimes = { ...prevTimes };
            if (updatedTimes[targetId]) {
                updatedTimes[targetId] = updatedTimes[targetId].filter((_, i) => i !== index); // Remove the time at the specified index
                if (updatedTimes[targetId].length === 0) {
                    delete updatedTimes[targetId]; // Remove the target if no times are left
                }
            }
            return updatedTimes;
        });
    };

    const handleBoardChange = (targetId, boardId) => {
        setSelectedBoards((prevBoards) => ({
            ...prevBoards,
            [targetId]: boardId,
        }));
    };

    const getServiceLabelView = (serviceName) => {
        const iconMap = {
            [PostUtil.ServiceName.TWITTER]: { icon: TwitterIcon, label: "Twitter" },
            [PostUtil.ServiceName.FACEBOOK]: { icon: FacebookIcon, label: "Facebook", color: fbColor },
            [PostUtil.ServiceName.YOUTUBE]: { icon: YoutubeIcon, label: "YouTube" },
            [PostUtil.ServiceName.INSTAGRAM]: { icon: InstagramIcon, label: "Instagram", color: instaColor },
            [PostUtil.ServiceName.LINKEDIN]: { icon: LinkedInIcon, label: "LinkedIn" },
            [PostUtil.ServiceName.TIKTOK]: { icon: TikTokIcon, label: "TikTok" },
            [PostUtil.ServiceName.GBUSINESS]: { icon: GBusinessIcon, label: "Google My Business" },
            [PostUtil.ServiceName.PINTEREST]: { icon: PinterestIcon, label: "Pinterest" },
        };

        const { icon, label, color } = iconMap[serviceName] || {};
        return icon && (
            <GroupHeader>
                <img alt={`${label}_Icon`} src={icon} width={30} height={30} style={{ marginLeft: '-5px' }} />
                <Typography sx={{ fontSize: 18, fontWeight: 800, color: color || 'black', ml: 1 }}>
                    {label}
                </Typography>
            </GroupHeader>
        );
    };

    const GroupHeader = styled('div')(({ theme }) => ({
        padding: '20px 10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
    }));

    const GroupItems = styled('ul')({
        padding: 0,
    });
    const handleNetworks = (newValue) => {
        setTargetProfiles(newValue);
        setTimesForTargets((prevTimes) => {
            const updatedTimes = { ...prevTimes };
            newValue.forEach((target) => {
                if (!updatedTimes[target.id]) {
                    updatedTimes[target.id] = [curentBrandTime]; // Initialize with one time slot
                }
            });
            return updatedTimes;
        });
    }
    const connectedMediaProps = { configuredSocialMedia: targetConnectedProfiles, selectedSocialMedias: targetProfiles, handleNetworks, handleProfileClick }
    return (
        <Dialog open maxWidth="md" fullWidth PaperProps={{
            style: {
                minHeight: '300px',
                maxHeight: '90vh',
                height: '90vh'
            },
        }}>
            <DialogContent dividers>
                <Typography variant="h5" gutterBottom>
                    Set Up Cross Post
                </Typography>

                {/* Source Profile */}
                <Typography style={{ color: theme.palette.text.secondary, ...theme.typography.subtitle2 }}>
                    Select Source Profile
                </Typography>
                <FormControl fullWidth sx={{ mt: 1 }}
                >
                    <Select value={sourceProfile} onChange={(e) => setSourceProfile(e.target.value)}
                        displayEmpty
                        renderValue={(selected) =>
                            selected ? (
                                connectedProfiles.find((profile) => profile.id === selected)
                                    ? CommonFunction.InitialAccountBadgeWithName(
                                        connectedProfiles.find((profile) => profile.id === selected)
                                    )
                                    : selected
                            ) : (
                                <Typography color="text.secondary">Select a Source Profile</Typography>
                            )
                        }>
                        {connectedProfiles.map((profile) => (
                            <MenuItem key={profile.id} value={profile.id} style={{
                                pointerEvents: !profile.is_active ? 'none' : 'auto',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                            }}>
                                <div style={{ opacity: !profile.is_active ? 0.5 : 1, flex: 1 }}>
                                    {CommonFunction.InitialAccountBadgeWithName(profile)}
                                </div>
                                {!profile.is_active && (
                                    <Button
                                        variant="contained"
                                        size="small"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (hasIntegratationPermission) {
                                                handleProfileClick(profile);
                                            }
                                        }}
                                        sx={{
                                            ml: 2,
                                            pointerEvents: 'auto',
                                            borderRadius: 1,
                                            backgroundColor: theme.palette.error.main,
                                            color: theme.palette.error.contrastText,
                                            '&:hover': {
                                                backgroundColor: theme.palette.error.dark,
                                                boxShadow: theme.shadows[3],
                                            }
                                        }}
                                        startIcon={<KeyIcon fontSize="small" />}
                                    >
                                        Re-authenticate
                                    </Button>
                                )}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Target Profiles - Multiple Select */}
                <Typography style={{ color: theme.palette.text.secondary, ...theme.typography.subtitle2 }} sx={{ mt: 2 }}>
                    Select Target Profile
                </Typography>
                <FormControl fullWidth sx={{ mt: 1 }}>
                    <ConnectedMediaComponent {...connectedMediaProps} />
                </FormControl>

                {/* Display Time Pickers for Each Selected Target Profile */}
                <Box
                    sx={{
                        mt: 2,
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 2,
                    }}
                >
                    {targetProfiles.map((target) => {
                        const targetProfile = targetConnectedProfiles.find((profile) => profile.id === target.id);
                        const times = timesForTargets[target.id] || [];
                        const isPinterest = targetProfile.service_name.toUpperCase() === PostUtil.ServiceName.PINTEREST;
                        const boards = isPinterest ? targetProfile.pinterst_boards : [];

                        return (
                            <Card
                                key={target.id}
                                sx={{
                                    flex: '1 1 calc(33.333% - 16px)',
                                    minWidth: '300px',
                                    maxWidth: '100%',
                                    mb: 2,
                                    boxShadow: 3,
                                    borderRadius: 2,
                                    transition: 'transform 0.2s, box-shadow 0.2s',
                                    '&:hover': {
                                        transform: 'scale(1.02)',
                                        boxShadow: 6,
                                    },
                                }}
                            >
                                <CardContent>
                                    <Avatar
                                        src={targetProfile.profile_image || targetProfile.profile_name || targetProfile.id}
                                        alt={targetProfile.profile_name || targetProfile.id}
                                        sx={{ height: 30, width: 30, bgcolor: 'primary.main' }}
                                    />
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                                        {targetProfile?.profile_name}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
                                        {targetProfile?.service_name.toUpperCase()}
                                    </Typography>

                                    {/* Board Selection for Pinterest */}
                                    {isPinterest && (
                                        <FormControl fullWidth sx={{ mb: 2 }}>
                                            <InputLabel>Select Board</InputLabel>
                                            <Select
                                                value={selectedBoards[target.id] || ''}
                                                onChange={(e) => handleBoardChange(target.id, e.target.value)}
                                                label="Select Board"
                                            >
                                                {boards.map((board) => (
                                                    <MenuItem key={board.board_id} value={board.board_id}>
                                                        {board.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <Button
                                                variant="contained"
                                                onClick={handleCreateBoard}
                                                sx={{ whiteSpace: 'nowrap', mt: 2 }} // Prevents the button text from wrapping
                                            >
                                                Create Board
                                            </Button>
                                            {pinterestCreateBoardOpen && (
                                                <PinterestCreateBoard
                                                    handleClose={handlePinterestBoardClose}
                                                    profileId={targetProfile.id}
                                                    NewBoardCreationCallback={(data) => NewBoardCreationCallback(data, targetProfile.id)}
                                                />
                                            )}
                                        </FormControl>
                                    )}


                                    {/* Time Pickers */}
                                    <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 2, alignItems: 'center', mt: 2 }}>
                                        {times.map((time, index) => (
                                            <Box key={index} sx={{ flex: '1 1 calc(33.333% - 16px)', minWidth: '200px', display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <TimePicker
                                                        label={`Select Time to Post ${index + 1}`}
                                                        value={dayjs(time) || null}
                                                        ampm={!is24HourFormat}
                                                        onChange={(newTime) => handleTimeChange(target.id, index, newTime)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                fullWidth
                                                                sx={{
                                                                    '& .MuiOutlinedInput-root': {
                                                                        borderRadius: 1,
                                                                    },
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                                <IconButton
                                                    onClick={() => removeTimeForTarget(target.id, index)}
                                                    sx={{
                                                        color: theme.palette.error.main,
                                                        '&:hover': {
                                                            backgroundColor: theme.palette.error.light,
                                                        },
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </Box>
                                        ))}
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <IconButton
                                                onClick={() => addTimeForTarget(target.id)}
                                                sx={{
                                                    backgroundColor: theme.palette.primary.main,
                                                    color: theme.palette.primary.contrastText,
                                                    '&:hover': {
                                                        backgroundColor: theme.palette.primary.dark,
                                                    },
                                                }}
                                            >
                                                <AddIcon />
                                            </IconButton>
                                            <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                                                Add Another Time
                                            </Typography>
                                        </Box>
                                    </Stack>
                                </CardContent>
                            </Card>
                        );
                    })}
                </Box>
            </DialogContent>

            <DialogActions>
                <Button variant="outlined" color="error" onClick={handleClose}>
                    Cancel
                </Button>
                <LoadingButton
                    loading={isLoading}
                    onClick={handleSubmitAutomation}
                    variant="contained"
                    color="primary"
                    disabled={targetProfiles.length === 0 || !sourceProfile}
                >
                    Set Cross Post
                </LoadingButton>
            </DialogActions>
            {reAuthenticateOpen && (
                <CommonReAuthenticateComponent
                    selectedPopOverAccount={selectedPopOverAccount}
                    getConnectedNetworks={getConnectedNetworks}
                    onClose={handleCloseReauthenticate}
                />
            )}
        </Dialog>
    );
};

export default AddAutomation;