import { LoadingButton } from '@mui/lab';
import { Button, DialogTitle, CardContent,ListItemIcon,Paper} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import VerifiedUserIcon from  '@mui/icons-material/VerifiedUser';

const TwitterAuthorizationModal = (props) => {
    const [isLoadingOn, setLoadingOn] = useState(false);

    const handleConnectTwitter = () => {
        setLoadingOn(true);
        props.handleConnectTwitter();
    };

    const handleClose = () => {
        props.handleClose();
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open>
           <DialogTitle id="customized-dialog-title">
                <Typography gutterBottom variant="h4">
                    {props.reAuth 
                        ? `Renew Access Permissions` 
                        : `Authorize to access your account`}
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <CardContent sx={{ p: { xs: 0, md: 0 }, color: 'grey.800' }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 2 }}>
                        {props.reAuth 
                            ? "Verify and update application permissions:" 
                            : "Application will request access to:"}
                    </Typography>

                    <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
                        <ListItemIcon>
                            <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
                        </ListItemIcon>
                        <Typography variant="body1">
                            {props.reAuth 
                                ? "Verify existing read access to Tweets, timeline, and profile"
                                : "Read access to Tweets, home timeline, and profile information"}
                        </Typography>
                    </Paper>

                    <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
                        <ListItemIcon>
                            <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
                        </ListItemIcon>
                        <Typography variant="body1">
                            {props.reAuth 
                                ? "Renew read/write permissions for Twitter resources"
                                : "Read/write access to Twitter resources"}
                        </Typography>
                    </Paper>

                    {!props.reAuth && (
                        <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
                            <ListItemIcon>
                                <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
                            </ListItemIcon>
                            <Typography variant="body1">
                                Access to read, write and delete Direct Messages
                            </Typography>
                        </Paper>
                    )}
                </CardContent>
            </DialogContent>
            <DialogActions>
                <Button  variant="outlined" color="error" onClick={handleClose}>Close</Button>
                <LoadingButton
                    variant='contained'
                    loading={isLoadingOn}
                    autoFocus
                    onClick={() => {
                        handleConnectTwitter();
                    }}
                >
                    {props.reAuth ? "Re-Authenticate" : "Connect"}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
export default TwitterAuthorizationModal;
