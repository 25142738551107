import { LoadingButton } from '@mui/lab';
import { Button, DialogTitle, CardContent, ListItemIcon, Paper } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';


const YoutubeAuthorizationModal = (props) => {
    const [isLoadingOn, setLoadingOn] = useState(false);

    const handleConnectYoutube = () => {
        setLoadingOn(true);
        props.handleConnectYoutube();
    };

    const handleClose = () => {
        props.handleClose();
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open>
            <DialogTitle id="customized-dialog-title">
                <Typography gutterBottom variant="h4">
                    {props.reAuth 
                        ? "Reauthenticate YouTube Account Connection" 
                        : "Have you converted your YouTube account to a Business Profile?"}
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <CardContent sx={{ p: { xs: 0, md: 0 }, color: 'grey.800' }}>
                    <Typography variant="body1" sx={{ mx: 'auto', fontWeight: 'bold' }}>
                        {props.reAuth 
                            ? "Verify these requirements to reconnect:" 
                            : "Make sure you've completed these steps before continuing:"}
                    </Typography>

                    <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1, mt: 2 }}>
                        <ListItemIcon>
                            <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
                        </ListItemIcon>
                        <Typography variant="body1">
                            {props.reAuth 
                                ? "Confirm you're still signed in to the original Google Account"
                                : "Make sure you're signed in to your Google Account"}
                        </Typography>
                    </Paper>

                    <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
                        <ListItemIcon>
                            <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
                        </ListItemIcon>
                        <Typography variant="body1">
                            {props.reAuth 
                                ? "Verify ongoing compliance with privacy and security standards"
                                : "This app ensures privacy and security of users' data"}
                        </Typography>
                    </Paper>

                    {!props.reAuth && (
                        <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
                            <ListItemIcon>
                                <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
                            </ListItemIcon>
                            <Typography variant="body1">
                                Please be sure not to violate others' copyright or privacy rights
                            </Typography>
                        </Paper>
                    )}

                    <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
                        <ListItemIcon>
                            <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
                        </ListItemIcon>
                        <Typography variant="body1">
                            {props.reAuth 
                                ? "Ensure your YouTube channel permissions are still valid"
                                : "Confirm proper permissions for YouTube channel integration"}
                        </Typography>
                    </Paper>

                </CardContent>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color='error' onClick={handleClose}>Close</Button>
                <LoadingButton
                    variant='contained'
                    loading={isLoadingOn}
                    autoFocus
                    onClick={() => {
                        handleConnectYoutube();
                    }}
                >
                    {props.reAuth ? "Re-Authenticate" : "Connect"}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
export default YoutubeAuthorizationModal;
