
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import {
    Badge,
    Avatar,
    Card,
    IconButton,
    Table, TableBody, TableCell, TableContainer, TablePagination, TableRow
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from "react-helmet-async";
import Label from 'src/components/Label';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import Spinner from 'src/utils/Loader/spinner';
import { useToaster } from 'src/utils/toaster/toasterContext';
import UserListHead from '../../sections/@dashboard/user/UserListHead';
import NoPostFound from '../publishedPosts/noPost.component';
import DeleteIcon from '../../assets/deletecomment.svg'
import AutomationToolbar from './automationToolbar';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import { CommonHelper } from 'src/utils/commonHelper';
import { PostDetailsHelper } from 'src/utils/postDetailsHelper';
import EditAutomationPost from './editAutomation.component';

const Automation = () => {
    const [automation, setAutomation] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loader, setLoader] = useState(false);
    const { showToast } = useToaster()
    const [toBeDeletedId, setToBeDeletedId] = useState(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [openAutomationModel, setOpenAutomationModel] = useState(false);
    const [automationId, setAutomationId] = useState(null);
    const [height, setHeight] = useState(window.innerHeight - 250);

    useEffect(() => {
        getAllAutomation();
    }, []);
    const getAllAutomation = () => {
        setLoader(true)
        let postProps = {
            url: userModuleURL.getAllAutomation,
            successCallback,
            failureCallback
        };
        HttpServices.Get(postProps);
    }
    const successCallback = (data, message) => {
        message && showToast(message, "s");
        setLoader(false);
        setAutomation(data);
    }
    const failureCallback = (message) => {
        showToast(message, "e")
        setLoader(false)
    }
    const deleteSuccessCallback = (data, message) => {
        message && showToast(message, "s")
        setLoader(false);
        getAllAutomation();
    }
    const handleDelete = (id) => {
        setToBeDeletedId(id);
        setConfirmOpen(true);
    }
    const handleUpdate = (data) => {
        setAutomationId(data.id);
        setOpenAutomationModel(true)
    }
    const handleClose = () => {
        setOpenAutomationModel(false);
    };

    const confirmDelete = () => {
        setConfirmOpen(false);
        let props = {
            automation_post_id: toBeDeletedId
        }
        let postProps = {
            url: userModuleURL.deleteAutomation,
            body: props,
            successCallback: deleteSuccessCallback,
            failureCallback
        }
        HttpServices.Post(postProps)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handleRoleCreated = (message) => {
         showToast(message, "s");
        setOpenAutomationModel(false);
        getAllAutomation();
    }
    return (
        <>
            <Helmet>
                <title> Manage Cross Post </title>
            </Helmet>
            {loader ?
                <Spinner />
                :
                <>
                    {automation && automation.length > 0 ?
                        <Card sx={{ m: 1 }}>
                            <AutomationToolbar handleSuccess={handleRoleCreated} />
                            <TableContainer sx={{
                                minHeight: 'calc(100vh - 305px)', maxHeight: 'calc(100vh - 305px)'
                            }} >
                                <Table aria-label="collapsible table" stickyHeader>
                                    <UserListHead
                                        headLabel={[
                                            { id: 'created_time', label: 'Created Time', width: '15%', alignCenter: false },
                                            { id: 'created_by', label: 'Created By', width: '15%', alignRight: false },
                                            { id: 'source_connected_profiles', label: "Source Connected Profile", width: '30%', alignRight: false },
                                            { id: 'target_connected_profiles', label: "Target Connected Profile", width: '30%', alignRight: false },
                                            { id: 'action', label: 'Action', width: '10%', alignCenter: true },
                                        ]}
                                        rowCount={automation.length}
                                        isShowCheckbox={false}
                                        style={{ whiteSpace: 'nowrap' }}
                                    />
                                     {automation.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                    <TableBody>
                                        <TableCell align="left">
                                            {CommonHelper.formatDateAndTime(row.created_time, 'date')}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.created_by_name}
                                        </TableCell>
                                        <TableCell align="left">
                                            {PostDetailsHelper.getSocialMediaViewWithTooltip([row.source_connected_profiles])}
                                        </TableCell>
                                        <TableCell align="left">
                                            {PostDetailsHelper.getSocialMediaViewWithTooltip(row.target_connected_profiles)}
                                        </TableCell>
                                        <TableCell align="center">
                                        <IconButton onClick={() => { handleDelete(row.id) }} size="large">
                                                                <img src={DeleteIcon} style={{ width: 23, height: 23 }} />
                                                            </IconButton>
                                                            <IconButton onClick={() => { handleUpdate(row) }} size="large">
                                                                <EditIcon sx={{ color: 'primary.main' }} />
                                                            </IconButton>
                                                        </TableCell>
                                    </TableBody>
                                ))}
                                </Table>
                            </TableContainer>
                            <TablePagination
                                component="div"
                                rowsPerPageOptions={[10, 15, 25]}
                                count={automation.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />

                            <ConfirmationDialog
                                open={confirmOpen}
                                onClose={() => setConfirmOpen(false)}
                                onConfirm={confirmDelete}
                                title="Confirm Deletion"
                                message="Are you sure you want to delete this Cross Post?"
                            />
                             {openAutomationModel && <EditAutomationPost handleClose={handleClose} automationId={automationId} handleSuccess={handleRoleCreated} />}
                        </Card>
                        :
                        <Card sx={{ minHeight: 'calc(100vh - 150px)', maxHeight: 'calc(100vh - 150px)' }}>
                            <AutomationToolbar handleSuccess={handleRoleCreated} />
                            <NoPostFound height={height} contentText={' No Cross Post Is Available ' }/>
                        </Card>
                    }
                </>
            }
        </>
    );
};

export default Automation;

