import { useRef, useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Paper,
  Stack,
  useMediaQuery,
  useTheme,
  Divider,
} from '@mui/material';
import {
  Public as PublicIcon,
  PlayArrow,
  MoreVert as MoreVertIcon,
  FiberManualRecord as FiberManualRecordIcon,
} from '@mui/icons-material';
import { CommonHelper } from 'src/utils/commonHelper';
import youtubeDownloadIcon from '../../../../assets/youtube-download-icon.svg';
import YoutubeLikeDislikeIcon from '../../../../assets/fblike.svg';
import youtubeShareIcon from '../../../../assets/youtube-share-icon.svg';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { styled } from '@mui/material/styles';

dayjs.extend(utc);
dayjs.extend(timezone);

const YoutubePostVideoPreview = ({ data }) => {
  const theme = useTheme();
  const respwidth = useMediaQuery(theme.breakpoints.up('xl'));
  const ConnectedProfiles = data.connected_profile_details;
  const videoRef = useRef(null);
  const brandTimeZone = CommonHelper.GetBrandTimeZone();
  const [isPlaying, setIsPlaying] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const CurrentBrandTime = dayjs().tz(brandTimeZone).format('DD/MM/YYYY HH:mm:ss');
  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true;
    }
  }, []);

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
        videoRef.current.muted = true;
      } else {
        videoRef.current.muted = false;
        videoRef.current.play().catch(console.error);
      }
      setIsPlaying(!isPlaying);
    }
  };

  const videoShow = () => {
    return (
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          maxWidth: 500,
          borderRadius: 1,
          overflow: 'hidden',
          aspectRatio: '16 / 9',
        }}
      >
        <video
          preload="metadata"
          crossOrigin="anonymous"
          ref={videoRef}
          src={data.media_url}
          controls={false}
          onClick={togglePlay}
          muted
          playsInline
          onError={(e) => console.error('Video error:', e)}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            display: 'block',
          }}
        />

        {/* Play Button */}
        {!isPlaying && (
          <IconButton
            onClick={togglePlay}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'rgba(0,0,0,0.6)',
              '&:hover': { bgcolor: 'rgba(0,0,0,0.8)' },
              width: 56,
              height: 56,
            }}
          >
            <PlayArrow sx={{ color: 'white', fontSize: 40 }} />
          </IconButton>
        )}
      </Box>
    );
  };

  const ProfileSubscribers = () => {
    return (
      <>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{ gap: '0.3rem' }}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            1k subscribers
          </Typography>
        </Stack>
      </>
    );
  };

  return (
    <>
      <Stack container justifyContent="center" alignItems="center">
        <Card
          sx={{
            maxWidth: 500,
            minWidth: respwidth ? 400 : null,
            boxShadow: 'none',
            border: 'solid 2px',
            borderColor: theme.palette.preview.postcard.color,
          }}
        >
          <CardMedia>{videoShow()}</CardMedia>
          <CardContent sx={{ p: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: 700 }}>
              {data.title}
            </Typography>
          </CardContent>
          <CardHeader
            avatar={
              <Avatar
                src={
                  ConnectedProfiles.connected_profile_image
                    ? ConnectedProfiles.connected_profile_image
                    : ConnectedProfiles.connected_profile_name || ConnectedProfiles.connected_profile_id
                }
                alt={
                  ConnectedProfiles.connected_profile_name
                    ? ConnectedProfiles.connected_profile_name
                    : ConnectedProfiles.connected_profile_id
                }
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  height: 40,
                  width: 40,
                  bgcolor: theme.palette.primary.main,
                }}
                style={{ color: 'white' }}
              />
            }
            title={ConnectedProfiles?.connected_profile_name}
            sx={{ fontSize: { xl: 15, lg: 13, md: 11, sm: 9, xs: 7 }, p: 1 }}
            subheader={<ProfileSubscribers />}
            action={<Stack direction="row" alignItems="center" spacing={1} sx={{ p: 1 }}>
              <Box
                sx={{
                  background: 'black',
                  color: 'white',
                  textTransform: 'none',
                  fontSize: '15x',
                  fontWeight: 600,
                  pt: '0.7rem',
                  pb: '0.7rem',
                  pl: '1.5rem',
                  pr: '1.5rem',
                  minWidth: 0,
                  borderRadius: '25px',
                }}
              >
                Subscribe
              </Box>
            </Stack>}
          />
          <CardActions disableSpacing sx={{ display: 'block' }}>
            <Stack direction="row" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <Paper sx={{ display: 'flex', alignItems: 'center', gap: '6.5rem', mt: 0.5 }}>
                <img
                  src={YoutubeLikeDislikeIcon}
                  style={{ height: 'auto', width: '100%', maxWidth: '18px', maxHeight: '18px', marginLeft: '1rem' }}
                />
              </Paper>
              <Paper sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                <img
                  src={YoutubeLikeDislikeIcon}
                  style={{
                    height: 'auto',
                    width: '100%',
                    maxWidth: '18px',
                    maxHeight: '18px',
                    transform: 'rotate(180deg)',
                    marginLeft: '0.5rem',
                  }}
                />
              </Paper>
              <Paper sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                <img
                  src={youtubeShareIcon}
                  style={{ height: 'auto', width: '100%', maxWidth: '22px', maxHeight: '22px', marginLeft: '0.5rem' }}
                />
              </Paper>
              <Paper sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                <img
                  src={youtubeDownloadIcon}
                  style={{ height: 'auto', width: '100%', maxWidth: '20px', maxHeight: '20px', marginRight: '1rem' }}
                />
              </Paper>
            </Stack>
          </CardActions>
          <CardContent sx={{ p: 1 }}>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{ gap: 1 }}>
              <Typography variant="body2" sx={{ fontWeight: 700 }}>1 Views</Typography>
              <Typography variant="body2" sx={{ fontWeight: 700 }}>{CommonHelper.formateDateWithYear(CurrentBrandTime)}</Typography>
            </Stack>
            <Typography
              noWrap={!showFullDescription}
              sx={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: showFullDescription ? 'initial' : 2,
                overflow: 'hidden',
                WebkitBoxDecorationBreak: 'clone',
                whiteSpace: 'pre-line',
                textOverflow: 'ellipsis',
                fontSize: '0.875rem',
              }}
            >
              {CommonHelper.ParseDescription(data.description)}
            </Typography>
            {!showFullDescription && data.description.length > 100 && (
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 700,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontSize: '0.875rem',
                  color: theme.palette.primary.main
                }}
                onClick={handleToggleDescription}
              >
                Show More
              </Typography>
            )}
            {showFullDescription && (
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 700,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontSize: '0.875rem',
                  color: theme.palette.primary.main
                }}
                onClick={handleToggleDescription}
              >
                Show Less
              </Typography>
            )}
            {data.has_comment && data.comment_description &&
              <Stack sx={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'flex-start', mt:1, mb: 1 }}>
                <Typography sx={{ fontWeight:700 }}>1 comment</Typography>
              </Stack>}
            {data.has_comment && data.comment_description && <>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: '0.5rem',
                  marginTop: '8px',
                }}
              >
                <Avatar
                  src={
                    ConnectedProfiles.connected_profile_image
                      ? ConnectedProfiles.connected_profile_image
                      : ConnectedProfiles.connected_profile_name || ConnectedProfiles.connected_profile_id
                  }
                  alt={
                    ConnectedProfiles.connected_profile_name
                      ? ConnectedProfiles.connected_profile_name
                      : ConnectedProfiles.connected_profile_id
                  }
                  sx={{
                    textAlign: 'flex-start',
                    height: 30,
                    width: 30,
                    bgcolor: theme.palette.primary.main,
                  }}
                />
                <Box sx={{ width: '100%' }}>
                  <Stack spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
                    <Typography variant="body2" sx={{ fontWeight: 800 }}>@{ConnectedProfiles?.connected_profile_name}</Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>Just Now</Typography>
                  </Stack>
                  <Typography
                    variant="body2"
                    style={{
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 1,
                      overflow: 'hidden',
                      WebkitBoxDecorationBreak: 'clone',
                      whiteSpace: 'pre-line',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {CommonHelper.ParseDescription(data.comment_description)}
                  </Typography>
                  <Stack spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
                    <img
                      src={YoutubeLikeDislikeIcon}
                      style={{ height: 'auto', width: '100%', maxWidth: '15px', maxHeight: '15px' }}
                    />
                    <img
                      src={YoutubeLikeDislikeIcon}
                      style={{
                        height: 'auto',
                        width: '100%',
                        maxWidth: '15px',
                        maxHeight: '15px',
                        transform: 'rotate(180deg)',
                      }}
                    />
                    <Typography variant="body2" sx={{ fontWeight: 800, color: 'text.secondary' }}>Reply</Typography>
                  </Stack>
                </Box>
              </Box>
            </>}
          </CardContent>
        </Card>
      </Stack>
    </>
  );
};

export default YoutubePostVideoPreview;
