import MailIcon from '@mui/icons-material/Mail';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
  AppBar,
  Badge,
  Box,
  Button,
  IconButton,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { createTheme, styled, useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';
import { NavConfig } from 'src/navConfig';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import { useToaster } from 'src/utils/toaster/toasterContext';
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';
import { useDesktop } from 'src/contexts/DesktopProvider';
import SettingsIcon from '@mui/icons-material/Settings';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import InactiveProfiles from './inActiveConnectedProfiles.component';
import { useReAuth } from 'src/utils/reAuth/ReAuthContext';

export default function Header({ headerName }) {
  const { isCollapse } = useCollapseDrawer();
  const navigate = useNavigate();
  const isDesktop = useDesktop();
  const theme = useTheme();
  const { showToast } = useToaster();
  const { checkAndTriggerReAuth } = useReAuth();
  const brandDetails = CommonHelper.GetBrandInfoFromLocalStorage("brand_details");
  const freePlan = 'free_plan';

  // State variable
  const [isVerified, setisVerified] = useState(true);
  const [isPlanExpired, setPlanExpired] = useState(false);

  useEffect(() => {
    getUserInfo();
    if (brandDetails && brandDetails?.length > 0){
      checkAndTriggerReAuth();
    }
    if (window.Tawk_API) {
      window.Tawk_API.onLoad = function () {
        window.Tawk_API.hideWidget();
      };
    }
  }, []);
  const getUserInfo = () => {
    let isVerified = CommonHelper.GetUserIsVerified();
    let planDetails = CommonHelper.GetCurrentFromLocalStorage();
    setisVerified(isVerified);
    if (planDetails) {
      if (planDetails.plan_id === freePlan || planDetails.expire_in_days === 0) setPlanExpired(true);
    }
  };
  const navigateToPayment = () => {
    navigate('/payment');
  };
  const failureCallback = (message) => {
    message && showToast(message, 'e');
  };
  const successCallback = (data, message) => {
    message && showToast(message, 's');
  };
  const resendEmail = () => {
    let postProps = {
      url: userModuleURL.resendVerificationMail,
      successCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };

  const handleOpenSettings = () => {
    navigate('/profile');
  };

  const handleOpenChatBot = () => {
    if (window.Tawk_API) {
      window.Tawk_API.maximize();
    }
  };

  //Styled Components
  const HEADER_MOBILE = 64;

  const customTheme = createTheme({
    breakpoints: {
      values: {
        mdCustom: 1100,
        mdCustom0: 1250,
      },
    },
  });

  const StyledRoot = styled(AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    [customTheme.breakpoints.up('mdCustom')]: {
      width: '100%',
      ...(headerName !== '' && {
        width: `calc(100% - ${NavConfig.NAVBAR.DASHBOARD_WIDTH + 70}px)`,
        ...(isCollapse && {
          width: `calc(100% - ${NavConfig.NAVBAR.DASHBOARD_COLLAPSE_WIDTH + 72}px)`,
        }),
      }),
      height: '64px',
      zIndex: '1101',
      backdropFilter: 'blur(6px)',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      transition: 'height 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      borderBottom: '1px solid rgba(0, 171, 85, 0.24)',
      borderBottomStyle: 'dashed',
    },
  }));
  const handleHelperTextClick = () => {
    navigate('/manage-brand');
  };
  const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    minHeight: HEADER_MOBILE,
    [customTheme.breakpoints.up('mdCustom')]: {
      padding: theme.spacing(0, 2.5),
    },
  }));

  return (
    <StyledRoot>
      <StyledToolbar>
        <Typography
          sx={{
            color: 'black',
            fontWeight: 700,
            fontSize: '20px',
          }}
        >
          {headerName}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          {headerName && (
            <>
              {isPlanExpired && (
                <Button
                  size="small"
                  sx={{ background: theme.palette.primary.main }}
                  variant="contained"
                  onClick={navigateToPayment}
                >
                  <Badge variant="dot" sx={{ mr: 1 }}>
                    <MailIcon />
                  </Badge>
                  Upgrade to Pro
                </Button>
              )}
              <InactiveProfiles />
              {!isVerified && isDesktop && (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: '#ffebee',
                      p: 1,
                      px: 2,
                      borderRadius: 2,
                    }}
                  >
                    <MailIcon color="error" sx={{ mr: 1 }} />
                    <Typography variant="body2" color="error" sx={{ flexGrow: 1 }}>
                      Verify your email to enable all features.{' '}
                      <Tooltip title="Send a new verification email" arrow>
                        <Typography
                          component="span"
                          color="error"
                          sx={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: 'medium' }}
                          onClick={resendEmail}
                        >
                          Resend Email
                        </Typography>
                      </Tooltip>
                    </Typography>
                  </Box>
                </>
              )}

              <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}>
                <AccessTimeIcon sx={{ fontSize: '18px', color: theme.palette.primary.main, mr: 1 }} />
                <Typography variant="body2" sx={{ color: '#555' }}>
                  Current TimeZone in {CommonHelper.GetBrandTimeZone()}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    cursor: 'pointer',
                    color: theme.palette.primary.main,
                    textDecoration: 'underline',
                    ml: 1,
                    '&:hover': {
                      textDecoration: 'none',
                    },
                  }}
                  onClick={handleHelperTextClick}
                >
                  Want to change it?
                </Typography>
              </Box>

              {/* <LanguagePopover /> */}
              <NotificationsPopover />

              {headerName && (
                <IconButton onClick={handleOpenChatBot} sx={{ width: 40, height: 40 }}>
                  <HeadsetMicIcon sx={{ width: '20px', height: '20px' }} />
                </IconButton>
              )}

              {headerName && (
                <IconButton onClick={handleOpenSettings} sx={{ width: 40, height: 40 }}>
                  <SettingsIcon sx={{ width: '20px', height: '20px' }} />
                </IconButton>
              )}
            </>
          )}
          <AccountPopover headerName={headerName} />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
