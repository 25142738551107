import { Box, Card, Grid, Stack } from '@mui/material';
import { useState } from 'react';
import ImageDraggable from 'src/components/image/image-draggable';
import { CommonHelper } from 'src/utils/commonHelper';
import { useToaster } from 'src/utils/toaster/toasterContext';
import { NewPostStyledComponent } from './newPostStyled.component';
import PostDescription from './post-description.component';
import PostUploadComponent from './post-upload.component';
import ConnectedMediaComponent from './connected-media.component';
import PostFirstComment from './post-first-comment.component';
import AIContentComponent from './ai/ai-content.component';
import { integrateModuleURL } from 'src/services/urlService';
import { useNavigate } from 'react-router-dom';
import HttpServices from 'src/services/httpService';
import PostHashtagGenerator from './post-hashtag-generator.component';
import PostBulkUploadComponent from './post-bulk-upload.component';


const PostContentComponent = ({
  configuredSocialMedia,
  selectedSocialMedias,
  handleProfileClick,
  handleNetworks,
  handleAddAllDescription,
  handleAddAllCommentDescription,
  handleMediaFiles,
  newPostForm,
  hashTags,
  handleAddHashTags
}) => {
  const { showToast } = useToaster();
  const navigate = useNavigate();

  //State Variables
  const [AIHashtagText, setAIHashtagText] = useState('AI Hashtags');
  const [isUploadMediaPopupOpen, setUploadMediaPopup] = useState(false);
  const [isBulkMediaPopupOpen, setBulkMediaPopup] = useState(false);
  const [fileUploadprogress, setFileUploadprogress] = useState();
  const [showAIPopup, setAIPopup] = useState(false);

  const handleUploadMediaPopup = (show = false) => {
    setUploadMediaPopup(show);
  };
  const handleBulkMediaPopup = (show = false) => {
    setBulkMediaPopup(show);
  };
  const handleUploadMedia = (files) => {
    let mFiles = [...newPostForm.media_data];
    mFiles = mFiles.concat(files);
    setUploadMediaPopup(false);
    handleMediaFiles(mFiles);
  };
  const privateFileUploadSuccessCallback = (data, message, uploadFiles = []) => {
    const mFiles = [...data?.media_datas, ...uploadFiles];
    message && showToast(message, 's');
    handleUploadMedia(mFiles);
    handleUploadMediaPopup(false);
  };

  const fileUploadSuccessCallback = (data, message, privateFiles) => {
    if (privateFiles.length > 0) {
      const uploadFilesProps = {
        privateFiles,
        successCallback: (privateData, message) => privateFileUploadSuccessCallback(privateData, message, data),
        failureCallback,
      };
      CommonHelper.UploadPrivateMediaFilesToServer({ ...uploadFilesProps });
    } else {
      message && showToast(message, 's');
      handleUploadMedia(data);
      handleUploadMediaPopup(false);
    }
  };

  const failureCallback = (message) => {
    showToast(message, 'e');
  };
  const handleUploadMediaToServer = (files, privateFiles) => {
    if (files.length == 0 && privateFiles.length > 0) {
      const uploadFilesProps = {
        privateFiles,
        successCallback: (data, message) => privateFileUploadSuccessCallback(data, message),
        failureCallback,
      };
      CommonHelper.UploadPrivateMediaFilesToServer({ ...uploadFilesProps });
    } else {
      let uploadFilesProps = {
        files,
        setFileUploadprogress,
        successCallback: (data, message) => {
          fileUploadSuccessCallback(data, message, privateFiles);
        },
        failureCallback,
      };
      CommonHelper.UploadMediaFilesToServer({ ...uploadFilesProps });
    }
  };
  const handleReOrderFiles = (oldIndex, newIndex) => {
    let files = newPostForm.media_data;
    let mediaFiles = CommonHelper.ReArrangeArray(files, oldIndex, newIndex);
    handleMediaFiles(mediaFiles);
  };
  const handleRemoveFiles = (mediaUrl) => {
    let files = [...newPostForm.media_data];
    let index = files.findIndex((a) => a.media_url === mediaUrl);
    if (index >= 0) files.splice(index, 1);
    handleMediaFiles(files);
  };

  const handleAIContent = () => {
    // checkAIEnabledForThisBrand();
    setAIPopup(true);
  };

  const aiEnabledSuccesscallback = (data, message) => {
    if (data) {
      setAIPopup(true);
    } else {
      showToast(
        "Ensure your OpenAI API key is configured in the 'Manage Brand' or 'Manage Organization' section to start using AI-generated content.",
        's'
      );
      navigate('/manage-brand');
    }
  };

  const checkAIEnabledForThisBrand = () => {
    let postProps = {
      url: integrateModuleURL.checkAIEnabledForThisBrand,
      body: null,
      successCallback: aiEnabledSuccesscallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };

  const handleUseAIContent = (content) => {
    handleAddAllDescription(content.trim());
    setAIPopup(false);
  };

  const handleCloseAIPopup = () => {
    setAIPopup(false);
  };

  const hashtagSuccessCallback = (data, message) => {
    if (data.status) {
      if (data && data.content?.length > 0) {
        let hashtags = data.content[0].replaceAll('\n', ' ');
        handleAddHashTags(hashtags);
      }
    } else {
      message && showToast(message, 'e');
    }
    setAIHashtagText("AI Hashtags")
  };

  const handleGenerateHashtags = () => {
    if (newPostForm.description) {
      setAIHashtagText("Generating Hashtags")
      const postProps = {
        url: integrateModuleURL.generateHashtag,
        body: { description: newPostForm.description },
        successCallback: hashtagSuccessCallback,
        failureCallback,
      };
      HttpServices.Post(postProps);
    } else {
      showToast('Please enter description to generate hashtags', 'e');
    }
  };

  const handleUseHashtag = (hashtags) => {
    const updatedDescription = `${newPostForm.description} \n ${hashtags}`;
    handleAddAllDescription(updatedDescription);
  };

  return (
    <>
      <Card sx={{ p: 3, height: 'calc(100vh - 190px)', overflow: 'auto' }}>
        <Grid container>
          <Grid item xs={12} alignItems="center">
            <Stack direction="row" justifyContent="space-between" spacing={2}>
              <NewPostStyledComponent.LabelStyle>Profile</NewPostStyledComponent.LabelStyle>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <ConnectedMediaComponent
              configuredSocialMedia={configuredSocialMedia}
              selectedSocialMedias={selectedSocialMedias}
              handleNetworks={handleNetworks}
              handleProfileClick={handleProfileClick}
            />
          </Grid>
        </Grid>
        <PostDescription
          handleChangeDescription={handleAddAllDescription}
          valueDescription={newPostForm.description}
          handleUploadMediaPopup={handleUploadMediaPopup}
          handleBulkMediaPopup={handleBulkMediaPopup}
          showInputProps={true}
          handleAIContent={handleAIContent}
          handleGenerateHashtags={handleGenerateHashtags}
          AIHashtagText={AIHashtagText}
        />
        {newPostForm.media_data?.length > 0 && (
          <Box>
            <ImageDraggable
              mediaFiles={newPostForm.media_data}
              handleRemoveFiles={handleRemoveFiles}
              handleReOrderFiles={handleReOrderFiles}
            />
          </Box>
        )}
        <PostFirstComment
          showInputProps={true}
          handleChangeDescription={handleAddAllCommentDescription}
          valueDescription={newPostForm.comment_description}
        />
        <PostHashtagGenerator hashtags={hashTags} handleUseHashtag={handleUseHashtag} />
      </Card>
      {/* Popup Place */}
      {isUploadMediaPopupOpen && (
        <PostUploadComponent
          handleClose={handleUploadMediaPopup}
          fileUploadprogress={fileUploadprogress}
          handleUpload={handleUploadMediaToServer}
        />
      )}
      {isBulkMediaPopupOpen && (
        <PostBulkUploadComponent handleClose={handleBulkMediaPopup} configuredSocialMedia={configuredSocialMedia} />
      )}
      {showAIPopup && <AIContentComponent handleUseAIContent={handleUseAIContent} handleClose={handleCloseAIPopup} />}
    </>
  );
};
export default PostContentComponent;
