import { useEffect } from "react";
import { Config } from "src/config";
import HttpServices from "src/services/httpService";
import { integrateModuleURL } from "src/services/urlService";
import { useState } from "react";
import { useToaster } from "src/utils/toaster/toasterContext";
import { CommonHelper } from "src/utils/commonHelper";
import CustomDomainRedirectIframe from '../customDomainIframe';
import GoogleMyBusinessAuthorizationModal from "../google-my-business-integration/google-my-business-authorization-modal";

const GoogleMyBusinessReAuthenticate = ({ handleClose, AccountId, getConnectedNetworks }) => {
    const [isAuthorizationModalOpen, setAuthorizationModal] = useState(true);
    const { showToast } = useToaster();
    const isCustomDomain = CommonHelper.CustomDomain();

    let isProcessing = false;
    let intervalId = null;

    useEffect(() => {
        intervalId = setInterval(() => {
            checkAccessTokenReceivedFromGoogleMyBusiness();
        }, Config.Common.socialAccountsIntegrationInterval);
        return () => {
            clearInterval(intervalId);
        };
    }, [])
    const getGoogleMyBusinessOauthToken = () => {
        window.open(
            `${Config.GoogleMyBusiness.authorizeUrl}?client_id=${Config.GoogleMyBusiness.clientId}&scope=${Config.GoogleMyBusiness.scope}&prompt=consent&access_type=offline&include_granted_scopes=true&state=state_parameter_passthrough_value&redirect_uri=${Config.GoogleMyBusiness.redirectUri}&response_type=code`, "_blank"
        );
    }

    const checkAccessTokenReceivedFromGoogleMyBusiness = () => {
        if (isCustomDomain) {
            const handleMessage = (event) => {
                const { type, code } = event.data;
                if (type === "googleMyBusiness_token" && code && !isProcessing) {
                    isProcessing = true;
                    window.removeEventListener("message", handleMessage, false); // Remove event listener
                    clearInterval(intervalId); // Stop polling
                    sendAccessTokenToServer(code); // Process the code
                }
            };

            window.addEventListener("message", handleMessage, false);
        } else {
            var token = localStorage.getItem("googleMyBusiness_token");
            var error = localStorage.getItem("googleMyBusiness_error");
            if (token) {
                localStorage.removeItem("googleMyBusiness_token");
                clearInterval(intervalId);
                sendAccessTokenToServer(token);
            }
            else if (error) {
                localStorage.removeItem("googleMyBusiness_error");
                clearInterval(intervalId);
                showToast(error, 'e');
            }
        }
    };

    const googleMyBusinessSuccessCallback = (data, message) => {
        showToast(message, 's');
        getConnectedNetworks();
        handleClose();
    }
    const googleMyBusinessFailureCallback = (message) => {
        showToast(message, 'e');
        handleClose();
    }
    const sendAccessTokenToServer = (code) => {
        let googleMyBusinessForm = {
            code,
            account_id: AccountId
        };
        let postProps = {
            url: integrateModuleURL.reAuthenticateGoogleMyBusiness,
            body: googleMyBusinessForm,
            successCallback: googleMyBusinessSuccessCallback,
            failureCallback: googleMyBusinessFailureCallback
        }
        HttpServices.Post(postProps);
    };


    return (
        <>
            {isAuthorizationModalOpen &&
                <GoogleMyBusinessAuthorizationModal
                    handleConnectGoogleMyBusiness={getGoogleMyBusinessOauthToken}
                    handleClose={handleClose}
                    reAuth={true}
                />}
                 {isCustomDomain && <CustomDomainRedirectIframe serviceName="gbusiness"/> }
        </>
    );
};
export default GoogleMyBusinessReAuthenticate;