import {
  Public as PublicIcon,
  MoreVert as MoreVertIcon,
  FiberManualRecord as FiberManualRecordIcon,
  PlayArrow,
} from '@mui/icons-material';
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
  Box,
  Paper,
  useMediaQuery,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { useState, useRef, useEffect } from 'react';
import { CommonHelper } from 'src/utils/commonHelper';
import Linklike from '../../../assets/linkedin_like.svg';
import LinkUnLike from '../../../assets/linkedin_unlike.svg';
import LinkedinRetweetIcon from '../../../assets/twitter_retweet.svg';
import LinkedinShareIcon from '../../../assets/linkedin-share-icon.svg';
import LinkedinCommentIcon from '../../../assets/linkedin_comment.svg';
import Nodocument from '../../../assets/no-document.svg';

const LinkedInPostCard = ({
  media_url,
  video_url,
  media_type,
  description,
  name,
  image_url,
  created_time,
  like_count,
  share_count,
  comment_count,
  is_liked,
  profile,
  handleUnLikePost,
  handleLikePost,
  handleFocusComment,
}) => {
  const theme = useTheme();
  const hasManageLikesCommentPermission = CommonHelper.CheckForPermissionAvailableForUser(
    CommonHelper.PermissionList.MANAGE_COMMENTS_LIKES
  );
  const respwidth = useMediaQuery(theme.breakpoints.up('xl'));
  const [showFullDescription, setShowFullDescription] = useState(false);
  let mediaFiles = [...(Array.isArray(media_url) ? media_url : []), video_url].filter(Boolean);

  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const LinkedInMediaView = () => {
    const [aspectRatios, setAspectRatios] = useState([]);
    const mediaRefs = useRef([]);
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const totalMedia = mediaFiles.length;
    const remainingCount = totalMedia > 4 ? totalMedia - 4 : 0;

    useEffect(() => {
      const newRatios = mediaRefs.current.map((el) => {
        if (!el) return 1;
        if (el.tagName === 'IMG') return el.naturalWidth / el.naturalHeight;
        if (el.tagName === 'VIDEO') return el.videoWidth / el.videoHeight;
        return 1;
      });
      setAspectRatios(newRatios);
    }, [mediaFiles]);

    useEffect(() => {
      if (videoRef.current) {
        videoRef.current.muted = true;
      }
    }, []);

    const togglePlay = () => {
      if (videoRef.current) {
        if (isPlaying) {
          videoRef.current.pause();
        } else {
          videoRef.current.play().catch(console.error);
        }
        setIsPlaying(!isPlaying);
      }
    };

    const renderMedia = (media, i) => {
      const isImage = media_type === 'IMAGE' || media_type === 'MULTIIMAGE';
      const src = media?.url || media;

      return (
        <Box sx={{ ...(totalMedia > 1 && { position: 'relative', paddingTop: '100%', overflow: 'hidden' }) }}>
          {isImage ? (
            <img
              ref={(el) => (mediaRefs.current[i] = el)}
              src={src}
              alt={`media-${i}`}
              style={{
                ...(totalMedia > 1 && {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }),
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          ) : (
            <Box
              sx={{
                position: totalMedia > 1 ? 'absolute' : 'relative',
                top: 0,
                left: 0,
                overflow: 'hidden',
                ...(totalMedia && { width: '100%', height: '100%' }),
              }}
            >
              <video
                ref={(el) => {
                  mediaRefs.current[i] = el;
                  videoRef.current = el;
                }}
                src={src}
                controls={false}
                onClick={togglePlay}
                muted
                playsInline
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
              {!isPlaying && (
                <IconButton
                  onClick={togglePlay}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'rgba(0,0,0,0.6)',
                    '&:hover': { bgcolor: 'rgba(0,0,0,0.8)' },
                  }}
                >
                  <PlayArrow sx={{ color: 'white', fontSize: 40 }} />
                </IconButton>
              )}
            </Box>
          )}
        </Box>
      );
    };

    if (totalMedia === 1) {
      return <Box>{renderMedia(mediaFiles[0], 0)}</Box>;
    }

    if (totalMedia === 2) {
      return (
        <Grid container spacing={0.2}>
          {mediaFiles.map((media, i) => (
            <Grid item xs={6} key={i}>
              {renderMedia(media, i)}
            </Grid>
          ))}
        </Grid>
      );
    }

    if (totalMedia === 3) {
      const hasWideFirst = aspectRatios[0] < 1.0;

      if (hasWideFirst) {
        return (
          <Grid container spacing={0.2}>
            <Grid item xs={8}>
              {renderMedia(mediaFiles[0], 0)}
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.2 }}>
                {renderMedia(mediaFiles[1], 1)}
                {renderMedia(mediaFiles[2], 2)}
              </Box>
            </Grid>
          </Grid>
        );
      }

      return (
        <Grid container spacing={0.2}>
          <Grid item xs={8}>
            {renderMedia(mediaFiles[0], 0)}
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.2 }}>
              {renderMedia(mediaFiles[1], 1)}
              {renderMedia(mediaFiles[2], 2)}
            </Box>
          </Grid>
        </Grid>
      );
    }

    if (totalMedia >= 4) {
      const firstFour = mediaFiles.slice(0, 4);
      return (
        <Grid container spacing={0.2}>
          <Grid item xs={9}>
            {renderMedia(firstFour[0], 0)}
          </Grid>
          <Grid item xs={3}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.2 }}>
              {renderMedia(firstFour[1], 1)}
              {renderMedia(firstFour[2], 2)}
              <Box sx={{ position: 'relative' }}>
                {renderMedia(firstFour[3], 3)}
                {remainingCount > 0 && (
                  <Typography
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      bgcolor: 'rgba(0,0,0,0.6)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: 'white',
                      fontSize: 32,
                      fontWeight: 'bold',
                    }}
                  >
                    +{remainingCount}
                  </Typography>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      );
    }

    // Default for 2 media
    return (
      <Grid container spacing={0.2}>
        {mediaFiles.slice(0, 2).map((media, i) => (
          <Grid item xs={6} key={i}>
            {renderMedia(media, i)}
          </Grid>
        ))}
      </Grid>
    );
  };

  const profileTime = () => {
    return (
      <>
        <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ gap: '0.3rem' }}>
          <Typography variant="span">{CommonHelper.DateTimeFromCurrentTime(profile?.date)}</Typography>
          <FiberManualRecordIcon sx={{ fontSize: 5, mt: '0.6rem' }} />
          <PublicIcon sx={{ fontSize: 15, mt: '0.3rem' }} />
        </Stack>
      </>
    );
  };

  const checkActionsAreAvailable = () => {
    return like_count > 0 || comment_count > 0 || share_count > 0;
  };
  return (
    <>
      <Stack container justifyContent="center" alignItems="center">
        <Card
          sx={{
            maxWidth: 500,
            minWidth: respwidth ? 400 : null,
            boxShadow: 'none',
            border: 'solid 2px',
            borderColor: theme.palette.preview.postcard.color,
          }}
        >
          <CardHeader
            avatar={
              <Avatar
                src={profile?.profile_image ? profile?.profile_image : profile?.profile_name || profile?.id}
                alt={profile?.profile_name ? profile?.profile_name : profile?.id}
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  bgcolor: theme.palette.primary.main,
                }}
                style={{ color: 'white' }}
              />
            }
            title={profile?.profile_name || name}
            sx={{ fontSize: { xl: 15, lg: 13, md: 11, sm: 9, xs: 7 } }}
            subheader={profileTime()}
            action={<MoreVertIcon sx={{ mt: 1 }} />}
          />
          {description && (
            <CardContent sx={{ pt: 1, pb: 1 }}>
              <Typography
                noWrap={!showFullDescription}
                sx={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: showFullDescription ? 'initial' : 2,
                  overflow: 'hidden',
                  WebkitBoxDecorationBreak: 'clone',
                  whiteSpace: 'pre-line',
                  textOverflow: 'ellipsis',
                  fontSize: '0.875rem',
                }}
              >
                {description}
              </Typography>
              {!showFullDescription && description.length > 100 && (
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 700,
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    fontSize: '0.875rem',
                    color: theme.palette.primary.main,
                  }}
                  onClick={handleToggleDescription}
                >
                  Show More
                </Typography>
              )}
              {showFullDescription && (
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 700,
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    fontSize: '0.875rem',
                    color: theme.palette.primary.main,
                  }}
                  onClick={handleToggleDescription}
                >
                  Show Less
                </Typography>
              )}
            </CardContent>
          )}
          <CardMedia sx={{ mt: description.length === 0 ? 1 : 0 }}>
            <LinkedInMediaView />
          </CardMedia>
          <CardActions disableSpacing sx={{ display: 'block' }}>
            {checkActionsAreAvailable() && (
              <>
                <Stack
                  flexWrap="wrap"
                  direction="row"
                  spacing={2}
                  sx={{ justifyContent: 'flex-end', display: 'flex', alignItems: 'flex-end', m: 2, mb: 1 }}
                >
                  {like_count !== 0 && <Typography color={'text.secondary'}>{like_count} likes</Typography>}
                  {comment_count !== 0 && <Typography color={'text.secondary'}>{comment_count} comments</Typography>}
                  {share_count !== 0 && <Typography color={'text.secondary'}>{share_count} share</Typography>}
                </Stack>
                <Divider />
              </>
            )}
            <Divider />
            <Stack direction="row" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <Paper sx={{ display: 'flex', alignItems: 'center', gap: '6.5rem', mt: 0.5 }}>
                <IconButton
                  disabled={!hasManageLikesCommentPermission}
                  onClick={is_liked ? handleUnLikePost : handleLikePost}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: { xl: '30px', lg: '24px' },
                    },
                  }}
                >
                  <img
                    src={is_liked ? Linklike : LinkUnLike}
                    style={{ height: 'auto', width: '100%', maxWidth: '18px', maxHeight: '18px' }}
                  />
                </IconButton>
                <IconButton
                  disabled={!hasManageLikesCommentPermission}
                  onClick={handleFocusComment ? handleFocusComment : null}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: { xl: '30px', lg: '24px' },
                    },
                  }}
                >
                  <img
                    src={LinkedinCommentIcon}
                    style={{ height: 'auto', width: '100%', maxWidth: '18px', maxHeight: '18px' }}
                  />
                </IconButton>
                <IconButton
                  onClick={handleFocusComment ? handleFocusComment : null}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: { xl: '30px', lg: '24px' },
                    },
                  }}
                >
                  <img
                    src={LinkedinRetweetIcon}
                    style={{ height: 'auto', width: '100%', maxWidth: '18px', maxHeight: '18px' }}
                  />
                </IconButton>
                <IconButton
                  disabled
                  onClick={handleFocusComment ? handleFocusComment : null}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: { xl: '30px', lg: '24px' },
                    },
                  }}
                >
                  <img
                    src={LinkedinShareIcon}
                    style={{ height: 'auto', width: '100%', maxWidth: '18px', maxHeight: '18px' }}
                  />
                </IconButton>
              </Paper>
            </Stack>
          </CardActions>
        </Card>
      </Stack>
    </>
  );
};
export default LinkedInPostCard;
