import {
  Launch as LaunchIcon,
} from '@mui/icons-material';
import { Divider, Grid, Typography, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { StyledComponent } from 'src/components/styled-components/styledComponent';
import TiktokPostCard from '../publishedPostsPostCard/tiktokPostCard.component';
import HttpServices from 'src/services/httpService';
import { postsModuleURL } from 'src/services/urlService';
import Spinner from 'src/utils/Loader/spinner';
import { useToaster } from 'src/utils/toaster/toasterContext';
import TikTokIcon from '../../../assets/tiktok.svg';

const TiktokPostDetailView = ({ pageId, postId, profile, height }) => {
  const sideBarWidth = 800;
  const [postDetails, setPostDetails] = useState(null);
  const { showToast } = useToaster();
  const [showLoader, setLoader] = useState(true);
  useEffect(() => {
    getPostDetails();
  }, []);
  const tiktokPostDetailsSuccessCallback = (data, message) => {
    let pDetails = {
      id: data.id,
      media_url: data.image_url || data.thumbnail_url,
      media_type: data.media_type,
      description: data.description,
      like_count: data.like_count,
      comment_count: data.comment_count,
      share_count: data.share_count,
      view_count: data.view_count,
      created_time: data.created_time,
      link: data.link,
    };
    setPostDetails(pDetails);
    setLoader(false);
  };
  const tiktokPostDetailsFailureCallback = (message) => {
    setPostDetails(null);
    showToast(message, 'e');
    setLoader(false);
  };
  const getPostDetails = () => {
    setLoader(true);
    let getProps = {
      url: `${postsModuleURL.getTiktokPostDetails}${pageId}&post_id=${postId}`,
      successCallback: tiktokPostDetailsSuccessCallback,
      failureCallback: tiktokPostDetailsFailureCallback,
    };
    HttpServices.Get(getProps);
  };

  const postContent = () => {
    if (postDetails) {
      let cardDetails = {
        media_url: postDetails.media_url,
        media_type: postDetails.media_type,
        description: postDetails.description,
        like_count: postDetails.like_count,
        comment_count: postDetails.comment_count,
        share_count: postDetails.share_count,
        view_count: postDetails.view_count,
        is_liked: postDetails.is_liked,
        profile,
      };
      return <TiktokPostCard {...cardDetails} />;
    } else return null;
  };

  return (
    <>
      <StyledComponent.StyledStack sx={{ width: sideBarWidth }}>
        <Box sx={{ position: 'fixed', width: sideBarWidth, zIndex: 1000, background: 'white' }}>
          <Grid alignItems="center" display="flex" sx={{ pt: 1, pb: 1 }}>
            <img alt="Facebook_Icon" width={75} height={40} sx={{ fontSize: 20, color: '#0866ff' }} src={TikTokIcon} />
            <Typography
              color="#000000"
              style={{
                fontSize: 25,
                fontWeight: 800,
              }}
            >
              Tiktok
            </Typography>
            <a href={postDetails?.link} target="_blank" rel="noopener noreferrer" style={{ marginLeft: 'auto' }}>
              <LaunchIcon sx={{ fontSize: '2rem', mr: 2, color: 'primary.main' }} />
            </a>
          </Grid>
          <Divider />
        </Box>
        <Box sx={{ mt: '5.6rem' }}>
          {showLoader ? (
            <Spinner height={height} />
          ) : (
            <>
              <Grid display="flex" alignItems="flex-start" justifyContent="flex-start" sx={{ ml: '1.5rem !important' }}>
                {postContent()}
              </Grid>
            </>
          )}
        </Box>
      </StyledComponent.StyledStack>
    </>
  );
};

export default TiktokPostDetailView;
