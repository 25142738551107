import {
  Grid,
  Typography,
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Paper,
  Stack,
  useMediaQuery,
  useTheme,
  Divider,
  Button,
  createTheme,
  Box,
} from '@mui/material';
import { CommonHelper } from 'src/utils/commonHelper';
import FBcomment from '../../../../assets/comment.svg';
import FBlike from '../../../../assets/fblike.svg';
import FBshare from '../../../../assets/fbshare.svg';
import PublicIcon from '@mui/icons-material/Public';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import { FiberManualRecord as FiberManualRecordIcon } from '@mui/icons-material';
import FacebookLikeIcon from '../../../../assets/icons/facebook-reaction-like.svg';
import { useState } from 'react';
import MediaView from '../facebook-media-view.component';

const FacebookPostImagesPreview = ({ data }) => {
  let mediaFiles = CommonHelper.ProcessMediaUrl(data?.media_url)?.media_data;
  const isMultipleMedia = mediaFiles.length > 1;
  const mediaHeight = isMultipleMedia ? 150 : 200;
  const [showFullDescription, setShowFullDescription] = useState(false);
  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const theme = useTheme();
  const customTheme = createTheme({
    breakpoints: {
      values: {
        mdCustom: 1100,
      },
    },
  });

  const isDesktop = useMediaQuery(customTheme.breakpoints.up('mdCustom'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const respwidth = useMediaQuery(theme.breakpoints.up('xl'));
  const ConnectedProfiles = data.connected_profile_details;
  const ProfileTime = () => {
    return (
      <>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{ gap: '0.3rem', display: 'flex', alignItems: 'center' }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
            Just Now
          </Typography>
          <FiberManualRecordIcon sx={{ fontSize: 3 }} />
          <PublicIcon sx={{ fontSize: 15 }} />
        </Stack>
      </>
    );
  };

  return (
    <>
      <Stack container justifyContent="center" alignItems="center">
        <Card
          sx={{
            maxWidth: 500,
            minWidth: respwidth ? 400 : null,
            boxShadow: 3,
            borderRadius: 2,
            border: 'none',
            borderColor: theme.palette.preview.postcard.color,
          }}
        >
          <CardHeader
            avatar={
              <Avatar
                src={ConnectedProfiles.connected_profile_image}
                alt={
                  ConnectedProfiles.connected_profile_name
                    ? ConnectedProfiles.connected_profile_name
                    : ConnectedProfiles.connected_profile_id
                }
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  height: 40,
                  width: 40,
                  bgcolor: theme.palette.primary.main,
                }}
                style={{ color: 'white' }}
              />
            }
            title={
              <Box sx={{ display: 'flex', gap: 0.7, alignItems: 'center' }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                  {ConnectedProfiles?.connected_profile_name}
                </Typography>
                <FiberManualRecordIcon sx={{ fontSize: 3 }} />
                <Typography
                  sx={{
                    textTransform: 'none',
                    color: theme.palette.preview.facebook.color,
                    '&:hover': { textDecoration: 'underline' },
                    cursor: 'pointer',
                    fontFamily: 'sans-serif',
                    variant: 'subtitle1',
                    fontWeight: 600,
                  }}
                >
                  Follow
                </Typography>
              </Box>
            }
            sx={{ fontSize: { xl: 15, lg: 13, md: 11, sm: 9, xs: 7 }, mb: 1 }}
            subheader={<ProfileTime />}
            action={<MoreHorizIcon sx={{ mt: 1 }} />}
          />
          {(data.description || data.link) && (
            <CardContent sx={{ pt: 0, pb: 1 }}>
              <Typography
                noWrap={!showFullDescription}
                sx={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: showFullDescription ? 'initial' : 2,
                  overflow: 'hidden',
                  WebkitBoxDecorationBreak: 'clone',
                  whiteSpace: 'pre-line',
                  textOverflow: 'ellipsis',
                  fontSize: '0.875rem',
                }}
              >
                {CommonHelper.ParseDescription(data.description)}
                <br />
                {CommonHelper.ParseDescription(data.link)}
              </Typography>
              {!showFullDescription && data.description.length > 100 && (
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 700,
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    fontSize: '0.875rem',
                    color: theme.palette.primary.main,
                  }}
                  onClick={handleToggleDescription}
                >
                  Show More
                </Typography>
              )}
              {showFullDescription && (
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 700,
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    fontSize: '0.875rem',
                    color: theme.palette.primary.main,
                  }}
                  onClick={handleToggleDescription}
                >
                  Show Less
                </Typography>
              )}
            </CardContent>
          )}
          <CardMedia>
            <MediaView data={data} />
          </CardMedia>
          <CardActions disableSpacing sx={{ display: 'block' }}>
            <Stack direction="row" style={{ justifyContent: 'space-between', alignItems: 'center' }} sx={{ mb: 1 }}>
              {/* Like & Heart group */}
              <Paper sx={{ display: 'flex', alignItems: 'center' }}>
                <img src={FacebookLikeIcon} alt="like" style={{ width: 18, height: 18, marginLeft: '0.5rem' }} />
                <FavoriteOutlinedIcon sx={{ width: 18, height: 18, color: '#ff3040' }} />
                <Typography variant="body2">1.3k</Typography>
              </Paper>

              {/* Comment count (only shown if data.has_comment and data.comment_description) */}
              {data?.has_comment && data?.comment_description && (
                <Typography variant="body2" color="text.secondary" sx={{ marginRight: '0.5rem' }}>
                  1 comment
                </Typography>
              )}
            </Stack>
            <Divider sx={{ borderBottom: '1px solid #ddd' }} />
            <Stack direction="row" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <Paper sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                <img
                  src={FBlike}
                  style={{ height: 'auto', width: '100%', maxWidth: '18px', maxHeight: '18px', marginLeft: '0.8rem' }}
                />
                <Typography
                  color={'text.secondary'}
                  sx={{ fontWeight: 700, fontSize: { xl: 15, lg: 13, md: 11, sm: 9, xs: 7 }, marginLeft: '0.5rem' }}
                >
                  Like{' '}
                </Typography>
              </Paper>
              <Paper sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                <img
                  src={FBcomment}
                  style={{ height: 'auto', width: '100%', maxWidth: '20px', maxHeight: '20px', marginLeft: '0.5rem' }}
                />
                <Typography
                  sx={{
                    fontWeight: 700,
                    color: 'text.secondary',
                    fontSize: { xl: 15, lg: 13, md: 11, sm: 9, xs: 7 },
                    marginLeft: '0.5rem',
                  }}
                >
                  Comment{' '}
                </Typography>
              </Paper>
              <Paper sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                <img
                  src={FBshare}
                  style={{ height: 'auto', width: '100%', maxWidth: '20px', maxHeight: '20px', marginRight: '0.5rem' }}
                />
                <Typography
                  sx={{
                    fontWeight: 700,
                    color: 'text.secondary',
                    fontSize: { xl: 15, lg: 13, md: 11, sm: 9, xs: 7 },
                    marginRight: '0.5rem',
                  }}
                >
                  Share{' '}
                </Typography>
              </Paper>
            </Stack>
            {data.has_comment && data.comment_description && (
              <>
                <Divider sx={{ mt: 0.5, borderBottom: '1px solid #ddd' }} />
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: '0.5rem',
                    marginTop: '8px',
                  }}
                >
                  <Avatar
                    src={
                      ConnectedProfiles.connected_profile_image
                        ? ConnectedProfiles.connected_profile_image
                        : ConnectedProfiles.connected_profile_name || ConnectedProfiles.connected_profile_id
                    }
                    alt={
                      ConnectedProfiles.connected_profile_name
                        ? ConnectedProfiles.connected_profile_name
                        : ConnectedProfiles.connected_profile_id
                    }
                    sx={{
                      textAlign: 'flex-start',
                      height: 30,
                      width: 30,
                      bgcolor: theme.palette.primary.main,
                    }}
                  />
                  <Box sx={{ width: '100%' }}>
                    <Box
                      sx={{
                        padding: 1,
                        borderRadius: '8px',
                        border: `1px solid ${theme.palette.text.border}`,
                        background: theme.palette.background.default,
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: 800 }}>
                        {ConnectedProfiles?.connected_profile_name}
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 1,
                          overflow: 'hidden',
                          WebkitBoxDecorationBreak: 'clone',
                          whiteSpace: 'pre-line',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {CommonHelper.ParseDescription(data.comment_description)}
                      </Typography>
                    </Box>
                    <Stack spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
                      <Typography variant="body2" sx={{ fontWeight: 800, color: 'text.secondary' }}>
                        Like
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: 800, color: 'text.secondary' }}>
                        Reply
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Just Now
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
              </>
            )}
          </CardActions>
        </Card>
      </Stack>
    </>
  );
};

export default FacebookPostImagesPreview;
