import { ErrorOutline as ErrorOutlineIcon, ExpandMore as ExpandMoreIcon, Info as InfoIcon,AddPhotoAlternateOutlined as AddPhotoAlternateOutlinedIcon } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  Box,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  Tooltip,
} from '@mui/material';
import CollectionsSharpIcon from '@mui/icons-material/CollectionsSharp';
import MovieFilterSharpIcon from '@mui/icons-material/MovieFilterSharp';
import PhotoCameraSharpIcon from '@mui/icons-material/PhotoCameraSharp';
import { useTheme } from '@mui/material/styles';
import { forwardRef, memo, useEffect, useImperativeHandle, useState } from 'react';
import TextIconLabel from 'src/components/TextIconLabel';
import { CommonHelper } from 'src/utils/commonHelper';
import ImageDraggable from 'src/components/image/image-draggable';
import PostDescription from '../post-description.component';
import { NewPostStyledComponent } from 'src/pages/new-post-new/newPostStyled.component';
import { CommonFunction } from 'src/utils/commonFunctions/common-function.component';
import { PostUtil } from '../post-util';
import Image from 'src/components/image/Image';
import PinterestThumbnail from '../post-condition/pinterest-thumbnail';
import PostFirstComment from '../post-first-comment.component';
import { NewPostErrorUtil } from '../new-post-errors-util';

const InstagramConditionComponent = forwardRef(({ updateRequestData }, ref) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const reelsConfiguration = PostUtil.ServiceConfigurations.INSTAGRAM.REELS;
  const postConfiguration = PostUtil.ServiceConfigurations.INSTAGRAM.POST;
  const storyConfiguration = PostUtil.ServiceConfigurations.INSTAGRAM.STORY;

  //State Variable

  const [isAspectRatioNotSupportedForTheSelectedVideo, setAspectRatioNotSupportedForTheSelectedVideo] = useState(false);
  const [socialMediaRequestData, setSocialMediaRequestData] = useState([]);
  const [requestData, setRequestData] = useState([]);
  const [manualExpand, setManualExpand] = useState({});
  const [openInstagramCoverImage, setOpenInstagramCoverImage] = useState({});

  useEffect(() => {
    checkForErrors();
  }, []);
  useImperativeHandle(ref, () => ({
    checkForErrors,
    handleRequestData,
  }));
  useEffect(() => {
      Object.keys(requestData).forEach((profileId) => {
        let hasErrors = requestData[profileId].some(
          (profile) => profile.errors && profile.errors.length > 0
        );
  
        if (!hasErrors && manualExpand[profileId] === undefined) {
          setManualExpand((prev) => ({
            ...prev,
            [profileId]: true,
          }));
        }
      });
    }, [requestData]);

  const handleRequestData = (data) => {
    checkForErrors(data, false);
  };
  const updateRequestDataToParent = (data) => {
    updateRequestData(data, PostUtil.ServiceName.INSTAGRAM);
  };
  const handleChangeSocialMediaRequestData = (data, isUpdateDataToParent = true) => {
    let formattedRequestData = CommonHelper.B2SGroupBy(data, 'connected_profile_id');
    setRequestData({ ...formattedRequestData });
    setSocialMediaRequestData([...data]);
    if (isUpdateDataToParent === true) {
      updateRequestDataToParent(data);
    }
  };
  const checkForErrors = (data = null, isUpdateDataToParent = true) => {
    let sRequestData = data ? [...data] : [...socialMediaRequestData];
    if (sRequestData?.length > 0) {
      sRequestData.map((request) => {
        let errors = getInstagramErrors(request);
        request['errors'] = errors;
      });
      handleChangeSocialMediaRequestData(sRequestData, isUpdateDataToParent);
    } else {
      handleChangeSocialMediaRequestData([], []);
    }
  };

  const handleReOrderFiles = (profileId, postType, oldIndex, newIndex) => {
    let sRequestData = [...socialMediaRequestData];
    let reqData = sRequestData.find((a) => a.connected_profile_id === parseInt(profileId) && a.post_type === postType);
    let files = [...reqData.media_data];
    let mediaFiles = CommonHelper.ReArrangeArray(files, oldIndex, newIndex);
    reqData.media_url = mediaFiles.map((a) => a.media_url).join();
    reqData.media_data = mediaFiles;
    checkForErrors(sRequestData);
    // handleChangeSocialMediaRequestData(sRequestData);
  };
  const handleRemoveFiles = (profileId, postType, mediaUrl) => {
    let sRequestData = [...socialMediaRequestData];
    let rData = sRequestData.find((a) => a.connected_profile_id === parseInt(profileId) && a.post_type === postType);
    let files = [...rData.media_data];
    let index = files.findIndex((a) => a.media_url === mediaUrl);
    if (index >= 0) files.splice(index, 1);
    rData.media_url = files.map((a) => a.media_url).join();
    rData.media_data = files;
    checkForErrors(sRequestData);
    // handleChangeSocialMediaRequestData(sRequestData);
  };
  const handleChangeDescription = (profileId, profile, value) => {
    let sRequestData = [...socialMediaRequestData];
    let connectedProfile = sRequestData.find(
      (a) => a.connected_profile_id === parseInt(profileId) && a.post_type === profile.post_type
    );
    connectedProfile.description = value;
    checkForErrors(sRequestData);
    // handleChangeSocialMediaRequestData(sRequestData);
  };

  const handleChangeFirstComment = (profileId, profile, value) => {
    let sRequestData = [...socialMediaRequestData];
    let connectedProfile = sRequestData.find(
      (a) => a.connected_profile_id === parseInt(profileId) && a.post_type === profile.post_type
    );
    connectedProfile.comment_description = value;
    connectedProfile.has_comment = connectedProfile.comment_description == '' ? false : true;
    checkForErrors(sRequestData);
    // handleChangeSocialMediaRequestData(sRequestData);
  };

  const handlePostChange = (profileId, postType) => {
    let sRequestData = [...socialMediaRequestData];
    let profileCount = sRequestData.filter((a) => a.connected_profile_id === parseInt(profileId))?.length;
    let index = sRequestData.findIndex(
      (a) => a.connected_profile_id === parseInt(profileId) && a.post_type === postType
    );
    if (index >= 0) {
      if (profileCount > 1) {
        sRequestData.splice(index, 1);
      }
    } else {
      let sNetwork = { ...sRequestData.find((a) => a.connected_profile_id === parseInt(profileId)) };
      sNetwork['post_type'] = postType;
      sNetwork['errors'] = getInstagramErrors(sNetwork);
      sRequestData.push(sNetwork);
    }
    checkForErrors(sRequestData);
    // handleChangeSocialMediaRequestData(sRequestData);
  };

  // const handlePostChange = (event) => {
  //     handlePostType(PostUtil.ServiceName.INSTAGRAM, event.target.value);
  // };
  const handleChangeAspectRatio = () => {};
  const instagramReelsConditions = (request) => {
    return NewPostErrorUtil.InstagramReelsConditions(request);
  };
  const instagramStoryConditions = (request) => {
    return NewPostErrorUtil.InstagramStoryConditions(request);
  };
  const instagramPostConditions = (request) => {
    return NewPostErrorUtil.InstagramPostConditions(request);
  };

  const getInstagramErrors = (request) => {
    let iErrors = [];
    let postType = request.post_type;
    if (postType === PostUtil.PostType.POST) {
      let postErrors = instagramPostConditions(request);
      iErrors = iErrors.concat(postErrors);
    } else if (postType === PostUtil.PostType.REELS) {
      let reelsErrors = instagramReelsConditions(request);
      iErrors = iErrors.concat(reelsErrors);
    } else if (postType === PostUtil.PostType.STORY) {
      let storyErrors = instagramStoryConditions(request);
      iErrors = iErrors.concat(storyErrors);
    }
    return iErrors;
  };

  const handleAccordion = (profileId, hasErrors) => {
    if (hasErrors) return;

    setManualExpand((prev) => ({
      ...prev,
      [profileId]: !prev[profileId],
    }));
    let sRequestData = [...socialMediaRequestData];
    let reqDatas = sRequestData.filter((a) => a.connected_profile_id === parseInt(profileId));
    if (reqDatas?.length > 0) {
      reqDatas.map((data) => {
        data['isExpanded'] = !data['isExpanded'];
      });
    }
    handleChangeSocialMediaRequestData(sRequestData, false);
  };

  const handleChangeCoverImage = (profileId, profile, media_url) => {
    let sRequestData = [...socialMediaRequestData];
    let connectedProfile = sRequestData.find(
      (a) => a.connected_profile_id === parseInt(profileId) && a.post_type === profile.post_type
    );
    connectedProfile.cover_url = media_url;
    checkForErrors(sRequestData);
  };
  // const checkAspectRatioChanges = () => {
  //     return images.some((a) => a.media_type.includes('VIDEO')) && (selectedPostType === PostUtil.PostType.REELS || selectedPostType === PostUtil.PostType.STORY) && (request.changeInstagramAspectRatio || isAspectRatioNotSupportedForTheSelectedVideo);
  // }
  // useImperativeHandle(ref, () => ({
  //     getInstagramErrors,
  // }));
  const errorView = (request) => {
    return (
      <Box style={{ marginTop: '0.5rem' }}>
        <Typography sx={{ fontSize: 16, fontWeight: 800 }}>Error Description</Typography>
        {request.errors.map((errorMessage) => {
          return (
            <TextIconLabel
              icon={<ErrorOutlineIcon sx={{ color: theme.palette.error.main, mr: 1, fontSize: 18 }} />}
              value={errorMessage}
              sx={{ typography: 'caption', color: theme.palette.error.main, mb: 1, fontWeight: 800, fontSize: 14 }}
            />
          );
        })}
      </Box>
    );
  };
  const checkPostTypeChecked = (profileId, postType) => {
    return requestData[profileId].some((a) => a.post_type === postType);
  };

  const POST_TYPES = {
    post: {
      value: 'post',
      icon: <CollectionsSharpIcon sx={{ width: 17, height: 17, mr: 0.5 }} />,
    },
    reels: {
      value: 'reels',
      icon: <MovieFilterSharpIcon sx={{ width: 17, height: 17, mr: 0.5 }} />,
    },
    story: {
      value: 'story',
      icon: <PhotoCameraSharpIcon sx={{ width: 17, height: 17, mr: 0.5 }} />,
    },
  };

  const handleCoverImagePopup = (profileId, show = false) => {
    setOpenInstagramCoverImage((prev) => ({ ...prev, [profileId]: show }));
  };

  return (
    <>
      {requestData &&
        Object.keys(requestData)?.length > 0 &&
        Object.keys(requestData).map((profileId) => {
          let isExpanded = socialMediaRequestData.find(
            (a) => a.connected_profile_id === parseInt(profileId)
          )?.isExpanded;

          let hasErrors = requestData[profileId].some((profile) => profile.errors && profile.errors.length > 0);
          let borderColor = hasErrors ? theme.palette.error.main : theme.palette.text.border;
          let connectedProfileLength = requestData[profileId].some(
            (profile) =>
              profile?.connected_profile_details?.connected_profile_name &&
              profile?.connected_profile_details?.connected_profile_name.length > 10
          );
          let paddingTop = connectedProfileLength ? 1 : 0.5;

          return (
            <Accordion
              expanded={hasErrors || manualExpand[profileId] || false}
              onChange={() => { handleAccordion(profileId, hasErrors) }}
              style={{ ...NewPostStyledComponent.AccordionStyle, borderColor }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ ...NewPostStyledComponent.AccordionSummary, paddingTop }}
              >
                <Grid container justifyContent="space-between">
                  <Grid alignItems="center" display="flex">
                    {CommonFunction.AccountBadgeWithName(
                      requestData[profileId].find((profile) => profile.connected_profile_id.toString() === profileId)
                        ?.connected_profile_details
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <FormGroup
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkPostTypeChecked(profileId, PostUtil.PostType.POST)}
                            onChange={() => handlePostChange(profileId, PostUtil.PostType.POST)}
                          />
                        }
                        label={
                          <NewPostStyledComponent.AccordianLabelStyles>
                            {PostUtil.PostType.POST}
                          </NewPostStyledComponent.AccordianLabelStyles>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkPostTypeChecked(profileId, PostUtil.PostType.REELS)}
                            onChange={() => handlePostChange(profileId, PostUtil.PostType.REELS)}
                          />
                        }
                        label={
                          <NewPostStyledComponent.AccordianLabelStyles>
                            {PostUtil.PostType.REELS}
                          </NewPostStyledComponent.AccordianLabelStyles>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkPostTypeChecked(profileId, PostUtil.PostType.STORY)}
                            onChange={() => handlePostChange(profileId, PostUtil.PostType.STORY)}
                          />
                        }
                        label={
                          <NewPostStyledComponent.AccordianLabelStyles>
                            {PostUtil.PostType.STORY}
                          </NewPostStyledComponent.AccordianLabelStyles>
                        }
                      />
                      <Tooltip
                        title="You can post stories only in Instagram Business Account"
                      >
                        <InfoIcon />
                      </Tooltip>
                    </FormGroup>
                  </Grid>
                </Grid>
                <Divider />
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0.5 }}>
                {requestData[profileId].map((profile) => {
                  return (
                    <>
                      <Box
                        style={{
                          ...NewPostStyledComponent.AccordionStyle,
                          borderColor:
                            profile.errors && profile.errors.length > 0
                              ? theme.palette.error.main
                              : theme.palette.background.paper,
                          padding: '0.5rem',
                          marginBottom: '0.5rem',
                        }}
                      >
                        {profile.post_type === POST_TYPES[profile.post_type].value && (
                          <Box
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              color: theme.palette.primary.main,
                              marginTop: 4,
                              fontSize: '0.875rem',
                              fontWeight: 800,
                            }}
                          >
                            {POST_TYPES[profile.post_type].icon}
                            {POST_TYPES[profile.post_type].value.toUpperCase()}
                          </Box>
                        )}
                        <PostDescription
                          handleChangeDescription={(event) => {
                            handleChangeDescription(profileId, profile, event);
                          }}
                          valueDescription={profile.description}
                        />
                        {profile.media_data?.length > 0 && (
                          <Box style={{ marginTop: 3 }}>
                            <ImageDraggable
                              mediaFiles={profile.media_data}
                              handleRemoveFiles={(e) => {
                                handleRemoveFiles(profileId, profile.post_type, e);
                              }}
                              handleReOrderFiles={(e, k) => {
                                handleReOrderFiles(profileId, profile.post_type, e, k);
                              }}
                            />
                          </Box>
                        )}
                        {profile.post_type !== PostUtil.PostType.STORY && (
                          <PostFirstComment
                            handleChangeDescription={(event) => {
                              handleChangeFirstComment(profileId, profile, event);
                            }}
                            valueDescription={profile.comment_description}
                          />
                        )}
                         {profile.post_type === PostUtil.PostType.REELS && profile?.media_data?.length > 0  && profile.media_data[0]?.media_type === 'VIDEO' && <>
                          <Typography style={{ color: theme.palette.text.secondary, ...theme.typography.subtitle2 }}>
                            Cover Image
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                              flexWrap: 'wrap',
                            }}
                          >
                            {profile?.cover_url && profile.media_data?.length > 0 && (
                              <Box
                                sx={{
                                  p: 0,
                                  m: 0.5,
                                  width: 80,
                                  height: 80,
                                  borderRadius: 1.25,
                                  overflow: 'hidden',
                                  position: 'relative',
                                  display: 'inline-flex',
                                  opacity: 1,
                                  border: (theme) => `solid 1px ${theme.palette.divider}`,
                                }}
                              >
                                <Image src={profile.cover_url} alt={profile.cover_url} ratio="1/1" />
                              </Box>
                            )}

                            {profile.media_data?.length > 0 && (
                              <Box
                                sx={{
                                  p: 0,
                                  m: 0.5,
                                  width: 80,
                                  height: 80,
                                  borderRadius: 1.25,
                                  overflow: 'hidden',
                                  position: 'relative',
                                  display: 'inline-flex',
                                  opacity: 1,
                                  border: (theme) => `solid 1px ${theme.palette.divider}`,
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  flexDirection: 'column',
                                  cursor: 'pointer',
                                }}
                                onClick={() => handleCoverImagePopup(profileId,true)}
                              >
                                <AddPhotoAlternateOutlinedIcon />
                                <Typography variant="caption" sx={{ textAlign: 'center' }}>
                                  {profile?.cover_image_url ? 'Update CoverImage' : 'Upload CoverImage'}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </>
                        }
                        {openInstagramCoverImage[profileId] && profile.media_data?.length > 0 && (
                          <PinterestThumbnail
                            videoSrc={profile.media_data[0]}
                            handleClose={() => handleCoverImagePopup(profileId,false)}
                            handleChangeThumbnail={(coverImageUrl) => handleChangeCoverImage(profileId, profile, coverImageUrl)}
                            serviceName="instagram"
                          />
                        )}
                        {false ? (
                          <>
                            <Typography sx={{ fontSize: 16, fontWeight: 800, mt: 2 }}>
                              Additional Information{' '}
                            </Typography>
                            <Grid container sx={{ mt: 2, mb: 3, display: 'flex', alignItems: 'center' }} xs={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={profile.changeInstagramAspectRatio}
                                      onChange={(event) => handleChangeAspectRatio(PostUtil.ServiceName.INSTAGRAM)}
                                    />
                                  }
                                  label="Change Aspect Ratio for video"
                                />
                              </FormGroup>
                            </Grid>
                            {profile.errors?.length > 0 && errorView(profile)}
                          </>
                        ) : (
                          profile.errors && profile.errors.length > 0 && errorView(profile)
                        )}
                      </Box>
                    </>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          );
        })}
    </>
  );
});
export default memo(InstagramConditionComponent);
