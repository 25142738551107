import HttpServices from "src/services/httpService";
import { userModuleURL } from "src/services/urlService";
import { useToaster } from "src/utils/toaster/toasterContext";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { Config } from "src/config";
import { CommonHelper } from "src/utils/commonHelper";

const CheckAndVerifyIsGoogleSigninComponent = ({ handleSuccess, handleFailure }) => {
    let intervalId = null;
    const { showToast } = useToaster();
    const [timeZone, setTimeZone] = useState('UTC');
    useEffect(() => {
        const localTimeZone = CommonHelper.GetLocalTimeZone();
            if (localTimeZone) {
              setTimeZone(localTimeZone);
            }
        intervalId = setInterval(() => {
            checkAuthCodeReceivedFromGoogle();
        }, Config.Common.socialAccountsIntegrationInterval);
        return () => {
            clearInterval(intervalId);
        };
    }, [])
    const successCallback = (data, message) => {
        handleSuccess(data, message);
    }
    const failureCallback = (message) => {
        showToast(message, 'e');
        handleFailure();
    }
    const sendAuthCodeToServer = (code) => {
        let form = {
            code,
            timezone: timeZone
        };
        let postProps = {
            url: userModuleURL.sendAuthCodeToServer,
            body: form,
            successCallback,
            failureCallback
        }
        HttpServices.Post(postProps);
    }
    const checkAuthCodeReceivedFromGoogle = () => {
        var code = localStorage.getItem("google_auth_code");
        var error = localStorage.getItem("google_signin_error");
        if (code) {
            localStorage.removeItem("google_auth_code");
            clearInterval(intervalId);
            sendAuthCodeToServer(code);
        }
        else if (error) {
            localStorage.removeItem("google_signin_error");
            clearInterval(intervalId);
            failureCallback(error);
        }
    }
    return (
        <LoadingButton fullWidth size="large" loading variant="contained"></LoadingButton>
    )
}
export default CheckAndVerifyIsGoogleSigninComponent;