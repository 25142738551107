import { useEffect, useRef, useState } from 'react';

const useAspectRatios = (mediaFiles) => {
  const [aspectRatios, setAspectRatios] = useState([]);
  const mediaRefs = useRef([]);

  useEffect(() => {
    const newRatios = mediaRefs.current.map((el) => {
      if (!el) return 1;
      if (el.tagName === 'IMG') return el.naturalWidth / el.naturalHeight;
      if (el.tagName === 'VIDEO') return el.videoWidth / el.videoHeight;
      return 1;
    });
    setAspectRatios(newRatios);
  }, [mediaFiles]);

  return { aspectRatios, mediaRefs };
};

export default useAspectRatios;