  import { PostUtil } from '../new-post-new/post-util';
  import FacebookReAuthenticate from './re-authenticate-accounts/facebookReAuthenticate';
  import InstagramReAuthenticate from './re-authenticate-accounts/instagramReAuthenticate';
  import YouTubeReAuthenticate from './re-authenticate-accounts/youtubeReAuthenticate';
  import LinkedInPageReAuthenticate from './re-authenticate-accounts/linkedinPageReAuthenticate';
  import LinkedInProfileReAuthenticate from './re-authenticate-accounts/linkedProfileReAuthenticate';
  import TiktokReAuthenticate from './re-authenticate-accounts/tiktokReAuthenticate';
  import TwitterReAuthenticate from './re-authenticate-accounts/twitterReAuthenticate';
  import GoogleMyBusinessReAuthenticate from './re-authenticate-accounts/googlemybusinessReAuthenticate';
  import TwitterDeveloperReAuthenticate from './re-authenticate-accounts/twitterDeveloperReAuthenticate';
  import PinterestReAuthorizate from './re-authenticate-accounts/pinterestReAuthenticate';
  
  const CommonReAuthenticateComponent = ({selectedPopOverAccount,getConnectedNetworks,onClose}) => {
    
    return (
      <>
      {selectedPopOverAccount?.service_name.toUpperCase() === PostUtil.ServiceName.FACEBOOK && (
        <FacebookReAuthenticate 
          handleClose={onClose} 
          AccountId={selectedPopOverAccount?.id} 
          getConnectedNetworks={getConnectedNetworks} 
        />
      )}
      
      {selectedPopOverAccount?.service_name.toUpperCase() === PostUtil.ServiceName.INSTAGRAM && (
        <InstagramReAuthenticate 
          handleClose={onClose} 
          AccountId={selectedPopOverAccount?.id} 
          getConnectedNetworks={getConnectedNetworks} 
        />
      )}

      {selectedPopOverAccount?.service_name.toUpperCase() === PostUtil.ServiceName.YOUTUBE && (
        <YouTubeReAuthenticate 
          handleClose={onClose} 
          AccountId={selectedPopOverAccount?.id} 
          getConnectedNetworks={getConnectedNetworks} 
        />
      )}
       {selectedPopOverAccount?.service_name.toUpperCase() === PostUtil.ServiceName.LINKEDIN && (selectedPopOverAccount?.profile_type == "PAGE" ? (
         <LinkedInPageReAuthenticate 
         handleClose={onClose} 
         AccountId={selectedPopOverAccount?.id} 
         getConnectedNetworks={getConnectedNetworks} 
       />
      ) : (
        <LinkedInProfileReAuthenticate 
          handleClose={onClose} 
          AccountId={selectedPopOverAccount?.id} 
          getConnectedNetworks={getConnectedNetworks} 
        />
      ))}

      {selectedPopOverAccount?.service_name.toUpperCase() === PostUtil.ServiceName.TWITTER && (selectedPopOverAccount?.profile_type == "DEVELOPER" ? (
        <TwitterDeveloperReAuthenticate 
          handleClose={onClose} 
          AccountId={selectedPopOverAccount?.id} 
          getConnectedNetworks={getConnectedNetworks} 
        />
      ) : (
        <TwitterReAuthenticate 
          handleClose={onClose} 
          AccountId={selectedPopOverAccount?.id} 
          getConnectedNetworks={getConnectedNetworks} 
        />
      ))}

      {selectedPopOverAccount?.service_name.toUpperCase() === PostUtil.ServiceName.GBUSINESS && (
        <GoogleMyBusinessReAuthenticate 
          handleClose={onClose} 
          AccountId={selectedPopOverAccount?.id} 
          getConnectedNetworks={getConnectedNetworks} 
        />
      )}

      {selectedPopOverAccount?.service_name.toUpperCase() === PostUtil.ServiceName.PINTEREST && (
        <PinterestReAuthorizate 
          handleClose={onClose} 
          AccountId={selectedPopOverAccount?.id} 
          getConnectedNetworks={getConnectedNetworks} 
        />
      )}

      {selectedPopOverAccount?.service_name.toUpperCase() === PostUtil.ServiceName.TIKTOK && (
        <TiktokReAuthenticate 
          handleClose={onClose} 
          AccountId={selectedPopOverAccount?.id} 
          getConnectedNetworks={getConnectedNetworks} 
        />
      )}
    </>
  );
  };
  
  export default CommonReAuthenticateComponent;
  