import React from 'react';
import { useState } from 'react';
import {
  Badge,
  IconButton,
  Avatar,
  ListItemIcon,
  ListItemText,
  Typography,
  Button,
  Box,
  Popover,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Key as KeyIcon } from '@mui/icons-material';
import WarningIcon from '@mui/icons-material/Warning';
import CommonReAuthenticateComponent from 'src/pages/integration/commonReAuthenticate';
import { useReAuth } from 'src/utils/reAuth/ReAuthContext';
import { CommonFunction } from 'src/utils/commonFunctions/common-function.component';
import { CommonHelper } from 'src/utils/commonHelper';

export default function InactiveProfiles({ getConnectedNetworks }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [reAuthenticateOpen, setReAuthenticateOpen] = useState(false);
  const hasIntegratationPermission = CommonHelper.CheckForPermissionAvailableForUser(
    CommonHelper.PermissionList.INTEGRATE_PERMISSION
  );
  const [selectedPopOverAccount, setSelectedPopOverAccount] = useState();
  const { inActiveProfiles,checkAndTriggerReAuth } = useReAuth();
  const theme = useTheme();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = (profile) => {
    setSelectedPopOverAccount(profile);
    setReAuthenticateOpen(true);
  };

  const handleCloseReauthenticate = () => {
    setReAuthenticateOpen(false);
    setSelectedPopOverAccount(null);
  };

  if (!inActiveProfiles || inActiveProfiles.length === 0) return null;

  return (
    <Box sx={{
      display: 'flex', alignItems: 'center', 
      background: theme.palette.error.lighter,
      p: 1,
      px: 2,
      gap: 1,
      borderRadius: 2,
    }}>
      <IconButton onClick={handleMenuOpen} sx={{ p: 0 }}>
        <WarningIcon sx={{ color: 'error.main', fontSize: 28 }} />
      </IconButton>
      <Typography
        variant="body2"
        sx={{
          cursor: 'pointer',
          color: theme.palette.error.dark,
          textDecoration: 'underline',
          '&:hover': { textDecoration: 'none' },
        }}
        onClick={handleMenuOpen}
      >
        {inActiveProfiles.length} Inactive connected profiles
      </Typography>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{
          sx: {
            minWidth: 360,
            maxWidth: 380,
            maxHeight: 500,
            borderRadius: 2,
            border: `1px solid ${theme.palette.divider}`,
            boxShadow: theme.shadows[16],
          }
        }}
      >
        {/* Header Section */}
        <Box sx={{ 
          p: 2,
          bgcolor: 'background.neutral',
          borderBottom: `1px solid ${theme.palette.divider}`
        }}>
          <Box display="flex" alignItems="center" gap={1}>
            <WarningIcon color="error" fontSize="small" />
            <Typography variant="subtitle1" color="text.primary">
              Inactive Profiles
            </Typography>
          </Box>
          <Typography variant="caption" color="text.secondary">
            {inActiveProfiles.length} accounts need re-authentication
          </Typography>
        </Box>

        {/* Profile List */}
        <Box sx={{ overflow: 'auto', maxHeight: 400 }}>
          {inActiveProfiles.map((profile) => (
            <Box key={profile.id} sx={{ 
              display: 'flex', 
              alignItems: 'center',
              p: 2,
              '&:not(:last-child)': {
                borderBottom: `1px solid ${theme.palette.divider}`
              }
            }}>
              <ListItemIcon sx={{ minWidth: 40 }}>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={CommonFunction.SocialMediaAccountImage(profile.service_name)}
                >
                  <Avatar
                    src={profile.profile_image}
                    sx={{
                      width: 40,
                      height: 40,
                      bgcolor: theme.palette.primary.main,
                    }}
                  >
                    {!profile.profile_image && profile.profile_name?.charAt(0).toUpperCase()}
                  </Avatar>
                </Badge>
              </ListItemIcon>

              <ListItemText
                primary={
                  <Typography variant="subtitle2" noWrap>
                    {profile.profile_name}
                  </Typography>
                }
                secondary={
                  <Typography variant="caption" color="text.secondary">
                    {profile.service_name?.toUpperCase()} • {profile.profile_type}
                  </Typography>
                }
                sx={{ ml: 1, flexGrow: 1 }}
              />

              {hasIntegratationPermission && (
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => handleProfileClick(profile)}
                  sx={{
                    minWidth: 110,
                    py: 0.5,
                    borderRadius: 1,
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.error.contrastText,
                    '&:hover': {
                      backgroundColor: theme.palette.error.dark,
                      boxShadow: theme.shadows[3],
                    }
                  }}
                  startIcon={<KeyIcon fontSize="small" />}
                >
                  Re-authenticate
                </Button>
              )}
            </Box>
          ))}
        </Box>
      </Popover>

      {reAuthenticateOpen && (
        <CommonReAuthenticateComponent
          selectedPopOverAccount={selectedPopOverAccount}
          getConnectedNetworks={checkAndTriggerReAuth}
          onClose={handleCloseReauthenticate}
        />
      )}
    </Box>
  );
}