import {
  Box,
  FormControl,
  Checkbox,
  Divider,
  FormControlLabel,
  InputLabel, Chip,
  Stack,
  TextField,
  Typography,
  MenuItem,
  Select,
  InputAdornment,
} from '@mui/material';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { reportModuleURL } from 'src/services/urlService';
import HttpServices from 'src/services/httpService';
import { CommonHelper } from 'src/utils/commonHelper';
import { StyledComponent } from 'src/components/styled-components/styledComponent';
import { useToaster } from 'src/utils/toaster/toasterContext';
import { useRightSidebar } from 'src/layouts/right-side-bar/rightSidebarProvider';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Extend dayjs with the plugins
dayjs.extend(utc);
dayjs.extend(timezone)


const ScheduledReportView = ({
  serviceName,
  startDate,
  endDate,
  viewBy,
  connectedProfileId,
  reportId,
  subReportId = -1,
  currReportData
}) => {

  const sideBarWidth = 500;
  const { showToast } = useToaster();
  const { handleCloseRightSidebar } = useRightSidebar();
  let currentBrandId = CommonHelper.GetCurrentBrandId();
  const cuurentBrandTime = CommonHelper.getBrandTimeZoneTime();
  const brandDetails = CommonHelper.GetBrandInfoFromLocalStorage("brand_details");
  const currentBrandDetails = brandDetails?.find((a) => a.brand_id === currentBrandId);
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState(`Check out the  ${serviceName} Account ${currReportData.report_display_name ? currReportData.report_display_name : currReportData.card_name} for ${currentBrandDetails.brand_name}`);
  const [message, setMessage] = useState(`Just wanted to let you know that I have shared ${serviceName} ${currReportData.report_display_name || currReportData.card_name} report for ${currentBrandDetails.brand_name} with you. Go ahead and take a look at the attachment below.`);
  const [format, setFormat] = useState('PDF');
  const [passwordProtect, setPasswordProtect] = useState(false);
  const [dateRange, setDateRange] = useState('last_7_days');
  const [groupBy, setGroupBy] = useState('day');
  const [frequency, setFrequency] = useState('daily');
  const [sendTime, setSendTime] = useState(cuurentBrandTime);
  const [height, setHeight] = useState(window.innerHeight - 150);
  const [isLoading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const is24HourFormat = CommonHelper.GetBrandInfoFromLocalStorage('is_24_hour_format')
  const [selectedDays, setSelectedDays] = useState({
    weekly_days: [0],
    monthly_days: [1]
  });



  const handleSend = () => {
    // Reset error messages
    setEmailError('');

    if (!email) {
      setEmailError('Email address is required');
      showToast('Email address is required', 'e');
      return;
    }

    // Validate email format
    const emailList = email.split(',').map((e) => e.trim());
    const isValidEmail = emailList.every((emailItem) => /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(emailItem));

    if (!isValidEmail) {
      setEmailError('Please enter valid email addresses');
      showToast('Please enter valid email addresses', 'e');
      return;
    }
    
    if (!subject || subject.trim() === '') {
      showToast('Subject is required', 'e');
      return;
    }

    if (!message || message.trim() === '') {
      showToast('Message is required', 'e');
      return;
    }


    setLoading(true);
    const formBody = {
      report_id: reportId,
      sub_report_id: subReportId,
      service_name: serviceName,
      connected_profile_id: connectedProfileId,
      recipient_email_addresses: emailList,
      subject: subject,
      email_body: message,
      date_range: dateRange,
      group_by: viewBy,
      schedule_type: frequency,
      scheduled_time: sendTime,
      report_format: format
    };

    if (frequency === 'weekly') {
      formBody.schedule_type = 'weekly';
      formBody.weekly_days = selectedDays.weekly_days
    }
    else if (frequency === 'monthly') {
      formBody.schedule_type = 'monthly';
      formBody.monthly_days = selectedDays.monthly_days
    }

    const getProps = {
      url: reportModuleURL.addScheduledReport,
      body: formBody,
      successCallback,
      failureCallback,
    };
    HttpServices.Post(getProps);
  };

  const handleDayChange = (event, type) => {
    const value = event.target.value;
    setSelectedDays((prevSelectedDays) => {
      const updatedDays = { ...prevSelectedDays };

      if (type === 'weekly') {
        updatedDays.weekly_days = value;
      } else if (type === 'monthly') {
        updatedDays.monthly_days = value;
      }

      return updatedDays;
    });
  };
  const successCallback = (data, message) => {
    showToast(message, 's');
    setLoading(false);
    handleCloseRightSidebar();
  };

  const failureCallback = (message) => {
    showToast(message, 'e');
    setLoading(false);
  };

  const handleScheduleTime = (newTime) => {
    if (newTime) {
      // Convert the new time to a specific timezone and ensure it's a dayjs object
      const convertedTime = CommonHelper.getBrandTimeZoneTime(newTime)
      setSendTime(convertedTime);
    }
  };

  return (
    <StyledComponent.StyledStack sx={{ width: sideBarWidth, p: 3 }}>
      <Typography variant="h6" gutterBottom>Scheduled Report</Typography>
      <Stack spacing={2}>
        <TextField
          label="Recipient's Email Address"
          placeholder="Type email addresses separated by comma"
          fullWidth
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!emailError}
          helperText={emailError}
        />

        <TextField
          label="Subject"
          multiline
          rows={2}
          fullWidth
          required
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />

        <TextField
          label="Message"
          multiline
          rows={5}
          fullWidth
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Box>
          <Typography variant="body1">Date range</Typography>
          <Select
            value={dateRange}
            onChange={(e) => setDateRange(e.target.value)}
            fullWidth
          >
            <MenuItem value="last_7_days">Last 7 Days</MenuItem>
            <MenuItem value="last_30_days">Last 30 Days</MenuItem>
            <MenuItem value="last_90_days">Last 90 Days</MenuItem>
          </Select>
        </Box>

        {/* Group By Field */}
        <Box>
          <Typography variant="body1">Group data by</Typography>
          <Select
            value={groupBy}
            onChange={(e) => setGroupBy(e.target.value)}
            fullWidth
          >
            <MenuItem value="day">Day</MenuItem>
            <MenuItem value="week">Week</MenuItem>
            <MenuItem value="month">Month</MenuItem>
          </Select>
        </Box>



        {/* Frequency Field */}
        <Box>
          <Typography variant="body1">How often should we send it out?</Typography>
          <Select
            value={frequency}
            onChange={(e) => setFrequency(e.target.value)}
            fullWidth
          >
            <MenuItem value="daily">Daily</MenuItem>
            <MenuItem value="weekly">Weekly</MenuItem>
            <MenuItem value="monthly">Monthly</MenuItem>
          </Select>
        </Box>
        {/* Conditional rendering of day checkboxes for Week and Month */}
        {/* Conditional rendering of day checkboxes for Week and Month */}
        {(frequency === 'weekly' || frequency === 'monthly') && (
          <Box>
            <Typography variant="body1">
              {frequency === 'weekly' ? 'Select Days of the Week' : 'Select Days of the Month'}
            </Typography>

            {/* Weekly Days Selection */}
            {frequency === 'weekly' && (
              <FormControl fullWidth>
                <Select
                  multiple
                  value={selectedDays.weekly_days}
                  onChange={(e) => handleDayChange(e, 'weekly')}
                  renderValue={(selected) => (
                    <Stack direction="row" spacing={1}>
                      {selected.map((day) => (
                        <Chip key={day} label={['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][day]} />
                      ))}
                    </Stack>
                  )}
                >
                  {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day, i) => (
                    <MenuItem key={day} value={i}>
                      {day}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {/* Monthly Days Selection */}
            {frequency === 'monthly' && (
              <FormControl fullWidth>

                <Select
                  multiple
                  value={selectedDays.monthly_days}
                  onChange={(e) => handleDayChange(e, 'monthly')}
                  renderValue={(selected) => (
                    <Stack direction="row" spacing={1}>
                      {selected.map((day) => (
                        <Chip key={day} label={`Day ${day}`} />
                      ))}
                    </Stack>
                  )}
                >
                  {[...Array(31).keys()].map((day) => (
                    <MenuItem key={day} value={day + 1}>
                      {`Day ${day + 1}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
        )}

        {/* Send Time Field */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>

          <Typography variant="body1">Choose a send time?</Typography>
          <TimePicker
            value={dayjs(sendTime)} // Pass the dayjs object directly
            onChange={handleScheduleTime}
            ampm={!is24HourFormat}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>


        <Box>
          <Typography variant="body1" gutterBottom>Report Format</Typography>
          <Select
            value={format}
            onChange={(e) => setFormat(e.target.value)}
            fullWidth
          >
            <MenuItem value="PDF">PDF</MenuItem>
            {/* <MenuItem value="PNG" disabled>PNG</MenuItem> */}
          </Select>
        </Box>

        <Divider />

        <Stack direction="row" spacing={2}>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={handleSend}
            loading={isLoading}
          >
            Send
          </LoadingButton>
          {/* <Button color="inherit" onClick={() => console.log('Cancel clicked')}>Cancel</Button> */}
        </Stack>
      </Stack>
    </StyledComponent.StyledStack>
  );
};

export default ScheduledReportView;
