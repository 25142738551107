import { ErrorOutline as ErrorOutlineIcon, ExpandMore as ExpandMoreIcon, AddPhotoAlternateOutlined as AddPhotoAlternateOutlinedIcon } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  useMediaQuery,
  TextField
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CollectionsSharpIcon from '@mui/icons-material/CollectionsSharp';
import { forwardRef, memo, useEffect, useImperativeHandle, useState } from 'react';
import { integrateModuleURL } from 'src/services/urlService';
import TextIconLabel from 'src/components/TextIconLabel';
import { CommonHelper } from 'src/utils/commonHelper';
import ImageDraggable from 'src/components/image/image-draggable';
import PostDescription from '../post-description.component';
import HttpServices from 'src/services/httpService';
import { NewPostStyledComponent } from 'src/pages/new-post-new/newPostStyled.component';
import { CommonFunction } from 'src/utils/commonFunctions/common-function.component';
import { PostUtil } from '../post-util';
import BoardView from './pinterest-board';
import Image from 'src/components/image/Image';
import VideoThumbnail from './youtube-thumbnail';
import PinterestThumbnail from './pinterest-thumbnail';
import { NewPostErrorUtil } from '../new-post-errors-util';

const PinterestConditionComponent = forwardRef(({ updateRequestData }, ref) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const postConfiguration = PostUtil.ServiceConfigurations.PINTEREST.POST;
  //State Variable
  const [isAspectRatioNotSupportedForTheSelectedVideo, setAspectRatioNotSupportedForTheSelectedVideo] = useState(false);
  const [socialMediaRequestData, setSocialMediaRequestData] = useState([]);
  const [requestData, setRequestData] = useState([]);
  const [openPinterestCoverImage, setOpenPinterestCoverImage] = useState(false);
  const [openCoverImage, setOpenCoverImage] = useState({});
  const [manualExpand, setManualExpand] = useState({});

  useEffect(() => {
    checkForErrors();
  }, []);
  useImperativeHandle(ref, () => ({
    checkForErrors,
    handleRequestData,
  }));
  useEffect(() => {
    Object.keys(requestData).forEach((profileId) => {
      let hasErrors = requestData[profileId].some(
        (profile) => profile.errors && profile.errors.length > 0
      );

      if (!hasErrors && manualExpand[profileId] === undefined) {
        setManualExpand((prev) => ({
          ...prev,
          [profileId]: true,
        }));
      }
    });
  }, [requestData]);

  const handleRequestData = (data) => {
    checkForErrors(data, false);
  };
  const updateRequestDataToParent = (data) => {
    updateRequestData(data, PostUtil.ServiceName.PINTEREST);
  };
  const handleChangeSocialMediaRequestData = (data, isUpdateDataToParent = true) => {
    let formattedRequestData = CommonHelper.B2SGroupBy(data, 'connected_profile_id');
    setRequestData({ ...formattedRequestData });
    setSocialMediaRequestData([...data]);
    if (isUpdateDataToParent === true) {
      updateRequestDataToParent(data);
    }
  };
  const checkForErrors = (data = null, isUpdateDataToParent = true) => {
    let sRequestData = data ? [...data] : [...socialMediaRequestData];
    if (sRequestData?.length > 0) {
      sRequestData.map((request) => {
        let errors = getPinterestErrors(request);
        request['errors'] = errors;
      });
      handleChangeSocialMediaRequestData(sRequestData, isUpdateDataToParent);
    } else {
      handleChangeSocialMediaRequestData([], []);
    }
  };
  const handleReOrderFiles = (profileId, postType, oldIndex, newIndex) => {
    let sRequestData = [...socialMediaRequestData];
    let reqData = sRequestData.find((a) => a.connected_profile_id === parseInt(profileId) && a.post_type === postType);
    let files = [...reqData.media_data];
    let mediaFiles = CommonHelper.ReArrangeArray(files, oldIndex, newIndex);
    reqData.media_url = mediaFiles.map((a) => a.media_url).join();
    reqData.media_data = mediaFiles;
    checkForErrors(sRequestData);
    // handleChangeSocialMediaRequestData(sRequestData);
  };
  const handleRemoveFiles = (profileId, postType, mediaUrl) => {
    let sRequestData = [...socialMediaRequestData];
    let rData = sRequestData.find((a) => a.connected_profile_id === parseInt(profileId) && a.post_type === postType);
    let files = [...rData.media_data];
    let index = files.findIndex((a) => a.media_url === mediaUrl);
    if (index >= 0) files.splice(index, 1);
    rData.media_url = files.map((a) => a.media_url).join();
    rData.media_data = files;
    checkForErrors(sRequestData);
    // handleChangeSocialMediaRequestData(sRequestData);
  };
  const handleChangeDescription = (profileId, profile, value) => {
    let sRequestData = [...socialMediaRequestData];
    let connectedProfile = sRequestData.find(
      (a) => a.connected_profile_id === parseInt(profileId) && a.post_type === profile.post_type
    );
    connectedProfile.description = value;
    checkForErrors(sRequestData);
    // handleChangeSocialMediaRequestData(sRequestData);
  };

  const handleTitle = (profileId, profile, value) => {
    let sRequestData = [...socialMediaRequestData];
    let connectedProfile = sRequestData.find(
      (a) => a.connected_profile_id === parseInt(profileId) && a.post_type === profile.post_type
    );
    connectedProfile.title = value;
    checkForErrors(sRequestData);
  };

  const handleLink = (profileId, profile, value) => {
    let sRequestData = [...socialMediaRequestData];
    let connectedProfile = sRequestData.find(
      (a) => a.connected_profile_id === parseInt(profileId) && a.post_type === profile.post_type
    );
    connectedProfile.link = value;
    checkForErrors(sRequestData);
  };

  const handleCoverImagePopup = (profileId, show = false) => {
    setOpenCoverImage((prev) => ({ ...prev, [profileId]: show }));
  };


  const handleChangeCoverImage = (profileId, profile, media_url) => {
    let sRequestData = [...socialMediaRequestData];
    let connectedProfile = sRequestData.find(
      (a) => a.connected_profile_id === parseInt(profileId) && a.post_type === profile.post_type
    );
    connectedProfile.cover_image_url = media_url;
    checkForErrors(sRequestData);
  };

  const handleChangeBoard = (profileId, profile, value) => {
    let sRequestData = [...socialMediaRequestData];
    let connectedProfile = sRequestData.find(
      (a) => a.connected_profile_id === parseInt(profileId) && a.post_type === profile.post_type
    );
    connectedProfile.board_id = value;
    checkForErrors(sRequestData);
  };
  const handlePostChange = (profileId, postType) => {
    let sRequestData = [...socialMediaRequestData];
    let profileCount = sRequestData.filter((a) => a.connected_profile_id === parseInt(profileId))?.length;
    let index = sRequestData.findIndex(
      (a) => a.connected_profile_id === parseInt(profileId) && a.post_type === postType
    );
    if (index >= 0) {
      if (profileCount > 1) {
        sRequestData.splice(index, 1);
      }
    } else {
      let sNetwork = { ...sRequestData.find((a) => a.connected_profile_id === parseInt(profileId)) };
      sNetwork['post_type'] = postType;
      sNetwork['errors'] = getPinterestErrors(sNetwork);
      sRequestData.push(sNetwork);
    }
    checkForErrors(sRequestData);
    // handleChangeSocialMediaRequestData(sRequestData);
  };
  const handleChangeAspectRatio = () => { };

  const isValidUrl = (url) => {
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
      'i'
    );
    return !!urlPattern.test(url);
  };

  const pinterestPostConditions = (request) => {
    return NewPostErrorUtil.PinterestPostConditions(request);
  };

  const getPinterestErrors = (request) => {
    let pErrors = [];
    let postType = request.post_type;
    if (postType === PostUtil.PostType.POST) {
      let postErrors = pinterestPostConditions(request);
      pErrors = pErrors.concat(postErrors);
    }
    return pErrors;
  };
  const handleAccordion = (profileId, hasErrors) => {
    if (hasErrors) return;

    setManualExpand((prev) => ({
      ...prev,
      [profileId]: !prev[profileId],
    }));
    let sRequestData = [...socialMediaRequestData];
    let reqDatas = sRequestData.filter((a) => a.connected_profile_id === parseInt(profileId));
    if (reqDatas?.length > 0) {
      reqDatas.map((data) => {
        data['isExpanded'] = !data['isExpanded'];
      });
    }
    handleChangeSocialMediaRequestData(sRequestData, false);
  };

  // const checkAspectRatioChanges = () => {
  //     return images.some((a) => a.media_type.includes('VIDEO')) && (selectedPostType === PostUtil.PostType.REELS || selectedPostType === PostUtil.PostType.STORY) && (request.changeInstagramAspectRatio || isAspectRatioNotSupportedForTheSelectedVideo);
  // }
  // useImperativeHandle(ref, () => ({
  //     getInstagramErrors,
  // }));
  const errorView = (request) => {
    return (
      <Box style={{ marginTop: '0.5rem' }}>
        <Typography sx={{ fontSize: 16, fontWeight: 800 }}>Error Description</Typography>
        {request.errors.map((errorMessage) => {
          return (
            <TextIconLabel
              icon={<ErrorOutlineIcon sx={{ color: theme.palette.error.main, mr: 1, fontSize: 18 }} />}
              value={errorMessage}
              sx={{ typography: 'caption', color: theme.palette.error.main, mb: 1, fontWeight: 800, fontSize: 14 }}
            />
          );
        })}
      </Box>
    );
  };
  const checkPostTypeChecked = (profileId, postType) => {
    return requestData[profileId].some((a) => a.post_type === postType);
  };

  const POST_TYPES = {
    post: {
      value: 'post',
      icon: <CollectionsSharpIcon sx={{ width: 17, height: 17, mr: 0.5 }} />,
    },
  };


  return (
    <>
      {requestData &&
        Object.keys(requestData)?.length > 0 &&
        Object.keys(requestData).map((profileId) => {
          let isExpanded = socialMediaRequestData.find(
            (a) => a.connected_profile_id === parseInt(profileId)
          )?.isExpanded;

          let hasErrors = requestData[profileId].some((profile) => profile.errors && profile.errors.length > 0);
          let borderColor = hasErrors ? theme.palette.error.main : theme.palette.text.border;
          let connectedProfileLength = requestData[profileId].some(
            (profile) =>
              profile?.connected_profile_details?.connected_profile_name &&
              profile?.connected_profile_details?.connected_profile_name.length > 10
          );
          let paddingTop = connectedProfileLength ? 1 : 0.5;

          return (
            <Accordion
              expanded={hasErrors || manualExpand[profileId] || false}
              onChange={() => { handleAccordion(profileId, hasErrors) }}
              style={{ ...NewPostStyledComponent.AccordionStyle, borderColor }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ ...NewPostStyledComponent.AccordionSummary, paddingTop }}
              >
                <Grid container justifyContent="space-between">
                  <Grid alignItems="center" display="flex">
                    {CommonFunction.AccountBadgeWithName(
                      requestData[profileId].find((profile) => profile.connected_profile_id.toString() === profileId)
                        ?.connected_profile_details
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <FormGroup style={{ display: 'inline' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkPostTypeChecked(profileId, PostUtil.PostType.POST)}
                            onChange={() => handlePostChange(profileId, PostUtil.PostType.POST)}
                          />
                        }
                        label={
                          <NewPostStyledComponent.AccordianLabelStyles>
                            {PostUtil.PostType.POST.toUpperCase()}
                          </NewPostStyledComponent.AccordianLabelStyles>
                        }
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Divider />
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0.5 }}>
                {requestData[profileId].map((profile) => {
                  return (
                    <>
                      <Box
                        style={{
                          ...NewPostStyledComponent.AccordionStyle,
                          borderColor:
                            profile.errors && profile.errors.length > 0
                              ? theme.palette.error.main
                              : theme.palette.background.paper,
                          padding: '0.5rem',
                          marginBottom: '0.5rem',
                        }}
                      >
                        {profile.post_type === POST_TYPES[profile.post_type].value && (
                          <Box
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              color: theme.palette.primary.main,
                              marginTop: 4,
                              fontSize: '0.875rem',
                              fontWeight: 800,
                            }}
                          >
                            {POST_TYPES[profile.post_type].icon}
                            {POST_TYPES[profile.post_type].value.toUpperCase()}
                          </Box>
                        )}
                        <PostDescription
                          handleChangeDescription={(event) => {
                            handleChangeDescription(profileId, profile, event);
                          }}
                          valueDescription={profile.description}
                        />
                        {profile.media_data?.length > 0 && (
                          <Box style={{ marginTop: 3 }}>
                            <ImageDraggable
                              mediaFiles={profile.media_data}
                              handleRemoveFiles={(e) => {
                                handleRemoveFiles(profileId, profile.post_type, e);
                              }}
                              handleReOrderFiles={(e, k) => {
                                handleReOrderFiles(profileId, profile.post_type, e, k);
                              }}
                            />
                          </Box>
                        )}
                        {profile.media_data && profile.media_data[0] && profile.media_data[0]?.media_type === 'VIDEO' && <>
                          <Typography style={{ color: theme.palette.text.secondary, ...theme.typography.subtitle2 }}>
                            Cover Image
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                              flexWrap: 'wrap',
                            }}
                          >
                            {profile?.cover_image_url && profile.media_data?.length > 0 && (
                              <Box
                                sx={{
                                  p: 0,
                                  m: 0.5,
                                  width: 80,
                                  height: 80,
                                  borderRadius: 1.25,
                                  overflow: 'hidden',
                                  position: 'relative',
                                  display: 'inline-flex',
                                  opacity: 1,
                                  border: (theme) => `solid 1px ${theme.palette.divider}`,
                                }}
                              >
                                <Image src={profile.cover_image_url} alt={profile.cover_image_url} ratio="1/1" />
                              </Box>
                            )}

                            {profile.media_data?.length > 0 && (
                              <Box
                                sx={{
                                  p: 0,
                                  m: 0.5,
                                  width: 80,
                                  height: 80,
                                  borderRadius: 1.25,
                                  overflow: 'hidden',
                                  position: 'relative',
                                  display: 'inline-flex',
                                  opacity: 1,
                                  border: (theme) => `solid 1px ${theme.palette.divider}`,
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  flexDirection: 'column',
                                  cursor: 'pointer',
                                }}
                                onClick={() => handleCoverImagePopup(profileId, true)}
                              >
                                <AddPhotoAlternateOutlinedIcon />
                                <Typography variant="caption" sx={{ textAlign: 'center' }}>
                                  {profile?.cover_image_url ? 'Update CoverImage' : 'Upload CoverImage'}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </>
                        }
                        {openCoverImage[profileId] && (
                          <PinterestThumbnail
                            videoSrc={profile.media_data[0]}
                            handleClose={() => handleCoverImagePopup(profileId,false)}
                            handleChangeThumbnail={(coverImageUrl) => handleChangeCoverImage(profileId, profile, coverImageUrl)}
                            serviceName="pinterest"
                          />
                        )}

                        <Typography style={{ color: theme.palette.text.secondary, ...theme.typography.subtitle2, marginBottom: '8px', marginTop: '8px' }}>
                          Title
                        </Typography>
                        <TextField
                          name="title"
                          placeholder="Enter Title (Optional)"
                          fullWidth
                          onChange={(event) => {
                            handleTitle(profileId, profile, event.target.value);
                          }}
                          value={profile.title}
                          style={{ position: 'relative', marginBottom: '8px' }}
                        />
                        <BoardView profileId={profileId} handleChangeBoard={handleChangeBoard} profile={profile} />
                        <Typography style={{ color: theme.palette.text.secondary, ...theme.typography.subtitle2, marginBottom: '8px' }}>
                          Link
                        </Typography>
                        <TextField
                          name="link"
                          placeholder="Enter Link (Optional)"
                          fullWidth
                          onChange={(event) => {
                            handleLink(profileId, profile, event.target.value);
                          }}
                          value={profile.link}
                          style={{ position: 'relative', marginBottom: '8px' }}
                        />
                        {false ? (
                          <>
                            <Typography sx={{ fontSize: 16, fontWeight: 800, mt: 2 }}>
                              Additional Information
                            </Typography>
                            <Grid container sx={{ mt: 2, mb: 3, display: 'flex', alignItems: 'center' }} xs={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={profile.changePinterestAspectRatio}
                                      onChange={(event) => handleChangeAspectRatio(PostUtil.ServiceName.PINTEREST)}
                                    />
                                  }
                                  label="Change Aspect Ratio for video"
                                />
                              </FormGroup>
                            </Grid>
                            {profile.errors?.length > 0 && errorView(profile)}
                          </>
                        ) : (
                          profile.errors && profile.errors.length > 0 && errorView(profile)
                        )}
                      </Box>
                    </>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          );
        })}
    </>
  );
});
export default memo(PinterestConditionComponent);
