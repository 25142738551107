import { ErrorOutline as ErrorOutlineIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Box,
  TextField,
  Typography,
  FormControl,
  useMediaQuery,
  Stack,
  MenuItem,
  Select,
} from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import InsertInvitationRoundedIcon from '@mui/icons-material/InsertInvitationRounded';
import { forwardRef, useEffect, useImperativeHandle, memo, useState } from 'react';
import TextIconLabel from 'src/components/TextIconLabel';
import { CommonHelper } from 'src/utils/commonHelper';
import ImageDraggable from 'src/components/image/image-draggable';
import PostDescription from '../post-description.component';
import { NewPostStyledComponent } from 'src/pages/new-post-new/newPostStyled.component';
import { CommonFunction } from 'src/utils/commonFunctions/common-function.component';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import { PostUtil } from '../post-util';
import { NewPostErrorUtil } from '../new-post-errors-util';
import GetPhoneNumber from '../post-condition/googleMyBusiness-getPhoneNumber';

const GBusinessConditionComponent = forwardRef(({ updateRequestData }, ref) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const postConfiguration = PostUtil.ServiceConfigurations.GBUSINESS.POST;

  //State Variable
  const [isAspectRatioNotSupportedForTheSelectedVideo, setAspectRatioNotSupportedForTheSelectedVideo] = useState(false);
  const [socialMediaRequestData, setSocialMediaRequestData] = useState([]);
  const [requestData, setRequestData] = useState([]);
  const is24HourFormat = CommonHelper.GetBrandInfoFromLocalStorage('is_24_hour_format')
  const [manualExpand, setManualExpand] = useState(true);

  useEffect(() => {
    checkForErrors();
  }, []);
  useImperativeHandle(ref, () => ({
    checkForErrors,
    handleRequestData,
  }));
  const handleRequestData = (data) => {
    checkForErrors(data, false);
  };
  const updateRequestDataToParent = (data) => {
    updateRequestData(data, PostUtil.ServiceName.GBUSINESS);
  };
  const handleChangeSocialMediaRequestData = (data, isUpdateDataToParent = true) => {
    let formattedRequestData = CommonHelper.B2SGroupBy(data, 'connected_profile_id');
    setRequestData({ ...formattedRequestData });
    setSocialMediaRequestData([...data]);
    if (isUpdateDataToParent === true) {
      updateRequestDataToParent(data);
    }
  };
  const checkForErrors = (data = null, isUpdateDataToParent = true) => {
    let sRequestData = data ? [...data] : [...socialMediaRequestData];
    if (sRequestData?.length > 0) {
      sRequestData.map((request) => {
        let errors = getGbusinessErrors(request);
        request['errors'] = errors;
      });
      handleChangeSocialMediaRequestData(sRequestData, isUpdateDataToParent);
    } else {
      handleChangeSocialMediaRequestData([], []);
    }
  };

  const handleReOrderFiles = (profileId, postType, oldIndex, newIndex) => {
    let sRequestData = [...socialMediaRequestData];
    let reqData = sRequestData.find((a) => a.connected_profile_id === parseInt(profileId) && a.post_type === postType);
    let files = [...reqData.media_data];
    let mediaFiles = CommonHelper.ReArrangeArray(files, oldIndex, newIndex);
    reqData.media_url = mediaFiles.map((a) => a.media_url).join();
    reqData.media_data = mediaFiles;
    checkForErrors(sRequestData);
    // handleChangeSocialMediaRequestData(sRequestData);
  };
  const handleRemoveFiles = (profileId, postType, mediaUrl) => {
    let sRequestData = [...socialMediaRequestData];
    let rData = sRequestData.find((a) => a.connected_profile_id === parseInt(profileId) && a.post_type === postType);
    let files = [...rData.media_data];
    let index = files.findIndex((a) => a.media_url === mediaUrl);
    if (index >= 0) files.splice(index, 1);
    rData.media_url = files.map((a) => a.media_url).join();
    rData.media_data = files;
    checkForErrors(sRequestData);
    // handleChangeSocialMediaRequestData(sRequestData);
  };
  const handleChangeDescription = (profileId, profile, value) => {
    let sRequestData = [...socialMediaRequestData];
    let connectedProfile = sRequestData.find(
      (a) => a.connected_profile_id === parseInt(profileId) && a.post_type === profile.post_type
    );
    connectedProfile.description = value;
    checkForErrors(sRequestData);
    // handleChangeSocialMediaRequestData(sRequestData);
  };

  const handleTitle = (profileId, profile, value) => {
    let sRequestData = [...socialMediaRequestData];
    let connectedProfile = sRequestData.find(
      (a) => a.connected_profile_id === parseInt(profileId) && a.post_type === profile.post_type
    );
    connectedProfile.title = value;
    checkForErrors(sRequestData);
  };
  const handleActionChange = (profileId, profile, value) => {
    let sRequestData = [...socialMediaRequestData];
    let connectedProfile = sRequestData.find(
      (a) => a.connected_profile_id === parseInt(profileId) && a.post_type === profile.post_type
    );
    connectedProfile.button_type = value;
    checkForErrors(sRequestData);
  };
  const handleLinkChange = (profileId, profile, value) => {
    let sRequestData = [...socialMediaRequestData];
    let connectedProfile = sRequestData.find(
      (a) => a.connected_profile_id === parseInt(profileId) && a.post_type === profile.post_type
    );
    connectedProfile.url = value;
    checkForErrors(sRequestData);
  };
  const handleEndTimeChange = (profileId, profile, value) => {
    let sRequestData = [...socialMediaRequestData];
    let connectedProfile = sRequestData.find(
      (a) => a.connected_profile_id === parseInt(profileId) && a.post_type === profile.post_type
    );
    connectedProfile.end_time = value;
    checkForErrors(sRequestData);
  };
  const handleEndDateChange = (profileId, profile, value) => {
    let sRequestData = [...socialMediaRequestData];
    let connectedProfile = sRequestData.find(
      (a) => a.connected_profile_id === parseInt(profileId) && a.post_type === profile.post_type
    );
    connectedProfile.end_date = value;
    checkForErrors(sRequestData);
  };
  const handleStartTimeChange = (profileId, profile, value) => {
    let sRequestData = [...socialMediaRequestData];
    let connectedProfile = sRequestData.find(
      (a) => a.connected_profile_id === parseInt(profileId) && a.post_type === profile.post_type
    );
    connectedProfile.start_time = value;
    checkForErrors(sRequestData);
  };
  const handleStartDateChange = (profileId, profile, value) => {
    let sRequestData = [...socialMediaRequestData];
    let connectedProfile = sRequestData.find(
      (a) => a.connected_profile_id === parseInt(profileId) && a.post_type === profile.post_type
    );
    connectedProfile.start_date = value;
    checkForErrors(sRequestData);
  };
  const handleOffercodeChange = (profileId, profile, value) => {
    let sRequestData = [...socialMediaRequestData];
    let connectedProfile = sRequestData.find(
      (a) => a.connected_profile_id === parseInt(profileId) && a.post_type === profile.post_type
    );
    connectedProfile.offer_code = value;
    checkForErrors(sRequestData);
  };
  const handleTermsConditionsChange = (profileId, profile, value) => {
    let sRequestData = [...socialMediaRequestData];
    let connectedProfile = sRequestData.find(
      (a) => a.connected_profile_id === parseInt(profileId) && a.post_type === profile.post_type
    );
    connectedProfile.terms_conditions = value;
    checkForErrors(sRequestData);
  };

  const handlePostChange = (profileId, postType) => {
    let sRequestData = [...socialMediaRequestData];
    let profileCount = sRequestData.filter((a) => a.connected_profile_id === parseInt(profileId))?.length;
    let index = sRequestData.findIndex(
      (a) => a.connected_profile_id === parseInt(profileId) && a.post_type === postType
    );
    if (index >= 0) {
      if (profileCount > 1) {
        sRequestData.splice(index, 1);
      }
    } else {
      let sNetwork = { ...sRequestData.find((a) => a.connected_profile_id === parseInt(profileId)) };
      sNetwork['post_type'] = postType;
      sNetwork['errors'] = getGbusinessErrors(sNetwork);
      sRequestData.push(sNetwork);
    }
    checkForErrors(sRequestData);
    // handleChangeSocialMediaRequestData(sRequestData);
  };
  const handleChangeAspectRatio = () => { };

  const gbusinessStandardConditions = (request) => {
    return NewPostErrorUtil.GbusinessStandardConditions(request);
  };

  const gbusinessEventConditions = (request) => {
    return NewPostErrorUtil.GbusinessEventConditions(request);
  };

  const gbusinessOfferConditions = (request) => {
    return NewPostErrorUtil.GbusinessOfferConditions(request);
  };

  const getGbusinessErrors = (request) => {
    let gErrors = [];
    let postType = request.post_type;

    if (postType === PostUtil.PostType.STANDARD) {
      let standardErrors = gbusinessStandardConditions(request);
      gErrors = gErrors.concat(standardErrors);
    } else if (postType === PostUtil.PostType.EVENT) {
      let eventErrors = gbusinessEventConditions(request);
      gErrors = gErrors.concat(eventErrors);
    } else if (postType === PostUtil.PostType.OFFER) {
      let offerErrors = gbusinessOfferConditions(request);
      gErrors = gErrors.concat(offerErrors);
    }
    return gErrors;
  };
  const handleAccordion = (profileId) => {
    setManualExpand((prev) => !prev);
    let sRequestData = [...socialMediaRequestData];
    let reqDatas = sRequestData.filter((a) => a.connected_profile_id === parseInt(profileId));
    if (reqDatas?.length > 0) {
      reqDatas.map((data) => {
        data['isExpanded'] = !data['isExpanded'];
      });
    }
    handleChangeSocialMediaRequestData(sRequestData, false);
  };

  const errorView = (request) => {
    return (
      <Box style={{ marginTop: '0.5rem' }}>
        <Typography sx={{ fontSize: 16, fontWeight: 800 }}>Error Description</Typography>
        {request.errors.map((errorMessage) => {
          return (
            <TextIconLabel
              icon={<ErrorOutlineIcon sx={{ color: theme.palette.error.main, mr: 1, fontSize: 18 }} />}
              value={errorMessage}
              sx={{ typography: 'caption', color: theme.palette.error.main, mb: 1, fontWeight: 800, fontSize: 14 }}
            />
          );
        })}
      </Box>
    );
  };
  const checkPostTypeChecked = (profileId, postType) => {
    return requestData[profileId].some((a) => a.post_type === postType);
  };

  const POST_TYPES = {
    STANDARD: {
      value: 'STANDARD',
      icon: <NewReleasesOutlinedIcon sx={{ width: 17, height: 17, mr: 0.5 }} />,
    },
    EVENT: {
      value: 'EVENT',
      icon: <InsertInvitationRoundedIcon sx={{ width: 17, height: 17, mr: 0.5 }} />,
    },
    OFFER: {
      value: 'OFFER',
      icon: <LocalOfferOutlinedIcon sx={{ width: 17, height: 17, mr: 0.5 }} />,
    },
  };

  const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
    '& .MuiInputBase-root': {
      mt: 2,
      width: '10rem',
      height: '40px',
      fontSize: '0.875rem',
    },
    '& .MuiInputLabel-root': {
      fontSize: '0.875rem',
    },
    '& .MuiInputBase-input': {
      padding: '8px 12px',
    },
  }));

  const StyledTimePicker = styled(TimePicker)(({ theme }) => ({
    '& .MuiInputBase-root': {
      width: '10rem',
      height: '40px',
      fontSize: '0.875rem',
    },
    '& .MuiInputLabel-root': {
      fontSize: '0.875rem',
    },
    '& .MuiInputBase-input': {
      padding: '8px 12px',
    },
  }));

  return (
    <>
      {requestData &&
        Object.keys(requestData)?.length > 0 &&
        Object.keys(requestData).map((profileId) => {
          let isExpanded = socialMediaRequestData.find(
            (a) => a.connected_profile_id === parseInt(profileId)
          )?.isExpanded;

          let hasErrors = requestData[profileId].some((profile) => profile.errors && profile.errors.length > 0);
          let borderColor = hasErrors ? theme.palette.error.main : theme.palette.text.border;
          let connectedProfileLength = requestData[profileId].some((profile) => profile?.connected_profile_details?.connected_profile_name && profile?.connected_profile_details?.connected_profile_name.length > 10);
          let paddingTop = connectedProfileLength ? 1 : 0.5;

          return (
            <Accordion
              expanded={manualExpand || hasErrors}
              onChange={() => {
                handleAccordion(profileId);
              }}
              style={{ ...NewPostStyledComponent.AccordionStyle, borderColor }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ ...NewPostStyledComponent.AccordionSummary, paddingTop }}>
                <Grid container justifyContent="space-between">
                  <Grid item alignItems="center" display="flex" xs={12}>
                    {CommonFunction.MobileViewAccountBadgeWithName(
                      requestData[profileId].find((profile) => profile.connected_profile_id.toString() === profileId)
                        ?.connected_profile_details
                    )}
                  </Grid>
                  <FormGroup>
                    <Grid sx={{ display: 'flex', flexDirection: 'row' }} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkPostTypeChecked(profileId, PostUtil.PostType.STANDARD)}
                            onChange={() => handlePostChange(profileId, PostUtil.PostType.STANDARD)}
                          />
                        }
                        label={
                          <NewPostStyledComponent.AccordianLabelStyles>
                            {PostUtil.PostType.STANDARD}
                          </NewPostStyledComponent.AccordianLabelStyles>
                        }

                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkPostTypeChecked(profileId, PostUtil.PostType.OFFER)}
                            onChange={() => handlePostChange(profileId, PostUtil.PostType.OFFER)}
                            sx={{ p: 0.2 }}
                          />
                        }
                        label={
                          <NewPostStyledComponent.AccordianLabelStyles>
                            {PostUtil.PostType.OFFER}
                          </NewPostStyledComponent.AccordianLabelStyles>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkPostTypeChecked(profileId, PostUtil.PostType.EVENT)}
                            onChange={() => handlePostChange(profileId, PostUtil.PostType.EVENT)}
                            sx={{ p: 0.2 }}
                          />
                        }
                        label={
                          <NewPostStyledComponent.AccordianLabelStyles>
                            {PostUtil.PostType.EVENT}
                          </NewPostStyledComponent.AccordianLabelStyles>
                        }
                      />
                    </Grid>
                  </FormGroup>
                </Grid>
                <Divider />
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0.5 }}>
                <>
                  {requestData[profileId].map((profile) => {
                    return (
                      <>
                        <Box
                          style={{
                            ...NewPostStyledComponent.AccordionStyle,
                            borderColor: profile.errors && profile.errors.length > 0 ? theme.palette.error.main : theme.palette.background.paper,
                            padding: '0.5rem',
                            marginBottom: '0.5rem',
                          }}
                        >
                          {profile.post_type === POST_TYPES[profile.post_type].value && (
                            <Box
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                color: theme.palette.primary.main,
                                marginTop: 4,
                                fontSize: '0.875rem',
                                fontWeight: 800,
                              }}
                            >
                              {POST_TYPES[profile.post_type].icon}
                              {POST_TYPES[profile.post_type].value.toUpperCase()}
                            </Box>
                          )}
                          {profile.post_type === PostUtil.PostType.EVENT && (
                            <>
                              <NewPostStyledComponent.LabelStyle>Event Title</NewPostStyledComponent.LabelStyle>
                              <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
                                <TextField
                                  name="eventTitle"
                                  placeholder="Enter Title content"
                                  fullWidth
                                  value={profile.Title}
                                  onChange={(event) => {
                                    handleTitle(profileId, profile, event.target.value);
                                  }}
                                />
                              </Stack>
                            </>
                          )}
                          <PostDescription
                            handleChangeDescription={(event) => {
                              handleChangeDescription(profileId, profile, event);
                            }}
                            valueDescription={profile.description}
                          />
                          {profile.media_data?.length > 0 && (
                            <Box style={{ marginTop: 3 }}>
                              <ImageDraggable
                                mediaFiles={profile.media_data}
                                handleRemoveFiles={(e) => {
                                  handleRemoveFiles(profileId, profile.post_type, e);
                                }}
                                handleReOrderFiles={(e, k) => {
                                  handleReOrderFiles(profileId, profile.post_type, e, k);
                                }}
                              />
                            </Box>
                          )}
                          {(profile.post_type === PostUtil.PostType.STANDARD ||
                            profile.post_type === PostUtil.PostType.EVENT) && (
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'flex-start',
                                  mt: 2,
                                }}
                              >
                                <Typography sx={{ fontSize: 16, fontWeight: 800, marginRight: 2 }}>
                                  Button (optional)
                                </Typography>
                                <GetPhoneNumber profileId={profileId} profile={profile} handleActionChange={handleActionChange} />
                                {/* <FormControl sx={{ minWidth: 120 }}>
                                  <Select
                                    value={profile.button_type}
                                    onChange={(event) => {
                                      handleActionChange(profileId, profile, event.target.value);
                                    }}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    size="small"
                                  >
                                    <MenuItem value={'None'}>None</MenuItem>
                                    <MenuItem value={'Book'}>Book</MenuItem>
                                    <MenuItem value={'Order Online'}>Order Online</MenuItem>
                                    <MenuItem value={'Buy'}>Buy</MenuItem>
                                    <MenuItem value={'Learn More'}>Learn More</MenuItem>
                                    <MenuItem value={'Sign Up'}>Sign Up</MenuItem>
                                  </Select>
                                </FormControl> */}
                              </Box>
                            )}

                          {(profile.post_type === PostUtil.PostType.STANDARD ||
                            profile.post_type === PostUtil.PostType.EVENT) &&
                            profile.button_type !== 'None' && (
                              <>
                                <Box spacing={2} sx={{ display: 'flex', alignItems: 'center', mt: 2 }} xs={12}>
                                  <Typography sx={{ fontSize: 16, fontWeight: 800, mr: 2 }}>Button Link</Typography>
                                  <TextField
                                    name="Buttonurl"
                                    placeholder="Enter URL"
                                    value={profile.url}
                                    onChange={(event) => {
                                      handleLinkChange(profileId, profile, event.target.value);
                                    }}
                                    size="small"
                                  />
                                </Box>
                              </>
                            )}
                          {(profile.post_type === PostUtil.PostType.OFFER ||
                            profile.post_type === PostUtil.PostType.EVENT) && (
                              <>
                                <Box sx={{ mt: 2 }}>
                                  <Typography sx={{ fontSize: 16, fontWeight: 800 }}>Start Date & Time</Typography>
                                  <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ mt: 2 }}>
                                    <DemoContainer components={['DatePicker']} sx={{ mt: 1 }}>
                                      <StyledDatePicker
                                        label="Start Date"
                                        format="LL"
                                        value={dayjs(profile.start_date)}
                                        onChange={(event) => {
                                          handleStartDateChange(profileId, profile, event);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                      />
                                      <StyledTimePicker
                                        label="Start Time"
                                        value={dayjs(profile.start_time)}
                                        ampm={!is24HourFormat}
                                        onChange={(event) => {
                                          handleStartTimeChange(profileId, profile, event);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                      />
                                    </DemoContainer>
                                  </LocalizationProvider>
                                </Box>
                                <Box sx={{ mt: 2 }}>
                                  <Typography sx={{ fontSize: 16, fontWeight: 800 }}>End Date & Time</Typography>
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']} sx={{ mt: 1 }}>
                                      <StyledDatePicker
                                        label="End Date"
                                        format="LL"
                                        value={dayjs(profile.end_date)}
                                        onChange={(event) => {
                                          handleEndDateChange(profileId, profile, event);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                      />
                                      <StyledTimePicker
                                        label="End Time"
                                        value={dayjs(profile.end_time)}
                                        ampm={!is24HourFormat}
                                        onChange={(event) => {
                                          handleEndTimeChange(profileId, profile, event);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                      />
                                    </DemoContainer>
                                  </LocalizationProvider>
                                </Box>
                              </>
                            )}
                          {profile.post_type === PostUtil.PostType.OFFER && (
                            <Grid container sx={{ mt: 2, display: 'flex', alignItems: 'center' }} xs={12}>
                              <Grid item xs={12}>
                                <NewPostStyledComponent.LabelStyle>Offer Title</NewPostStyledComponent.LabelStyle>
                                <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
                                  <TextField
                                    name="offerTitle"
                                    placeholder="title"
                                    fullWidth
                                    value={profile.title}
                                    onChange={(event) => {
                                      handleTitle(profileId, profile, event.target.value);
                                    }}
                                  />
                                </Stack>
                                <Typography sx={{ fontSize: 16, fontWeight: 800, mt: 2 }}>
                                  Additional Detials (Optional)
                                </Typography>
                                <>
                                  <NewPostStyledComponent.LabelStyle>Coupon Code</NewPostStyledComponent.LabelStyle>
                                  <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
                                    <TextField
                                      name="youtubeTitle"
                                      placeholder="Enter Tite Content"
                                      fullWidth
                                      value={profile.offer_code}
                                      onChange={(event) => {
                                        handleOffercodeChange(profileId, profile, event.target.value);
                                      }}
                                    />
                                  </Stack>
                                  <NewPostStyledComponent.LabelStyle>Offer Link</NewPostStyledComponent.LabelStyle>
                                  <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
                                    <TextField
                                      name="offerlink"
                                      placeholder="eg: https://brand2social.com"
                                      fullWidth
                                      value={profile.url}
                                      onChange={(event) => {
                                        handleLinkChange(profileId, profile, event.target.value);
                                      }}
                                    />
                                  </Stack>
                                  <NewPostStyledComponent.LabelStyle>
                                    Terms & Conditions
                                  </NewPostStyledComponent.LabelStyle>
                                  <Stack direction="row" alignItems="center" >
                                    <TextField
                                      name="youtubeTitle"
                                      placeholder="Enter Tite Content"
                                      fullWidth
                                      value={profile.terms_conditions}
                                      onChange={(event) => {
                                        handleTermsConditionsChange(profileId, profile, event.target.value);
                                      }}
                                    />
                                  </Stack>
                                </>
                              </Grid>
                            </Grid>
                          )}
                          {false ? (
                            <>
                              <Typography sx={{ fontSize: 16, fontWeight: 800, mt: 2 }}>
                                Additional Information{' '}
                              </Typography>
                              <Grid container sx={{ mt: 2, mb: 3, display: 'flex', alignItems: 'center' }} xs={12}>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={profile.changeGbusinessAspectRatio}
                                        onChange={(event) => handleChangeAspectRatio(PostUtil.ServiceName.GBUSINESS)}
                                      />
                                    }
                                    label="Change Aspect Ratio for video"
                                  />
                                </FormGroup>
                              </Grid>
                              {profile.errors?.length > 0 && errorView(profile)}
                            </>
                          ) : (
                            profile.errors &&
                            profile.errors.length > 0 && (
                              errorView(profile)
                            )
                          )}
                        </Box>
                      </>
                    );
                  })}
                </>
              </AccordionDetails>
            </Accordion>
          );
        })}
    </>
  );
});
export default memo(GBusinessConditionComponent);
