import { Box, Card, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Page from 'src/components/Page';
import PaymentResponse from 'src/components/stripe/paymentResponse';
import PlanPaymentComponent from 'src/components/stripe/planPaymentComponent';
import PricingPlanCard from 'src/pages/payment/pricing.component';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import { useToaster } from 'src/utils/toaster/toasterContext';
import PlanUpgrade from '../../../assets/app-image/plan-upgrade.svg';
import PlanBillingComponent from './plan-billing.component';

const PlanInfoComponent = () => {
  
  const ACCOUNT_TABS = [
    {
      value: 30,
      label: 'MONTHLY',
    },
    {
      value: 365,
      label: 'YEARLY',
    },
  ];

  const { showToast } = useToaster();
  const location = useLocation();

  const orgDetails = CommonHelper.GetOrgDetails();
  const [planType, setPlanType] = useState(30);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isPaymentDialogOpen, setPaymentDialog] = useState(false);
  const [pricingPlan, setPricingPlan] = useState([]);
  const [planAddonDetials, setPlanAddonDetials] = useState([]);
  const [isPaymentResponseDialogOpen, setPaymentDialogOpen] = useState(false);
  const [upgradeItems, setUpgradeItems] = useState();
  const [totalAmount, setTotalAmount] = useState(0);
  const [expiresOn, setPlanExpiresOn] = useState('');
  const [newExpiresOn, setNewExpiresOn] = useState('');
  const [height, setHeight] = useState(window.innerHeight - 150);
  const [timeZone, setTimeZone] = useState(CommonHelper.GetLocalTimeZone());
  const [currentPlanDetails, setCurrentPlanDetails] = useState(null);

  useEffect(() => {
    getCurrentSubscription();
    getPlanList();
    //Need to check the below code - if it is needed or not
    if (location && location.state !== null) {
      setPaymentDialogOpen(true);
      setTimeout(() => {
        clearLocationState();
      }, 2000);
    }
  }, []);

  const getPlanList = () => {
    let getProps = {
      url: userModuleURL.getAllPlan + timeZone,
      successCallback: planSuccessCallback,
      failureCallback,
    };
    HttpServices.Get(getProps);
  };

  const getCurrentSubscriptionSuccessCallback = (data, message) => {
    setCurrentPlanDetails(data);
  };

  const getCurrentSubscription = () => {
    const getProps = {
      url: userModuleURL.getCurrentSubscription,
      successCallback: getCurrentSubscriptionSuccessCallback,
      failureCallback,
    };
    HttpServices.Get(getProps);
  };

  const clearLocationState = () => {
    window.history.replaceState({}, '');
  };

  const planSuccessCallback = (data, message) => {
    setPricingPlan(data.paid_plan_detials);
    setPlanAddonDetials(data.plan_addon_detials);
    // getCurrentPlan(data.paid_plan_detials);
  };
  const failureCallback = (message) => {
    showToast(message, 'e');
  };

  // const getCurrentPlan = (data) => {
  //   const planDetails = CommonHelper.GetBrandInfoFromLocalStorage('current_plan_info');
  //   let plan = data.find((a) => a.id === planDetails.id);
  //   if (selectedPlan?.id) {
  //     plan = data.find((a) => a.id === selectedPlan.id);
  //   }
  //   setSelectedPlan(plan);
  // };

  const handlePlan = (id) => {
    const plan = pricingPlan.find((a) => a.id === id);
    setSelectedPlan(plan);
    getCurrentPlanPrice(id, upgradeItems);
  };

  const successCallback = (data) => {
    setTotalAmount(data.amount);
    setPlanExpiresOn(data.expire_date);
    setNewExpiresOn(data.new_expire_date);
    // setActiveSubscription(data.has_active_subscription);
  };

  const handleUpdate = (data) => {
    if (selectedPlan) {
      getCurrentPlanPrice(selectedPlan.id, data);
      setUpgradeItems(data);
    }
  };

  const getCurrentPlanPrice = (id, upgradeItems) => {
    const body = {
      new_plan_id: id,
      add_ons: upgradeItems,
    };
    HttpServices.Post({
      url: userModuleURL.changePlanPrice,
      body,
      successCallback,
      failureCallback,
    });
  };

  const handlePaymentDialog = (show) => {
    setPaymentDialog(show);
  };

  const handleInputChange = (event, newValue) => {
    setPlanType(newValue);
    // if (event?.target?.name === 'planType') {
    //   setPlanType(newValue);
    // } else {
    //   const { name, value } = event.target;
    //   switch (name) {
    //     case 'billingName':
    //       setBillingName(value);
    //       break;
    //     case 'billingAddress':
    //       setBillingAddress(value);
    //       break;
    //     default:
    //       break;
    //   }
    // }
  };

  const handleClosePaymentResponse = () => {
    setPaymentDialogOpen(false);
    getPlanList();
  };

  const stripeProps = {
    planId: selectedPlan?.id,
    amount: totalAmount,
    upgradeItems: upgradeItems,
    currencySymbol: orgDetails.currency_symbol,
    handlePaymentDialog: handlePaymentDialog,
  };

  const paymentResponseProps = {
    paymentIntent: location?.state?.paymentIntent,
    paymentIntentClientSecret: location?.state?.paymentIntentClientSecret,
    handleClosePaymentResponse,
  };

  const planBillingProps = {
    expiresOn,
    orgDetails: orgDetails.currency_symbol,
    totalAmount,
    newExpiresOn,
    // handleInputChange,
    handlePaymentDialog,
  };

  const pricingPlanProps = {
    selectedPlan,
    handlePlan,
    handleUpdate,
    planAddonDetials,
    currentPlanDetails,
  };

  return (
    <Page title="Pricing Plan">
      <Card sx={{ pt: 3, height: height, overflow: 'auto' }}>
        <Box sx={{ textAlign: 'center' }}>
          <Stack alignItems="center" justifyContent="center">
            <Tabs name="planType" value={planType} onChange={handleInputChange}>
              {ACCOUNT_TABS.map((tab) => (
                <Tab
                  name="planType"
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                  sx={{ flexDirection: 'row', minHeight: '10px', mr: '0.50rem', fontWeight: 600 }}
                />
              ))}
            </Tabs>
          </Stack>
        </Box>
        <Grid container md={12} sx={{ mt: 2 }}>
          <Grid container md={8} sx={{ mt: 2 }}>
            {pricingPlan.length > 0 &&
              pricingPlan
                .filter((a) => a.plan_validity === planType)
                .map((plan) => (
                  <Grid item xs={12} md={6} key={plan.id} sx={{ display: 'flex' }}>
                    <PricingPlanCard {...plan} {...pricingPlanProps} />
                  </Grid>
                ))}
          </Grid>
          <Grid container md={4} sx={{ mt: 2 }}>
            {selectedPlan?.price > 0 ? (
              <PlanBillingComponent {...planBillingProps} />
            ) : (
              <Grid item xs={12} md={12} sx={{ display: 'flex' }}>
                <Card sx={{ p: 3, maxWidth: 400, width: '100%', mt: 1, mb: 1, flex: 1 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Stack sx={{ width: '100%', alignItems: 'center' }}>
                      <img src={PlanUpgrade} alt="Plan Upgrade" />
                      <Typography color="text.secondary" variant="overline" display="block" sx={{ mt: 3 }}>
                        Currently you are in
                      </Typography>
                      <Typography
                        color="text.secondary"
                        variant="overline"
                        display="block"
                        sx={{ mt: 1, fontSize: 22 }}
                      >
                        {/* Uncomment and add plan name */}
                        {currentPlanDetails?.has_subscription
                          ? `${currentPlanDetails?.paid_plan_name ? currentPlanDetails?.paid_plan_name : ''} Plan`
                          : 'Free Plan'}
                      </Typography>
                    </Stack>
                  </Box>
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Card>
      {isPaymentDialogOpen && <PlanPaymentComponent {...stripeProps} />}
      {isPaymentResponseDialogOpen && <PaymentResponse {...paymentResponseProps} />}
    </Page>
  );
};

export default PlanInfoComponent;
