import { LoadingButton } from '@mui/lab';
import { Button, DialogTitle, CardContent, ListItemIcon, Paper } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';


const PinterestAuthorizationModal = (props) => {
    const [isLoadingOn, setLoadingOn] = useState(false);

    const handleConnectPinterest = () => {
        setLoadingOn(true);
        props.handleConnectPinterest();
    };

    const handleClose = () => {
        props.handleClose();
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open>
             <DialogTitle id="customized-dialog-title">
                <Typography gutterBottom variant="h4">
                    {props.reAuth 
                        ? "Reauthenticate Pinterest Account Connection" 
                        : "Have you converted your Pinterest account to a Business Profile?"}
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <CardContent sx={{ p: { xs: 0, md: 0 }, color: 'grey.800' }}>
                    <Typography variant="body1" sx={{ mx: 'auto', fontWeight: 'bold' }}>
                        {props.reAuth 
                            ? "Verify these requirements to reconnect:" 
                            : "Make sure you've completed these steps before continuing:"}
                    </Typography>

                    <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1, mt: 2 }}>
                        <ListItemIcon>
                            <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
                        </ListItemIcon>
                        <Typography variant="body1">
                            {props.reAuth 
                                ? "Confirm you're still using the original login method (Google/Facebook/Email)"
                                : "Ensure you're signed in with Google, Facebook, or email"}
                        </Typography>
                    </Paper>

                    <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
                        <ListItemIcon>
                            <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
                        </ListItemIcon>
                        <Typography variant="body1">
                            {props.reAuth 
                                ? "Verify your account type (Business/Personal) remains the same"
                                : "Confirm your account type (Business or Personal Profile)"}
                        </Typography>
                    </Paper>

                    {!props.reAuth && (
                        <>
                            <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
                                <ListItemIcon>
                                    <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
                                </ListItemIcon>
                                <Typography variant="body1">
                                    This app maintains data privacy and security standards
                                </Typography>
                            </Paper>

                            <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
                                <ListItemIcon>
                                    <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
                                </ListItemIcon>
                                <Typography variant="body1">
                                    Respect copyright and privacy rights
                                </Typography>
                            </Paper>
                        </>
                    )}

                    <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
                        <ListItemIcon>
                            <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
                        </ListItemIcon>
                        <Typography variant="body1">
                            {props.reAuth 
                                ? "Ensure your account permissions are still valid"
                                : "Maintain valid account permissions for integration"}
                        </Typography>
                    </Paper>

                </CardContent>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color='error' onClick={handleClose}>Close</Button>
                <LoadingButton
                    variant='contained'
                    loading={isLoadingOn}
                    autoFocus
                    onClick={() => {
                        handleConnectPinterest();
                    }}
                >
                    {props.reAuth ? "Re-Authenticate" : "Connect"}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
export default PinterestAuthorizationModal;
