import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import PreviewComponent from './preview/previewComponent';
import { Box, Typography, Stack, useTheme } from '@mui/material';
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';

const PostPreviewComponent = forwardRef(({}, ref) => {
  const [postForm, setPostForm] = useState(null);
  const theme = useTheme();

  const clearServiceErrors = (serviceData) => {
    return serviceData.filter((data) => {
      return data?.errors?.length == 0;
    });
  };

  const filterErrorData = (newFormData) => {
    const updatedData = { ...newFormData };
    const { scheduled_event_requests_data } = updatedData;

    if (scheduled_event_requests_data.facebook_requests_data.length > 0) {
      scheduled_event_requests_data.facebook_requests_data = clearServiceErrors(
        scheduled_event_requests_data.facebook_requests_data
      );
    }
    if (scheduled_event_requests_data.instagram_requests_data.length > 0) {
      scheduled_event_requests_data.instagram_requests_data = clearServiceErrors(
        scheduled_event_requests_data.instagram_requests_data
      );
    }
    if (scheduled_event_requests_data.twitter_requests_data.length > 0) {
      scheduled_event_requests_data.twitter_requests_data = clearServiceErrors(
        scheduled_event_requests_data.twitter_requests_data
      );
    }
    if (scheduled_event_requests_data.youtube_requests_data.length > 0) {
      scheduled_event_requests_data.youtube_requests_data = clearServiceErrors(
        scheduled_event_requests_data.youtube_requests_data
      );
    }
    if (scheduled_event_requests_data.linkedin_requests_data.length > 0) {
      scheduled_event_requests_data.linkedin_requests_data = clearServiceErrors(
        scheduled_event_requests_data.linkedin_requests_data
      );
    }
    if (scheduled_event_requests_data.gbusiness_requests_data.length > 0) {
      scheduled_event_requests_data.gbusiness_requests_data = clearServiceErrors(
        scheduled_event_requests_data.gbusiness_requests_data
      );
    }
    if (scheduled_event_requests_data.pinterest_requests_data.length > 0) {
      scheduled_event_requests_data.pinterest_requests_data = clearServiceErrors(
        scheduled_event_requests_data.pinterest_requests_data
      );
    }
    if (scheduled_event_requests_data.tiktok_requests_data.length > 0) {
      scheduled_event_requests_data.tiktok_requests_data = clearServiceErrors(
        scheduled_event_requests_data.tiktok_requests_data
      );
    }
    return updatedData;
  };

  const hasNoValidData = (data) => {
    const { scheduled_event_requests_data } = data;
    return (
      scheduled_event_requests_data.facebook_requests_data.length === 0 &&
      scheduled_event_requests_data.instagram_requests_data.length === 0 &&
      scheduled_event_requests_data.twitter_requests_data.length === 0 &&
      scheduled_event_requests_data.youtube_requests_data.length === 0 &&
      scheduled_event_requests_data.linkedin_requests_data.length === 0 &&
      scheduled_event_requests_data.gbusiness_requests_data.length === 0 &&
      scheduled_event_requests_data.pinterest_requests_data.length === 0 &&
      scheduled_event_requests_data.tiktok_requests_data.length === 0
    );
  };

  const handlePreviewData = (data) => {
    let fErrorData = filterErrorData({ ...data });
    setPostForm(fErrorData);
  };

  useImperativeHandle(ref, () => ({
    handlePreviewData,
  }));

  if (!postForm) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh',
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 1,
          p: 3,
        }}
      >
        <Stack direction="column" alignItems="center" spacing={2}>
          <ErrorOutlineIcon sx={{ fontSize: 48, color: 'text.secondary' }} />
          <Typography variant="h6" color="text.secondary">
            No Data Available
          </Typography>
          <Typography variant="body2" color="text.secondary">
            It looks like there’s nothing to display at the moment.
          </Typography>
        </Stack>
      </Box>
    );
  }

  if (hasNoValidData(postForm)) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign:'center',
          height: '50vh',
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 1,
          p:3,
        }}
      >
        <Stack direction="column" alignItems="center" spacing={2}>
          <ErrorOutlineIcon sx={{ fontSize: 48, color: 'text.secondary' }} />
          <Typography variant="h6" color="text.secondary">
            No Data Available
          </Typography>
          <Typography variant="body2" color="text.secondary" >
            Some errors were found in your posts. Go to the{' '}
            <span
              style={{
                cursor: 'pointer',
                color: theme.palette.primary.main,
                marginLeft: '2px',
                marginRight: '2px',
                fontWeight: 500,
              }}
            >
              Conditions
            </span>{' '}
            tab to fix them.
          </Typography>
        </Stack>
      </Box>
    );
  }

  return <PreviewComponent newPostForm={postForm} />;
});
export default PostPreviewComponent;
