import { LoadingButton } from '@mui/lab';
import { Button, DialogTitle, CardContent, Link, ListItemIcon, Paper, Box, useMediaQuery } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { YouTube as YouTubeIcon } from '@mui/icons-material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import TextIconLabel from 'src/components/TextIconLabel';
import ErrorIcon from '@mui/icons-material/Error';
const InstagramAuthorizationModal = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoadingOn, setLoadingOn] = useState(false);

  const handleConnectInstagram = () => {
    setLoadingOn(true);
    props.handleConnectInstagram();
  };

  const handleClose = () => {
    props.handleClose();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography gutterBottom variant="h4">
          {props.reAuth
            ? 'Reauthenticate Instagram Business Profile Connection'
            : 'Have you converted your Instagram account to a Business Profile ?'}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        {isMobile ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              padding: '20px',
            }}
          >
            {/* First line: User Guide */}
            <Typography sx={{ fontSize: '14px', color: 'gray', marginBottom: '10px' }}>
              Read our
              <Link
                href="https://brand2social.com/user-guides/setting-up-instagram-on-brand2social/"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  color: 'blue',
                  textDecoration: 'none',
                  fontWeight: 'bold',
                  marginLeft: '5px',
                  marginRight: '5px',
                }}
              >
                User Guide
              </Link>
              for step-by-step instructions to link a Facebook page to Instagram.
            </Typography>

            <Typography sx={{ fontSize: '14px', color: 'gray' }}>
              Watch the video to learn how to link a Facebook page to Instagram.
            </Typography>

            <Button
              variant="outlined"
              startIcon={<YouTubeIcon />}
              onClick={() => window.open('https://www.youtube.com/watch?v=ER3Il2nm_W8', '_blank')}
              color="primary"
            >
              Tutorial
            </Button>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              padding: '20px',
            }}
          >
            {/* First line: User Guide */}
            <Typography sx={{ fontSize: '14px', color: 'gray', marginBottom: '10px' }}>
              Read our
              <Link
                href="https://brand2social.com/user-guides/setting-up-instagram-on-brand2social/"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  color: 'blue',
                  textDecoration: 'none',
                  fontWeight: 'bold',
                  marginLeft: '5px',
                  marginRight: '5px',
                }}
              >
                User Guide
              </Link>
              for step-by-step instructions to link a Facebook page to Instagram.
            </Typography>

            {/* Second line: Video tutorial */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Typography sx={{ fontSize: '14px', color: 'gray' }}>
                Watch the video to learn how to link a Facebook page to Instagram.
              </Typography>
              <Button
                variant="outlined"
                startIcon={<YouTubeIcon />}
                onClick={() => window.open('https://www.youtube.com/watch?v=ER3Il2nm_W8', '_blank')}
                color="primary"
              >
                Tutorial
              </Button>
            </Box>
          </Box>
        )}
        <CardContent sx={{ p: { xs: 0, md: 0 }, color: 'grey.800', typography: 'body1' }}>
          <Typography variant="body1" sx={{ margin: 'auto 0', fontWeight: 'bold' }}>
            {props.reAuth
              ? 'Verify these requirements to reconnect your account:'
              : "Make sure you've completed these steps before continuing:"}
          </Typography>

          <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1, mt: 2 }}>
            <ListItemIcon>
              <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
            </ListItemIcon>
            <Typography variant="body1">
              {props.reAuth
                ? 'Your Instagram profile remains a Business Profile with valid permissions'
                : "You've converted your profile to a Business Profile in the Instagram application"}
            </Typography>
          </Paper>
          <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
            <ListItemIcon>
              <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
            </ListItemIcon>
            <Typography variant="body1">
              {props.reAuth
                ? 'You still have Admin permissions on the connected Facebook Page'
                : "You're an Admin on your Facebook Page"}
            </Typography>
          </Paper>
          <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
            <ListItemIcon>
              <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
            </ListItemIcon>
            <Typography variant="body1">
              {props.reAuth
                ? 'Your Facebook Page connection with Instagram Business account is still valid'
                : "You're signing in to the Facebook profile with Admin permissions for the connected Page"}
            </Typography>
          </Paper>
          <Paper sx={{ display: 'flex', alignItems: 'flex-start' }}>
            <ListItemIcon>
              <ErrorIcon sx={{ color: 'secondary.main', width: 20, height: 20, ml: 2, mt: 0.5 }} />
            </ListItemIcon>

            <Typography variant="body1">
              {props.reAuth ? 'Review connection requirements for ' : 'Learn how to connect an '}
              <strong>Instagram account</strong> with a Facebook Page
              <Link
                href="https://brand2social.com/user-guides/setting-up-instagram-on-brand2social/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>{props.reAuth ? ' refresh guide' : ' click here'}</strong>
              </Link>
            </Typography>
          </Paper>
        </CardContent>
      </DialogContent>
      <DialogActions>
        <Button color="error" variant="outlined" onClick={handleClose}>
          Close
        </Button>
        <LoadingButton
          variant="contained"
          loading={isLoadingOn}
          autoFocus
          onClick={() => {
            handleConnectInstagram();
          }}
        >
          {props.reAuth ? 'Re-Authenticate' : 'Connect'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default InstagramAuthorizationModal;
