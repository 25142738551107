import { LoadingButton } from '@mui/lab';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Button, DialogTitle, CardContent, TextField, IconButton, Box, useMediaQuery } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { CommonHelper } from 'src/utils/commonHelper';
import { useTheme } from '@mui/material/styles';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';

const TwitterAppSumoAuthorizationModal = ({
  handlTwitterOauthToken,
  handleClose,
  handleInputChange,
  appKey,
  appSecretKey,
  accessToken,
  accessSecretToken,
  isLoadingOn,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleConnectTwitter = () => {
    handlTwitterOauthToken();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography gutterBottom variant="h4">
          Authorize {CommonHelper.GetAppName()} to access your account?
        </Typography>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          backgroundColor: 'whitesmoke',
          padding: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            padding: '20px',
          }}
        >
          {isMobile ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Typography sx={{ fontSize: '14px', color: 'gray' }}>
                Watch the video to learn how to get the developer access token.
              </Typography>
              <Button
                variant="outlined"
                startIcon={<YouTubeIcon />}
                onClick={() => window.open('https://www.youtube.com/watch?v=Hpfi4e9WMzQ', '_blank')}
                color="primary"
              >
                Tutorial
              </Button>
            </Box>
          ) : (
            <>
              <Typography sx={{ fontSize: '14px', color: 'gray' }}>
                Watch the video to learn how to get the developer access token.
              </Typography>
              <Button
                variant="outlined"
                startIcon={<YouTubeIcon />}
                onClick={() => window.open('https://www.youtube.com/watch?v=Hpfi4e9WMzQ', '_blank')}
                color="primary"
              >
                Tutorial
              </Button>
            </>
          )}
        </Box>
        <CardContent
          sx={{
            p: 0,
            color: 'grey.800',
          }}
        >
          <TextField
            name="appKey"
            value={appKey}
            label="API Key"
            fullWidth
            margin="normal"
            onChange={handleInputChange}
          />
          <TextField
            name="appSecretKey"
            value={appSecretKey}
            label="API Key Secret"
            fullWidth
            margin="normal"
            onChange={handleInputChange}
          />
          <TextField
            name="accessToken"
            value={accessToken}
            label="Access Token"
            fullWidth
            margin="normal"
            onChange={handleInputChange}
          />
          <TextField
            name="accessSecretToken"
            value={accessSecretToken}
            label="Access Token Secret"
            fullWidth
            margin="normal"
            onChange={handleInputChange}
          />
        </CardContent>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={handleClose}>
          Close
        </Button>
        <LoadingButton variant="contained" loading={isLoadingOn} autoFocus onClick={handleConnectTwitter}>
          Connect
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default TwitterAppSumoAuthorizationModal;
