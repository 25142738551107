import {
  Avatar,
  Box,
  Card,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  useMediaQuery,
  CardMedia,
  Typography,
  IconButton,
  Grid,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { StyledComponent } from 'src/components/styled-components/styledComponent';
import { useRightSidebar } from 'src/layouts/right-side-bar/rightSidebarProvider';
import HttpServices from 'src/services/httpService';
import { postsModuleURL } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import DynamicHeightComponent from 'src/utils/dynamicSize.component';
import Spinner from 'src/utils/Loader/spinner';
import { useToaster } from 'src/utils/toaster/toasterContext';
import Scrollbar from '../../../components/scrollbar';
import { UserListHead } from '../../../sections/@dashboard/user';
import NoPostFound from '../noPost.component';
import Comments from '../../../assets/comment.svg';
import NoDocument from '../../../assets/no-document.svg';
import LinkedInPagePostDetailView from './linkedInPostDetailView.component';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { PostDetailsHelper } from 'src/utils/postDetailsHelper';
import { Warning as WarningIcon } from '@mui/icons-material';
import LinkedInMobileViewPosts from '../mobile-publishedPosts/linkedinMobileViewPosts';

const LinkedInPagePostGrid = ({ connectedNetworks, handleProfileClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const cardMaxWidth = useMediaQuery(theme.breakpoints.up('xl'));
  const { showToast } = useToaster();
  const { showRightSidebar } = useRightSidebar();
  const navigate = useNavigate();
  const [mobileLoader, setMobileLoader] = useState(false);
  const [isLoading, setloading] = useState(true);
  const [linkedInPageId, setLinkedInPageId] = useState('');
  const [publishedPosts, setPublishedPosts] = useState([]);
  const [connectedMedias, setConnectedMedias] = useState([]);
  const [getProfile, setProfiles] = useState([]);
  const [height, setHeight] = useState(window.innerHeight - 250);
  const [isAccountNeedsReauth, setAccountNeedsReauth] = useState(false);
  const hasIntegratationPermission = CommonHelper.CheckForPermissionAvailableForUser(
    CommonHelper.PermissionList.INTEGRATE_PERMISSION
  );

  // Table Variables
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const getTableDesk = [
    { id: 'created_time', width: '15%', label: 'Date Time', alignRight: false },
    { id: 'image_url', width: '15%', label: 'Image', alignRight: false },
    { id: 'message', width: '40%', label: 'Content', alignRight: false },
    { id: 'like_count', width: '10%', label: 'Likes', alignCenter: true },
    { id: 'comment_count', width: '10%', label: 'Comments', alignCenter: true },
    { id: 'share_count', width: '10%', label: 'Shares', alignCenter: true },
  ];
  const getTableMobile = [
    { id: 'created_time', label: 'Date Time', alignRight: false },
    { id: 'message', label: 'Content', alignRight: false },
    { id: 'like_count', label: 'Likes', alignRight: false },
    { id: 'comment_count', label: 'Comments', alignRight: false },
    { id: 'share_count', label: 'Shares', alignRight: false },
    { id: 'image_url', label: 'Image', alignRight: false },
  ];
  const TABLE_HEAD = isMobile ? getTableMobile : getTableDesk;

  useEffect(() => {
    const activeNetworks = connectedNetworks.filter((c) => c.is_active);
    let linkedId = activeNetworks && activeNetworks.length > 0 && activeNetworks[0].id;
    linkedId && setLinkedInPageId(linkedId);
    setConnectedMedias(connectedNetworks);
    if (activeNetworks && activeNetworks.length === 0) {
      setloading(false);
      setAccountNeedsReauth(true);
    }
    const handleResize = () => {
      setHeight(window.innerHeight - 300);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [connectedNetworks]);
  useEffect(() => {
    linkedInPageId && getLinkedPublishedPosts();
  }, [linkedInPageId]);
  useEffect(() => {}, [isLoading]);
  const linkedInPublishedPostSuccessCallback = (data, message) => {
    setPublishedPosts(data.posts);
    setProfiles(data);
    setloading(false);
  };
  const linkedInNextPublishedPostSuccessCallback = (data, message) => {
    let updatedPost = [...publishedPosts, ...data.posts];
    setPublishedPosts(updatedPost);
    setloading(false);
    setMobileLoader(false);
  };
  const linkedInPublishedPostFailureCallback = (message) => {
    setPublishedPosts([]);
    setProfiles([]);
    message && showToast(message, 'e');
    setloading(false);
  };
  const linkedInNextPublishedPostFailureCallback = (message) => {
    setPage(0);
    message && showToast(message, 'e');
    setloading(false);
    setMobileLoader(false);
  };
  const getLinkedPublishedPosts = (newRowsPerPage = '10') => {
    setloading(true);
    let getProps = {
      url: `${postsModuleURL.linkedInPosts}${linkedInPageId}&post_limit=${newRowsPerPage}`,
      successCallback: linkedInPublishedPostSuccessCallback,
      failureCallback: linkedInPublishedPostFailureCallback,
    };
    HttpServices.Get(getProps);
  };
  const getNextLinkedPublishedPosts = (pagecount) => {
    setloading(true);
    let getProps = {
      url: `${postsModuleURL.linkedInPosts}${linkedInPageId}&post_limit=${rowsPerPage}&start=${pagecount}`,
      successCallback: linkedInNextPublishedPostSuccessCallback,
      failureCallback: linkedInNextPublishedPostFailureCallback,
    };
    HttpServices.Get(getProps);
  };
  const getMobileViewLinkedinNextPublishedPosts = () => {
    setMobileLoader(true);
    let getProps = {
      url: `${postsModuleURL.linkedInPosts}${linkedInPageId}&post_limit=10&start=${publishedPosts?.length}`,
      successCallback: linkedInNextPublishedPostSuccessCallback,
      failureCallback: linkedInNextPublishedPostFailureCallback,
    };
    HttpServices.Get(getProps);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if ((newPage + 1) * rowsPerPage > publishedPosts.length) {
      getNextLinkedPublishedPosts(publishedPosts.length);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    if (newRowsPerPage > publishedPosts.length) {
      getLinkedPublishedPosts(newRowsPerPage);
    }
  };
  const handleOpenPost = (post) => {
    let data = {
      content: postView,
      data: post,
    };
    showRightSidebar(data);
  };
  const updatedPublishedPost = (data) => {
    setPublishedPosts(data);
  };
  const getMediaType = (media_url) => {
    if (!media_url) return NoDocument;
    let mediaFiles = CommonHelper.ProcessMediaUrl(media_url)?.media_data;
    if (!mediaFiles || mediaFiles.length === 0) return NoDocument;
    let firstMediaFile = mediaFiles[0];
    if (firstMediaFile.type === 'document') return NoDocument;
    return firstMediaFile.url;
  };
  const postView = (post) => {
    let profile = connectedMedias.find((a) => a.id === linkedInPageId);
    if (post.created_time?.includes('UTC')) {
      profile['date'] = CommonHelper.GetDateFormat(post.created_time);
    } else {
      profile['date'] = CommonHelper.GetLinkedinPreviewDateFormat(post.created_time);
    }
    let name = getProfile.name;
    let url = getProfile.url;
    let postDetails = {
      postId: post.id,
      pageId: linkedInPageId,
      profile,
      ...post,
      height,
      message: post.message,
      images: post.image_urls,
      video_url: post.video_url,
      media_type: post.media_type,
      thumbnail_url: post.thumbnail_url,
      is_liked: post.is_liked,
      name: name,
      image_url: url,
      share_count: post.share_count,
      comment_count: post.comment_count,
      like_count: post.like_count,
      created_time: post.created_time,
    };
    return (
      <LinkedInPagePostDetailView
        {...postDetails}
        publishedPosts={publishedPosts}
        updatedPublishedPost={updatedPublishedPost}
      />
    );
  };
  const handleLinkedInPage = (id) => {
    setLinkedInPageId(id);
    setRowsPerPage(10);
    setPage(0);
  };
  const navigateToIntegration = () => {
    navigate('/integration');
  };
  const getTableBodyData = () => {
    return publishedPosts.length > 0 ? (
      publishedPosts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
        const { created_time, id, image_urls, thumbnail_url, like_count, message, share_count, comment_count } = row;
        return (
          <StyledComponent.StyledTableRow
            key={id}
            onClick={() => {
              handleOpenPost(row);
            }}
          >
            <StyledComponent.StyledTableCell align="left">
              {created_time?.includes('UTC')
                ? CommonHelper.getTableDateAndTime(created_time)
                : CommonHelper.GetLinkedinDateFormat(created_time)}
            </StyledComponent.StyledTableCell>
            <StyledComponent.StyledTableCell align="left">
              {PostDetailsHelper.getPostMedias(
                image_urls.length > 0 ? getMediaType(image_urls[0]) : null || thumbnail_url
              )}
            </StyledComponent.StyledTableCell>
            <StyledComponent.StyledTableCell align="left">
              {PostDetailsHelper.GetPostContent(message, 400)}
            </StyledComponent.StyledTableCell>
            <StyledComponent.StyledTableCell align="center">
              {CommonHelper.formatNumber(like_count)}
            </StyledComponent.StyledTableCell>
            <StyledComponent.StyledTableCell align="center">
              {CommonHelper.formatNumber(comment_count)}
            </StyledComponent.StyledTableCell>
            <StyledComponent.StyledTableCell align="center">
              {CommonHelper.formatNumber(share_count)}
            </StyledComponent.StyledTableCell>
          </StyledComponent.StyledTableRow>
        );
      })
    ) : (
      <NoPostFound />
    );
  };
  const getNetworkChip = () => {
    return (
      connectedMedias &&
      connectedMedias.length > 0 &&
      connectedMedias.map((c) => {
        const isActive = c.id === linkedInPageId;
        const isProfileActive = c.is_active;
        const showReauth = !isProfileActive && hasIntegratationPermission;
        return (
          <Chip
            key={c.id}
            style={{
              borderRadius: 8,
              opacity: isActive ? 1 : 0.9,
              ...(!isProfileActive && {
                backgroundColor: theme.palette.error.lighter,
                border: `1px solid ${theme.palette.error.main}`,
              }),
              ...(isActive &&
                isProfileActive && {
                  backgroundColor: theme.palette.primary.main,
                  color: 'white',
                  fontWeight: 800,
                }),
            }}
            sx={{
              m: 0.4,
              '&:hover': {
                opacity: isProfileActive ? 0.8 : 1,
              },
              ...(!isProfileActive && {
                '& .MuiChip-deleteIcon': {
                  color: theme.palette.error.main,
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                },
              }),
            }}
            onClick={
              isProfileActive ? () => handleLinkedInPage(c.id) : showReauth ? () => handleProfileClick(c) : undefined
            }
            deleteIcon={!isProfileActive ? <WarningIcon /> : undefined}
            onDelete={showReauth ? () => handleProfileClick(c) : undefined}
            avatar={
              <Avatar
                src={c.profile_image ? c.profile_image : c.profile_name || c.id}
                alt={c.profile_name ? c.profile_name : c.id}
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  height: 40,
                  width: 40,
                  bgcolor: isActive ? 'white' : theme.palette.primary.main,
                  ...(!isProfileActive && {
                    bgcolor: theme.palette.error.main,
                    color: 'white',
                  }),
                }}
                style={{
                  color: isActive ? theme.palette.primary.main : 'white',
                }}
              />
            }
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {c.profile_name ? c.profile_name : c.id}
                {!isProfileActive && (
                  <Typography variant="caption" sx={{ ml: 1, color: theme.palette.error.dark }}>
                    (Needs Re-auth)
                  </Typography>
                )}
              </Box>
            }
          />
        );
      })
    );
  };
  const getGridView = () => {
    return (
      <DynamicHeightComponent>
        <Scrollbar>
          {isMobile ? (
            <LinkedInMobileViewPosts
              publishedPosts={publishedPosts}
              getMobileViewLinkedinNextPublishedPosts={getMobileViewLinkedinNextPublishedPosts}
              mobileLoader={mobileLoader}
            />
          ) : (
            <TableContainer
              sx={{
                maxHeight: 'calc(100vh - 290px)',
                minHeight: 'calc(100vh - 290px)',
                height: height - 60,
              }}
            >
              <Table stickyHeader size="small">
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={publishedPosts.length}
                  isShowCheckbox={false}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell colSpan={6} align="center" sx={{ width: '100%', borderBottom: 'none' }}>
                        <Spinner height={height} />
                      </TableCell>
                    </TableRow>
                  ) : (
                    getTableBodyData()
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {!isMobile && (
            <TablePagination
              component="div"
              rowsPerPageOptions={[10, 20, 30, 50]}
              count={publishedPosts.length + 1}
              rowsPerPage={rowsPerPage}
              page={page}
              labelDisplayedRows={() => ''}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Scrollbar>
      </DynamicHeightComponent>
    );
  };
  return (
    <Box>
      <Card sx={{ minHeight: 'calc(100vh - 150px)', maxHeight: 'calc(100vh - 150px)' }}>
        {connectedMedias && connectedMedias.length > 0 ? (
          <>
            <Card sx={{ mb: 2, p: 2, maxHeight: 100, minHeight: isMobile && 100, overflowY: 'auto', visibility: true }}>
              {getNetworkChip()}
            </Card>
            <Card
              sx={{
                boxShadow: isMobile && publishedPosts?.length > 0 && 'none',
                backgroundColor: isMobile && publishedPosts?.length > 0 && 'transparent',
              }}
            >
              {isLoading ? (
                <Spinner height={height} />
              ) : publishedPosts?.length === 0 ? (
                <NoPostFound
                  height={isMobile ? 'calc(100vh - 330px)' : height}
                  contentText={isAccountNeedsReauth ? 'Needs Re-authentication' : 'No Post Available'}
                />
              ) : (
                getGridView()
              )}
            </Card>
          </>
        ) : isLoading ? (
          <Spinner height={height} />
        ) : isLoading ? (
          <Spinner height={height} />
        ) : (
          <Card
            sx={{
              mb: 2,
              p: 2,
              height: height,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: 'calc(100vh - 150px)',
              maxHeight: 'calc(100vh - 150px)',
            }}
          >
            <LoadingButton
              variant="contained"
              autoFocus
              onClick={() => {
                navigateToIntegration();
              }}
            >
              Connect Social Accounts
            </LoadingButton>
          </Card>
        )}
      </Card>
    </Box>
  );
};
export default LinkedInPagePostGrid;
