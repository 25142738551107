import { Popover } from '@mui/material';
import ColorPickerComponent from 'src/components/color-picker/color-picker.component';

const ColorPopover = ({
    handleColor,
    color,
    popoverElement,
    handleCloseMenu,
    selectedPopOverData
}) => {
    return (
        <Popover
            open={Boolean(popoverElement)}
            anchorEl={popoverElement}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
                sx: { p: 1, width:'auto', '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 } },
            }}
        >
            <ColorPickerComponent handleColor={(e) => { handleColor(e, selectedPopOverData) }} color={color} />
        </Popover>
    );
};
export default ColorPopover;
