import { Box, Tab, Tabs, TextField, Stack, tabsClasses } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { capitalCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import HttpServices from 'src/services/httpService';
import { CommonHelper } from 'src/utils/commonHelper';
import { integrateModuleURL } from 'src/services/urlService';
import Facebook_Icon from '../../assets/facebook.svg';
import Instagram_Icon from '../../assets/instagram.svg';
import Youtube_Icon from '../../assets/youtube.png';
import { PostUtil } from 'src/pages/new-post-new/post-util';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import FacebookReportComponent from './facebook-report/facebook-report.component';
import InstagramReportComponent from './instagram-report/instagram-report.component';
import YoutubeReportComponent from './youtube-report/youtube-report.component';
import { useReAuth } from 'src/utils/reAuth/ReAuthContext';
import CommonReAuthenticateComponent from '../integration/commonReAuthenticate';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Extend dayjs with the plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const Report = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xl'));
  const cuurentBrandTime = CommonHelper.getBrandTimeZoneTime();
  const { checkAndTriggerReAuth } = useReAuth();

  //State Variable
  const [currentTab, setCurrentTab] = useState('Facebook Page');
  const [facebookNetworks, setFacebookNetworks] = useState([]);
  const [instagramNetworks, setInstagramNetworks] = useState([]);
  const [youtubeNetworks, setYoutubeNetworks] = useState([]);
  const [twitterNetworks, setTwitterNetworks] = useState([]);
  const [linkedInNetworks, setLinkedInNetworks] = useState([]);
  const [tiktokNetworks, setTiktokNetworks] = useState([]);
  const [gbusinessNetworks, setGBusinessNetworks] = useState([]);
  const [isNetworksLoaded, setNetworkLoaded] = useState(false);
  const [selectedPopOverAccount, setSelectedPopOverAccount] = useState();
  const [reAuthenticateOpen, setReAuthenticateOpen] = useState(false);
  const [startDate, setStartDate] = useState(() => {
    return cuurentBrandTime.subtract(30, 'days').toDate(); 
  });
  const [endDate, setEndDate] = useState(() => {
    return cuurentBrandTime.toDate();
  });
  const [viewBy, setViewBy] = useState('day');

  useEffect(() => {
    getConnectedNetworks();
  }, []);

  useEffect(() => { }, [
    facebookNetworks,
    instagramNetworks,
    youtubeNetworks,
    twitterNetworks,
    linkedInNetworks,
    tiktokNetworks,
    gbusinessNetworks,
    isNetworksLoaded,
  ]);
  const connectedNetworksSuccessCallback = (data, message) => {
    const sortByActive = (arr) =>
      [...arr].sort((a, b) => Number(b.is_active) - Number(a.is_active));

    let fNetworks = sortByActive(
      data.filter(a =>
        a.service_name.toUpperCase() === PostUtil.ServiceName.FACEBOOK &&
        a.profile_type === PostUtil.ProfileType.PAGE &&
        a.is_enabled
      )
    );


    let iNetworks = sortByActive(
      data.filter(a =>
        a.service_name.toUpperCase() === PostUtil.ServiceName.INSTAGRAM &&
        a.is_enabled
      )
    );

    let yNetworks = sortByActive(
      data.filter(a =>
        a.service_name.toUpperCase() === PostUtil.ServiceName.YOUTUBE &&
        a.is_enabled
      )
    );

    let tNetworks = sortByActive(
      data.filter(a =>
        a.service_name.toUpperCase() === PostUtil.ServiceName.TWITTER &&
        a.is_enabled
      )
    );

    let gNetworks = sortByActive(
      data.filter(a =>
        a.service_name.toUpperCase() === PostUtil.ServiceName.GBUSINESS &&
        a.is_enabled
      )
    );

    let pNetworks = sortByActive(
      data.filter(a =>
        a.service_name.toUpperCase() === PostUtil.ServiceName.PINTEREST &&
        a.is_enabled
      )
    );

    let tiNetworks = sortByActive(
      data.filter(a =>
        a.service_name.toUpperCase() === PostUtil.ServiceName.TIKTOK &&
        a.is_enabled
      )
    );

    let lNetworks = sortByActive(
      data.filter(a =>
        a.service_name.toUpperCase() === PostUtil.ServiceName.LINKEDIN &&
        a.profile_type === 'PAGE' &&
        a.is_enabled
      )
    );
    checkAndTriggerReAuth(data)
    setGBusinessNetworks(gNetworks);
    setFacebookNetworks(fNetworks);
    setInstagramNetworks(iNetworks);
    setYoutubeNetworks(yNetworks);
    setTwitterNetworks(tNetworks);
    setLinkedInNetworks(lNetworks);
    setTiktokNetworks(tiNetworks);
    setNetworkLoaded(true);
  };
  const connectedNetworkFailureCallback = (message) => {
    setFacebookNetworks([]);
    setInstagramNetworks([]);
    setYoutubeNetworks([]);
    setTwitterNetworks([]);
    setLinkedInNetworks([]);
    setTiktokNetworks([]);
    setGBusinessNetworks([]);
  };
  const getConnectedNetworks = () => {
    let getProps = {
      url: integrateModuleURL.getConnectedNetworks,
      successCallback: connectedNetworksSuccessCallback,
      failureCallback: connectedNetworkFailureCallback,
    };
    HttpServices.Get(getProps);
  };

  const handleTabs = (value) => {
    setCurrentTab(value);
  };
  const handleCloseReauthenticate = () => {
    setReAuthenticateOpen(false);
    setSelectedPopOverAccount(null);
  };
  const handleProfileClick = (profile) => {
    setSelectedPopOverAccount(profile);
    setReAuthenticateOpen(true);
  };
  
  const ACCOUNT_TABS = [
    {
      value: 'Facebook Page',
      icon: <img src={Facebook_Icon} width={28} height={20} />,
      component: <FacebookReportComponent connectedNetworks={facebookNetworks} startDate={startDate} endDate={endDate} viewBy={viewBy} handleProfileClick={handleProfileClick}/>,
    },
    {
      value: 'Instagram',
      icon: <img src={Instagram_Icon} width={28} height={20} />,
      component: <InstagramReportComponent connectedNetworks={instagramNetworks} startDate={startDate} endDate={endDate} viewBy={viewBy} handleProfileClick={handleProfileClick}/>,
    },
    {
      value: 'Youtube',
      icon: <img src={Youtube_Icon} width={35} height={35} />,
      component: <YoutubeReportComponent connectedNetworks={youtubeNetworks} startDate={startDate} endDate={endDate} viewBy={viewBy} handleProfileClick={handleProfileClick}/>,
    },
    // {
    //   value: 'LinkedIn Page',
    //   icon: <img src={LinkedIn_Icon} width={28} height={20} />,
    //   component: <>Coming soon</>,
    // },
    // {
    //   value: 'GoogleMyBusiness',
    //   icon: <img src={Gbusiness_Icon} width={28} height={20} />,
    //   component: <>Coming soon</>,
    // },
    // {
    //   value: 'TikTok',
    //   icon: <img src={Tiktok_Icon} width={28} height={20} />,
    //   component: <TikTokPagePostGrid connectedNetworks={tiktokNetworks} />,
    // }
  ];

  return (
    <>
      <Helmet>
        <title> Published Post </title>
      </Helmet>
      <Box display='flex' justifyContent='space-between'>
        <Tabs
          value={currentTab}
          variant="scrollable"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { display: 'none' },
            },
            ...(!isMobile && { gap: '0.5rem', minHeight: '37px' }),
          }}
          allowScrollButtonsMobile
          onChange={(e, value) => handleTabs(value)}
        >
          {ACCOUNT_TABS.map((tab) => {
            return (
              <Tab
                disableRipple
                key={tab.value}
                label={isMobile ? '' : capitalCase(tab.value)}
                icon={tab.icon}
                value={tab.value}
                sx={{
                  ...(!isMobile && {
                    flexDirection: 'row',
                    gap: '0.25rem',
                    padding: 0,
                    minHeight: '10px',
                    mr: '0.75rem',
                    fontWeight: 600,
                  }),
                }}
              />
            );
          })}
        </Tabs>
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
        {/* <Select
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            value={viewBy}
            onChange={(e) => setViewBy(e.target.value)}

            sx={{
              '& .MuiInputBase-root': { fontSize: '0.875rem', padding: '6px 10px' },
            }}
          >
            <MenuItem value="day">Day</MenuItem>
            <MenuItem value="week">Week</MenuItem>
            <MenuItem value="month">Month</MenuItem>
          </Select> */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              slotProps={{ textField: { size: 'small' } }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  sx={{
                    '& .MuiInputBase-root': { fontSize: '0.875rem', padding: '6px 10px' },
                    '& .MuiInputLabel-root': { fontSize: '0.875rem' },
                    minWidth: 120,
                  }}
                />
              )}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              slotProps={{ textField: { size: 'small' } }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  sx={{
                    '& .MuiInputBase-root': { fontSize: '0.875rem', padding: '6px 10px' },
                    '& .MuiInputLabel-root': { fontSize: '0.875rem' },
                    minWidth: 120,
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Stack>
      </Box>
      <Box sx={{ mb: 2 }} />
      {isNetworksLoaded &&
        ACCOUNT_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
        {reAuthenticateOpen && (
        <CommonReAuthenticateComponent
          selectedPopOverAccount={selectedPopOverAccount}
          getConnectedNetworks={getConnectedNetworks}
          onClose={handleCloseReauthenticate}
        />
      )}
    </>
  );
};

export default Report;
