import { Box, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import MaintainanceImage from "../../assets/app-image/maintainance.svg";
import moment from 'moment-timezone'; 

const StyledContent = styled('div')(({ theme }) => ({
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const MaintenanceComponent = ({ toDate }) => {
  const [timeLeft, setTimeLeft] = useState({});

  const calculateTimeLeft = () => {
   const nowUTC = moment.utc();  
    const targetDateUTC = moment.tz(toDate, "DD/MM/YYYY hh:mm A", "Asia/Kolkata").utc(); 

    const difference = targetDateUTC.diff(nowUTC); 

    if (difference <= 0) {
      window.location.reload();
    }

    const duration = moment.duration(difference);
    
    return {
      days: Math.floor(duration.asDays()),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
    };
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(interval);
  }, [toDate]); 

  return (
    <>
      <Helmet>
        <title>Maintenance</title>
      </Helmet>

      <Container>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            We are under maintenance
          </Typography>

            <Typography sx={{ color: 'text.secondary' }}>
              We will be back in {timeLeft.days} days {timeLeft.hours} hours {timeLeft.minutes} minutes {timeLeft.seconds} seconds
            </Typography>

          <Box
            component="img"
            src={MaintainanceImage}
            sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
          />
        </StyledContent>
      </Container>
    </>
  );
};

export default MaintenanceComponent;
