import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import HttpServices from 'src/services/httpService';
import { paymentModuleUrl } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import { LocalStorage } from 'src/utils/storage/local-storage';
import { useToaster } from 'src/utils/toaster/toasterContext';

const PaymentResponse = ({ paymentIntentClientSecret, paymentIntent, handleClosePaymentResponse }) => {
  const { showToast } = useToaster();
  const [seconds, setSeconds] = useState(60);
  const [retryPayment, setRetryPayment] = useState(true);
  const paymentRetrySeconds = 20000;
  let intervalId = null;
  let secondsIntervalId = null;
  useEffect(() => {
    intervalId = setInterval(() => {
      if (retryPayment) {
        setRetryPayment(false);
        checkPaymentSuccess();
      }
    }, paymentRetrySeconds);
    checkPaymentSuccess();

    secondsIntervalId = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(secondsIntervalId);
          return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
      clearInterval(secondsIntervalId);
    };
  }, []);
  const checkPaymentSuccess = () => {
    getPaymentResponse();
  };
  const paymentSuccessCallback = (data, message) => {
    if (data.status) {
      LocalStorage.setItem('token', data.token);
      CommonHelper.GetCurrentPlan();
      showToast(message, 's');
      handleClosePaymentResponse();
    } else {
      setRetryPayment(true);
    }
  };
  const paymentFailureCallback = (message) => {
    message && showToast(message, 'e');
    handleClosePaymentResponse();
  };
  const getPaymentResponse = () => {
    if (paymentIntent && paymentIntentClientSecret) {
      let url = `${paymentModuleUrl.checkPayment}${paymentIntent}&payment_intent_client_secret=${paymentIntentClientSecret}`;
      let postProps = {
        url,
        successCallback: paymentSuccessCallback,
        failureCallback: paymentFailureCallback,
      };
      HttpServices.Post(postProps);
    }
  };
  return (
    <Dialog open onClose={handleClosePaymentResponse}>
      <DialogTitle>Processing Payment</DialogTitle>
      <DialogContent>
        <Typography>Please wait for {seconds} seconds. We will notify once the payment gets completed</Typography>
      </DialogContent>
    </Dialog>
  );
};
export default PaymentResponse;
