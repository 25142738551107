import { LocalStorage } from 'src/utils/storage/local-storage';
import PlanInfoComponent from '../account/billing/plan-info.component';

const PaymentOnBoardingComponent = () => {
  // let onBoardingJson = LocalStorage.getItem('onboarding');
  // const onBoardingInfo = onBoardingJson ? JSON.parse(onBoardingJson) : null;

  // if (onBoardingInfo['payment']) {
  // } else {
  // }

  return <PlanInfoComponent />;
};

export default PaymentOnBoardingComponent;
