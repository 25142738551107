import { Dialog, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import StripePaymentComponent from "src/components/stripe/stripe-payment.component";

const PaymentDialogComponent = ({ clientSecret, amount, handleClose, currencySymbol }) => {
    return (
        <Dialog onClose={() => { handleClose(false) }} open>
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" fontWeight="bold">Payment</Typography>
                </Stack> </DialogTitle>
            <DialogContent dividers>
                <StripePaymentComponent clientSecret={clientSecret} currencySymbol={currencySymbol} amount={amount} />
            </DialogContent>
        </Dialog>
    );
}
export default PaymentDialogComponent;