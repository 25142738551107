import {
    Avatar,
    Box,
    Card, Chip,
    Typography,
    useMediaQuery,
    Button,
    Tab,
    Tabs,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState, useRef } from 'react';
import { CommonHelper } from 'src/utils/commonHelper';
import Spinner from 'src/utils/Loader/spinner';
import { useNavigate } from 'react-router-dom';
import { Warning as WarningIcon } from '@mui/icons-material';
import ReviewsGrid from '../reviews-questions/review.component';
import GBusiness_Icon from '../../assets/google-my-business-logo.svg';
import QuestionsGrid from '../reviews-questions/questions.component';


const GbusinessChat = ({ connectedNetworks,handleProfileClick }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const [value, setValue] = useState(0);
    const [height, setHeight] = useState(window.innerHeight - 250);
    const [isLoading, setLoading] = useState(true);
    const [gBusinessId, setGBusinessId] = useState('');
    const [connectedMedias, setConnectedMedias] = useState([]);
    const hasIntegratationPermission = CommonHelper.CheckForPermissionAvailableForUser(
        CommonHelper.PermissionList.INTEGRATE_PERMISSION
      );
   

    useEffect(() => {
        let gbusinessId = connectedNetworks && connectedNetworks.length > 0 && connectedNetworks[0].id;
        gbusinessId && setGBusinessId(gbusinessId);
        setConnectedMedias(connectedNetworks);
        connectedNetworks && connectedNetworks.length === 0 && setLoading(false);
    }, [connectedNetworks]);

   
    const handleFacebookPage = (id) => {
        setGBusinessId(id);
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const navigateToIntegration = () => {
        navigate('/integration');
    }

    const a11yProps = (index) => {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      };
    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ pt: 3, pb: 3 }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
      };
   
      const getNetworkChip = () => {
        return (
          connectedMedias &&
          connectedMedias.length > 0 &&
          connectedMedias.map((c) => {
            const isActive = c.id === gBusinessId;
            const isProfileActive = c.is_active;
            const showReauth = !isProfileActive && hasIntegratationPermission;
            return (
              <Chip
                key={c.id}
                style={{
                  borderRadius: 8,
                  opacity: isActive ? 1 : 0.9,
                  ...(!isProfileActive && {
                    backgroundColor: theme.palette.error.lighter,
                    border: `1px solid ${theme.palette.error.main}`
                  }),
                  ...(isActive && isProfileActive && {
                    backgroundColor: theme.palette.primary.main,
                    color: 'white',
                    fontWeight: 800
                  })
                }}
                sx={{
                  m: 0.4,
                  '&:hover': {
                    opacity: isProfileActive ? 0.8 : 1
                  },
                  ...(!isProfileActive && {
                    '& .MuiChip-deleteIcon': {
                      color: theme.palette.error.main,
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: 'transparent'
                      }
                    }
                  })
                }}
                onClick={isProfileActive ?
                  () => handleFacebookPage(c.id) :
                  (showReauth ? () => handleProfileClick(c) : undefined)
                }
                deleteIcon={!isProfileActive ? <WarningIcon /> : undefined}
                onDelete={showReauth ? () => handleProfileClick(c) : undefined}
                avatar={<Avatar
                  src={c.profile_image ? c.profile_image : c.profile_name || c.id}
                  alt={c.profile_name ? c.profile_name : c.id}
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    height: 40,
                    width: 40,
                    bgcolor: isActive ? 'white' : theme.palette.primary.main,
                    ...(!isProfileActive && {
                      bgcolor: theme.palette.error.main,
                      color: 'white'
                    })
                  }}
                  style={{
                    color: isActive ? theme.palette.primary.main : 'white',
                  }}
                />}
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {c.profile_name ? c.profile_name : c.id}
                    {!isProfileActive && (
                      <Typography variant="caption" sx={{ ml: 1, color: theme.palette.error.dark }}>
                        (Needs Re-auth)
                      </Typography>
                    )}
                  </Box>
                }
              />
            );
          })
        );
      };

    const isMobileIcon = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Box>
            {connectedMedias && connectedMedias.length > 0 ?
                <>
                    <Card sx={{mb: 2, p: 2, maxHeight: 100, minHeight: isMobile && 100, overflowY: 'auto', visibility: true }} >
                        {getNetworkChip()}
                    </Card>
                    <Card sx={{maxHeight: 'calc(100vh - 240px)',minHeight: 'calc(100vh - 240px)'}}>
                        {/* {isLoading ?
                            <Spinner />
                            :
                            reviews?.length === 0 ?
                                <Typography>No Reviews Found</Typography>
                                : getGridView()} */}
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    variant="scrollable"
                                    scrollButtons
                                    allowScrollButtonsMobile
                                    aria-label="scrollable force tabs example"
                                    sx={{ borderBottom: 1, borderColor: 'divider' }}
                                >
                                    <Tab
                                        icon={<img src={GBusiness_Icon} width={28} height={20} />}
                                        iconPosition="start"
                                        label={isMobileIcon ? '' : 'Review'}
                                        {...a11yProps(0)}
                                    />
                                    <Tab
                                        icon={<img src={GBusiness_Icon} width={28} height={20} />}
                                        iconPosition="start"
                                        label={isMobileIcon ? '' : 'Questions'}
                                        {...a11yProps(1)}
                                    />

                                </Tabs>
                                <TabPanel value={value} index={0}>
                                   <ReviewsGrid gBusinessId={gBusinessId}/>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                  <QuestionsGrid  gBusinessId={gBusinessId}/>
                                </TabPanel>
                        </Box>
                    </Card>
                </>
                :
                isLoading ?
                    <Spinner />
                    :
                    <Card sx={{ mb: 2, p: 2, display: 'flex',maxHeight: 'calc(100vh - 150px)',minHeight: 'calc(100vh - 150px)', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                            variant="contained"
                            autoFocus
                            onClick={navigateToIntegration}
                        >
                            Connect GoogleMyBusiness Accounts
                        </Button>
                    </Card>}
        </Box>
    )
}
export default GbusinessChat;
